'use strict';

var util = require('../../util');
var shipping = require('./shipping');
var formPrepare = require('./formPrepare');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    var $form = $('.address');
    // select address from list
    $('select[name$="_addressList"]', $form).on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $form);

        if ($('#shipping-method-list').length) {
            shipping.updateShippingMethodList();
        } else {
            // avoids duplicate call because updateShippingMethodList has a validateForm
            formPrepare.validateForm();
        }
    });
    $('#dwfrm_singleshipping_shippingAddress input[name$="_firstName"], #dwfrm_singleshipping_shippingAddress input[name$="_lastName"], #dwfrm_singleshipping_shippingAddress input[name$="_address1"], #dwfrm_singleshipping_shippingAddress input[name$="_address2"], #dwfrm_singleshipping_shippingAddress input[name$="_city"], #dwfrm_singleshipping_shippingAddress input[name$="_countryCode"], #dwfrm_singleshipping_shippingAddress input[name$="_phone"], #dwfrm_singleshipping_shippingAddress input[name$="_postalCode"]').on('keyup', function () {
        var selected = $('#dwfrm_singleshipping_shippingAddress select[name$="_addressList"]').children(':selected').first();
        if (selected.length > 0) {
            var fields = ['firstName','lastName','address1','address2','city','countryCode','phone','postalCode','stateCode'];
            for (var i=0,j=fields.length; i<j; i++) {
                if ($(this)[0].name.indexOf(fields[i]) > -1) {
                    if ($(this)[0].value != selected.data('address')[fields[i]]) {
                        $('#dwfrm_singleshipping_shippingAddress select[name$="_addressList"]').children(':selected').first().removeAttr('selected');
                    }
                }
            }
        }
    });
    $('#dwfrm_singleshipping_shippingAddress select[name$="_country"], #dwfrm_singleshipping_shippingAddress select[name$="_stateCA"], #dwfrm_singleshipping_shippingAddress select[name$="_state"]').on('change', function () {
        var selected = $('#dwfrm_singleshipping_shippingAddress select[name$="_addressList"]').children(':selected').first();
        if (selected.length > 0) {
            var fields = ['firstName','lastName','address1','address2','city','countryCode','phone','postalCode','stateCode'];
            for (var i=0,j=fields.length; i<j; i++) {
                if ($(this)[0].name.indexOf('country') > -1 && selected[0].value != '' && fields[i].indexOf('country') > -1) {
                    if ($(this)[0].value != selected.data('address')[fields[i]]) {
                        $('#dwfrm_singleshipping_shippingAddress select[name$="_addressList"]').children(':selected').first().removeAttr('selected');
                    }
                }
                if ($(this)[0].name.indexOf('state') > -1 && selected[0].value != '' && fields[i].indexOf('state') > -1) {
                    if ($(this)[0].value != selected.data('address')[fields[i]]) {
                        $('#dwfrm_singleshipping_shippingAddress select[name$="_addressList"]').children(':selected').first().removeAttr('selected');
                    }
                }
            }
        }
    });
};
