'use strict';
var bopisLocator = require('../bopis-locator'),
    dialog = require('../dialog');
var validator = require('../validator');
var tealiumGlobal = require('../tealium/pages/common');
var quickLook = require('../quickview');

var handleQuickLookClose = function () {
    if (window.pageContext && window.pageContext.ns && window.pageContext.ns === 'cart' && window.storeChangedFromQL) {
        window.location.href = window.Urls.cartShow;
    }
    window.storeChangedFromQL = false;
}

var openQuickLookBack = function (isSetStoreOpenedFromQL) {
    if (isSetStoreOpenedFromQL && $('.pdp-ql-main-container').length > 0) {
        $('body').addClass('updating-cart');
        var quickLookURL = $('.pdp-ql-main-container').find('.details-view').attr('href');
        quickLook.show({
            url: quickLookURL,
            source: 'quickview',
            callback: function () {
                if (window.pageContext.ns === 'product') {
                    $('.quickview .ui-dialog-titlebar-close').on('click', function () {
                        if (!(window.SessionAttributes && window.SessionAttributes.BOPIS_SELECTED_STORE_ID)) {
                            $('.pdp-main-container').find('.bopis-eligible-for-pickup .bopis-shipping-option').trigger('click');
                        } else {
                            $('.pdp-main-container').find('.bopis-pick-from-store-option').trigger('click');
                        }
                    });
                }
                $('body').removeClass('updating-cart');
                $('.quickview .ui-dialog-titlebar-close').off('click',handleQuickLookClose).on('click',handleQuickLookClose);
            }
        });
    }
    if (window.pageContext.ns === 'product' && !(window.SessionAttributes && window.SessionAttributes.BOPIS_SELECTED_STORE_ID)) {
        $('.bopis-shipping-option').prop('checked', true).focus();
    }
}

var bopisModal = function(isOpenedFromQL){
    dialog.close();
    $('#bopisAlertModal').dialog({
        modal: true,
        dialogClass: 'alertmodal',
        clickOutside: true,
        width: 290,
        title: 'Wait! Are you sure?',
        draggable: false,
        position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'flipfit'
        },
        open: function(){
            $('.alertmodal').focus();
            $(this).data('isOpenedFromQL', isOpenedFromQL);
            $(this).data('reopenQuickLock', true);
            $('.ui-widget-overlay').hide().fadeIn().on('click', function () {
                $('#bopisAlertModal').dialog('close');
            });
            $('body').removeClass('updating-cart');
        },
        close: function () {
            if ($(this).data('reopenQuickLock') && $(this).data('isOpenedFromQL')) {
                openQuickLookBack(isOpenedFromQL);
            }
        }
    });

    $('.change-store-action-cancel, .change-store-action').on('click', function () {
        if ($(this).hasClass('change-store-action')) {
            $('#bopisAlertModal').data('reopenQuickLock', false);
        }
        $('#bopisAlertModal').dialog('close');
    });

}


exports.init = function () {
    //set-store modal open
    var locator = window.Scripts.storeLocator,
        options = locator.vars;

    window.storeChangedFromQL = false;

    $('body').on('click keypress', '.bopis-set-store', function (e, skipAnalyticsUpdate) {
        var productSku = $(this).data().productSku || $(this).data().productsku;

        if (e.type === 'keypress' && !(e.keyCode === 13 || e.keyCode == 32)) {
            return;
        }
        $('body').addClass('updating-cart');
        var analyticsElementId = $(this).data('cm-tag-id');

        if (!skipAnalyticsUpdate && analyticsElementId) {
            tealiumGlobal.bopisSetStorePopUp(analyticsElementId);
            window.sessionStorage.setStoreButtonLocation = analyticsElementId;
        }

        var openedFromQL = $(this).parents('.pdp-ql-main-container').length > 0;
        if ($(this).parents('#bopisAlertModal').length > 0) {
            openedFromQL = $('#bopisAlertModal').data('isOpenedFromQL');
        }

        if (window.pageContext.ns === 'cart' && $(this).attr('data-alert-modal') === 'true' && 'SessionAttributes' in window && window.SessionAttributes.BOPIS_SELECTED_STORE_NAME) {
            bopisModal(openedFromQL);

            var newProductSku = $(this).closest('.cart-row').find('.sku .value').html();

            if (newProductSku) {
                $('#bopisAlertModal .bopis-set-store').data('productSku', newProductSku);
            } else {
                $('#bopisAlertModal .bopis-set-store').removeData('productSku');
            }
            return;
        }

        var $container;
        if (!$('#BopisLocatorDialog').length) {
            $container = $('<div/>').attr('id', 'BopisLocatorDialog').appendTo(document.body);
        } else {
            $container = $('#BopisLocatorDialog');
        }
        dialog.open({
            target: $container,
            url: $(this).attr('data-href'),
            options: {
                dialogClass: 'bopisDialog',
                clickOutside: true,
                width:420,
                title: Resources.BOPIS_INSTORE_HEAD,
                draggable: false,
                open: function () {
                    validator.initBopisForm();
                    $('body').removeClass('updating-cart');
                    $('.bopisDialog').focus();
                    $('.ui-dialog-title').attr('role', 'heading');
                    $('.ui-dialog-title').attr('aria-level', '1');
                    bopisLocator.init(options.zoomradius, options.bopisurl, options.setstoreURL, openedFromQL, productSku);
                    $('html').addClass('dialog-modal-opened');
                    $('.ui-widget-overlay').hide().fadeIn().on('click', function() {
                        $('.bopisDialog .ui-dialog-titlebar-close').trigger('click');
                    });
                },
                close: function() {
                    $('html').removeClass('dialog-modal-opened');
                    openQuickLookBack(openedFromQL);
                }
            }
        });
        return false;
    });
};
