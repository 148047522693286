'use strict';
module.exports = function () {
    var activeTiles = document.getElementsByClassName('ds-visual-navigation-active');
     
    for (var i = 0; i < activeTiles.length; i++) {
        var activeTile = activeTiles[i];
        var activeOffset = activeTile.offsetLeft;
        var activeTileStyle = window.getComputedStyle(activeTile);
        var marginLeft = parseInt(activeTileStyle.marginLeft, 10);
        activeTile.parentElement.scrollLeft = activeOffset - marginLeft;
        $(activeTile).closest('.ds-loading-skeleton').removeClass('ds-loading-skeleton');
    }
}
