'use strict'
var productTile = require('../product-tile');
var initShopTheModule = function () {
    var shopTheModules = document.getElementsByClassName('ds-shop-the-module');
    for (var i = 0; i < shopTheModules.length; i++) {
        var shopTheModule = shopTheModules[i];
        var shopTheModuleProductTiles = shopTheModule.getElementsByClassName('shop-the-module-slide');
        var partsToSync = ['.product-badge', '.product-pricing', '.product-promo'];
        productTile.syncProductTileParts(shopTheModuleProductTiles, partsToSync);
    }
};

module.exports = initShopTheModule
