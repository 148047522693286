'use strict';

var analyticsHelper = {
    sendSatellite: function satelliteTrackHelper(trackEvent) {
        if (window._satellite && window._satellite.track) {
            window._satellite.track(trackEvent);
        } else {
            window.setTimeout(function() {
                satelliteTrackHelper(trackEvent);
            }, 100);
        }
    }
};

module.exports = analyticsHelper;
