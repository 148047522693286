'use strict';

var imagesLoaded = require('imagesloaded'),
    quickview = require('./quickview'),
    addToCart = require('./pages/product/addToCart'),
    util = require('./util'),
    modal = require('./designSystem/modal/modal');

function qvinit($thisObj) {
    var $qvButton = $thisObj.find('#quickviewbutton'),
        $link = $thisObj.find('.product-tile-link');

    if ($qvButton.length === 0) {
        $qvButton = $('<a id="quickviewbutton" class="quickview button">' + Resources.QUICK_VIEW + '</a>');
    }

    $qvButton.attr({
        'href': $link.attr('href'),
        'title': $link.attr('title'),
        'data-name': $link.data('name'),
        'data-pid': $link.data('pid')
    });
    $thisObj.append($qvButton);

    //hide the quicklook option for gift card products
    $thisObj.find('a#quickviewbutton[data-name*="Gift Card"]').attr('style', 'display: none !important');

    $qvButton.off('click').on('click', function (e) {
        e.preventDefault();
        quickview.show({
            url: $(this).attr('href'),
            source: 'quickview',
            callback: function () {
                modal.setupHandlers(quickview.$container[0]);
            }
        });

        if (SitePreferences.ELEMENT_TAGS_IN_GRID && 'cmCreateElementTag' in window) {
            /* eslint-disable */
            cmCreateElementTag(window.digitalData.page.pageInfo.pageID, 'Quicklook', '-_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_-' + $(this).data('pid') + '-_-' + $(this).data('name'));
            /* eslint-enable */
        }
    });
}

function initQuickViewButtons() {
    if (!$('.qv-off').length) {
        $('.tiles-container .product-image').each(function () {
            var $self = $(this);
            $(this).on('mouseenter', function () {
                qvinit($self);
            });
            $(this).find('.product-tile-link').on('focus', function () {
                $self.addClass('focused')
                qvinit($self);
            });
        });
    }
}

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

function syncRowItemHeights($tileSet) {
    var $tiles = window.matchMedia('(max-width: 479px)').matches ? $tileSet.filter(':not(.grid-slot)') : $tileSet;
    var columnCount = getColumnCount();
    var totalItems = $tiles.length;
    if (totalItems > 1) {
        var start = 0,
            end = columnCount;
        for (var i = 0; i <= totalItems; i += columnCount) {
            var $subset = $tiles.slice(start, end);

            // revert additional product images layout to get image height
            $subset.find('.zone_product-image_main img').css('position', 'relative');

            $subset.removeAttr('style');
            $subset.find('.product-image, .product-badge, .product-badging, .product-cont, .product-promo, .product-review, .product-image .product-tile-link, .product-availability').removeAttr('style');
            $subset.find('.product-image').syncHeight();
            $subset.find('.product-image .product-tile-link').syncHeight();
            $subset.find('.product-badge').syncHeight();
            $subset.find('.product-badging').syncHeight();
            $subset.find('.product-cont').syncHeight();
            $subset.find('.product-promo').syncHeight();
            $subset.find('.product-review').syncHeight();
            $subset.find('.product-availability').syncHeight();
            $subset.syncHeight();
            start = end;
            end = (end + columnCount) > totalItems ? totalItems : end + columnCount;

            // restore additional product images layout for animations
            if ($subset.find('.zone_product-image_alts img').length > 1) $subset.find('.zone_product-image_main img').css('position', 'absolute');
        }
    }
}

function getColumnCount() {
    if (window.matchMedia('(max-width: 1024px)').matches) {
        if (window.matchMedia('(max-width: 479px)').matches) {
            return 2;
        } else {
            return 3;
        }
    } else {
        return 4;
    }
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    addToCart();
    gridViewToggle();
    initRatingsUI();
    if (!util.isMobileDevice() && SitePreferences.ENABLE_QUICKLOOK_IN_GRID) {
        initQuickViewButtons();
    }

    $('.swatch-list').on('mouseleave', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $thumb.data('current');

        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).children('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
    $('.tiles-container').each(function () {
        setTwoImagesHeight($(this));
        syncRowItemHeights($(this).find('.grid-tile'));
    });

    $(window).smartresize(function () {
        $('.tiles-container').each(function () {
            syncRowItemHeights($(this).find('.grid-tile'));
        });
    });
    setCardControls();

    // Set height of position absolute images to support the syncHeight function.
    // Can be removed if we update grid strucutre to work naturally with flex or CSS grid.
    function setTwoImagesHeight($tileSet) {
        var $disolveAnimationTiles = $tileSet.find('.tile-two-images');

        $disolveAnimationTiles.each(function (i, tile) {
            var $this = $(this);
            var $tileImageWrappers = $this.find('.tile-image-wrapper');
            var $productImages = $(tile).find('.tile-image-wrapper img');

            // This should always be 2 unless something is wrong with the producttile.isml
            if ($productImages.length === 2) {
                $tileImageWrappers.height(Math.max(
                    $($productImages[0]).height(),
                    $($productImages[1]).height())
                );
            }
        });
    }
}

// BEGIN ADDITIONAL PRODUCT IMAGE CONTROLS
// =======================================
function setCardControls() {
    $('.product-tile').each(function () {

        var $tile = $(this),
            $images = $tile.find('.tile-image-wrapper'),
            imageCount = $images.length,
            $frontFace = $tile.find('.front.face'),
            $controls = $tile.find('.zone_product-image_controls'),
            $next = $controls.find('.next'),
            $prev = $controls.find('.prev'),
            currentImage = 0;

        if (imageCount > 2) {
            // Move to HTML
            $prev.hide();

            var handleChevronVisibility = function () {
                if (currentImage + 1 === imageCount) {
                    $prev.show();
                    $next.hide();
                    $prev.focus();
                } else if (currentImage === 0) {
                    $prev.hide();
                    $next.show();
                    $next.focus();
                } else {
                    $prev.show();
                    $next.show();
                }
            };

            var setScrollPos = function () {
                var scrollLeftPosition = $($images[currentImage]).position().left;
                $frontFace.animate({
                    scrollLeft: '+=' + scrollLeftPosition
                }, 400);
                handleChevronVisibility();
            };

            $prev.off('click.tilePrevArrow').on('click.tilePrevArrow', function () {
                currentImage--;
                setScrollPos();
            });

            $next.off('click.tileNextArrow').on('click.tileNextArrow', function () {
                currentImage++;
                setScrollPos();
            });

            $tile.off('mouseleave.tileMouseLeave').on('mouseleave.tileMouseLeave', function () {
                if (currentImage !== 0) {
                    currentImage = 0;
                    setScrollPos();
                }
            });

            $tile.off('focusin.tileFocusIn').on('focusin.tileFocusIn', function () {
                $controls.addClass('show');
            });

            $tile.off('focusout.tileFocusOut').on('focusout.tileFocusOut', function (e) {
                // This event fires on any focusout within child elements as well
                // If the current active element is still within the tile, don't hide the controls.

                if ($(e.relatedTarget).closest(this).length > 0) {
                    return;
                }
                $controls.removeClass('show');
            });
        }
    });
}
// ====================================
// END ADDITIONS PRODUCT IMAGE CONTROLS

function initRatingsUI() {
    $('.product-tile').each(function () {
        // get the average rating
        var $inlineRatingsUI = $(this).find('.rating-stars');
        if ($inlineRatingsUI.length) {
            var averageRating = $inlineRatingsUI.data('star-average').toString();
            // break apart the remainder
            var ratingParts = averageRating.split('.'),
                fullStars = parseInt(ratingParts[0], 10),
                partialStar = parseInt(ratingParts[1], 10);
            $inlineRatingsUI.find('.rating-star').each(function (index) {
                if (fullStars >= index + 1) {
                    $(this).attr('class', 'rating-star full-star');
                } else if (fullStars + 1 === index + 1 && partialStar > 0) {
                    $(this).attr('class', 'rating-star partial-star');
                    var partFill = 'linear-gradient(to right, rgb(0,86,153) ' + (partialStar * 10) + '%, rgb(242,242,242) ' + (partialStar * 10) + '%)';
                    $(this).css('background', partFill);
                } else {
                    $(this).attr('class', 'rating-star empty-star');
                }
            });
        }
    });
}

module.exports = {
    init: function () {
        var $tiles = $('.tiles-container .product-tile');
        if ($tiles.length === 0) { return; }
        imagesLoaded('.tiles-container').on('always', function () {
            $tiles.each(function (idx) {
                $(this).data('idx', idx);
            });
            initializeEvents();
        });

        // adds class that enables snap scrolling for multiple images
        // on iphone sometimes the alt image would be snapped to as the primary image
        var $multipleImageFrontFace = $('.tile-three-plus-images .front.face, .tile-two-images .front.face');
        $multipleImageFrontFace.one('touchstart mousedown', function () {
            $(this).addClass('scrolled');
        });
    },

    syncProductTileParts: function (tilesToSync, partsToSync) {
        if (!tilesToSync || !partsToSync) { return; }

        partsToSync.forEach(function (partToSync) {
            var similarParts = Array.prototype.map.call(tilesToSync, function (tile) {
                return tile.querySelector(partToSync);
            });

            if (similarParts.length) {
                var maxHeight = Math.max.apply(null, similarParts.map(function (similarPart) {
                    return similarPart ? similarPart.clientHeight : 0
                }));
                similarParts.forEach(function (similarPart) {
                    if (similarPart) {
                        similarPart.style.height = maxHeight + 'px';
                    }
                });
            }
        });
    },

    syncRowItemHeights: syncRowItemHeights
};
