/*jslint browser:true */
/*global document,google,console*/
'use strict';

var loyalty = require('../../loyalty'), dialog = require('../../dialog'), validator = require('../../validator'), account = require('../../pages/account'), pwHadFocus = false;
var util = require('../../util');
var setupTopBanner = require('../../setupTopBanner');
var orderconfirmation = {
    init: function () {
        loyalty.init();
        passHideShow();
        validConfirmEmail();
        validatePassword();
        switchClassOnPasswordRules();
        userExistOnLoad();
        emailConfirmOnload();
        initAccordion();
        maskBirthdayInit();
        maskPhoneInit();
        account.initUserExistOnBlur();
        initializeEvents();
        scrollToOrderConfirmation();
    }
}
/**
* @private
* @function
* @description Show/Hide the password for login
*/
function passHideShow() {
    $('.hide-show').show();
    $('.hide-show span').addClass('show')
    $('.hide-show').click(function () {
        if ($(this).find('span').hasClass('show')) {
            $(this).attr('aria-label', 'Hide Password');
            $(this).find('span').text('Hide');
            $(this).find('span').removeClass('show');
            $('input[name$="_login_password"]').attr('type', 'text').focus();
        } else {
            $(this).find('span').text('Show');
            $(this).attr('aria-label', 'Show Password');
            $(this).find('span').addClass('show');
            $('input[name$="_login_password"]').attr('type', 'password').focus();
        }
    });
    $('form button[type="submit"]').on('click', function () {
        $('.hide-show span').text('Show').addClass('show');
        $('.hide-show').attr('aria-label', 'Show Password');
        $('.hide-show').parent().find('input[name$="_login_password"]').attr('type', 'password');
    });
}

function setMapObj(lat, long, zoom, $ele) {
    if ($ele == undefined){return;}
    var $map_id = $ele;
    var hoverOffset = 80000; 
    var icon = {
        url: window.Scripts.storeLocator.vars.smallmarkerurl, // url
        size: new google.maps.Size(20, 24),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(15, 34)
    };
    if (lat && long) {
        lat = parseFloat(lat,10);
        long = parseFloat(long,10);
        if (document.getElementById($map_id)) {
            var map = new google.maps.Map(document.getElementById($map_id), {
                center: {lat: lat, lng: long},
                zoom: zoom,
                fullscreenControl: false,
                gestureHandling: 'none',
                zoomControl: false,
                styles: [{'featureType':'landscape','stylers':[{'hue':'#FFBB00'},{'saturation':43.400000000000006},{'lightness':37.599999999999994},{'gamma':1}]},{'featureType':'road.highway','stylers':[{'hue':'#FFC200'},{'saturation':-61.8},{'lightness':45.599999999999994},{'gamma':1}]},{'featureType':'road.arterial','stylers':[{'hue':'#FF0300'},{'saturation':-100},{'lightness':51.19999999999999},{'gamma':1}]},{'featureType':'road.local','stylers':[{'hue':'#FF0300'},{'saturation':-100},{'lightness':52},{'gamma':1}]},{'featureType':'water','stylers':[{'hue':'#0078FF'},{'saturation':-13.200000000000003},{'lightness':2.4000000000000057},{'gamma':1}]},{'featureType':'poi','stylers':[{'hue':'#00FF6A'},{'saturation':-1.0989010989011234},{'lightness':11.200000000000017},{'gamma':1}]}]
            });
            var marker = new google.maps.Marker({
                position: {lat: lat, lng: long},
                map: map,
                icon: icon
            });	
            marker.setZIndex(hoverOffset);
            return map;
        }
    }
}

/**
 * @private
 * @function
 * @description validate email confirm is a match to email
 */
function validConfirmEmail() {
    var submitButton = $('#RegistrationForm').find('button[type="submit"][name$="_confirm"]'),
        formRegister = $('#RegistrationForm');

    if (!formRegister.hasClass('loyalty-registration-confirmation')) {
        $('<span class="foundMatch"></span>').appendTo($('#dwfrm_profile_customer_email').closest('.field-wrapper'));
    }
    $('input[name$="_profile_customer_emailconfirm"]').on('keyup', function () {
        var $misMatch = $('.misMatch');
        if ($misMatch.length > 0) {
            $misMatch.empty().remove();
        }
    });
    $('input[name$="_profile_customer_emailconfirm"]').blur(function () {
        var $misMatch = $('.misMatch');
        if ($misMatch.length > 0) {
            $misMatch.remove();
        }
        if ($('input[name$="_profile_customer_emailconfirm"]').val() && $('input[name$="_profile_customer_emailconfirm"]').val() != $('input[name$="_profile_customer_email"]').val() && ($('.error-message').length != 1)) {
            $('input[name$="_profile_customer_emailconfirm"]').addClass('error');
            var errorstring = 'Your email addresses don\'t match. Please try again.';
            var errorMsg = $('#RegistrationForm #dwfrm_profile_customer_emailconfirm').closest('.field-wrapper')

            $('input[name$="_profile_customer_emailconfirm"]').attr('aria-describedby', 'dwfrm_profile_customer_emailconfirm-error');
            var ariaDescribed = $('input[name$="_profile_customer_emailconfirm"]').attr('aria-describedby').split(' ')[0];
            $('input[name$="_profile_customer_emailconfirm"]').attr('aria-describedby', ariaDescribed + ' emailconfirm-mismatch');
            $('input[name$="_profile_customer_emailconfirm"]').attr('aria-invalid', 'true');
            $('<span id="emailconfirm-mismatch" class="misMatch" role="alert">' + errorstring + '</span>').appendTo(errorMsg);
        } else {
            $('.misMatch').css('display', 'none');
            $('input[name$="_profile_customer_emailconfirm"]').attr('aria-invalid', 'false');
        }
    });
    submitButton.on('click', function () {
        $('#RegistrationForm').valid();
        $.ajax({
            type: 'POST',
            url: Urls.userExists,
            data: {email: $('input[name$="_profile_customer_email"]').val()},
            success: function (data) {
                var errorstring = '';
                if (data.success) {
                    errorstring = data.errorMessage;
                    $('.foundMatch').html(errorstring).show().attr('tabindex', '-1').focus();
                    return false;
                } else {
                    $('.foundMatch').hide();
                    var emailConfirmInput = $('input[name$="_profile_customer_emailconfirm"]');
                    if (formRegister.find('input.error').length > 0) {
                        formRegister.find('input.error').first().focus();
                        return false;
                    }

                    if (emailConfirmInput.val() != $('input[name$="_profile_customer_email"]').val() && ($('.error-message').length != 1)) {
                        emailConfirmInput.addClass('error');
                        errorstring = 'Your email addresses don\'t match.';
                        var errorMsg = $('#RegistrationForm #dwfrm_profile_customer_emailconfirm').closest('.field-wrapper')
                        var $misMatch = $('.misMatch');
                        if ($misMatch.length > 0) {
                            $misMatch.empty().remove();
                        }
                        emailConfirmInput.attr('aria-describedby', 'dwfrm_profile_customer_emailconfirm-error');
                        var ariaDescribed = $('input[name$="_profile_customer_emailconfirm"]').attr('aria-describedby').split(' ')[0];
                        emailConfirmInput.attr('aria-describedby', ariaDescribed + ' emailconfirm-mismatch');
                        emailConfirmInput.attr('aria-invalid', 'true').focus();
                        $('<span id="emailconfirm-mismatch" class="misMatch" role="alert">' + errorstring + '</span>').appendTo(errorMsg);
                        return false;
                    }
                }
                if (formRegister.find('#confirm-action-input').length === 0) {
                    var input = $('<input id="confirm-action-input">').attr('type', 'hidden').attr('name', submitButton.attr('name')).val('Create an Account');
                    formRegister.append(input);
                }
                formRegister.submit();
            }
        });
        return false;
    });
}
/**
 * @private
 * @function
 * @description validate email to specs in BBWS-672
 */
function validatePassword() {
    var RegistrationForm = $('#RegistrationForm');
    var submitButton = $('#RegistrationForm').find('button[type="submit"][name$="_confirm"]');
    var eightToFifty = /^(?=.{8,50})/;
    var numerical = /^(?=.*[0-9])/;
    var capital = /^(?=.*[A-Z])/;
    var lowercase = /^(?=.*[a-z])/;
    var noSpaces = /^(?!.*\s)/;
    $('input#dwfrm_profile_login_password, input#dwfrm_resetpassword_password').on('input', function () {
        /*^(?=.{8,50})(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?!.*\s)*/
        var $pwreq = $('#pwreq-error');
        if ($pwreq.length > 0) {
            $pwreq.remove();
        }
        var inputText = $(this).val();
        var errorMessage = '';
        if ($('#password-requirements').css('display') == 'none') {
            $('#password-requirements').show();
        }
        if (inputText.match(eightToFifty)) {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_LENGTH + ' met.';
            $('.rule8to50char').removeClass('pw-error');
            $('.rule8to50char').addClass('success');
        } else {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_LENGTH + ' not met.';
            $('.rule8to50char').removeClass('success');
            if (pwHadFocus == true) {
                $('.rule8to50char').addClass('pw-error');
            }
        }

        if (inputText.match(numerical)) {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_NUMBER + ' met.';
            $('.rule1number').removeClass('pw-error');
            $('.rule1number').addClass('success');
        } else {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_NUMBER + ' not met.';
            $('.rule1number').removeClass('success');
            if (pwHadFocus == true) {
                $('.rule1number').addClass('pw-error');
            }
        }

        if (inputText.match(capital)) {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_UPPERCASE + ' met.';
            $('.rule1uppercase').removeClass('pw-error');
            $('.rule1uppercase').addClass('success');
        } else {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_UPPERCASE + ' not met.';
            $('.rule1uppercase').removeClass('success');
            if (pwHadFocus == true) {
                $('.rule1uppercase').addClass('pw-error');
            }
        }

        if (inputText.match(lowercase)) {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_LOWERCASE + ' met.';
            $('.rule1lowercase').removeClass('pw-error');
            $('.rule1lowercase').addClass('success');
        } else {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_LOWERCASE + ' not met.';
            $('.rule1lowercase').removeClass('success');
            if (pwHadFocus == true) {
                $('.rule1lowercase').addClass('pw-error');
            }
        }

        if (inputText.match(noSpaces)) {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_SPACE + ' met.';
            $('.ruleNoSpaces').removeClass('pw-error');
            $('.ruleNoSpaces').addClass('success');
        } else {
            errorMessage = errorMessage + ' ' + Resources.VALIDATE_PASSWORD_SPACE + ' not met.';
            $('.ruleNoSpaces').removeClass('success');
            if (pwHadFocus == true) {
                $('.ruleNoSpaces').addClass('pw-error');
            }
        }

        if (inputText.match(eightToFifty) &&
            inputText.match(numerical) &&
            inputText.match(capital) &&
            inputText.match(lowercase) &&
            inputText.match(noSpaces)) {
            submitButton.removeAttr('data-disallow');
            $(this).removeClass('pw-error');
            $(this).siblings('.pwreq-error').remove();
        } else {
            submitButton.attr('data-disallow', 'true');
            if (!$(this).hasClass('pw-error')) {
                $(this).addClass('pw-error');
            }
        }
        if (inputText.length >= 1) {
            $('#password-requirments-aria').text(errorMessage);
            if (RegistrationForm.find('#password-requirements').attr('aria-hidden') !== 'true') {
                RegistrationForm.find('#password-requirements').attr('aria-hidden', 'true');
                RegistrationForm.find('#password-requirements').find('span').attr('aria-hidden', 'true');
            }
        }
    });
    submitButton.on('click', function (e) {
        $('#RegistrationForm').valid();
        var $pwReqError = $('.pwreq-error');
        var errorMsgWrapper = $('[id$="_login_password"]').closest('.field-wrapper');
        var isEmpty = $('input[id$="_login_password"]').val() === '';
        if (isEmpty) {
            return;
        }
        if ($(this).attr('data-disallow') === 'true') {
            e.preventDefault();
            if ($pwReqError.length > 0) {
                $pwReqError.empty().remove();
            }
            $('<span class="pwreq-error" id="pwreq-error">' + Resources.VALIDATE_REQPASSWORD + '</span>').appendTo(errorMsgWrapper);
            $('[id$="_login_password"]').addClass('pw-error').focus();
        } else {
            if ($pwReqError.length > 0) {
                $pwReqError.empty().remove();
            }
        }
    });
    RegistrationForm.on('submit', function (e) {
        var authenticated = window.User.authenticated;
        var $pwReqError = $('.pwreq-error');
        var inputText = $(this).find('input[id$="_login_password"]').val();
        var errorMsgWrapper = $('[id$="_login_password"]').closest('.field-wrapper');
        if (inputText.match(eightToFifty) &&
            inputText.match(numerical) &&
            inputText.match(capital) &&
            inputText.match(lowercase) &&
            inputText.match(noSpaces)) {
            if ($pwReqError.length > 0) {
                $pwReqError.empty().remove();
            }
            e.preventDefault();
            var birthday = $(this).find('.birthday').val();
            var birthdayPlaceholder = birthday;
            if (birthday) {
                e.preventDefault();
                birthday = birthday.replace(/\s+/g, '');
                birthday += '/1990';
                $(this).find('.birthday').val(birthday);
                $(this).find('.birthday').removeAttr('maxlength');

            }

            var url = $(this).attr('action'),
                data = $(this).serialize(),
                submitButton = $(this).find('button[name$="_confirm"]').attr('name');
            if (birthday) {
                $(this).find('.birthday').val(birthdayPlaceholder);
            }

            var rgx = /^\d{5}(-\d{4})?$/;
            var zipValue = $(this).find('input[name$="_postalCode"]').val();
            var isUsZipValid = rgx.test($.trim(zipValue));

            $.ajax({
                type: 'POST',
                url: url,
                data: data + '&' + submitButton + '=x' + '&format=ajax',
                success: function () {
                    if (RegistrationForm.hasClass('loyalty-registration-confirmation')) {
                        $.ajax({
                            type: 'GET',
                            url: Urls.showRewardsFormConfirm,
                            dataType: 'html'
                        }).done(function (data) {
                            var confirmationMsg = $('.loyalty-slot-confirm');
                            if (confirmationMsg.length > 0) {
                                if (RegistrationForm.find('input[id$="_addtoemaillist"]').length > 0 && RegistrationForm.find('input[id$="_addtoemaillist"]').prop('checked') == true) {
                                    $.ajax({
                                        type: 'GET',
                                        url: Urls.getRewardsDataShort,
                                        dataType: 'json'
                                    }).done(function (data) {
                                        if (data.success == true && data.rewardsDataShort) {
                                            if (authenticated) {
                                                window.cmCreatePageElementTag('SIGNUP COMPLETE BBW ACCT', 'LOYALTY_SIGNUP_CHECKOUT');
                                            } else {
                                                window.cmCreatePageElementTag('SIGNUP COMPLETE GUEST', 'LOYALTY_SIGNUP_CHECKOUT');
                                            }
                                            window.cmCreateRegistrationTag(window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.address.city, window.digitalData.transaction.profile.address.stateProvince, window.digitalData.transaction.profile.address.postalCode, window.digitalData.transaction.profile.address.country, '-_--_--_--_--_--_--_--_-' + data.rewardsDataShort.loyaltyID + '-_-' + data.rewardsDataShort.count + '-_-' + data.rewardsDataShort.currentSpend);
                                            if (window.hasOwnProperty('digitalData') && window.digitalData.hasOwnProperty('user') && Array.isArray(window.digitalData.user) && window.digitalData.user[0].hasOwnProperty('profile') && Array.isArray(window.digitalData.user[0].profile) && window.digitalData.user[0].profile[0].hasOwnProperty('profileInfo')) {
                                                window.digitalData.user[0].profile[0].profileInfo.loyaltyID = data.rewardsDataShort.loyaltyID;
                                                window.digitalData.user[0].profile[0].profileInfo.loyaltyZip = data.rewardsDataShort.loyaltyZip;
                                                window.digitalData.user[0].profile[0].profileInfo.rewardsCount = data.rewardsDataShort.count;
                                                window.digitalData.user[0].profile[0].profileInfo.currentSpend = data.rewardsDataShort.currentSpend;
                                                // set an event flag for tealium to know when loyalty users sign up
                                                window.digitalData.user[0].profile[0].profileInfo.isLoyaltySignupEvent = true;
                                            }
                                            satelliteTrackHelper('account-create-success');
                                        }
                                    });
                                } else {
                                    if (!isUsZipValid) {
                                        window.cmCreateRegistrationTag(window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.address.city, window.digitalData.transaction.profile.address.stateProvince, window.digitalData.transaction.profile.address.postalCode, window.digitalData.transaction.profile.address.country, '-_--_--_--_--_--_--_--_-0-_-0-_-0-_--_--_-bbw_acct_ineligible');
                                        window.cmCreateElementTag('SIGNUP COMPLETE', 'SIGNUP_CHECKOUT', '-_--_-bbw_acct_ineligible-_-confirmation');
                                        if (window.hasOwnProperty('digitalData') && window.digitalData.hasOwnProperty('user') && Array.isArray(window.digitalData.user) && window.digitalData.user[0].hasOwnProperty('profile') && Array.isArray(window.digitalData.user[0].profile) && window.digitalData.user[0].profile[0].hasOwnProperty('profileInfo')) window.digitalData.user[0].profile[0].profileInfo.loyaltyaccountstatus = 'bbw_acct_ineligible';
                                    } else {
                                        window.cmCreateRegistrationTag(window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.address.city, window.digitalData.transaction.profile.address.stateProvince, window.digitalData.transaction.profile.address.postalCode, window.digitalData.transaction.profile.address.country, '-_--_--_--_--_--_--_--_-0-_-0-_-0-_--_--_-bbw_acct');
                                        window.cmCreateElementTag('SIGNUP COMPLETE', 'SIGNUP_CHECKOUT', '-_--_-bbw_acct-_-confirmation');
                                        if (window.hasOwnProperty('digitalData') && window.digitalData.hasOwnProperty('user') && Array.isArray(window.digitalData.user) && window.digitalData.user[0].hasOwnProperty('profile') && Array.isArray(window.digitalData.user[0].profile) && window.digitalData.user[0].profile[0].hasOwnProperty('profileInfo')) window.digitalData.user[0].profile[0].profileInfo.loyaltyaccountstatus = 'bbw_acct';
                                    }
                                    satelliteTrackHelper('account-create-success');
                                }
                                confirmationMsg.html(data);
                                window.picturefill(); // picturefill re-init required because IE11 doesn't render correct image after DOM modifications
                                $('html, body').animate({
                                    scrollTop: $('.order-information').offset().top
                                }, 1000);
                                $('.confirmation-register').remove();
                            }

                        });

                        $.ajax({
                            type: 'GET',
                            url: Urls.customerHeaderMenuDesktop,
                            dataType: 'html'
                        }).done(function (html) {
                            $('.menu-utility-user .user-info').replaceWith(html);
                            setupTopBanner();
                        });

                        $.ajax({
                            type: 'GET',
                            url: Urls.customerHeaderMenuMobile,
                            dataType: 'html'
                        }).done(function (html) {
                            $('#my-account-dropdown').replaceWith(html);
                            void new window.topNavigation.Navigation_tier2button($('nav#navigation li[data-id="my-account"]'), 'my-account');
                        });
                    } else {
                        if (!isUsZipValid) {
                            window.cmCreateRegistrationTag(window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.address.city, window.digitalData.transaction.profile.address.stateProvince, window.digitalData.transaction.profile.address.postalCode, window.digitalData.transaction.profile.address.country, '-_--_--_--_--_--_--_--_-0-_-0-_-0-_--_--_-bbw_acct_ineligible');
                            window.cmCreateElementTag('SIGNUP COMPLETE', 'SIGNUP_CHECKOUT', '-_--_-bbw_acct_ineligible-_-confirmation');
                            if (window.hasOwnProperty('digitalData') && window.digitalData.hasOwnProperty('user') && Array.isArray(window.digitalData.user) && window.digitalData.user[0].hasOwnProperty('profile') && Array.isArray(window.digitalData.user[0].profile) && window.digitalData.user[0].profile[0].hasOwnProperty('profileInfo')) window.digitalData.user[0].profile[0].profileInfo.loyaltyaccountstatus = 'bbw_acct_ineligible';
                        } else {
                            window.cmCreateRegistrationTag(window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.address.city, window.digitalData.transaction.profile.address.stateProvince, window.digitalData.transaction.profile.address.postalCode, window.digitalData.transaction.profile.address.country, '-_--_--_--_--_--_--_--_-0-_-0-_-0-_--_--_-bbw_acct');
                            window.cmCreateElementTag('SIGNUP COMPLETE', 'SIGNUP_CHECKOUT', '-_--_-bbw_acct-_-confirmation');
                            if (window.hasOwnProperty('digitalData') && window.digitalData.hasOwnProperty('user') && Array.isArray(window.digitalData.user) && window.digitalData.user[0].hasOwnProperty('profile') && Array.isArray(window.digitalData.user[0].profile) && window.digitalData.user[0].profile[0].hasOwnProperty('profileInfo')) window.digitalData.user[0].profile[0].profileInfo.loyaltyaccountstatus = 'bbw_acct';
                        }
                        window.location = Urls.accountLanding + '?registration=true';
                        satelliteTrackHelper('account-create-success');
                    }
                }
            });

        } else {
            e.preventDefault();
            if ($pwReqError.length > 0) {
                $pwReqError.empty().remove();
            }
            $('<span class="pwreq-error" id="pwreq-error">' + Resources.VALIDATE_REQPASSWORD + '</span>').appendTo(errorMsgWrapper);
            $('[id$="_login_password"]').focus();
        }
    });
}
/**
 * @private
 * @function
 * @description switch grey status to red on blur of password field in BBWS-672
 */
function switchClassOnPasswordRules() {
    $('input#dwfrm_profile_login_password, input#dwfrm_resetpassword_password').on('focus', function () {
        if ($('#password-requirements').css('display') == 'none') {
            $('#password-requirements').show();
        }
    });
    $('input#dwfrm_profile_login_password, input#dwfrm_resetpassword_password').on('blur', function () {
        pwHadFocus = true;
        if (!$(this).val()) {
            $('#password-requirements').hide();
        }
        if ($(this).val() && $('#password-requirements').css('display') == 'none') {
            $('#password-requirements').show();
        }
        if (!$('.rule8to50char').hasClass('success')) {
            $('.rule8to50char').addClass('pw-error');
        }
        if (!$('.rule1number').hasClass('success')) {
            $('.rule1number').addClass('pw-error');
        }
        if (!$('.rule1uppercase').hasClass('success')) {
            $('.rule1uppercase').addClass('pw-error');
        }
        if (!$('.rule1lowercase').hasClass('success')) {
            $('.rule1lowercase').addClass('pw-error');
        }
        if (!$('.ruleNoSpaces').hasClass('success')) {
            $('.ruleNoSpaces').addClass('pw-error');
        }
    });
}
/**
 * @private
 * @function
 * @description sets error message on page load for user existing with email given
 */
function userExistOnLoad() {
    if ($('#dwfrm_profile_customer_email').closest('.required.error').find('.error-message').length > 0) {
        if ($('body').find('.foundMatch').length == 0) {
            if ($('body').find('.misMatch').length == 0) {
                $('<span class="foundMatch"></span>').appendTo($('#dwfrm_profile_customer_email').closest('.field-wrapper'));
                $('#dwfrm_profile_customer_email').closest('.required.error').find('.error-message').hide();
            }
        }
        $.post(Urls.userExists,
            {email: $('input[name$="_profile_customer_email"]').val()},
            function (data) {
                if (data.success) {
                    var errorstring = data.errorMessage;
                    $('.foundMatch').html(errorstring);
                } else {
                    $('.foundMatch').hide();
                }
            }, 'json'
        );
    }
}

/**
* @private
* @function
 * @description add red outline of email confirm field in BBWS-795 if error
 */
function emailConfirmOnload() {
    if ($('.emailconfirm').closest('.form-row').find('.error-message').length > 0) {
        $('#dwfrm_profile_customer_emailconfirm').removeClass('valid');
        $('#dwfrm_profile_customer_emailconfirm').addClass('error');
    }
}

/**
* @private
* @function
 * @description initiate accordion on the page
 */
function initAccordion() {
    var accordionEl = $('.confirmation-register');
    var accordionState = false;
    if (accordionEl.hasClass('accordion-opened')) {
        accordionState = 0;
    }
    var icons = {
        header: 'iconClosed',
        activeHeader: 'iconOpen'
    };

    accordionEl.accordion({
        active: accordionState,
        collapsible: true,
        header: 'h2',
        heightStyle: 'content',
        icons: icons,
        activate: function() {
            if (!$(this).find('.ui-accordion-header').hasClass('ui-state-active')) {
                $(this).find('.ui-accordion-header').attr('aria-expanded', 'false');
            }
        }
    });
}
/**
 * @private
 * @function
 * @description mask of Birthday Input
 */
function maskBirthdayInit() {
    $('.birthday').mask('00 / 00');
}
/**
 * @private
 * @function
 * @description mask of phone Input
 */
function maskPhoneInit() {
    $('.phone').mask('(000) 000-0000');
}

/**
 * @private
 * @function
 * @description Initialize rewards popup
 */
function initRewardsPopup() {
    var loyaltyPopupDiv = $('#confirmation-loyalty-popup');
    if (loyaltyPopupDiv.length > 0) {
        var zipInputBilling = loyaltyPopupDiv.attr('data-shipping-zip');
        var zipInputShipping = loyaltyPopupDiv.attr('data-billing-zip');
        var loyaltyEnabled = SitePreferences.IS_BOND_LOYALTY_ENABLED || SitePreferences.IS_LOYALTY_ENROLLMENT_ENABLED;
        //check if loyalty subscription is enabled 
        if ((zipInputBilling != '' || zipInputShipping != '') && loyaltyEnabled) {
            var zipBilling = zipInputBilling.substring(0, 5);
            var zipShipping = zipInputShipping.substring(0, 5);
            if (util.isUSPostalCode(zipBilling) || util.isUSPostalCode(zipShipping)) {
                var rewardsUrl = util.appendParamsToUrl(Urls.showRewardsDialog, {'orderConfirmation': true});

                //analytics
                window.cmCreatePageElementTag('POP-UP', 'LOYALTY_SIGNUP_CHECKOUT');
                //show rewards dialog
                dialog.open({
                    url: rewardsUrl,
                    options: {
                        dialogClass: 'rewardspopup',
                        width: 425,
                        open: function () {
                            $('#wrapper').attr('aria-hidden', 'true');
                            validator.init();
                            var rewardsForm = $('form#join-rewards-form');
                            initRewardsPopupEvents(rewardsForm);
                            $('.ui-widget-overlay').on('click', function () {
                                dialog.close();
                            });
                            $('#cancel-rewards').on('click', function (e) {
                                e.preventDefault();
                                dialog.close();
                            });
                            satelliteTrackHelper('loyaltyjoinpopup');
                        },
                        close: function () {
                            $('#wrapper').removeAttr('aria-hidden');
                        }
                    }
                });
            }
        }
    }
}

/**
 * @private
 * @function
 * @description Initialize rewards page logic
 */
function initRewardsPopupEvents(rewardsForm) {
    var rewardsInput = $('#join-rewards-form input');
    var rewardsButton = $('button[name$="_joinrewards"]');
    var rewardsCheck = $('input[name$="_addtoemaillist"]');
    rewardsInput.next('label').remove();
    rewardsInput.on('blur', function () {
        if (!rewardsInput.hasClass('error') && rewardsCheck.is(':checked')) {
            rewardsButton.removeAttr('disabled');
        } else {
            rewardsButton.attr('disabled', 'disabled');
        }
    });
    rewardsCheck.on('change', function () {
        if (!rewardsInput.hasClass('error') && $(this).is(':checked')) {
            rewardsButton.removeAttr('disabled');
        } else {
            rewardsButton.attr('disabled', 'disabled');
        }
    })
    rewardsForm.on('submit', function (e) {
        if (!rewardsForm.valid()) {
            return;
        }
        e.preventDefault();
        var birthday = $(this).find('.birthday').val();
        var birthdayPlaceholder = birthday;
        if (birthday) {
            birthday = birthday.replace(/\s+/g, '');
            birthday += '/1990';
            $(this).find('.birthday').val(birthday);
            $(this).find('.birthday').removeAttr('maxlength');
        }
        var formData = $(this).serialize(),
            trigger = rewardsButton.attr('name'),
            postData = formData + '&format=ajax' + '&page_id=confirmation' + '&' + trigger + '=true';
        $(this).find('.birthday').val(birthdayPlaceholder);
        //ajax call to become Loyalty - if user logged in and billing zip code matches loyalty zip codes
        $.ajax({
            type: 'POST',
            url: rewardsForm.attr('action'),
            data: postData
        }).done(function () {
            //analytics
            window.cmCreatePageElementTag('SIGNUP COMPLETE BBW ACCT', 'LOYALTY_SIGNUP_CHECKOUT');
            $.ajax({
                type: 'GET',
                url: Urls.getRewardsDataShort,
                dataType: 'json'
            }).done(function (data) {
                if (data != null && data.success == true && data.rewardsDataShort) {
                    window.cmCreateRegistrationTag(window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.profileInfo.profileEmailHash, window.digitalData.transaction.profile.address.city, window.digitalData.transaction.profile.address.stateProvince, window.digitalData.transaction.profile.address.postalCode, window.digitalData.transaction.profile.address.country, '-_--_--_--_--_--_--_--_-' + data.rewardsDataShort.loyaltyID + '-_-' + data.rewardsDataShort.count + '-_-' + data.rewardsDataShort.currentSpend + '-_--_--_-loyl_acct_active');
                    if (window.hasOwnProperty('digitalData') && window.digitalData.hasOwnProperty('user') && Array.isArray(window.digitalData.user) && window.digitalData.user[0].hasOwnProperty('profile') && Array.isArray(window.digitalData.user[0].profile) && window.digitalData.user[0].profile[0].hasOwnProperty('profileInfo')) window.digitalData.user[0].profile[0].profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                    satelliteTrackHelper('loyalty-signup-success');
                }
            });
            dialog.close();
            //show confirmation popup, content asset inside
            dialog.open({
                url: Urls.showRewardsDialogConfirm,
                options: {
                    dialogClass: 'rewardspopupimg',
                    width: 400,
                    open: function () {
                        $('#wrapper').attr('aria-hidden', 'true');
                        $('.ui-widget-overlay').on('click', function () {
                            dialog.close();
                        });
                    },
                    close: function () {
                        $('#wrapper').removeAttr('aria-hidden');
                    }
                }
            });
        });
    });

}

/**
 * @private
 * @function
 * @description Initialize events on the order confirmation page
 */
function initializeEvents() {
    var RegistrationForm = $('#RegistrationForm');
    var RegistrationFormCheckbox = RegistrationForm.find('input[name$="_addtoemaillist"]');
    if (RegistrationForm.hasClass('loyalty-registration-confirmation')) {
        window.cmCreatePageElementTag('POP-UP', 'LOYALTY_SIGNUP_CHECKOUT');
        RegistrationFormCheckbox.next('label').addClass('visually-hidden');
    }
    RegistrationFormCheckbox.on('keydown', function (e) {
        var keyCode = e.keyCode || window.event.keyCode;
        // check for an ENTER
        if (keyCode === 13) {
            e.preventDefault();
            if ($(this).prop('checked') === true) {
                $(this).prop('checked', false);
            } else {
                $(this).prop('checked', true);
            }
        }
    });

    initRewardsPopup();
    if ($('.store-map').length > 0) {
        var storedata = $('.store-map').data('address');
        var mapelement = $('.map').attr('id');
        setMapObj(storedata.latitude,storedata.longitude,10,mapelement);
    }
    
    //clear Ordergroove cache
    if ('OG' in window && window.OG) {
        window.OG.clear();
    }
    //clear cart analytics
    if (util.storageAvailable('localStorage') && window.localStorage.getItem('loadCart') != null) {
        window.localStorage.removeItem('loadCart');
    }

}

function scrollToOrderConfirmation(){
    $('.view-order-details-btn').on('click', function () {
        $('.your-order-section-heading .your-order-text').focus();
        document.querySelector('.order-confirmation-details').scrollIntoView({ 
            behavior: 'smooth' 
        });
        var pageID = '';
        if (window.hasOwnProperty('digitalData') && window.digitalData.hasOwnProperty('page') && window.digitalData.page.hasOwnProperty('pageInfo') && window.digitalData.page.pageInfo.hasOwnProperty('pageID')) {
            pageID = window.digitalData.page.pageInfo.pageID;
        }
        if ('cmCreateElementTag' in window) {
            window.cmCreateElementTag('View Order Details', 'BOPIS Confirmation', '-_--_--_-'+pageID);    
        }
    })
}

function satelliteTrackHelper(trackEvent) {
    if (window._satellite && window._satellite.track) {
        window._satellite.track(trackEvent);
    } else {
        window.setTimeout(function() {
            satelliteTrackHelper(trackEvent);
        }, 100);
    }
}

module.exports = orderconfirmation;
