'use strict';
// iRoll tooltip based on ADA Promotional Tooltip
exports.init = function() {
    var BBWDiRoll = '.bbwd-iroll, .bbwd-tooltip, .bbwd-tooltip-content, .bbwd-tooltip-close-button', isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    function toggleTooltip(event) {
        var type = event.type, code = event.keyCode, target = event.target, tooltipParent = '.form-field-tooltip, .promo, .promotion, .expected-delivery', tooltip = $(target).closest(tooltipParent).find('.bbwd-tooltip-content'), trigger = $(target).closest(tooltipParent).find('.bbwd-tooltip'), closeX = $(target).closest(tooltipParent).find('.bbwd-tooltip-close-button'), openTest = $(target)[0] == trigger[0], closeTest = $(target)[0] == closeX[0];
        
        if (code === 27) closeTooltip(tooltip, trigger); // esc
        if (code === 13 || // enter
            code === 32 || // space
            type === 'click' || type === 'mouseenter' || type === 'mouseleave') {
            if (code) {
                event.preventDefault();
                if (!openTest && !closeTest) $(target)[0].click();
                if (tooltip.hasClass('active') && closeTest || openTest) closeTooltip(tooltip, trigger);
            }
            if (type === 'click') event.stopPropagation();
            if (type === 'click' && openTest) event.preventDefault();
            if (tooltip.hasClass('active')) {
                if (closeTest && type != 'mouseenter' && type != 'mouseleave') {
                    event.preventDefault();
                    closeTooltip(tooltip, trigger);
                }
                if (type != 'mouseenter') {
                    var delay = setTimeout(function() { // delay for motor/cognitive a11y
                        if (trigger.is(':hover') || tooltip.is(':hover')) {
                            clearTimeout(delay);
                            return;
                        }
                        closeTooltip(tooltip, trigger);
                        clearTimeout(delay);
                    }, 200);
                }
            } else if (openTest) openTooltip(tooltip, trigger);
        }
    }
    function closeTooltip(tooltip, trigger) {
        trigger.attr('aria-expanded', 'false');
        $('.active .arrow-outline').remove();
        $('.active .arrow-fill').remove();
        tooltip.removeClass('active');
        $(trigger).focus();
    }
    function openTooltip(tooltip, trigger) {
        trigger.attr('aria-expanded', 'true');
        tooltip.addClass('active');
        $('.active .bbwd-tooltip-content-container').prepend('<div class="arrow-outline" role="presentation"></div>').append('<div class="arrow-fill" role="presentation"></div>');
        tooltipPositioning(tooltip, trigger);
        $(tooltip).focus();
        
        $('.bbwd-tooltip-close-button').on('keydown', function(e) {
            if (e.keyCode==9){
                e.preventDefault();
                $('.bbwd-tooltip-content').focus();
            }
        });
        $('.bbwd-tooltip-content').on('keydown', function(e) {
            if (e.keyCode==9 && e.shiftKey && $(e.target).is('.bbwd-tooltip-content')){
                e.preventDefault();
                $('.bbwd-tooltip-close-button').focus();
            }
        });
        
    }
    function tooltipPositioning(tooltip, trigger) {
        tooltip.removeAttr('style');
        var isTablet = window.matchMedia('(max-width: 767px)').matches, isMobile = window.matchMedia('(max-width: 429px)').matches, tooltipWidth = tooltip.outerWidth(), tooltipHeight = tooltip.outerHeight(), triggerWidth = trigger.outerWidth(), triggerHeight = trigger.height(), offset = trigger.offset(), positioning = 'placement', classes = tooltip[0].classList;
        // check viewport
        if (isMobile || isTablet) positioning += '-mobile';
        // check classes
        for (var i = 0; i < classes.length; i++) {
            if (tooltip.hasClass(classes[i])) {
                if (positioning === 'placement' || positioning === 'placement-mobile') {
                    // check vertical class
                    if (classes[i].indexOf(positioning + '-above') != -1) positioning += '-above';
                    else if (classes[i].indexOf(positioning + '-below') != -1) positioning += '-below';
                    // check horizontal class
                    if (classes[i].indexOf(positioning + '-start') != -1) positioning += '-start';
                    else if (classes[i].indexOf(positioning + '-center') != -1) positioning += '-center';
                    else if (classes[i].indexOf(positioning + '-end') != -1) positioning += '-end';
                }
            }
        }
        // correct placement and styling if placement pattern was not followed
        if (positioning == 'placement-mobile-below' || positioning == 'placement-mobile-above') {
            tooltip.removeClass(positioning);
            positioning += '-center';
            tooltip.addClass(positioning);
        }
        // set vertical offset
        if (positioning.match('-above')) offset.top -= Math.round(tooltipHeight + (triggerHeight * 0.5));
        else if (positioning.match('-below')) offset.top += Math.round(triggerHeight + 16);
        else offset.top -= Math.round((tooltipHeight * 0.5) - (triggerHeight * 0.5));
        // set horizontal offset
        if (positioning.match('-start')) offset.left -= 10;
        else if (positioning.match('-end')) offset.left -= Math.round(tooltipWidth - triggerWidth - 10);
        else if (positioning.match('-center')) offset.left -= Math.round((tooltipWidth * 0.5) - (triggerWidth * 0.5));
        else offset.left += Math.round(triggerWidth + 16);
        // set phone positioning
        if (isMobile) {
            offset.left = Math.round((document.documentElement.clientWidth * 0.5) - (tooltipWidth * 0.5));
        } else {
            var arrowOutline = $('.active .arrow-outline'), arrowFill = $('.active .arrow-fill'), arrowOutlineOffset = arrowOutline.offset(), arrowFillOffset = arrowFill.offset(), halfTooltipWidth = Math.round(tooltipWidth * 0.5), halfTriggerWidth = Math.round(triggerWidth * 0.5), halfTooltipHeight = Math.round(tooltipHeight * 0.5), halfTriggerHeight = Math.round(triggerHeight * 0.5);
            // set vertical offset
            if (positioning.match('-below')) {
                arrowFillOffset.top -= (tooltipHeight + triggerHeight - 11);
                arrowOutlineOffset.top -= (tooltipHeight + triggerHeight - 9);
            } else if (!positioning.match('-above')) {
                arrowOutlineOffset.top += (halfTooltipHeight - triggerHeight);
                arrowFillOffset.top = arrowOutlineOffset.top + 1;
            }
            // set horizontal offset
            if (positioning.match('-start')) {
                arrowFillOffset.left -= halfTooltipWidth - halfTriggerWidth - 10;
                arrowOutlineOffset.left -= halfTooltipWidth - halfTriggerWidth - 10;
            } else if (positioning.match('-end')) {
                arrowFillOffset.left += halfTooltipWidth - halfTriggerWidth - 10;
                arrowOutlineOffset.left += halfTooltipWidth - halfTriggerWidth - 10;
            }
            if (isIE11) {
                if (positioning.match('-above') || positioning.match('-below')) {
                    arrowOutlineOffset.top -= 19;
                    arrowFillOffset.top -= 17;
                    arrowOutlineOffset.left += 10;
                    arrowFillOffset.left += 9;
                } else {
                    console.log('CHECK!');
                    arrowOutlineOffset.top += halfTriggerHeight;
                    arrowFillOffset.top = arrowOutlineOffset.top;
                    arrowOutlineOffset.left += 9;
                    arrowFillOffset.left += 8;
                }
            }
            $(arrowFill).offset(arrowFillOffset);
            $(arrowOutline).offset(arrowOutlineOffset);
        }
        // apply possitioning
        tooltip.offset(offset);
    }
    $('body').on('mouseenter mouseleave keydown click', BBWDiRoll, function(event){toggleTooltip(event);});
    // TODO: add touch events
};
