'use strict';

var fragrance = {
    init: function() {
        $(window).on('load', function() {
            if ($(window).width() < 481) {
                startCarousel();
                $('.fragrance-category-container').css('display','block');
            } else {
                $('.owl-carousel').addClass('off');
            }
        });
        $(window).resize(function() {
            if ($(window).width() < 481) {
                startCarousel();
                $('.fragrance-category-container').css('display','block');
            } else {
                stopCarousel();
                $('.fragrance-category-container').css('display','flex');
            }
        });
        function startCarousel(){
            $('.fragrance-category-container').owlCarousel({
                navigation : false, 
                slideSpeed : 500,
                margin: 0,
                paginationSpeed : 400,
                autoplay:false,
                autoWidth: true,
                items : 1,
                itemsDesktop : false,
                itemsDesktopSmall : false,
                itemsTablet: false,
                loop:false,
                center: true,
                nav: false,
                dots:true
            }).on('dragged.owl.carousel', function(event) {
                if ('digitalData' in window && window.digitalData.hasOwnProperty('page'))
                    window.digitalData.page.modulename = 'Fragrance Category Olfactive';
                if (event.relatedTarget._drag.direction === 'left') {
                    window.cmCreateElementTag('Swipe Left Olfactive Space', 'Fragrance LP');
                    if ('_satellite' in window) window._satellite.track('rightswipe');
                }
                if (event.relatedTarget._drag.direction === 'right') {
                    window.cmCreateElementTag('Swipe Right Olfactive Space', 'Fragrance LP');
                    if ('_satellite' in window) window._satellite.track('leftswipe');
                }
            });
            $('.fragrance-category-container .owl-dots').css('display','block');
        }
        function stopCarousel() {
            var owl = $('.fragrance-category-container.owl-carousel');
            owl.trigger('destroy.owl.carousel');
            owl.addClass('off');
        }
        $('.shop-now-icon').click(function() {
            $('html, body').animate({
                scrollTop: $('.search-result-options').offset().top - 20
            }, 500);
        });
        $('.frag-icon').attr('aria-label','top fragrance icon');
        fragrance.fragranceAnalyticsInit();
    },
    
    fragranceAnalyticsInit: function(){
        
        var $fragranceNameElement = $('.filter-overflow-fragrance .name-list a');
        var $fragranceCategoryElement = $('.fragrance-category-container .fragrance-category-section a');
        
        $($fragranceNameElement).on('click', function(){
            var fragranceName = $(this).data('fragrancename');
            window.cmCreateElementTag(fragranceName, 'Fragrance LP', '-_--_--_--_--_--_--_--_-Fragrances By Name');
        });
        
        $($fragranceCategoryElement).on('click', function(){
            var fragranceCategory = $(this).data('analytics-categoryname');
            window.cmCreateElementTag(fragranceCategory, 'Fragrance LP', '-_--_--_--_--_--_--_--_--_-Fragrance Category Olfactive');
        });
        
    }
}

module.exports = fragrance;

