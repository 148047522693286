'use strict';
var dialog = require('./dialog'),
    validator = require('./validator'),
    page = require('./page');
var pages = {
    account: require('./pages/account')
};


/* cookie utilities */
function createCookie(name, value, days) {
    var expires;
    
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
}

function readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function bocAdaInit() {
    var dialogBox = $('.ui-dialog');
    dialogBox.find('.ui-dialog-titlebar-close').attr('title', 'Button to close Sign in Modal');
    dialogBox.find('#password-reset').attr('target', '_blank').attr('title', 'Link to Forgot password');
    dialogBox.find('a.privacy-policy').attr('target', '_blank').attr('title', 'Link to Privacy policy');
}

function bocLoginEvents() {
    validator.init();
    pages.account.initPopupLogin();
    var dialogContainer = $('#dialog-container');
    var dialogForm = dialogContainer.find('.loginform-new');
    var dialogSubmit = dialogForm.find('button[type="submit"]');
    var dialogClose = dialogContainer.find('#boc-continue-shopping');
    dialogClose.on('click', function() {
        dialog.close();
        page.refresh();
    })
    dialogForm.on('submit', function(e) {
        e.preventDefault();
        var submitButton = dialogSubmit.attr('name');
        var data = $(this).serialize() + '&' + submitButton + '=x&format=ajax';
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data,
            success: function(response) {
                dialogContainer.html(response);
                window.digitalData.user.attributes.signinModule = 'member sign in popup';
                bocLoginEvents();
                $.ajax({
                    type: 'GET',
                    url: Urls.getRewardsDataShort,
                    dataType: 'json'
                }).done(function(data) {
                    if (data.success === true && data.rewardsDataShort) {
                        // expected only active loyalty status, might be different in future
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_active');
                        window.cmCreatePageElementTag('COMPLETE', 'LOYALTY SIGN IN');
                        //add datalayer values
                        if (data.rewardsDataShort.address) {
                            window.digitalData.user.profile.address = data.rewardsDataShort.address;
                        }
                        window.digitalData.user.profile.profileInfo = {
                            loyalityMemberStatus: data.rewardsDataShort.loyaltyStatus || '',
                            loyaltySubscriptionDate: data.profileInfo.loyaltySubscriptionDate || '',
                            loyaltyaccountstatus: data.profileInfo.loyaltyaccountstatus || '',
                            profileEmail: data.profileInfo.profileEmail || '',
                            profileEmailHash: data.rewardsDataShort.emailHash || '',
                            profileID: data.profileInfo.profileID || '',
                            userName: data.profileInfo.userName || ''
                        }
                        if (data.rewardsDataShort.count) {
                            window.digitalData.user.profile.profileInfo.currentSpend = data.rewardsDataShort.currentSpend;
                        }
                        if (data.rewardsDataShort.loyaltyID) {
                            window.digitalData.user.profile.profileInfo.loyaltyID = data.rewardsDataShort.loyaltyID;
                        }
                        if (data.rewardsDataShort.loyaltyZip) {
                            window.digitalData.user.profile.profileInfo.loyaltyZip = data.rewardsDataShort.loyaltyZip;
                        }
                        if (data.rewardsDataShort.count) {
                            window.digitalData.user.profile.profileInfo.rewardsCount = data.rewardsDataShort.count;
                        }

                        window._satellite.track('signin-member-popup');
                    }
                });
            }
        });
    });

    if ('digitalData' in window) window.digitalData.page.membersigninpopup = 'true';

    bocAdaInit();
}

function bocLoginInit() {
    if ($('#boc_popup').length > 0 && window.User.authenticated === false) {
        var cookieName = $('#email-dialog').data('cookiename');
        var cookieDuration = $('#email-dialog').data('cookieduration');
        if (readCookie(cookieName) == null) {
            dialog.open({
                url : Urls.bocLogin,
                options : {
                    dialogClass : 'bocpopup',
                    width : 400,
                    open : function() {
                        bocLoginEvents();
                        $('.ui-widget-overlay, #boc-continue-shopping').on('click', function() {
                            dialog.close();
                        });
                    },
                    close : function() {
                        //create the cookie because it doesn't exist
                        createCookie(cookieName, 'true', cookieDuration);
                    }
                }
            });
            window.cmCreatePageElementTag('POP-UP', 'LOYALTY SIGN IN');
        }
    }
}

function emailDialogInit() {

    var cmName = 'Email Lightbox';

    /*
     *  logic to decide to show the lighbox, so long as the hasVisitedBefore cookie is not set
     */
    // check to see if the cookie "hasVisitedBefore" is already set
    var cookieName = $('#email-dialog').data('cookiename');
    var cookieDuration = $('#email-dialog').data('cookieduration');

    if (readCookie(cookieName) == null) {

        var markup = $('#email-dialog-form').html();
        dialog.open({
            html: markup,
            options: {
                width: 570,
                height: 'auto',
                title: null,
                dialogClass: 'global-email-dialog',
                show: {
                    effect: 'fade',
                    duration: 600
                },
                hide: {
                    effect: 'fade',
                    duration: 600
                },
                close: function() {
                    // remove the dialogClass on close, causes styling issues otherwise
                    $('.ui-dialog').removeClass('global-email-dialog');
                }
            },
            callback: function() {
                var $dialogContainer = $('#dialog-container');
                if ('cmCreateElementTag' in window){
                    window.cmCreateElementTag(cmName, 'Popup');
                }
                $('input[name="srcURL"]').attr('value', location.href);

                //create the cookie because it doesn't exist
                createCookie(cookieName, 'true', cookieDuration);

                $dialogContainer.find('#emailSignupForm').submit(function(e) {
                    var form = $(this);
                    var emailVal = form.find('input[type="email"]').val();
                    var emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                    e.preventDefault(); // avoid to execute the actual submit of the form.

                    if (emailVal != '' && emailVal.match(emailRegex)) {
                        $.ajax({
                            type: 'POST',
                            url: Urls.emailDialogSubmitURL,
                            data: form.serialize(), // serializes the form's elements.
                            success: function(data) {
                                if (data.success) {
                                    $dialogContainer.html($('#email-dialog-success'));

                                    // Track when email subscription done
                                    window.digitalData.page.lightboxpopup = 'done' || '';
                                }
                            }
                        });
                    } else {
                        $dialogContainer.find('.error').show();
                        $dialogContainer.find('#email').focus();
                    }
                    $dialogContainer.find('input[type="email"]').on('keydown', function() {
                        $dialogContainer.find('.error').hide();
                    });
                });
            }
        });
    }
}

var emaildialog = {
    init: function() {
        var arr = [];
        if ($('#email-dialog').data('pagesuppression')){
            arr = $('#email-dialog').data('pagesuppression').split('|');
        }
        var enabled = SitePreferences.EMAIL_DIALOG_ENABLED;
        var suppress = false;
        var overlay = document.querySelector('.ui-widget-overlay');
        if (overlay) {
            overlay.addEventListener('click', function() {
                dialog.close();
            })
        }
        var checkCookie = function() {
            var cookieEnabled = navigator.cookieEnabled;
            if (!cookieEnabled) {
                document.cookie = 'testcookie';
                cookieEnabled = document.cookie.indexOf('testcookie') != -1;
            }
            return cookieEnabled;
        }
        
        
        // make sure the dialog is enabled
        // makes sure the users browser supports cookies
        if (enabled && checkCookie()) {

            if (arr[0] !== 'empty') {
                for (var i = 0; i < arr.length; i++) {
                    var el = $('#wrapper.' + arr[i]);
                    if (el.length > 0) {
                        suppress = true;
                        break;
                    }
                }
            }
            if (!suppress) {
                emailDialogInit();
                bocLoginInit();
            }
        }
    },
    createCookie: createCookie,
    readCookie: readCookie
}

module.exports = emaildialog;
