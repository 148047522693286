/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var tealium = require('../tealium');

var account = {
    init: function () {
        //successful registration
        if (window.location.search.indexOf('registration=true') != -1) {
            account.register();

        //successful login
        } else if ('UserLoggedIn' in window && window.UserLoggedIn === true){
            account.login();
        }

        //account logout
        $('#main .account-logout').on('click', account.logout);
        $('.header-logout').on('click', account.logout);

        //account updated
        if ($('#account-updated').size() > 0) {
            account.accountUpdated();
        }
    },
    login: function() {
        var accountInfo = account.getAccountData();
        tealium.eventCount += 1;
        var obj = {
            'event_category' : 'Account',
            'event_value' : tealium.eventCount + '',
            'event_action' : 'account login',
            'event_label' : 'Login',
            'event_name' : 'user_login'
        }
        $.extend(obj, accountInfo);
        tealium.triggerEvent(obj);
    },
    logout: function() {
        var accountInfo = account.getAccountData();
        tealium.eventCount += 1;
        var obj = {
            'event_category' : 'Account',
            'event_value' : tealium.eventCount + '',
            'event_action' : 'account logout',
            'event_label' : 'Logout',
            'event_name' : 'user_logout'
        }
        $.extend(obj, accountInfo);
        tealium.triggerEvent(obj);
    },
    register: function() {
        var accountInfo = account.getAccountData();
        tealium.eventCount += 1;
        var obj = {
            'event_category' : 'Account',
            'event_value' : tealium.eventCount + '',
            'event_action' : 'account registration',
            'event_label' : 'Registration',
            'event_name' : 'user_register'
        }
        $.extend(obj, accountInfo);
        tealium.triggerEvent(obj);
    },
    accountUpdated: function() {
        var accountInfo = account.getAccountData();
        tealium.eventCount += 1;
        var obj = {
            'event_category' : 'Account',
            'event_value' : tealium.eventCount + '',
            'event_action' : 'account update',
            'event_label' : 'Account Update',
            'event_name' : 'user_update'
        }
        $.extend(obj, accountInfo);
        tealium.triggerEvent(obj);
    },
    getAccountData: function(){
        var data = window.digitalData,
            profile = data.user.profile,
            profileAttributes = profile.attributes;
        var obj = {
            'country_code' : profileAttributes.country_code,
            'customer_city' : profileAttributes.customer_city,
            'customer_country' : profileAttributes.customer_country,
            'customer_email' : profileAttributes.customer_email,
            'customer_first_name' : profileAttributes.customer_first_name,
            'customer_id' : profileAttributes.customer_id,
            'customer_last_name' : profileAttributes.customer_last_name,
            'customer_postal_code' : profileAttributes.customer_postal_code,
            'customer_state' : profileAttributes.customer_state
        };
        return obj;
    }
};

module.exports = account;
