'use strict';

var util = require('./util');

var currentQuery = null,
    lastQuery = null,
    runningQuery = null,
    listTotal = -1,
    listCurrent = -1,
    delay = 30,
    $resultsContainer,
    progress = require('./progress'),
    mobileMenuAria = require('./designSystem/mobileMenuAria');

/**
 * @function
 * @description Handles height for the suggestion content
 * @param elems are the elements to be handled
 */
var maxHeight = function(elems) {
    return Math.max.apply(null, elems.map(function () {
        return $(this).height();
    }).get());
}

/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
function handleArrowKeys(keyCode) {
    switch (keyCode) {
        case 38:
            // keyUp
            listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
            break;
        case 40:
            // keyDown
            listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
            break;
        default:
            // reset
            listCurrent = -1;
            return false;
    }

    $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
    $('input[name="q"]').val($resultsContainer.find('.selected .suggestionterm').first().text());
    return true;
}

var searchsuggest = {
    slideAnimation : true,
    /**
     * @function
     * @description Configures parameters and required object instances
     */
    init: function (container, defaultValue) {
        var $searchContainer = $(container);
        var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
        var $searchField = $searchForm.find('input[name="q"]');

        
        // disable browser auto complete
        $searchField.attr('autocomplete', 'off');

        //add default css
        var mainSearchField = $('.on-site-search-box').css({
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            'overflow': 'hidden',
            'transition' : 'width 400ms'
        });

        // on focus listener (clear default value)
        mainSearchField.focus(function (e) {
            var $target = $(e.target);
            if ($target.val() === '' || $target.val() === $target.attr('placeholder')) {
                $('#search-reset').hide();
            } else {
                $('#search-reset').show();
            }
            $('.header-search .cancel.arrow-left').css({'display': 'block'});
            
            var windowSize = window.matchMedia('(min-width: 767px)');
            if (windowSize.matches) {
                $('.menu-utility-user').hide();
                $('#mini-cart').hide();
                $target.addClass('BBW-search-expand');
                $target.removeClass('BBW-search-shrink');
                $('.header-search form').removeClass('shrink-position-right');
                $('.header-search form').addClass('expand-position-right');
                $('.search-fly-out-opacity').css({'display': 'block'});
                mobileMenuAria.hideNonModal($('.header-search')[0]);
            }
            
            if (!$('#search-suggestions').length) {
                // create results container if needed
                $resultsContainer = $('<div/>').attr('id', 'search-suggestions').appendTo($searchContainer);
            }
            if ($target.val() === defaultValue) {
                $target.val('');
            }
            
            //On Search Fly out Active hyperlink marked up using ARIA
            $('.hitgroup').on('click', function (){
                $('a.hit').attr('aria-selected','true');
            });
            
            currentQuery = $target.val().trim();
            if (currentQuery.length < 3) {
                currentQuery = '';
            }
            //no query currently running, init an update
            if (currentQuery !== lastQuery) {
                setTimeout(this.suggest.bind(this), delay);
            }
        }.bind(this));
    
//        $(document).on('click', function (e) {
//            if (!$searchContainer.is(e.target)) {
//                setTimeout(this.clearResults, 200);
//            }
//        }.bind(this));
        
        var clickedElement = null;
        
        $(document).mousedown(function(event){
            clickedElement = event.target;
        });
        
        //$(document).mouseup(function(){
            //clickedElement = null;
        //});
        
        $(document).click(function() {
            var searchSuggestions = $('#search-suggestions');
            if (searchSuggestions.html() && searchSuggestions.html().length > 0 && document.activeElement.id !== 'q') {
                if ($(clickedElement).attr('id') !== 'search-reset' && $(clickedElement).parents('div.flyout-product-recommendation').length === 0 
                && $(clickedElement).parents('div.phrase-suggestions').length === 0 && document.activeElement && $(document.activeElement).attr('id') !== 'headersearch' 
                && $(document.activeElement).attr('id') !== 'search-reset' && $(document.activeElement).parents('div.flyout-product-recommendation').length === 0 
                && $(document.activeElement).parents('div.phrase-suggestions').length === 0) {
                    var windowSize = window.matchMedia('(min-width: 767px)');
                    if (windowSize.matches) {
                        $searchField.css('width', '100%');
                        $searchField.removeClass('BBW-search-expand');
                        $searchField.addClass('BBW-search-shrink');
                        $('.header-search form').removeClass('expand-position-right');
                        $('.header-search form').addClass('shrink-position-right');
                        $('.menu-utility-user').show();
                        $('#mini-cart').show();
                        $('.search-fly-out-opacity').css({'display': 'none'});
                        $('.header-search .cancel.arrow-left').css({'display': 'none'});
                        mobileMenuAria.unhideNonModal($('.header-search')[0]);
                    }

                    var $suggestionHTML = $('.search-suggestion-wrapper');
                    $suggestionHTML.stop().slideUp();
                    $('.search-fly-out-opacity').hide();
                    $('.header-search .cancel.arrow-left').css({'display': 'none'});

                    //trigger initial state for mobile
                    var mobileOverlay = $('.ui-widget-overlay.mobile-search');
                    if (mobileOverlay.length) {
                        mobileOverlay.trigger('click');
                    }
                }
                searchsuggest.slideAnimation = true;
            }
        });

        $(document).keyup(function(event) {
            var headerSearchForm = $('.header-search form'),
                menuUtilityUser = $('.menu-utility-user'),
                miniCart = $('#mini-cart'),
                flyOutOpacity = $('.search-fly-out-opacity'),
                searchSuggestionWrapper = $('.search-suggestion-wrapper'),
                popularSearchList = $('.phrase-suggestions .hitgroup div:last-child').last(),
                searchPhraseList = $('.phrase-suggestions .search-data-list .searchpath:last-child').last();

            if (event.keyCode == 27) { 
                $searchField.css('width', '100%');
                $searchField.removeClass('BBW-search-expand');
                $searchField.addClass('BBW-search-shrink');
                headerSearchForm.removeClass('expand-position-right');
                headerSearchForm.addClass('shrink-position-right');
                menuUtilityUser.show();
                miniCart.show();
                flyOutOpacity.css({'display': 'none'});
                searchSuggestionWrapper.css({'display': 'none'});
            }

            $(popularSearchList).focusout(function() {
                $searchField.focus();
            });

            $(searchPhraseList).focusout(function() {
                $searchField.focus();
            });
        });

        var sticky = $('#sticky');
        var mobileSizeOnly = window.matchMedia('(max-width: 480px)'),
            onsiteSearchBoxHeight = $('.onsite-search-wrapper').height();

        if (mobileSizeOnly.matches) {
            $('.onsite-search-wrapper').closest('body').addClass('removeoverflow');
            $(window).scroll(function() {
                if ($(window).scrollTop() > onsiteSearchBoxHeight) {
                    sticky.addClass('fixed');
                } else {
                    sticky.removeClass('fixed');
                }
            });
        } else {
            $('.onsite-search-wrapper').closest('body').removeClass('removeoverflow');
        }

        $('body').on('click', '.searchpath', function() {
            var text = '';
            if ($(this).prev('a').find('span.completed,span.match').length > 1){
                $(this).prev('a').find('span.completed,span.match').each(function(index, element){
                    if (text == ''){
                        text = $(element).data('suggestedphrase');
                    } else {
                        text = text + ' ' + $(element).data('suggestedphrase');
                    }
                });
            } else {
                text = $(this).prev('a').find('span').data('suggestedphrase');
            }
            var queryElement = $('#headerSearch #q');
            queryElement.val(text);
            currentQuery = $searchField.val().trim();
            queryElement.focus();
        });

        $searchField.click(function() {
            var productDesc = $('.onsite-search-wrapper #search-suggestions .product-cont'),
                productDescHeight = maxHeight(productDesc),
                badgeContainer = $('.search-result-items .product-tile .product-badge'),
                badgeDesc = badgeContainer.find('.badge'),
                checkResetButton = $('#search-reset').css('display'),
                searchSuggestionWrapper = $('.search-suggestion-wrapper'),
                phraseSuggestion = $(searchSuggestionWrapper).find('.phrase-suggestions'),
                viewAllLink = $(searchSuggestionWrapper).find('.search-product-viewall'),
                productSuggestionImage = $(searchSuggestionWrapper).find('.flyout-product-recommendation'),
                productSuggestiontitle = $(searchSuggestionWrapper).find('.flyout-product-recommendation'),
                matchedSuggestion = $(searchSuggestionWrapper).find('.autocomplete-search'),
                historySuggestion = $(phraseSuggestion).find('.hit'),
                suggestionProductTitle = $(productSuggestiontitle).find('.suggestion-product-title'),
                suggestionProductImage = $(productSuggestionImage).find('.suggestion-product-image');
                

            productDesc.css('height', productDescHeight);

            if (badgeContainer && badgeDesc.length) {
                badgeContainer.addClass('badge-container');
            }

            if (checkResetButton === 'block') {
                $(this).addClass('reset-button-present');
            } else {
                $(this).removeClass('reset-button-present');
            }

            // View all link tracking
            $(viewAllLink).off('click').on('click', function() {
                /* eslint-disable */
                var dataAttrViewAll = $(this).data('viewall');
                if('cmCreateManualLinkClickTag' in window){
                    cmCreateManualLinkClickTag(dataAttrViewAll);
                }
                /* eslint-disable */
            });

            // Autocomplete suggestion tracking            
            $(matchedSuggestion).off('click').on('click', function() {
                /* eslint-disable */
                var suggestedAutocompletePhrase = $(this).data('autocompletesearch');
                if('cmCreateManualLinkClickTag' in window){
                    cmCreateManualLinkClickTag(suggestedAutocompletePhrase);
                }
                /* eslint-disable */
            });

            // History suggestion tracking            
            $(historySuggestion).off('click').on('click', function() {
                /* eslint-disable */
                var suggestedHistoryPhrase = $(this).data('phrasesuggestion');
                if('cmCreateManualLinkClickTag' in window){
                    cmCreateManualLinkClickTag(suggestedHistoryPhrase);
                }
                /* eslint-disable */
            });

            $(suggestionProductTitle).off('click').on('click', function() {
                /* eslint-disable */
                var suggestedPruductTitle = $(this).data('producttitle');
                if('cmCreateManualLinkClickTag' in window){
                    cmCreateManualLinkClickTag(suggestedPruductTitle);
                }
                /* eslint-disable */
            });

            $(suggestionProductImage).off('click').on('click', function() {
                /* eslint-disable */
                var suggestedProductImage = $(this).data('productimage');
                if('cmCreateManualLinkClickTag' in window){
                    cmCreateManualLinkClickTag(suggestedProductImage);
                }
                /* eslint-disable */
            });
        });

        $('#search-reset').click(function() {
            $('#headerSearch #q').val('');
            $(this).hide();
            currentQuery = $searchField.val().trim();
            $('#headerSearch #q').focus();
        }.bind(this));

        // on key up listener
        mainSearchField.keyup(function (e) {
            var target = $(e.target);
            var windowSize = window.matchMedia('(max-width: 767px)');
            
            if (target.val() === '') {
                $('#search-reset').hide();
            } else {
                $('#search-reset').show();
            }

            // get keyCode (window.event is for IE)
            var keyCode = e.keyCode || window.event.keyCode;

            // check and treat up and down arrows
            if (handleArrowKeys(keyCode)) {
                return;
            }
            // check for an ENTER or ESC
            if (keyCode === 27) {
                this.clearResults();
                return;
            }
            
            if (windowSize.matches) {
                if (keyCode === 13 || keyCode === 27) {
                    target.siblings('.arrow-left').click();    
                }
            }

            currentQuery = target.val().trim();

            // no query currently running, init an update
            if (!runningQuery && (currentQuery.length == 0 || currentQuery.length > 2)) {
                runningQuery = currentQuery;
                setTimeout(this.suggest.bind(this), delay);
            }
        }.bind(this));
    },

    /**
     * @function
     * @description trigger suggest action
     */
    suggest: function () {
        
        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)

        var productSuggestion = $('#search-suggestions .flyout-product-recommendation');        
        var windowSize = window.matchMedia('(max-width: 767px)');

        if (!windowSize.matches && productSuggestion.length > 0) {
            progress.show(productSuggestion);
        }

        if (runningQuery !== currentQuery) {
            // update running query to the most recent search phrase
            runningQuery = currentQuery;
        }

        // if it's empty clear the results box and return
        /*if (runningQuery.length === 0) {
            this.clearResults();
            runningQuery = null;
            return;
        }*/

        // if the current search phrase is the same as for the last suggestion call, just return
        /*if (lastQuery === runningQuery) {
            runningQuery = null;
            return;
        }*/

        // build the request url
        var isMobileDevice = util.isMobileDevice();
        var reqUrl = util.appendParamsToUrl(Urls.searchsuggest, {q: runningQuery, isMobileDevice: isMobileDevice});

        // execute server call
        $.get(reqUrl, function (data) {
            var suggestionHTML = data,
                ansLength = suggestionHTML.trim().length;

            // if there are results populate the results div
            if (ansLength === 0) {
                this.clearResults();
            } else {
                // update the results div
                $resultsContainer.html(suggestionHTML);
                if ($resultsContainer.find('#noResultsFound').length > 0) { 
                    $('#q').attr('aria-label', 'No Results Found!!');
                } else {
                    $('#q').attr('aria-label', 'Navigate forward to review your suggested search terms');
                }
                searchsuggest.resizeSuggestionWrapper();
                var $suggestionHTML = $('.search-suggestion-wrapper');
                if (searchsuggest.slideAnimation) {
                    $suggestionHTML.stop().slideDown({
                        start: function () {
                            $suggestionHTML.css({
                                display: 'flex'
                            });
                            searchsuggest.slideAnimation = false;
                            $('.search-fly-out-opacity').show();
                            $('.header-search .cancel.arrow-left').css({'display': 'block'});
                        }
                    });
                } else {
                    $suggestionHTML.css({
                        display: 'flex'
                    });
                }
            }

            // record the query that has been executed
            lastQuery = runningQuery;
            // reset currently running query
            runningQuery = null;

            // check for another required update (if current search phrase is different from just executed call)
            if (currentQuery !== lastQuery) {
                // ... and execute immediately if search has changed while this server call was in transit
                runningQuery = currentQuery;
                setTimeout(this.suggest.bind(this), delay);
            }
            this.hideLeftPanel();

            progress.hide();
            
            var badgeContainer = $('.search-result-items .product-tile .product-badge'),
                badgeDesc = badgeContainer.find('.badge.new'),
                productDesc = $('.onsite-search-wrapper #search-suggestions .product-cont'),
                productDescHeight = maxHeight(productDesc),
                headerSearch = $('#headerSearch #q');

            if (badgeContainer && badgeDesc.length) {
                badgeContainer.addClass('badge-container');
            }

            productDesc.css('height', productDescHeight);

            setTimeout(function() {
                headerSearch.click();
            }, 1500);
        }.bind(this));
    },
    /**
     * @function
     * @description
     */
    clearResults: function () {
        if (!$resultsContainer) { return; }
    },
    /**
     * @function
     * @description
     */
    hideLeftPanel: function () {
        //hide left panel if there is only a matching suggested custom phrase
        if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
            $('.search-suggestion-left-panel').css('display', 'none');
            $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
            $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
        }
    },
    /**
     * Resize the search suggestion wrapper according to the large screens
     */
    windowWidth: $(window).outerWidth(),

    resizeSuggestionWrapper: function () {
        if ($('#search-suggestion-wrapper-style').length == 0) {
            var suggestionWrapper = $('.search-suggestion-wrapper');
            var suggestionWrapperWidth = suggestionWrapper.outerWidth();
            if (searchsuggest.windowWidth > 1400 && searchsuggest.windowWidth > suggestionWrapperWidth) {
                var calWidth = searchsuggest.windowWidth+'px';
                var calLeft = ((searchsuggest.windowWidth - suggestionWrapperWidth)/2) * -1;
                suggestionWrapper.css({width : calWidth, left : calLeft + 'px'});

                var cssStyle = '#search-suggestions .search-suggestion-wrapper { width : '+ calWidth + ' !important; left : ' + calLeft + 'px !important; }',
                    headElement = document.head || document.getElementsByTagName('head')[0],
                    styleElement = document.createElement('style');

                headElement.appendChild(styleElement);

                styleElement.type = 'text/css';
                styleElement.id = 'search-suggestion-wrapper-style';
                if (styleElement.styleSheet){
                    // This is required for IE8 and below.
                    styleElement.styleSheet.cssText = cssStyle;
                } else {
                    styleElement.appendChild(document.createTextNode(cssStyle));
                }
            }
        }
    }

};

module.exports = searchsuggest;
