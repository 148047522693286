'use strict';

var ajax = require('./ajax'),
    minicart = require('./minicart'),
    addToCart = require('./pages/product/addToCart'),
    addedProductsView = require('./justaddtocart'),
    addedProductsViewRecommendations = require('./justaddtocartrecommendations'),
    util = require('./util'),
    validator = require('./validator');

var setAddToCartHandler = function (e) {
    e.preventDefault();
    var form = $(this).closest('form');
    if (form.valid()){
        var options = {
            url: util.ajaxUrl(form.attr('action')),
            method: 'POST',
            cache: false,
            data: form.serialize()
        };
        $.ajax(options).done(function (response) {
            if (SitePreferences.ENABLE_JATC_SNACKBARS) {
                var fulfillmentType = false;
                if (response.result.egc) {
                    fulfillmentType = 'egc';
                }
                var domResponse = '<div><div class="mini-cart-products" data-newqty="' + response.result.newQty + '" data-newfulfillment="' + fulfillmentType + '"></div>';
                addToCart.initSnackbar(domResponse, true, true);
            }
            if (response.success) {
                if ($('#GiftCertificateLineItemID').size() > 0 && window.pageContext.ns === 'cart') {
                    window.location.href = Urls.cartShow;
                } else {
                    ajax.load({
                        url: Urls.minicartGC,
                        data: {lineItemId: response.result.lineItemId},
                        callback: function (response) {
                            if (SitePreferences.LISTING_ADDTOCARD_POPUP) {
                                var pid = $('.pdp-main.gift-card').find('input[name="pid"]').val();
                                if (SitePreferences.RECOMMENDATIONS_IN_JATC) {
                                    addedProductsViewRecommendations.show(response, pid);
                                } else {
                                    addedProductsView.show(response, pid);
                                }
                            } else {
                                minicart.show(response);
                            }
                            form.find('input,textarea').not(':hidden').val('');
                        }
                    });
                }
            } else {
                form.find('span.error').hide();
                for (var id in response.errors.FormErrors) {
                    var $errorEl = $('#' + id).addClass('error').removeClass('valid').next('.error');
                    if (!$errorEl || $errorEl.length === 0) {
                        $errorEl = $('<span for="' + id + '" generated="true" class="error" style=""></span>');
                        $('#' + id).after($errorEl);
                    }
                    $errorEl.text(response.errors.FormErrors[id].replace(/\\'/g, '\'')).show();
                }
            }
        }).fail(function (xhr, textStatus) {
            // failed
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        });
    }
};

exports.init = function () {
    $('#AddToBasketButton').on('click', setAddToCartHandler);
    validator.initForm($('#giftcard-details-form'));
};
