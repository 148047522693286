'use strict';

var dialog = require('./dialog'),
    product = require('./pages/product'),
    util = require('./util'),
    quantitySelector = require('./quantity-selector'),
    _ = require('lodash');


var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var quickview = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });
    },

    setup: function (qvUrl) {

        product.initializeEvents();

        this.productLinkIndex = _(this.productLinks).findIndex(function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });
    },

    /**
     * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
     */
    navigateQuickview: function (step) {
        // default step to 0
        this.productLinkIndex += (step ? step : 0);
        var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
        dialog.replace({
            url: url,
            callback: this.setup.bind(this, url)
        });
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url;
        if (!this.exists()) {
            this.init();
        }
        url = makeUrl(options.url, options.source, options.productlistid);

        dialog.open({
            target: this.$container,
            url: url,
            options: {
                width: 670,
                dialogClass: 'quickview',
                show: {
                    effect: 'fade',
                    duration: 100
                },
                hide: {
                    effect: 'fade',
                    duration: 100
                },
                open: function () {
                    var QuickViewBopisOption = $('#QuickViewDialog .bopis-delivery-options input:checked').val(),
                        notForPickup = $('.bopis-not-for-pickup');

                    $('.ui-widget-overlay').hide().fadeIn().off('click').on('click', function() {
                        $('.quickview .ui-dialog-titlebar-close').trigger('click');
                    });

                    if (QuickViewBopisOption === 'shipping') {
                        window.digitalData.product.bopis = 'n';
                    } else {
                        window.digitalData.product.bopis = 'y';
                    }

                    if (notForPickup.length) {
                        window.digitalData.product.bopis = 'n';
                    }

                    this.setup(url);
                    if (typeof options.callback === 'function') {
                        options.callback();
                    }
                    setTimeout(function(){
                        var ratingLink = $('.bv-rating-stars-container');
                        var targetLink = $('.details-view').attr('href')+'#BVRRContainer';
                        ratingLink.on('click', function(){
                            $(location).attr('href',targetLink);
                        });
                                               
                    }, 1000)
                    
                    $('#shipping-restriction-accordion').accordion({
                        heightStyle: 'content',
                        active: false,
                        collapsible: true,
                        icons: {
                            header: 'iconOpen',
                            activeHeader: 'iconClosed'
                        }
                    });
                    $('dd.ui-accordion-content').attr('role', 'region');
                    $('#shipping-restriction-accordion').find('button').on('click keyup', function(e){
                        //fixes known bug where aria-expanded doesn't toggle with jQuery accordion 
                        if (!$(this).hasClass('ui-state-active') || (!$(this).hasClass('ui-state-active') && e.keyCode == 32)){
                            $(this).attr('aria-expanded', 'false');
                        }
                    });
                    
                    $('.slider-owl-pdp button.owl-prev').attr('aria-label', 'Previous');
                    $('.slider-owl-pdp button.owl-next').attr('aria-label', 'Next');
                    $('.thumbnails-owl-pdp .owl-stage .owl-item.active.current button').attr('aria-current', 'true');
                    $('.thumbnails-owl-pdp .BBWRKS-quickview-thumbnail-product').click(function() {
                        $('.thumbnails-owl-pdp .owl-stage .owl-item.active button').removeAttr('aria-current', 'true');
                        $(this).attr('aria-current', 'true');
                    });
                    var qvInterval = setInterval(function() {
                        if ($('#QuickViewDialog').is(':visible')) {
                            clearInterval(qvInterval);
                            if ($('#QuickViewDialog .not-available-msg').length) {
                                $('#QuickViewDialog').find('.product-add-to-cart').hide();
                            }
                        }
                    }, 100);
                    if (document.querySelectorAll('#QuickViewDialog #add-to-cart:not(:disabled)').length && 'SitePreferences' in window && 'ORDERGROOVE_ENABLED' in window.SitePreferences && window.SitePreferences.ORDERGROOVE_ENABLED && 'OG' in window && 'getOptins' in window.OG) {              
                        window.autoRefreshTracking.updateATBButton(document.querySelectorAll('#QuickViewDialog #add-to-cart:not(:disabled)'));
                    }

                    var enableQtySelector = SitePreferences.ENABLE_QTY_SELECTOR;
                    var enableQtySelectorTest = SitePreferences.ENABLE_QTY_SELECTOR_TEST;
                    var targetEnableQtySelector = window.digitalData && window.digitalData.page && window.digitalData.page.attributes && window.digitalData.page.attributes.targetflags && window.digitalData.page.attributes.targetflags.enableQtySelector ? window.digitalData.page.attributes.targetflags.enableQtySelector : false;
                    var displayQtySelector = enableQtySelectorTest ? targetEnableQtySelector : enableQtySelector;
                    if (displayQtySelector) {
                        $('#QuickViewDialog #add-to-cart').off('click').on('click', function() {
                            var $addToCartContainer = $(this).closest('.product-add-to-cart');
                            var productId = $addToCartContainer.find('#pdpproductid').val();
                            var quantity = $addToCartContainer.find('#Quantity').val();
                        
                            quantitySelector.addToQtySelectorValue(productId, quantity);
                        });
                    }

    
                }.bind(this),
                close: function() {
                    if (typeof options.closeCallback === 'function') {
                        options.closeCallback();
                    }
                }
            }
        });
    },
    isMobile: function () {
        return util.isMobileDevice();
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = quickview;
