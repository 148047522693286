'use strict';

var quickview = null,
    wishlist = null;

var Certona = {
    inited: false,
    init: function (){
        if ('certona' in window && !Certona.inited) {
            quickview = require('./quickview');
            wishlist = require('./pages/wishlist');
            window.certonaRecommendations = this.certonaRecommendations;
  
            /**
             * manually run callback with test data if site preference value 'CertonaMockEnabled' enabled
             */
            if ('certona' in window && 'developerMode' in window.certona && window.certona.developerMode === true) {
                if ($('#nosearch1_rr').length > 0) {
                    window.certonaRecommendations(this.getFakeData('nosearch1_rr'));
                } else {
                    var validContexts = ['product', 'cart', 'storefront'];
                    var schema = window.pageContext.ns;
                    if (!validContexts.includes(schema)) {
                        schema = 'cart';
                    }
                    
                    window.certonaRecommendations(this.getFakeData(schema + '1_rr'));
                    window.certonaRecommendations(this.getFakeData(schema + '2_rr'));
                }
            }
            wishlist.init();
            Certona.inited = true;
            $('.recommendations').on('click', '.quickview-rec-link', function (e) {
                e.preventDefault();
                quickview.show({
                    url: $(this).attr('href'),
                    source: 'quickview'
                });

                var $parent = $(this).closest('.recommendation-item'),
                    pid = $parent.data('pid'),
                    productName = $parent.data('productname'),
                    index = $parent.data('index'),
                    containerID = $parent.closest('.recommendations').attr('id');
                 /* eslint-disable */
                 if ('cmCreateElementTag' in window) {
                    cmCreateElementTag('Quicklook Click', containerID, pid + '-_-' + productName + '-_--_--_-' + index + '-_-' + window.digitalData.page.pageInfo.pageID);
                }
                /* eslint-enable */
            });
        }
    },
    certonaRecommendations: function(obj){
        if ('resonance' in obj){
            var schemes = obj.resonance.schemes;
            for (var k = 0; k < schemes.length; k++) {
                var schema = schemes[k];
                if (schema !== undefined && 'display' in schema && schema.display.toLowerCase() === 'yes') {
                    Certona.proceedItems(schema);
                }
            }
        }
    },
    proceedItems: function (schema) {
        var recommendationsBlock = $('#' + schema.scheme),
            items = [],
            recsUrl = Urls.certonaReccomendations;

        // #854981 - Certona_Duplicate Containers on PDP
        recommendationsBlock.find('.horizontal-carousel').remove();
        // #852871 - [Certona] incorrect amount of items in most Certona slots & no scrollability (no arrows) present
        var itemsLimit = 12;
        for (var i = 0; i < schema.items.length; i++) {
            if (i >= itemsLimit) {
                break;
            }
            var item = schema.items[i];
            if (item !== undefined && 'id' in item) {
                items.push(item.id);
            }
        }
        /**
        * Pass the Certona object to the pipeline call
        */
        var certonaItems = JSON.stringify(schema.items);
        
        if (items.length > 0) {
            $.post(recsUrl, {items : items.join(','), scheme: schema.scheme, certonaItems: certonaItems}, function (data){
                recommendationsBlock.addClass('promo-block')
                    .append(data);

                /**
                 * #847791
                 * we need run separate page scroll here in case if load Certona products after all page load 
                 */
                //firescroll appear in DOM as hidden input if customer apply coupon code on cart page
                if ($('#firescroll').length > 0) {
                    var imgCount = recommendationsBlock.find('img').length;
    
                    $('img', recommendationsBlock).load(function (){
                        imgCount --;
                        if (imgCount === 0 && $('.error-form').length == 0){
                            $('html,body').animate({scrollTop: $('.cart-footer').offset().top - $('.promo-block').first().height()});
                        }
                    });
                }

                var owlConfig = {
                    loop: false,
                    responsiveClass: true,
                    mouseDrag: true,
                    navSpeed: 1000,
                    navRewind: false,
                    dots: true,
                    responsive: {
                        0: {
                            center: true,
                            items: 2,
                            margin: 30
                        },
                        768: {
                            nav: true,
                            items: 4,
                            margin: 45,
                            stagePadding: 45,
                            mouseDrag: false,
                            slideBy: 4,
                            navSpeed: 200
                        }
                    }
                };
                // provide special config for 'home' implementation
                if (schema.scheme === 'home1_rr') {
                    owlConfig = {
                        loop: false,
                        responsiveClass: true,
                        mouseDrag: true,
                        navSpeed: 200,
                        navRewind: false,
                        dots: false,
                        nav: true,
                        responsive: {
                            0: {
                                center: true,
                                items: 1,
                                margin: 30
                            },
                            480: {
                                items: 2,
                                margin: 15,
                                stagePadding: 15,
                                slideBy: 2
                            },
                            768: {
                                items: 4,
                                margin: 15,
                                stagePadding: 15,
                                slideBy: 4
                            },
                            1024: {
                                items: 4,
                                margin: 15,
                                stagePadding: 15,
                                slideBy: 4
                            }
                        },
                        onInitialized: Certona.carouselLoaded
                    }
                } else {
                    Certona.quickView();

                    $('.grid-tile.recommendation-item').off('click').on('click', function (e) {
                        var pid = $(this).data('pid'),
                            productName = $(this).data('productname'),
                            index = $(this).data('index'),
                            containerID = $(this).parents('.recommendations').attr('id');

                        /* eslint-disable */
                        if ('cmCreateElementTag' in window) {
                            // prevent PDP Click when quickview button is clicked
                            if ($(e.target).attr('id') !== 'quickviewbutton') {
                                cmCreateElementTag('PDP Click', containerID, pid + '-_-' + productName + '-_--_--_-' + index + '-_-' + window.digitalData.page.pageInfo.pageID);
                            }
                        }
                        /* eslint-enable */
                    });
                }
                var owlCarousel = $('.horizontal-carousel');
                owlCarousel.on('dragged.owl.carousel', function() { 
                    recommendationsBlock.find('.product-tile.tooltip').show();
                });
                owlCarousel.owlCarousel(owlConfig);

            });
        } else {
            recommendationsBlock.hide();
        }
    },
    quickView: function() {
        if (!$('.qv-off').length && !quickview.isMobile()) {
            var search = require('./tealium/pages/search');
            search.init();
        }
    },
    getFakeData: function(scheme) {
        var data = {
            'product1_rr': {
                'resonance': {
                    'schemes': [
                        {
                            'scheme': 'product1_rr',
                            'explanation': 'More You\'ll Adore',
                            'display': 'yes',
                            'items': [{'id': '020002432'}, {'id': '020026246'}, {'id': '020039097'}, {'id': '020043936'}, {'id': '020044669'}]
                        }
                    ]
                }
            },
            'product2_rr': {
                'resonance': {
                    'schemes': [
                        {
                            'scheme': 'product2_rr',
                            'explanation': 'More You\'ll Adore',
                            'display': 'yes',
                            'items': [{'id': '020050516'}, {'id': '020112851'}, {'id': '020178978'}, {'id': '020178980'}, {'id': '020254024'}]
                        }
                    ]
                }
            },
            'cart1_rr': {
                'resonance': {
                    'schemes': [
                        {
                            'scheme': 'cart1_rr',
                            'explanation': 'Wait! add our fun picks',
                            'display': 'yes',
                            'items': [{'id': '020515729'}, {'id': '020515472'}, {'id': '020515483'}, {'id': '020518770'},{'id': '020002432'}, {'id': '020026246'}, {'id': '020039097'}, {'id': '020043936'}, {'id': '020044669'},{'id': '020002432'}, {'id': '020026246'}, {'id': '020039097'}, {'id': '020043936'}, {'id': '020044669'},{'id': '020002432'}, {'id': '020026246'}, {'id': '020039097'}, {'id': '020043936'}, {'id': '020044669'}]
                        }
                    ]
                }
            },
            'cart2_rr': {
                'resonance': {
                    'schemes': [
                        {
                            'scheme': 'cart2_rr',
                            'explanation': 'More You\'ll Adore',
                            'display': 'yes',
                            'items': [{'id': '020518774'}, {'id': '020518775'}, {'id': '020518777'}, {'id': '020640600'}]
                        }
                    ]
                }
            },
            'home1_rr': {
                'resonance': {
                    'schemes': [
                        {
                            'scheme': 'home1_rr',
                            'explanation': 'Wait! add our fun picks',
                            'display': 'yes',
                            'items': [{'id': '020515729'}, {'id': '020515472'}, {'id': '020515483'}, {'id': '020518770'},{'id': '020002432'}, {'id': '020026246'}, {'id': '020039097'}, {'id': '020043936'}, {'id': '020044669'},{'id': '020002432'}, {'id': '020026246'}, {'id': '020039097'}, {'id': '020043936'}, {'id': '020044669'},{'id': '020002432'}, {'id': '020026246'}, {'id': '020039097'}, {'id': '020043936'}, {'id': '020044669'}]
                        }
                    ]
                }
            },
            'nosearch1_rr': {
                'resonance': {
                    'schemes': [
                        {
                            'scheme': 'nosearch1_rr',
                            'explanation': 'Your Next Favorite',
                            'display': 'yes',
                            'items': [{'id': '020518774'}, {'id': '020518775'}, {'id': '020518777'}, {'id': '020640600'}]
                        }
                    ]
                }
            }
        }

        return data[scheme];
    },
    carouselLoaded: function() {
        // this function is for post-owl rendering ONLY fot he homepage
        // adding ATB button via JS - should be fixed with template update in future Certona enhancement work
        $('.recommendations-module .product-tile').each(function () {
            var pid = $(this).find('.capture-product-id').text(),
                $button = $(this).find('button.hide');
            $button.detach();
            $button.attr('class', 'add-to-cart').attr('type', 'submit').attr('title', 'Add to Bag').attr('value', 'Add to Bag').text('Add to Bag');
            var $form = $('<form method="post" action="' + window.location.origin + '/on/demandware.store/Sites-BathAndBodyWorks-Site/en_US/Cart-AddProduct" novalidate="novalidate"><fieldset><input type="hidden" name="pid" value="' + pid + '"><input type="hidden" name="maxQtyAvailable" value="null"><input type="hidden" name="purchaseLimit" value="null"><input type="hidden" id="totalQty" name="totalQty" value="null"><input type="hidden" name="Quantity" value="1"><div class="clearfix centered"></div></fieldset></form>');
            $form.find('div.centered').append($button);
            $(this).append($form);
        });

        $('.recommendations-module').addClass('loaded');
    }
}

module.exports = Certona;
