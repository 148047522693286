'use strict';
var addProductToCart = require('./product/addToCart'),
    account = require('./account'),
    clipboardUtils = require('../designSystem/clipboardUtils');


var wlInited = false;

exports.init = function () {
    account.initAccountNav();
    if (!wlInited) {
        $(document).off('click', '.wish-list-link.wl', addToWishList);
        $(document).on('click', '.wish-list-link.wl', addToWishList);
        wlInited = true;
    }

    var showJustAdded = function() {
        $('.wish-list-link.remove-from-wish-list').hover(function(){
            $('.wish-list-added.hover').stop(true).slideDown('slow');
        }, function(){
            $('.wish-list-added.hover').delay(500).slideUp('slow');
        });
    }
    showJustAdded();

    if ($('.wish-list-added.redirect').size() > 0) {
        $('.wish-list-link.remove-from-wish-list').unbind('mouseenter mouseleave');
        setTimeout(function () {
            $('.wish-list-added.redirect').not('.hover').slideUp('slow');
            showJustAdded();
        }, 4000);
    }

    if ($('#BBW-loveit-table').length > 0) {
        addProductToCart();
    }

    $('.view-more-row').on('click', '.view-more', function(e) {
        e.preventDefault();
        var parent = $(this).parents('.view-more-row');
        parent.hide();
        parent.nextAll('.wishlist-row').show();
    });

    function addToWishList(event) {
        var elem = $(this);
        var events = $(event.target);
        if (elem.hasClass('disabled')) {
            $('.wish-list-guest-message').stop(true).slideDown('slow');
            $(this).removeClass('wl');
            elem.unbind('mouseenter mouseleave');
            var timeoutID;
            elem.hover(function () {
                clearInterval(timeoutID)
            }, function () {
                clearInterval(timeoutID);
                function slideDown() {
                    $('.wish-list-guest-message').stop(true).slideUp('slow');
                    setTimeout(function () {
                        $('.wish-list-link').addClass('wl')
                    }, 500);
                }

                timeoutID = setTimeout(function () {
                    slideDown()
                }, 4000);
                //$(this).unbind('mouseenter mouseleave');
                return false;

            });
        } else {
            $.get(elem.data('href'), function (data) {
                if (data && data.success) {
                    if (events.is('.wish-list-link.wl')) {
                        $('.wish-list-link.hidden').removeClass('hidden');
                        elem.addClass('hidden');
                        if (elem.hasClass('simple')) {
                            $('.wish-list-link.remove-from-wish-list').unbind('mouseenter mouseleave');
                            $('.wish-list-added.justadded').slideDown('slow');
                            setTimeout(function () {
                                $('.wish-list-added.justadded').slideUp('slow');
                                showJustAdded();
                            }, 4000);
                        }
                    }
                }
            });
        }
    }
    $('.wish-list-added').on('click', function (e) {
        e.stopPropagation();
    });
    $('#BBW-loveit-table .product-list-item').ready(function () {
        $('#BBW-loveit-table').find('.product-list-item').find('.name').attr('role', 'heading').attr('aria-level', '3');
    });

    var triggerButton = document.getElementById('copyWishlistUrl');
    if (triggerButton) {
        triggerButton.addEventListener('click', function (event) {
            clipboardUtils.copyToClipboard(event, triggerButton, 'wishlistUrl', Resources.WISHLIST_SHARE_COMPLETED);
        });
    }
};


