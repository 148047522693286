'use strict';

var giftcard = require('../giftcard'),
    progress = require('../progress'),
    ajax = require('../ajax'),
    util = require('../util'),
    giftcert = require('../giftcert'),
    validator = require('../validator');

function landingPageEvents () {
    $('#giftcard-landing-form').on('submit', function () {
        var code = $('input[name$="_giftCardID"]').val(),
            pin = $('input[name$="_giftCardPin"]').val(),
            $balance = $('.balance'),
            captchaToken = (window.grecaptcha ? window.grecaptcha.getResponse() : '');

        $balance.empty();
        giftcard.checkBalance(code, pin, captchaToken, function (data) {
            if (code.length === 0) {
                $balance.addClass('error').html(Resources.GIFT_CERT_MISSING);
                return;
            } else if (code.length < 15) {
                $balance.addClass('error').html(Resources.GIFT_CERT_INVALID_LENGTH);
                return;
            }
            if (pin.length === 0) {
                $balance.addClass('error').html(Resources.GIFT_CERT_PIN_MISSING);
                return;
            } else if (pin.length < 4) {
                $balance.addClass('error').html(Resources.GIFT_CERT_PIN_INVALID_LENGTH);
                return;
            }
            if (code.length > 14 && pin.length > 3){
                $balance.removeClass('error').empty();
            }
            if ((data && data.error) || (!data || !data.balance)) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                if (window.grecaptcha) {
                    window.grecaptcha.reset();
                }
                return;
            }
            $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.balance).removeClass('error').addClass('success');
        });

        return false;
    });

    var icons = {
        header: 'iconClosed',
        activeHeader: 'iconOpen'
    };

    $('.gift-card-balance').accordion({
        heightStyle: 'content',
        active: false,
        collapsible: true,
        icons: icons
    });
}

function detailsPageEvents () {
    var $pdpMain = $('.pdp-main.gift-card').first();

    $pdpMain.on('click', '.gc-variants li', function (e) {
        e.preventDefault();
        var elem = $(this);
        if (elem.hasClass('selected')) {
            return;
        }
        progress.show($pdpMain);
        var href = elem.find('a').first().attr('href');
        $('.gc-image-thumb.selected').attr('aria-checked', false);
        $('.gc-image-thumb.selected').removeClass('selected');
        elem.addClass('selected');
        elem.attr('aria-checked', true);
        ajax.load({
            url: util.appendParamsToUrl(href, {format: 'ajax'}),
            target: $('#product-content'),
            callback: function(){
                $('.primary-image').attr('src', elem.data('lgimage'));
                $('.primary-image').attr('alt', elem.data('lg-img-alt'));
                validator.init();
                giftcert.init();
            }
        });
    });
}

exports.init = function () {
    landingPageEvents();
    detailsPageEvents();
    giftcert.init();
}
