'use strict';
var storeLocator = require('../store-locator');

function storeLocatorAnalyticsLoyaltyAsset() {
    if ($('#wrapper').hasClass('pt_store-locator') &&
        'digitalData' in window &&
        $('.store-locator-slot .prospect_locator').length > 0) {
        window.digitalData.page.loyaltyJoinAsset = true;
    }
}

exports.init = function () {
    var locator = window.Scripts.storeLocator,
        options = locator.vars,
        marker  = options.markerurl;
    if ($('#storedetails-wrapper').length > 0) {
        marker = options.markerdetailurl;
    }
    storeLocatorAnalyticsLoyaltyAsset();
    storeLocator.init(options.zoomradius, marker, options.activemarkerurl, options.queryurl, options.cookieurl, options.cookiename, options.defaultlocation, options.maptype);
};
