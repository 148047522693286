'use strict';
module.exports = function () {
    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    function scrollHandler(videoHandle, isUserInteract, playVideo) {
        var ratio = .3,
            boundingRectangle = videoHandle.el().getBoundingClientRect(),
            boundingHeightRatio = boundingRectangle.height * ratio,
            elTop = boundingRectangle.top + boundingHeightRatio,
            elBot = boundingRectangle.bottom - boundingHeightRatio,
            viewHeight = window.innerHeight;

        if (isUserInteract) {
            return;
        } else if (elTop > 0 && elBot < viewHeight) {
            if (videoHandle.paused()) {
                playVideo();
            }
        } else {
            videoHandle.pause();
        }
    }

    var isAncestorBlock = function (el, ancestorSelector) {
        var ancestor = el.closest(ancestorSelector);
        if (ancestor) {
            return window.getComputedStyle(ancestor).display === 'block';
        }
        return false;
    }

    var videoJSElements = document.querySelectorAll('.design-system video-js');
    if (typeof videojs !== 'undefined' && videoJSElements.length) {
        for (var i = 0; i < videoJSElements.length; i++) {

            videojs.getPlayer(videoJSElements[i]).ready(function () {  // eslint-disable-line no-undef
                var videoReadyToPlay = false;
                var videoHandle = this;
                var videoJSElement = videoHandle.el();
                var posters = videoJSElement.querySelectorAll('.vjs-poster');
                var isUserInteract = window.sessionStorage.getItem('userVideoPause') === 'true' ? true : false;
                var videoLoopNum = 0;
                var numberOfTimesToLoop = 1;
                var playPauseBtn = videoJSElement.querySelector('.vjs-control-bar .vjs-play-control');

                var playVideo = function () {
                    // meta data not loaded dont play yet
                    if (!videoReadyToPlay) return;

                    if (videoLoopNum < numberOfTimesToLoop &&
                        isAncestorBlock(videoJSElement, '.ds-video-desktop, .ds-video-mobile')
                    ) {
                        videoHandle.play();
                    }
                };

                var hidePosters = function () {
                    posters.forEach(function (poster) {
                        poster.style.display = 'none';
                    });
                };

                var showPosters = function () {
                    posters.forEach(function (poster) {
                        poster.style.display = 'block';
                    });
                };

                var autoplayDebouncer = debounce(function () {
                    scrollHandler(videoHandle, isUserInteract, playVideo);
                }, 300, false);

                var handlePlayPauseClick = function() {
                    isUserInteract = true;
                    window.sessionStorage.setItem('userVideoPause', true);
                };

                var detectIfCaptionsActive = function() {
                    var captionsEnabled = false
                    var captionsList = videoHandle.textTracks()

                    for (var i = 0; i < captionsList.length; i++) {
                        var track = captionsList[i];
                        // Check each track if is valid subtitle or caption AND if it's showing
                        if ((track.kind === 'subtitles' || track.kind === 'captions') && track.mode === 'showing') {
                            captionsEnabled = true;
                            break;
                        }
                    }

                    if (captionsEnabled){
                        videoJSElement.classList.add('vjs-captions-on')
                    } else {
                        videoJSElement.classList.remove('vjs-captions-on')
                    }

                }

                videoHandle.on('loadstart', function () {
                    var imgPoster = videoJSElement.querySelectorAll('picture.vjs-poster');
                    if (imgPoster) {
                        imgPoster.setAttribute('role', 'img');
                        imgPoster.setAttribute('aria-label', videoHandle.mediainfo.description || '');
                    }
                });

                videoHandle.on('play', function () {
                    hidePosters();
                });

                videoHandle.on('ended', function () {
                    videoLoopNum++;
                    // on end if posters exist, reveal the poster again
                    if (posters.length !== 0) {
                        showPosters();
                    }

                    // programatic looping => numberOfTimesToLoop
                    playVideo();
                });

                // required to confirm meta data is avaliable
                // https://player.support.brightcove.com/basics/developer-basics-autoplaying-video.html
                videoHandle.on('loadedmetadata', function () {
                    videoReadyToPlay = true;

                    // check if captions active
                    // safari/ios has a problem with attaching event triggers to brightcove captiosn button and is preventing bubbling events
                    // mobile touch has a bug that would require a settimeout - this solves for both issues by checking on interval
                    setInterval(detectIfCaptionsActive, 300);

                    if (!videoJSElement.hasAttribute('preventautoplay')) {
                        window.addEventListener('scroll', autoplayDebouncer);
                        // when video is ready, do one check to see if it should autoplay without scrolling.
                        autoplayDebouncer();
                    }
                });

                if (playPauseBtn) {
                    playPauseBtn.addEventListener('touchstart', handlePlayPauseClick);
                    playPauseBtn.addEventListener('click', handlePlayPauseClick);
                }

            });
        }
    }
};
