'use strict';

var variantSizeSelection = {
    initVariantSizeSelection: function() {
        var buttons = document.querySelectorAll('.size-button');
        var productID = document.getElementById('pid').value;
        var initialMaxQtyAvailable = document.getElementById('maxQtyAvailable').value;
        var qtyInput = document.getElementById('Quantity');
        var qtyIncreaseButton = document.getElementsByClassName('quantity-button increase')[0];

        var webInventoryRadio = document.getElementById('pdp-shippingOption');
        var storeInventoryRadio = document.getElementById('pdp-pickFromStoreOption');

        function isQtyIncreaseButtonDisabled(pid, productID) {
            var productVariations = document.querySelector('.product-variations');
            if (!productVariations) {
                return;
            }
            if (pid.value === productID) {
                qtyIncreaseButton.classList.add('disabled');
                qtyInput.readOnly = true;
            } else {
                qtyIncreaseButton.classList.remove('disabled');
                qtyInput.readOnly = false;
            }
        }

        function setUpdatedDataSku(button) {
            var pid = document.getElementById('pid');
            var pdpProductId = document.getElementById('pdpproductid');
            var maxQty = document.getElementById('maxQtyAvailable');
            var totalQty = document.getElementById('totalQty');
            var sku = button.getAttribute('data-sku');
            var totalButtonQty;
            if (webInventoryRadio && webInventoryRadio.checked) {
                totalButtonQty = button.getAttribute('web-inventory');
            } else if (storeInventoryRadio && storeInventoryRadio.checked) {
                totalButtonQty = button.getAttribute('store-inventory');
            } else {
                totalButtonQty = button.getAttribute('web-inventory');
            }

            if (sku) {
                if (pid) {
                    pid.value = sku;
                }
                if (pdpProductId) {
                    pdpProductId.value = sku;
                }
                if (maxQty) {
                    maxQty.value = initialMaxQtyAvailable;
                }
                if (totalQty) {
                    totalQty.value = totalButtonQty;
                }
            }

            isQtyIncreaseButtonDisabled(pid, productID);
        }

        function resetSku() {
            var pid = document.getElementById('pid');
            var pdpProductId = document.getElementById('pdpproductid');
            pid.value = productID;
            pdpProductId.value = productID;

            isQtyIncreaseButtonDisabled(pid, productID);
        }

        function getSessionStorageKey(name) {
            return (storeInventoryRadio && storeInventoryRadio.checked ? 'store' : 'web') + '_' + name + '_' + productID;
        }

        function resetSizeSelectionHeader() {
            var selectedSizeHeader = document.querySelector('.selected-size-header-text');
            var selectSizeText = document.querySelector('.size-guide-select-link');

            if (selectedSizeHeader) {
                selectedSizeHeader.innerText = '';
            }
            if (selectSizeText) {
                selectSizeText.innerText = selectSizeText.getAttribute('data-select-size-text');
            }
        }

        function loadSelectedButton() {
            var selectedButtonIndex, selectedSizeText;
            if (webInventoryRadio || storeInventoryRadio) {
                selectedButtonIndex = sessionStorage.getItem(getSessionStorageKey('selectedButtonIndex'));
                selectedSizeText = sessionStorage.getItem(getSessionStorageKey('selectedSizeText'));
            } else {
                selectedButtonIndex = sessionStorage.getItem('selectedButtonIndex_' + productID);
                selectedSizeText = sessionStorage.getItem('selectedSizeText_' + productID);
            }

            var selectedSizeHeader = document.querySelector('.selected-size-header-text');

            buttons.forEach(function(btn) {
                btn.classList.remove('selected');
            });

            if (selectedButtonIndex && buttons[selectedButtonIndex]) {
                buttons[selectedButtonIndex].classList.add('selected');
                var button = buttons[selectedButtonIndex];
                setUpdatedDataSku(button);
                // If size is selected then remove error
                $('#sizeNotSelectedError').hide();
            } else {
                resetSku();
            }

            if (selectedSizeText) {
                selectedSizeHeader = document.querySelector('.selected-size-header-text');
                if (selectedSizeHeader) {
                    selectedSizeHeader.innerText = selectedSizeText;
                }
                var selectSizeText = document.querySelector('.size-guide-select-link');
                if (selectSizeText) {
                    selectSizeText.innerText = selectSizeText.getAttribute('data-selected-size-text');
                }
            } else {
                if (selectedSizeHeader && selectedSizeHeader.innerText !== '') {
                    resetSizeSelectionHeader();
                }
            }
        }

        function updateButtons(isStoreSelected) {
            if (buttons) {
                buttons.forEach(function(button, index) {
                    var inventory;
                    if (webInventoryRadio || storeInventoryRadio) {
                        inventory = isStoreSelected ? button.getAttribute('store-inventory') : button.getAttribute('web-inventory');
                    } else {
                        inventory = button.getAttribute('web-inventory');
                    }
                    var slash = document.createElement('span');
                    if (inventory === '0.0' || inventory === '0') {
                        button.classList.add('sold-out');
                        slash.classList.add('slash');
                        button.appendChild(slash);
                        if (button.classList.contains('selected') && button.classList.contains('sold-out')) {
                            var pid = document.getElementById('pid');
                            var pdpProductId = document.getElementById('pdpproductid');
                            sessionStorage.removeItem(getSessionStorageKey('selectedSizeText'));
                            sessionStorage.removeItem(getSessionStorageKey('selectedButtonIndex'));
                            if (pid) {
                                pid.value = productID;
                            }
                            if (pdpProductId) {
                                pdpProductId.value = productID;
                            }
                            isQtyIncreaseButtonDisabled(pid, productID);
                        }
                    } else {
                        button.classList.remove('sold-out');
                        slash = button.querySelector('.slash');
                        if (slash) {
                            button.removeChild(slash);
                        }
                    }
                    button.addEventListener('click', function(event) {
                        event.preventDefault();
                        buttons.forEach(function(btn) {
                            btn.classList.remove('selected');
                        });
                        button.classList.add('selected');
                        $('#sizeNotSelectedError').hide();

                        var selectedSize = button.textContent;
                        var selectedSizeHeader = document.querySelector('.selected-size-header-text');
                        if (selectedSizeHeader) {
                            selectedSizeHeader.innerText = selectedSize;
                        }

                        var selectSizeText = document.querySelector('.size-guide-select-link');
                        if (selectSizeText) {
                            selectSizeText.innerText = selectSizeText.getAttribute('data-selected-size-text');
                        }

                        if (webInventoryRadio || storeInventoryRadio) {
                            sessionStorage.setItem(getSessionStorageKey('selectedButtonIndex'), index.toString());
                            sessionStorage.setItem(getSessionStorageKey('selectedSizeText'), selectedSize);
                        } else {
                            sessionStorage.setItem('selectedButtonIndex_' + productID, index.toString());
                            sessionStorage.setItem('selectedSizeText_' + productID, selectedSize);
                        }
                        // Updating productID to sku associated w/ size button
                        setUpdatedDataSku(button);
                    });
                });
                refreshInventoryState();
            }
        }

        function refreshInventoryState() {
            var selectedButtonIndex = (webInventoryRadio || storeInventoryRadio)
                ? selectedButtonIndex = sessionStorage.getItem(getSessionStorageKey('selectedButtonIndex'))
                : sessionStorage.getItem('selectedButtonIndex_' + productID);

            if (selectedButtonIndex === null) return;

            var selectedButton = buttons[selectedButtonIndex];

            if (selectedButton) {
                if (webInventoryRadio && webInventoryRadio.checked) {
                    webInventoryRadio.click();
                } else if (storeInventoryRadio && storeInventoryRadio.checked) {
                    storeInventoryRadio.click();
                } else {
                    selectedButton.click();
                }
            }
        }

        if (webInventoryRadio && storeInventoryRadio) {
            webInventoryRadio.addEventListener('click', function() {
                updateButtons(false);
                loadSelectedButton();
            });
            storeInventoryRadio.addEventListener('click', function() {
                updateButtons(true);
                loadSelectedButton();
            })
        }

        if (storeInventoryRadio && storeInventoryRadio.checked) {
            updateButtons(true);
        } else {
            updateButtons(false);
        }
        loadSelectedButton();

        // Clearing stored index and text from session storage when navigating away
        // from the product page, while maintaining persistence on sign in/refresh.
        window.addEventListener('pageshow', function() {
            if (webInventoryRadio || storeInventoryRadio) {
                sessionStorage.removeItem(getSessionStorageKey('selectedButtonIndex'));
                sessionStorage.removeItem(getSessionStorageKey('selectedSizeText'));
            }
        });
    }
};

module.exports = variantSizeSelection;

