'use strict';

var validator = require('../validator'),
    account = require('./account');

/**
 * @function
 * @description Initializes the events on the Loyalty BOC form.
 * @param {Element}
 *            form The form which will be initialized
 */
function initializeBOCForm() {
    var $form = $('#RegistrationFormBoc');
    validator.init();
    $form.on('click', 'button[type="submit"]:not(.link-edit)', function(e) {
        if (!$form.valid()) {
            e.preventDefault();
            return false;
        }
    });
    $form.on('keydown', function(e) {
        var keyCode = e.keyCode || window.event.keyCode;
        if (keyCode === 13) {
            var editButton = $form.find('button.link-edit[type="submit"]');
            if (!editButton.is(':focus')) {
                e.preventDefault();
                $form.find('button[type="submit"]:not(.link-edit)').last().click();
            }
        }
    });
}

function setupAppDownloadButtons() {
    var $downloadButtons = $('.bbwd-button');
    var isAndroid = (/android/i).test(navigator.userAgent.toLowerCase());
    var isiDevice = (/ipad|iphone|ipod/i).test(navigator.userAgent.toLowerCase());

    $downloadButtons.each(function() {
        var $downloadButton = $(this);
        
        //changing link depending on useragent
        if (isAndroid && $downloadButton.data('android') !== undefined) {
            $downloadButton.attr('href', $downloadButton.data('android'));
        }
        if (isiDevice && $downloadButton.data('ios') !== undefined) {
            $downloadButton.attr('href', $downloadButton.data('ios'));
        }
    });
}

function activateSubmitBtn() {
    var registrationFormBoc = $('#RegistrationFormBoc');
    var submitButton = $('#RegistrationFormBoc').find('button[type="submit"]').not('.link-edit');
    
    var isValid = true;
    if (registrationFormBoc.find('input[name$="_addtoemaillist"]').length > 0 && registrationFormBoc.find('input[name$="_addtoemaillist"]:checked').val() != 'true') {
        isValid = false;
    }
    
    if (isValid) {
        submitButton.removeAttr('disabled');
    } else {
        submitButton.attr('disabled', 'disabled');
    }
}

function inputTester() {
    $('input').on('click blur keyup', function(){
        activateSubmitBtn();
    });
}

/**
 * @function
 * @description Initializes the Tracking events on the Loyalty BOC form.
 * @param {Element}
 *            form The form which will be initialized
 */
function initializeBOCTracking() {
    var dd = returnDigitalData(),
        extendedValues = returnTrackingParams([
            {index: 9, value: dd.loyaltyID},
            {index: 10, value: dd.rewardsCount},
            {index: 11, value: dd.currentSpend},
            {index: 14, value: 'loyl_acct_active'}
        ]);
    
    $('.boc-rewards.landing, .refresh-rlp.landing').on('click', 'a.button', function() {
        window.cmCreateElementTag('get started', 'LOYALTY_SIGNUP_RLP');
    });
    
    $('.boc-rewards.start').on('click', 'button', function() {
        window.cmCreateElementTag('email', 'LOYALTY_SIGNUP_RLP');
    });
    
    $('.boc-rewards.member-start ').on('click', 'button[value="Sign In"]', function() {
        window.cmCreateElementTag('SIGN IN COMPLETE LOYALTY ACCT', 'LOYALTY_SIGNUP_RLP');
    });

    $('.boc-rewards.guest-meta').on('click', 'button', function() {
        window.cmCreateElementTag('eligible-all', 'LOYALTY_SIGNUP_RLP');
    });
    
    $('.boc-rewards.store-signup').on('click', 'button', function() {
        window.cmCreateElementTag('pos-email', 'LOYALTY_SIGNUP_RLP');
    });
    
    $('.boc-rewards.store-signup-reset').on('click', 'a.button', function() {
        window.cmCreateElementTag('pos-signin', 'LOYALTY_SIGNUP_RLP');
    });
    
    if ($('.boc-rewards.ineligible').length) {
        window.cmCreateElementTag('ineligible-zip', 'LOYALTY_SIGNUP_RLP');
    }
    
    if ($('.boc-rewards.eligible-zip').length) {
        window.cmCreateElementTag('eligible-zip', 'LOYALTY_SIGNUP_RLP');
    }
    
    if ($('.boc-rewards.registered-meta').length) {
        window.cmCreateRegistrationTag(dd.profileEmailHash, dd.profileEmailHash, dd.city, dd.state, dd.postal, dd.country, returnTrackingParams([
            {index: 9, value: dd.loyaltyID},
            {index: 10, value: dd.rewardsCount},
            {index: 11, value: dd.currentSpend},
            {index: 14, value: 'bbw_acct'}])
        );
    }
     
    if ($('.boc-rewards.active.rewards-login').length) {
        window.cmCreateRegistrationTag(dd.profileEmailHash, dd.profileEmailHash, dd.city, dd.state, dd.postal, dd.country, extendedValues);
    }
    
    if ($('.boc-rewards.success.member-flow').length) {
        window.cmCreateRegistrationTag(dd.profileEmailHash, dd.profileEmailHash, dd.city, dd.state, dd.postal, dd.country, extendedValues);
        window.cmCreateElementTag('SIGNUP COMPLETE BBW ACCT', 'LOYALTY_SIGNUP_RLP');
    }
    
    if ($('.boc-rewards.success.guest-flow').length) {
        window.cmCreateRegistrationTag(dd.profileEmailHash, dd.profileEmailHash, dd.city, dd.state, dd.postal, dd.country, extendedValues);
        window.cmCreateElementTag('SIGNUP COMPLETE GUEST', 'LOYALTY_SIGNUP_RLP');
    }
}

/**
 * @function
 * @description Utility method for setting dynamic tracking elements.
 * @param {Array} An arry ary of objects, which contain key and index locations, as to where they belong in the param string.
 * @return {String} The delimited param string.
 */
function returnTrackingParams(values) {
    var delimiter = '-_-',
        params = [];
    
    // Create an array with the blank values, with the maximum values that CoreMetrics will accept.
    for (var i = 0; i < 50; i++) {
        params.push(delimiter);
    }
    
    // Loop through the params array.
    params = params.map(function(param, index) {
        // Loop through the passed values array, and add them to the params array in the correct location.
        for (var i = 0; i < values.length; i++) {
            // Take into account, the index is being subtracted by 1.
            // Whenever a value is requested by CoreMetrics, they do not utilize array index position.
            // If our value falls in line with the requested location, return the value, and not the param.
            if ((values[i].index - 1) == index) {
                // Make sure the value is valid.
                if (values[i].value.toString().length) {
                    return values[i].value.toString() + delimiter;
                } else {
                    return param;
                }
            }
        }
        // If we land here, there is no value for the current index.
        // Return the param.
        return param;
    });
    
    // Take the param array, and return it as a string.
    return params.join('');
}

/**
 * @function
 * @description Utility method for returning digitalUser data
 * @return {Object} The Object that makes fetching of user data more simple.
 */
function returnDigitalData() {
    var ddExists = typeof window.digitalData != 'undefined',
        userExists = ddExists && typeof window.digitalData.user != 'undefined',
        profileExists = userExists && typeof window.digitalData.user.profile != 'undefined';
    
    return {
        'profileEmailHash': profileExists ? returnValidString(window.digitalData.user.profile.profileInfo.profileEmailHash) : '',
        'city': profileExists ? returnValidString(window.digitalData.user.profile.address.city) : '',
        'state': profileExists ? returnValidString(window.digitalData.user.profile.address.stateProvince) : '',
        'postal': profileExists ? returnValidString(window.digitalData.user.profile.address.postalCode) : '',
        'country': profileExists ? returnValidString(window.digitalData.user.profile.attributes.country_code) : '',
        'loyaltyID': profileExists ? returnValidString(window.digitalData.user.profile.profileInfo.loyaltyID) : '',
        'rewardsCount': profileExists ? returnValidNumber(window.digitalData.user.profile.profileInfo.rewardsCount) : 0,
        'currentSpend': profileExists ? returnValidNumber(window.digitalData.user.profile.profileInfo.currentSpend) : 0
    }
}

/**
 * @function
 * @description Utility method for returning digitalUser data, which isn't undefined.
 * @param {String} The String Value to test against
 * @return {String} The value, or empty string.
 */
function returnValidString(value) {
    return typeof value != 'undefined' ? value : '';
}

/**
 * @function
 * @description Utility method for returning digitalUser data, which isn't undefined.
 * @param {Number} The number Value to test against
 * @return {Number} The value, or 0.
 */
function returnValidNumber(value) {
    return typeof value != 'undefined' ? value : 0;
}

/**
 * @function
 * @description Function for adding aria-label that adds error messages on the password field for screen readers
 */
function addAriaLabelForErrorMessage() {
    if ($('#RegistrationFormBoc .error-form').length > 0) {
        var errorText = $('#RegistrationFormBoc .error-form').text();
        if ($('#dwfrm_login_password').length > 0){
            $('#dwfrm_login_password').attr('aria-label', errorText).focus();
        }
    }
}

function initializePrivateSaleAnalytics() {
    if (window.digitalData.page.isPrivateSaleSignIn == 'true') {
        window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN PRIVATE SALE');
        $.ajax({
            type: 'GET',
            url: Urls.getRewardsDataShort,
            dataType: 'json'
        }).done(function(data) {
            if (data.success === true && data.rewardsDataShort){
                if (data.rewardsDataShort != null) {
                    if (data.rewardsDataShort.loyaltyStatus == 'cancelled') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_canceled');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'cancelledgrace') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_canceled_grace');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled_grace';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'frozen') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_frozen');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_frozen';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'terminated') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_terminated');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'terminatedgrace') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_terminated_grace');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated_grace';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'active') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_active');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                    } else {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-bbw_acct');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'bbw_acct';
                    }
                }
            }
        });
    }
}

function initializeEvents() {
    account.init();
    initializeBOCForm();
    initializeBOCTracking();
    inputTester();
    addAriaLabelForErrorMessage();
    setupAppDownloadButtons();
    initializePrivateSaleAnalytics();
}

var loyaltysignup = {
    init : function() {
        initializeEvents();
    }
};

module.exports = loyaltysignup;
