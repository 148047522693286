'use strict';
// HOMEPAGE MODULES - PROMO
var owl = $('.promo-display');
function createCarousel() {
    owl.owlCarousel({
        slideBy: 'page',
        merge: true,
        mergeFit: false,
        loop: false,
        responsive: {
            0: {
                margin: 15,
                autoWidth: true,
                nav: false,
                items: 1
            },
            479: {
                margin: 25,
                autoWidth: true,
                nav: false,
                items: 2
            },
            768: {
                margin: 25,
                autoWidth: false,
                nav: true,
                items: 3
            },
            1024: {
                margin: 25,
                autoWidth: false,
                nav: true,
                items: 4
            }
        },
        onInitialized: carouselLoaded
    });
}
function carouselLoaded() {
    owl.addClass('loaded');
    equalizeHeights();
    $('.promo-tile').find('.promo-image').each(function () {
        $(this).parent()[0].style.background = 'transparent';
    });
    $(window).resize(function () { equalizeHeights() });
    if (!$('body').hasClass('.slideInReady')) $.getScript('util.js');
}
function equalizeHeights() {
    var blocks = [
        {class: '.urgency', h: 0},
        {class: '.form', h: 0},
        {class: '.promo', h: 0},
        {class: '.qualifier', h: 0}
    ];
    for (var i = 0; i < blocks.length; i++) {
        $('.promo-display ' + blocks[i].class).each(function () {
            this.style.height = 'auto';
            var H = $(this).innerHeight();
            if (H > blocks[i].h) blocks[i].h = H;
        });
        $('.promo-display ' + blocks[i].class).each(function () {
            this.style.height = blocks[i].h + 'px';
        });
    }
}
createCarousel();
