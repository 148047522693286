'use strict';

var dialog = require('./dialog'),
    ajax = require('./ajax');

function flyInDialog() {
    if (document.cookie.indexOf('flyin_accepted') < 0) {        
        var contentURL = Urls.checkoutFlyIn;
        ajax.load({
            url: contentURL,
            callback: function (data) {
                var jsonData = data;
                if (!jsonData) {
                    return false;
                }
                var flyInWrap = '<div class="fly-in-wrap">' + jsonData + '</div>';
                $('#wrapper').append(flyInWrap);
                if ($('.fly-in-wrap #flyInPid').val()) {
                    var flyInContent = $('.fly-in-wrap').html();
                    dialog.open({
                        html: flyInContent,
                        options: {
                            width: 570,
                            dialogClass : 'fly-in-dialog',
                            show: {
                                effect: 'fade',
                                duration: 600
                            },
                            hide: {
                                effect: 'fade',
                                duration: 600
                            }
                        },
                        callback: function () { 
                            $('.ui-widget-overlay').on('click', function () {
                                dialog.close();
                            });
                        }
                    });
                }
            }
        });
        enableCookies();
    } else {
        enableCookies();
    }

    function enableCookies() {
        if (document.cookie.indexOf('flyin=1') < 0) {
            document.cookie = 'flyin=1; path=/; SameSite=Lax';
        }
        if (document.cookie.indexOf('flyin_accepted') < 0) {
            document.cookie = 'flyin_accepted=1; path=/; SameSite=Lax';
        }
    }
}


var CheckoutFlyIn = {
    init: function () {
        flyInDialog();
    }
}

module.exports = CheckoutFlyIn;
