'use strict';

var dialog = require('./dialog'),
    minicart = require('./minicart'),
    jatcrecommendations = require('./jatc-recommendations');

var jATCShippingInfo = function(storeName, addedForShipping) {
    var shippingInfoHTML = '<div class="changedeliveryoption">';
    if (addedForShipping) {
        shippingInfoHTML += '<h3 class="added-for-shipping">Added for shipping</h3>'
    } else {
        shippingInfoHTML += '<div class="added-for-pick-up"><div class="pick-up-icon"></div><h3 class="pick-up-store-msg">Added for pickup at <span class="store-name">' + storeName.toLowerCase() + '</span></h3></div>'
    }
    shippingInfoHTML += '<p class="change-option-info">Don’t worry! You can make changes to this option in your bag.</p></div>';
    return shippingInfoHTML;
}

var addedProductsView = {
    /**
     * @function
     * @description Open added product dialog
     */
    show: function (item, pid, storename, addedForShipping) {
        //build response for added product in pop up
        var simpleProdCode = $(item).find('.mini-cart-products').find('*[data-productid="' + pid + '"]:first').html();
        var miniCartSlot = $(item).find('.mini-cart-slot').html();
        var resultCart = simpleProdCode+miniCartSlot+'<div class="clearfix"></div>';
        var newQty = $(item).find('.mini-cart-products').length  > 0 ? parseInt($(item).find('.mini-cart-products').attr('data-newqty'), 10) : 0;

        dialog.open({
            html: resultCart,
            options: {
                dialogClass: 'jatc-recommendations',
                width: 570,
                title: newQty === 0 ? Resources.NOT_ADDED_TO_CART_TITLE_JATC : Resources.ADDED_TO_CART,
                draggable: false,
                buttons: {
                    'CONTINUE SHOPPING': {
                        click: function(){
                            $(this).dialog('close');
                        },
                        text: 'CONTINUE SHOPPING',
                        'class': 'cancelButton'
                    },
                    'VIEW BAG & CHECKOUT': function() {
                        location.href = Urls.cartShow;
                        $(this).dialog('close');
                    }
                },
                show: { 
                    effect: 'fade',
                    duration: 100
                },
                hide: {
                    effect: 'fade',
                    duration: 100
                },
                open: function() {
                    var $jatcRecosDialog = $('.ui-dialog.jatc-recommendations'),
                        subscribedproductId = $('.mini-cart-attributes .productpid').val(),
                        frequencyCounter = $.trim($('og-offer p:nth-child(3) og-select-frequency option:selected').text()),
                        frequencyText = '<og-offer class="jatc-indicator frequency_block jatc-freq" product='+subscribedproductId+' location="mini-cart" subscribed=""><span>Sending</span> every '+frequencyCounter+'</og-offer>';

                    $jatcRecosDialog.find('.mini-cart-pricing').append(frequencyText); 
                                      
                    $jatcRecosDialog.scrollTop(0);
                    $jatcRecosDialog.find('.recommendations-container').remove();
                    var isCCUser = $('.current-user-name').val() != 'storefront';
                    if ((newQty > 0 && SitePreferences.STORE_PICKUP && window.SessionAttributes.IS_REQUEST_IN_AVAILABLE_BOPIS_MARKET && !isCCUser) || (isCCUser && window.SessionAttributes.IS_CSR_BOPIS_EXPERIENCE_ALLOWED)) {
                        $('.ui-dialog-buttonpane .ui-dialog-buttonset').prepend(jATCShippingInfo(storename, addedForShipping));
                    }
                    if ($('.ui-dialog-title:contains("' + Resources.ADDED_TO_CART + '")').length > 0) {
                        $('.dialog-content .mini-cart-name').prepend('<div class="jatc-success" aria-hidden="true"><span>' + Resources.ADDED_TO_CART + '</span></div>');
                    }
                    $('.ui-widget-overlay').hide().fadeIn().on('click', function() {
                        $('.ui-dialog-titlebar-close').trigger('click');
                    });
                    $('.add-to-cart').addClass('nonblur');
                    $('body').addClass('prevent-scroll');
                    if (newQty == 0) {
                        $('.dialog-content').html('<p class="not-added title"><span>' + Resources.NOT_ADDED_TO_CART_TITLE_JATC + ' ' + Resources.NOT_ADDED_TO_CART_JATC + '</span></p><p class="not-added error">' + Resources.NOT_ADDED_TO_CART_MESSAGE_JATC + '</p>');
                        $jatcRecosDialog.addClass('maxqty');
                    }
                    $jatcRecosDialog.removeClass('global-email-dialog');
                    $jatcRecosDialog.append('<div class="recommendations-container" id="addtocart1_rr"><h4><span>' + SitePreferences.JATC_RECOMMENDATIONS_LABEL + '</span></h4></div>');
                    jatcrecommendations.init();
                    $('body').children().not('.ui-dialog, script').attr('aria-hidden', 'true');
                },
                create: function () {
                    $(window).resize(function () {
                        $('.ui-dialog').position({
                            my: 'center',
                            at: 'center',
                            of: window
                        });
                    });
                    $(window).scroll(function () {
                        $('.ui-dialog').position({
                            my: 'center',
                            at: 'center',
                            of: window
                        });
                    });
                },
                close: function () {
                    $('.add-to-cart').removeClass('nonblur');
                    $('.ui-dialog').removeClass('maxqty');
                    $('body').children().not('.ui-dialog, script').attr('aria-hidden', 'false');
                },
                beforeClose: function () {
                    $('body').removeClass('prevent-scroll');
                    $('.ui-widget-overlay:first')
                        .clone()
                        .appendTo('body')
                        .show()
                        .fadeOut(400, function(){
                            $(this).remove();
                        })
                    ;
                }
            },
            callback: function () {
                $('#mini-cart').html(item);
                minicart.init();
            }
        });
    }
};

var scroller = {
    preventDefault: function(e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;
    },

    preventDefaultForScrollKeys: function(e) {
        var keys = {37: 1, 38: 1, 39: 1, 40: 1};
        if (keys[e.keyCode]) {
            scroller.preventDefault(e);
            return false;
        }
    },
    disableScroll: function(){
        if (window.addEventListener) {
            window.addEventListener('DOMMouseScroll', scroller.preventDefault, false);
        }
        window.onwheel = scroller.preventDefault; // modern standard
        window.onmousewheel = document.onmousewheel = scroller.preventDefault; // older browsers, IE
        window.ontouchmove  = scroller.preventDefault; // mobile
        document.onkeydown  = scroller.preventDefaultForScrollKeys;
    },
    enableScroll: function(){
        if (window.removeEventListener) {
            window.removeEventListener('DOMMouseScroll', scroller.preventDefault, false);
        }
        window.onmousewheel = document.onmousewheel = null;
        window.onwheel = null;
        window.ontouchmove = null;
        document.onkeydown = null;
    }
};

module.exports = addedProductsView;
