'use strict';

function initCustomerCare() { 
    var contents = $('#contents');
    if (contents.length > 0) {
        $('#contents').on('click', 'button[href^="#"]', function (event) {
            event.preventDefault();
            var thisElement = $(this);
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 30
            }, 1000, function(){
                $(thisElement.attr('href')).focus();
            });
        });
        
        var backToTopButton = $('.back-to-top');
        if (backToTopButton.length > 0) {
            var backToTop = function(e) {
                e.preventDefault();
                $([document.documentElement, document.body]).animate({
                    scrollTop: 0
                }, 1000, function(){
                    $('.page-title').focus();
                });
            };
            backToTopButton.click(backToTop);
        }
    }
}

var customerCare = {
    init: function () {
        initCustomerCare();
    }
}

module.exports = customerCare;
