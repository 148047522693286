'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    radial = require('../../radial'),
    shipping = require('./shipping'),
    progress = require('../../progress'),
    loyalty = require('../../loyalty'),
    account = require('../../pages/account');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    radial.init();
    loyalty.init();
    passHideShow();
    if ($('.checkout-shipping').length > 0) {
        shipping.init();
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
    } else {
        billing.init();
    }
    account.initMyAccountRewardsPopup();
    //Enable order submit buttons when script loads and disable the buttons on form submit
    var $orderSubmitButtons = $('.submit-order .button-fancy-large');
    $orderSubmitButtons.removeAttr('disabled').on('click', function(){
        progress.show('#main');
    });

    //if on the order review page and there are products that are not available disable the submit order button
    var $orderSummaryFooter = $('.order-summary-footer');
    if ($orderSummaryFooter.length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }

    $('.customer-info-accordion').accordion({
        heightStyle: 'content',
        active: 0,
        collapsible: true,
        animate: 250,
        header: 'h2',
        activate: function() {
            if (!$(this).find('.ui-accordion-header').hasClass('ui-state-active')) {
                $(this).find('.ui-accordion-header').attr('aria-expanded', 'false');
            }
        }
    });
    $('.customer-info-header a').on('click keydown', function(e) {
        e.stopPropagation();
    });
};
/**
* @private
* @function
* @description Show/Hide the password for login
*/
function passHideShow(){

    var hideShowButton = $('.hide-show'),
        hideShowSpan = hideShowButton.find('span');

    hideShowButton.show();
    hideShowSpan.addClass('show');
    hideShowButton.click(function(){
        var hideShowThisButton = $(this),
            hideShowThisSpan = hideShowThisButton.find('span');

        if (hideShowThisSpan.hasClass('show')) {
            hideShowThisSpan.text('Hide');
            hideShowThisButton.attr('aria-label','Hide Password');
            hideShowThisButton.siblings('input[name$="password"]').attr('type','text').focus();
            hideShowThisSpan.removeClass('show');
        } else {
            hideShowThisSpan.text('Show');
            hideShowThisButton.attr('aria-label','Show Password');
            hideShowThisButton.siblings('input[name$="password"]').attr('type','password').focus();
            hideShowThisSpan.addClass('show');
        }
    });
    $('form button[type="submit"]').on('click', function(){
        hideShowSpan.text('Show').addClass('show');
        hideShowButton.attr('aria-label','Show Password');
        hideShowButton.parent().find('input[name$="_password"]').attr('type','password');
    });
}

$('.trigger-app-login').on('click', function(){
    var event = new Event('dispatch-app-sign-in');
    window.dispatchEvent(event);
});
