'use strict'

var handleDigitalData = function(data) {
    var loyaltyStatus = data.rewardsDataShort.loyaltyStatus ? data.rewardsDataShort.loyaltyStatus : '';
    var pageID = window.digitalData.page.pageInfo.pageID ? window.digitalData.page.pageInfo.pageID : '';
    var postalCode = data.rewardsDataShort.postalCode ? data.rewardsDataShort.postalCode : '';
    var accountStatus = window.digitalData.user.profile.profileInfo.loyaltyaccountstatus ? window.digitalData.user.profile.profileInfo.loyaltyaccountstatus : '';

    function isCAPostalCode(postalCode) {
        var retVal = (/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/).test(postalCode);
        return  retVal;
    }

    var getAccountStatusTag = function(acctStatus) {
        if (acctStatus.search('grace')) accountStatus = 'loyl_acct_' + acctStatus.replace('grace', '') + '_grace'+pageID
        else accountStatus = 'loyl_acct_' + acctStatus+pageID;

        return accountStatus;
    };

    if (window.window.digitalData.user) {
        if (loyaltyStatus != '') getAccountStatusTag(loyaltyStatus);
        else if (isCAPostalCode(postalCode)) accountStatus = 'bbw_acct_ineligible';
        else accountStatus = 'bbw_acct';
    }

    if (loyaltyStatus == 'inactive' || loyaltyStatus == 'pointscapped' || loyaltyStatus == 'active') window.location.reload();
}

module.exports = handleDigitalData;
