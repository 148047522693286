'use strict';
var dialog = require('../../dialog'),
    util = require('../../util');


/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
var setMainImage = function (atts, direction) {
    if (direction === undefined) {
        direction = 'none';
    }

    $('#pdpMain .primary-image').hide().attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    }).fadeIn();
    //}).show('slide', {direction: direction}, 300);
    if (!dialog.isActive() && !util.isMobileDevice()) {
        $('#pdpMain .main-image').attr('href', atts.hires);
    }
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
var replaceImages = function () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.hide().html($newImages.html()).fadeIn('slow');
    $newImages.remove();
};
var checkArrow = function () {
    if ($('.product-thumbnails li').size() > 1){

        var targetN = $('.thumb.selected');
        var prev = $('.navigation .prev');
        var next = $('.navigation .next');

        next.show();

        if (targetN.next().length > 0){
            next.show();
        } else {
            next.hide();
        }

        if (targetN.prev().length > 0){
            prev.show();
        } else {
            prev.hide();
        }
    }
};

/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {


    var $sync1 = $('.slider-owl-pdp'),
        $sync2 = $('.thumbnails-owl-pdp'),
        flag = false,
        config,
        duration = 500;

    if ($sync2.children().length > 4) {
        config = {
            items: 4,
            nav: true,
            dots: false
        }
    } else {
        config = {
            items: 4,
            nav: false,
            dots: false
        }
    }
    $sync1
        .owlCarousel({
            items: 1,
            nav: true,
            dots: false
        })
        .on('changed.owl.carousel', function (e) {
            if (!flag) {
                flag = true;
                $sync2.trigger('to.owl.carousel', [e.item.index, duration, true]);
                var current = e.relatedTarget.current();
                $sync2.find('.owl-item').removeClass('current').eq(current).addClass('current');
                flag = false;
            }
        });

    $sync2
        .on('initialized.owl.carousel', function (e) {
            if (!e.namespace || e.type != 'initialized' && e.property.name != 'position') return;

            var items = $(this).find('.owl-stage').children();
            var add = e.type == 'initialized';
            items.eq(e.relatedTarget.normalize(0)).toggleClass('current', add);
        })
        .owlCarousel(config)
        .on('click', '.owl-item', function () {
            $sync1.trigger('to.owl.carousel', [$(this).index(), duration, true]);

        })
        .on('changed.owl.carousel', function (e) {
            if (!flag) {
                flag = true;
                $sync1.trigger('to.owl.carousel', [e.item.index, duration, true]);
                var current = e.relatedTarget.current();
                $sync2.find('.owl-item').removeClass('current').eq(current).addClass('current');
                flag = false;
            }
        });

    //provide thumbnail functionality
    /*
    var dotCount = 1;
    var owlDot = $('.owl-dot');

    owlDot.each(function() {
        $(this).addClass('dotnumber' + dotCount);
        $(this).attr('data-info', dotCount);
        dotCount=dotCount+1;
    });

    var slideCount = 1;

    jQuery('.owl-item').not('.cloned').each(function() {
        $(this).addClass('slidenumber' + slideCount);
        slideCount=slideCount+1;
    });

    owlDot.each(function() {
        var grab = $(this).data('info');
        var slidegrab = $('.slidenumber'+ grab +' img').attr('src');
        var rootPath = slidegrab.split('?');
        $(this).css('background-image', 'url('+rootPath[0]+'?sw=50)');
    });
    */

};
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.checkArrow = checkArrow;
