'use strict';

exports.init = function () {
    if ($('.submit-order').size() > 0) {
        $('.submit-order').on('submit', function() {
            var inputID = $(this).find('input[name*=userPrefs]').attr('id');
            window.value.payload(inputID);
            
            var rdfuid1 = document.getElementById('RDFUID');
            var rdfuid2 = document.getElementById('RDFUID2');
            rdfuid2.value =  rdfuid1.value;
            return true;
        });
    }
}
