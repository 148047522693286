/* eslint-disable */
module.exports = function () {
    /*
     * ES2015 simple and accessible hide-show system (collapsible regions), using ARIA
     * Website: https://van11y.net/accessible-hide-show/
     * License MIT: https://github.com/nico3333fr/van11y-accessible-hide-show-aria/blob/master/LICENSE
     */
    /**
     * Factory for config management
     */
    'use strict';

    var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

    var DATA_HASH_ID = 'data-hashtooltip-id';
    var BELOW_CLASS = 'ds-simpletooltip-below';
    var RIGHT_EDGE_CLASS = 'ds-right-edge';
    var LEFT_EDGE_CLASS = 'ds-left-edge';
    var CENTER_CLASS = 'ds-center';

    /** Find an element based on an Id
     * @param  {String} id Id to find
     * @param  {String} hash hash id (not mandatory)
     * @return {Node} the element with the specified id
     */
    var findById = function findById(id) {
        var hash = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];
        return hash !== '' ? document.querySelector('#' + id + '[' + DATA_HASH_ID + '="' + hash + '"]') : document.getElementById(id);
    };

    /** add a class to a node
     * @param  {Node} el node to attach class
     * @param  {String} className the class to add
     */
    var addClass = function addClass(el, className) {
        if (el.classList) {
            el.classList.add(className); // IE 10+
        } else {
            el.className += ' ' + className; // IE 8+
        }
    };

    /** add a class to a node
     * @param  {Node} el node to attach class
     * @param  {String} className the class to add
     */
    var removeClass = function removeClass(el, className) {
        if (el.classList) {
            el.classList.remove(className); // IE 10+
        }
    };

    /** check if node has specified class
     * @param  {Node} el node to check
     * @param  {String} className the class
     */
    var hasClass = function hasClass(el, className) {
        if (el.classList) {
            return el.classList.contains(className); // IE 10+
        } else {
            return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className); // IE 8+ ?
        }
    };

    /** search if element is or is contained in another element with attribute data-hashtooltip-id
     * @param  {Node} el element (node)
     * @param  {String} hashId the class
     * @return {String} the value of attribute data-hashtooltip-id
     */
    var searchParentHashId = function searchParentHashId(el, hashId) {
        var found = false;

        var parentElement = el;
        while (parentElement.nodeType === 1 && parentElement && found === false) {

            if (parentElement.hasAttribute(hashId) === true) {
                found = true;
            } else {
                parentElement = parentElement.parentNode;
            }
        }
        if (found === true) {
            return parentElement.getAttribute(hashId);
        } else {
            return '';
        }
    };

    var plugin = function plugin() {
        var config = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

        var CONFIG = _extends({
            TOOLTIP_SIMPLE: 'ds-js-simple-tooltip',
            TOOLTIP_ID: 'aria-describedby',
        }, config);

        /** Find  all tooltips inside a container
         * @param  {Node} node Default document
         * @return {Array}
         */
        var $listTooltip = function $listTooltip() {
            var node = arguments.length <= 0 || arguments[0] === undefined ? document : arguments[0];
            return [].slice.call(node.querySelectorAll('.' + CONFIG.TOOLTIP_SIMPLE));
        }; //[...node.querySelectorAll('.' + CONFIG.TOOLTIP_SIMPLE)]; // that does not work on IE when transpiled :-(

        /*
         * Build tooltips for a container
         * @param  {Node} node
         */
        var attach = function attach(node) {

            $listTooltip(node).forEach(function (tooltip_node) {
                var tooltipId = tooltip_node.hasAttribute(CONFIG.TOOLTIP_ID) === true ? tooltip_node.getAttribute(CONFIG.TOOLTIP_ID) : '';
                if (tooltipId !== '') {
                    setButtonCloseEvent(tooltipId);
                }
            });
        };

        return {
            attach: attach
        };
    };

    var main = function main() {

        function addTooltipEvents(tooltipContainer, eventName) {

            tooltipContainer.addEventListener(eventName, function (e) {

                var hashId = searchParentHashId(e.target, DATA_HASH_ID); //e.target.dataset.hashId;
                // search if click on button or on element in a button contains data-hash-id (it is needed to load config and know which class to search)

                if (hashId !== '') {
                    // click on button
                    var tooltipLauncher = tooltipContainer.getElementsByClassName('ds-js-simple-tooltip')[0];
                    // display
                    if (eventName === 'mouseover' || eventName === 'focus' || eventName === 'click') {
                        var item = findById(tooltipLauncher.getAttribute('aria-describedby'), hashId);
                        if (item) {
                            tooltipContainer.classList.remove(BELOW_CLASS);
                            item.setAttribute('aria-hidden', 'false');
                            setScrollHideEvent(item);
                            handleDropdownPosition(item, tooltipContainer);
                            handleEdgeOfScreen(item, tooltipContainer);
                        }
                    }
                    // hide
                    var escapePressed = eventName === 'keydown' && e.keyCode === 27;
                    if ((eventName === 'mouseout' && !tooltipContainer.contains(e.relatedTarget)) || escapePressed) {
                        console.log('mouseout', e.relatedTarget);
                        var item = findById(tooltipLauncher.getAttribute('aria-describedby'), hashId);
                        if (item) {
                            if (escapePressed) {
                                setFocusToTrigger(item.parentElement);
                            }
                            item.setAttribute('aria-hidden', 'true');
                        }
                    }

                    if (eventName === 'blur' && !tooltipContainer.contains(e.relatedTarget)) {
                        var tooltipLaunchers = e.currentTarget.getElementsByClassName('ds-js-simple-tooltip');
                        if (tooltipLaunchers.length) {
                            var tooltipLauncher = tooltipLaunchers[0];
                            var item = findById(tooltipLauncher.getAttribute('aria-describedby'), hashId);
                            if (item && item.getAttribute('aria-hidden') !== 'true') {
                                if (e.relatedTarget == null) {
                                    setFocusToTrigger(item.parentElement);
                                }

                                item.setAttribute('aria-hidden', 'true');
                            }
                        }
                    }
                }
            }, true);
        }

        var tooltipContainers = document.getElementsByClassName("ds-tooltip-container");
        /* listeners for all configs */
        ['click', 'mouseover', 'focus', 'mouseout', 'blur', 'keydown'].forEach(function (eventName) {
            for (var i = 0; i < tooltipContainers.length; i++) {
                addTooltipEvents(tooltipContainers[i], eventName);
            }
        });

        return plugin;
    };

    window.van11yAccessibleSimpleTooltipAria = main();

    var onLoad = function onLoad() {
        var tooltip_default = window.van11yAccessibleSimpleTooltipAria();
        tooltip_default.attach();
        document.removeEventListener('DOMContentLoaded', onLoad);

    };

    document.addEventListener('DOMContentLoaded', onLoad);

    var setScrollHideEvent = function (tooltipToHide) {
        var mobileHide = function () {
            if (tooltipToHide.contains(document.activeElement)) {
                setFocusToTrigger(tooltipToHide.parentElement);
            }
            tooltipToHide.setAttribute("aria-hidden", "true");
            document.removeEventListener('scroll', mobileHide);
        };

        document.addEventListener('scroll', mobileHide);
    };



    /********
     * BBW Stuff ADDED
     * Exploration
     */
    var setFocusToTrigger = function (tooltipContainer) {
        if (tooltipContainer) {
            var trigger = tooltipContainer.querySelector('.ds-tooltip-button');
            if (trigger) {
                trigger.focus({ preventScroll: true });
            }
        }
    }

    var setButtonCloseEvent = function setButtonCloseEvent(tooltipId) {
        var tooltipParent = document.getElementById(tooltipId);
        var closeButton = tooltipParent.querySelector('.ds-close-popover');
        if (closeButton) {
            var hideTooltip = function () {
                setFocusToTrigger(tooltipParent.parentElement);
                tooltipParent.setAttribute("aria-hidden", "true");
            };
            closeButton.addEventListener("click", hideTooltip);
        }
    }

    var handleDropdownPosition = function (tooltipContent, tooltipContainer) {
        var topBanner = document.getElementsByClassName('top-banner');
        var isInCarousel = tooltipContent && tooltipContent.closest('.carousel-item');
        if (!isInCarousel && topBanner[0] && tooltipContent && tooltipContainer) {
            var topBannerRect = topBanner[0].getBoundingClientRect();
            var tooltipContentRect = tooltipContent.getBoundingClientRect();
            if (topBannerRect.bottom > tooltipContentRect.top) {
                addClass(tooltipContainer, BELOW_CLASS);
            }
        }
    }

    var handleEdgeOfScreen = function (tooltipContent, tooltipContainer) {
        if (tooltipContent && tooltipContainer) {
            var tooltipContentRect = tooltipContent.getBoundingClientRect();
            if (tooltipContentRect.left < 24) {
                removeClass(tooltipContainer, CENTER_CLASS)
                removeClass(tooltipContainer, RIGHT_EDGE_CLASS)
                addClass(tooltipContainer, LEFT_EDGE_CLASS);
            } else if (tooltipContentRect.right > window.innerWidth - 24) {
                removeClass(tooltipContainer, CENTER_CLASS)
                removeClass(tooltipContainer, LEFT_EDGE_CLASS)
                addClass(tooltipContainer, RIGHT_EDGE_CLASS);
            }

            tooltipContentRect = tooltipContent.getBoundingClientRect();
            if (tooltipContentRect.left < 24 || tooltipContentRect.right > window.innerWidth - 24) {
                removeClass(tooltipContainer, LEFT_EDGE_CLASS)
                removeClass(tooltipContainer, RIGHT_EDGE_CLASS)
                addClass(tooltipContainer, CENTER_CLASS)
            }
        }
    }
};
