'use strict';

function getCookieConsentAndSetBvConsent() {
    var bvConsentSessionStorage = sessionStorage.getItem('bvConsent') === 'true';
    setCookieConsentFromTealiumData(bvConsentSessionStorage);
}

function setCookieConsentFromTealiumData(consentValue) {
    setBVCookieConsent(consentValue);

    try {
        if (window.utag && window.BV && window.BV.cookieConsent) {
            var tealiumCookieValues = window.utag.gdpr.getCookieValues();
            var bvCookieConsent = tealiumCookieValues.consent !== 'false' && tealiumCookieValues.c1 !== '0';
            setBVCookieConsent(bvCookieConsent);
        } else {
            window.setTimeout(function () {
                setCookieConsentFromTealiumData(consentValue);
            }, 200);
        }
    } catch (err) {
        console.log(err);
    }
}

function setBVCookieConsent(bvCookieConsent) {
    try {
        if (window.BV && window.BV.cookieConsent.getConsent('BVBRANDID') !== bvCookieConsent) {
            window.BV.cookieConsent.setConsent({
                BVBRANDID: bvCookieConsent,
                BVBRANDSID: bvCookieConsent
            });

            sessionStorage.setItem('bvConsent', bvCookieConsent);
        }
    } catch (error) {
        console.error('Error with BV.cookieConsent.setConsent: ', error.message)
    }
}

module.exports = {
    getCookieConsentAndSetBvConsent: getCookieConsentAndSetBvConsent
};
