'use strict';

/**
 * @function cookieprivacy    Used to display/control the scrim containing the cookie privacy code
 **/
module.exports = function () {

    /**
     * Check geolocation against country list
     */
    var showCookie = false;
    var countryList = SitePreferences.COOKIE_COUNTRY_LIST.split(' ');
    var geoIpCountry = User.geolocation.countryCode.toLowerCase();
    for (var i = 0; i < countryList.length; i++) {
        var country = countryList[i].toLowerCase();
        if (country == geoIpCountry) {
            showCookie = true;
            break;
        }
    }

    /**
     * If we have not accepted cookies AND we're not on the Privacy Policy page, then show the notification
     * NOTE: You will probably want to adjust the Privacy Page test to match your site's specific privacy / cookie page
     */
    if (SitePreferences.COOKIE_HINT === true && document.cookie.indexOf('dw_cookies_accepted') < 0 && showCookie) {
        // check for privacy policy page
        var $cookieWrapper = $('.cookie-notice-wrapper');
        var $footerBottom = $('.under-footer');
        var $stickyDrawer = $('#sticky-drawer');
        $footerBottom.css('margin-bottom', ($cookieWrapper.outerHeight() - 1) + 'px');
        $cookieWrapper.show();
        $('.cookie-notice-accept').on('click', function() {
            $cookieWrapper.hide();
            enableCookies();
            $footerBottom.css('margin-bottom', '');
            if ($stickyDrawer.is(':visible')) {
                $stickyDrawer.css('bottom', '15px');
            }
        });
    } else {
        // Otherwise, we don't need to show the asset, just enable the cookies
        enableCookies();
    }

    function enableCookies() {
        // Create new date object
        // Get cookie expiration days from site pref
        var numOfDays = SitePreferences.COOKIE_EXP_DAYS;
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 86400*1000*numOfDays;

        // Update date object
        now.setTime(expireTime);
        if (document.cookie.indexOf('dw=1') < 0) {
            document.cookie = 'dw=1; path=/ ; SameSite=Lax';
        }
        if (document.cookie.indexOf('dw_cookies_accepted') < 0 && showCookie) {
            document.cookie = 'dw_cookies_accepted=1' + '; expires=' + now.toGMTString() + ';path=/' + ';SameSite=Lax';
        }
    }

    //When site visitors have declined targeting cookies
    var checkForUtag = setInterval(function() {
        if (window.utag) {
            clearInterval(checkForUtag);
            var consent = window.utag.gdpr.getCookieValues().c2 === '1';
            $.ajax({
                type: 'GET',
                url:  window.Urls.trackingStatus + '?trackingAllowed=' + consent
            })
        }
    }, 1000);
};
