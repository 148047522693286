'use strict';

/******* app.scrolltotop ********/
/**
 * @function
 * @Animated Scroll To Top Button
 */
exports.init = function () {
    $.fn.hoverIntent = function (handlerIn,handlerOut,selector) {

        // default configuration values
        var cfg = {
            interval: 100,
            sensitivity: 6,
            timeout: 0
        };

        if (typeof handlerIn === 'object') {
            cfg = $.extend(cfg, handlerIn);
        } else if ($.isFunction(handlerOut)) {
            cfg = $.extend(cfg, {over: handlerIn, out: handlerOut, selector: selector});
        } else {
            cfg = $.extend(cfg, {over: handlerIn, out: handlerIn, selector: handlerOut});
        }

        // instantiate variables
        // cX, cY = current X and Y position of mouse, updated by mousemove event
        // pX, pY = previous X and Y position of mouse, set by mouseover and polling interval
        var cX, cY, pX, pY;

        // A private function for getting mouse position
        var track = function (ev) {
            cX = ev.pageX;
            cY = ev.pageY;
        };

        // A private function for comparing current and previous mouse position
        var compare = function (ev,ob) {
            ob.hoverIntentT = clearTimeout(ob.hoverIntentT);
            // compare mouse positions to see if they've crossed the threshold
            if (Math.sqrt((pX - cX) * (pX - cX) + (pY - cY) * (pY - cY)) < cfg.sensitivity) {
                $(ob).off('mousemove.hoverIntent',track);
                // set hoverIntent state to true (so mouseOut can be called)
                ob.hoverIntentS = true;
                return cfg.over.apply(ob,[ev]);
            } else {
                // set previous coordinates for next time
                pX = cX; pY = cY;
                // use self-calling timeout, guarantees intervals are spaced out properly (avoids JavaScript timer bugs)
                ob.hoverIntentT = setTimeout(function () {compare(ev, ob);} , cfg.interval);
            }
        };

        // A private function for delaying the mouseOut function
        var delay = function (ev,ob) {
            ob.hoverIntentT = clearTimeout(ob.hoverIntentT);
            ob.hoverIntentS = false;
            return cfg.out.apply(ob,[ev]);
        };

        // A private function for handling mouse 'hovering'
        var handleHover = function (e) {
            // copy objects to be passed into t (required for event object to be passed in IE)
            var ev = $.extend({},e);
            var ob = this;

            // cancel hoverIntent timer if it exists
            if (ob.hoverIntentT) { ob.hoverIntentT = clearTimeout(ob.hoverIntentT);}

            // if e.type === "mouseenter"
            if (e.type === 'mouseenter') {
                // set "previous" X and Y position based on initial entry point
                pX = ev.pageX; pY = ev.pageY;
                // update "current" X and Y position based on mousemove
                $(ob).on('mousemove.hoverIntent',track);
                // start polling interval (self-calling timeout) to compare mouse coordinates over time
                if (!ob.hoverIntentS) { ob.hoverIntentT = setTimeout(function () {compare(ev,ob);} , cfg.interval);}

                // else e.type == "mouseleave"
            } else {
                // unbind expensive mousemove event
                $(ob).off('mousemove.hoverIntent',track);
                // if hoverIntent state is true, then call the mouseOut function after the specified delay
                if (ob.hoverIntentS) { ob.hoverIntentT = setTimeout(function () {delay(ev,ob);} , cfg.timeout);}
            }
        };

        // listen for mouseenter and mouseleave
        return this.on({'mouseenter.hoverIntent':handleHover,'mouseleave.hoverIntent':handleHover}, cfg.selector);
    };
};
