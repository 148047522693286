'use strict';

/**
 * @function
 * @description Load details to a given gift certificate
 * @param {String} id The ID of the gift certificate
 * @param {Function} callback A function to called
 */
exports.checkBalance = function (code, pin, captchaToken, callback) {
    $.post(Urls.giftCardCheckBalance, {
        giftCertCode: code, 
        giftCertPin: pin, 
        reCaptchaToken: captchaToken, 
        format: 'ajax'
    }, callback,'json');
};
