/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var tealium = require('../tealium');

var cart = {
    init: function() {
        //Cart Remove
        $('button[name$="deleteProduct"]').on('click', cart.cartRemove);
        
        //Cart Empty
        if ($('.cart-empty').size() > 0) {
            cart.cartEmpty();
        }
    },
    cartRemove: function() {
        var button = $(this);
        var data = button.data('tealium');
        var productQuantity = button.data('qty');
        tealium.eventCount += 1;
        var obj = {
            'ecommerce_action' : 'Cart Remove',
            'event_category' : 'Cart',
            'event_action' : 'Cart Remove',
            'event_label' : 'Cart Remove',
            'event_name' : 'cart_remove',
            'event_value' : tealium.eventCount + '',
            'product_brand': [data.brand],
            'product_category': [data.category],
            'product_id': [data.id],
            'product_name': [data.name],
            'product_image_url': [data.image],
            'product_original_price': [data.original_price],
            'product_price': [data.price],
            'product_promo_code': [data.promo_code],
            'product_sku': [data.sku],
            'product_quantity': [productQuantity + ''],
            'product_subcategory': [('subcategory' in data) ? data.subcategory : ''],
            'product_discount_amount': [('discount_amount' in data) ? data.discount_amount : '']
        };

        tealium.triggerEvent(obj);
    },
    cartEmpty: function() {
        tealium.eventCount += 1;
        var obj = {
            'ecommerce_action' : 'detail',
            'event_category' : 'Cart',
            'event_action' : 'cart empty',
            'event_label' : 'cart empty',
            'event_name' : 'cart_empty',
            'event_value' : tealium.eventCount + ''
        };
        tealium.triggerEvent(obj);
    }
};

exports.init = cart.init;
