'use strict';
module.exports = function () {

    function isMobile() {
        return window.matchMedia('(max-width: 599px)').matches;
    }

    var initNavMenu = function () {
        //add keyboard/mouse handler to top level items; add kbd handler to children
        var $parents = $('.ds-accordion-link-lists > .parent');
        var $headings = $parents.children('.ds-accordion-heading');
        var $collapsible = $('.ds-accordion-link-lists > .accordion-item .accordionSubMenu');

        $parents.keydown(menuTopKeyPress);
        $('.ds-accordion-link-lists .accordionSubMenu .accordion-item').keydown(menuChildKeyPress);
        $('.ds-accordion-link-lists > .accordion-item > .ds-accordion-heading').click(menuTopClick);
        $headings.attr('aria-expanded', 'false');
        $collapsible.attr('aria-expanded', 'false');


        $(window).smartresize(function () {
            if (isMobile()) {
                $headings.each(function () {
                    var $currentHeading = $(this);
                    $currentHeading.attr('aria-expanded', $currentHeading.closest('.accordion-item').hasClass('menu-expanded'));
                    $currentHeading.attr('tabindex', '0');
                });

                $collapsible.each(function () {
                    var $currentMenu = $(this);
                    $currentMenu.attr('aria-expanded', $currentMenu.hasClass('expanded'));
                });
            } else {
                $headings.removeAttr('aria-expanded');
                $headings.removeAttr('tabindex');
                $collapsible.removeAttr('aria-expanded');
            }
        });
    };

    var menuTopClick = function (event) {
        var subMenu = $(event.currentTarget).parent().find('.accordionSubMenu');

        if (!subMenu.hasClass('expanded')) {
            expandMenu(subMenu);
        } else {
            collapseMenu(subMenu);
        }

        event.preventDefault();
    };

    var menuTopKeyPress = function (event) {
        var subMenu = $(event.currentTarget).find('.accordionSubMenu');

        if (event.which === 13 || event.which === 32) { //enter key
            if (!subMenu.hasClass('expanded')) {
                expandMenu(subMenu);
            } else {
                collapseMenu(subMenu);
            }
        } else if (event.which === 37) { //left arrow key
            //if there's a previous one, go to that; otherwise nothing
            var prevItem = $(event.currentTarget).prev('.accordion-item').children('.ds-accordion-heading');

            if (prevItem.length > 0) {
                prevItem.focus();
            }
        } else if (event.which === 39 || event.which === 40) { //right arrow key
            //if there's a next one, go to that; otherwise nothing
            var nextItem = $(event.currentTarget).next('.accordion-item').children('.ds-accordion-heading');

            if (nextItem.length > 0) {
                nextItem.focus();
            }
        } else if (event.which === 38) { //up arrow
            var prevItem2 = $(event.currentTarget).prev('.accordion-item');

            if (prevItem2.length > 0) {
                if (prevItem2.children('.accordionSubMenu').hasClass('expanded')) {
                    prevItem2.find('.accordionSubMenu .accordion-item').last().children('a').focus();
                } else {
                    prevItem2.children('.ds-accordion-heading').focus();
                }
            }
            event.preventDefault();
        } else if (event.which === 27) { // esc
            //close the menu
            if (subMenu.hasClass('expanded')) {
                collapseMenu(subMenu);
            }
            event.preventDefault();
        }
    };

    var menuChildKeyPress = function (event) {
        if (event.which === 13 || event.which === 32) { //enter key or space key
            event.stopPropagation();
        } else if (event.which === 40) { //down arrow
            //go to the next item if there is one; otherwise go to next menu if there is one
            var nextItem = $(event.currentTarget).next('.accordion-item');

            if (nextItem.length > 0) {
                nextItem.children('a').focus();
            } else {
                var nextMenu = $(event.currentTarget).parent().parent().next('.accordion-item');

                if (nextMenu.length > 0) {
                    nextMenu.children('.ds-accordion-heading').focus();
                }
            }
            event.stopPropagation();
            event.preventDefault();
        } else if (event.which === 38) { //up arrow
            //go to prev item if there is one; otherwise, go up to the parent menu
            var prevItem = $(event.currentTarget).prev('.accordion-item');

            if (prevItem.length > 0) {
                prevItem.children('a').focus();
            } else {
                $(event.currentTarget).parent().closest('.accordion-item').children('.ds-accordion-heading').focus();
            }

            event.stopPropagation();
            event.preventDefault();
        }
    };


    var expandMenu = function (menu) {
        menu.toggleClass('expanded', true);
        menu.attr('aria-expanded', 'true');
        menu.parent().closest('.accordion-item').addClass('menu-expanded');
        menu.parent().closest('.accordion-item').children('.ds-accordion-heading').attr('aria-expanded', 'true').focus();
    };

    var collapseMenu = function (menu) {
        //Close the menu, and if focus was inside it, put focus on the menu itself
        var focusedChild = menu.find(':focus');

        if (focusedChild.length > 0) {
            menu.parent().children('.ds-accordion-heading').focus();
        }

        menu.parent().removeClass('menu-expanded');
        menu.parent().children('.ds-accordion-heading').attr('aria-expanded', 'false');
        menu.toggleClass('expanded', false).attr('aria-expanded', 'false');
    };

    initNavMenu();

}
