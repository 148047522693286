/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var tealium = require('../tealium');
var TrackingHelper = require('./common');

var product = {
    init: function () {
        // we don't need bind cart-add event here, as we already do it in tealium/pages/search.js
        // tealium/pages/search.js - we load it on PDP to have possibility to work with product tiles from Certona recommendations
        // so, tealium/pages/search.js - is used on CLP/search, PDP, Certona tiles (PDP and cart)
        //
        //Cart Add button
        //$('#pdpMain').off('click', '#add-to-cart', product.cartAdd);
        //$('#pdpMain').on('click', '#add-to-cart', product.cartAdd);
    },
    //see pages/product/addToCart.js
    addToCartButton: null,
    cartAdd: function () {
        var button = product.addToCartButton;
        var data = button.data('tealium');
        var qty = button.parents('form').find('input[name="Quantity"]').val();
        var cartData = {
            'cart_product_id': [],
            'cart_product_price': [],
            'cart_product_quantity': [],
            'cart_product_sku': [],
            'cart_total_items': '0',
            'cart_total_value': '0'
        };

        if ($('.tealium-cart-data').size() > 0) {
            cartData = $('.tealium-cart-data').data('tealium');
        }
        tealium.eventCount += 1;
        var obj = {
            'cart_product_id' : cartData.cart_product_id,
            'cart_product_price' : cartData.cart_product_price,
            'cart_product_quantity' : cartData.cart_product_quantity,
            'cart_product_add_location' : window.digitalData.page.type,
            'cart_product_sku' : cartData.cart_product_sku,
            'cart_total_items' : cartData.cart_total_items + '',
            'cart_total_value' : cartData.cart_total_value + '',
            'ecommerce_action' : 'Cart Add',
            'event_category' : 'Product',
            'event_action' : 'Cart Add',
            'event_label' : 'Cart Add',
            'event_name' : 'cart_add',
            'event_value' : tealium.eventCount + '',
            'product_brand': [data.brand],
            'product_category': [data.category],
            'product_id': [data.id],
            'product_name': [data.name],
            'product_image_url': [data.image],
            'product_original_price': [data.original_price],
            'product_price': [data.price],
            'product_promo_code': [data.promo_code],
            'product_quantity': [qty],
            'product_sku': [data.sku],
            'product_subcategory': [('subcategory' in data) ? data.subcategory : ''],
            'product_discount_amount': [('discount_amount' in data) ? data.discount_amount : ''],
            'page_id': window.digitalData.page.pageInfo.pageID || ''
        };
        tealium.triggerEvent(obj);
        var additionalData = [];

        // Set custom shop action 5 tags
        var isBopis = false;
        if ($('#bopis_toggle').length > 0 && (window.digitalData.pageInstanceID == 'search'|| window.digitalData.pageInstanceID == 'category')){
            isBopis = $('#bopis_toggle')[0].checked;
        }
        if ($('#pdp-pickFromStoreOption').length > 0 && window.digitalData.pageInstanceID == 'product'){
            isBopis = $('#pdp-pickFromStoreOption')[0].checked;
        }
        if ($('#QuickViewDialog').length > 0 && $('#QuickViewDialog').parent().css('display') == 'block'){
            isBopis = ($('#QuickViewDialog').find('#ql-pickFromStoreOption').length > 0)? $('#QuickViewDialog').find('#ql-pickFromStoreOption')[0].checked: false;
        }
        isBopis = (isBopis)?'Y':'N';
        
        var customTags = [{index: 2, value: isBopis}];
        var extendedValues = TrackingHelper.returnTrackingParams(customTags);
        //#857531 - we need pass some value to detect page from which product was added to cart, 
        //'product' - for pdp, categoryID  for clp 
        var categoryID = 'product';
        if ('pageContext' in window && 'ns' in window.pageContext && window.pageContext.ns !== 'product') {
            categoryID = window.digitalData.page.pageInfo.pageID;
        }

        if ($('#AddedItems').size() > 0 && $('#AddedItems').data('value') != undefined) {
            additionalData = $('#AddedItems').data('value');

            for (var i = 0; i < additionalData.length; i++) {
                additionalData[i].category.primaryCategory = categoryID;
                if (!additionalData[i].attributes.exploreAttributes){
                    additionalData[i].attributes.exploreAttributes = extendedValues;
                }
            }

            window.digitalData.cart.item = additionalData;
        } else {
            additionalData = button.data('ibm');
            additionalData.quantity = qty;

            //#857531 - we need pass category ID from which product was added on clp
            additionalData.category.primaryCategory = categoryID;
            additionalData.attributes.exploreAttributes = extendedValues;
            window.digitalData.cart.item.push(additionalData);
        }
        window.cmAddToCart();
    }
}

module.exports = product;
