'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    tooltip = require('../../tooltip'),
    dialog = require('../../dialog'),
    validator = require('../../validator'),
    util = require('../../util'),
    page = require('../../page'),
    states = require('./states'),
    minicart = require('../../minicart'),
    designSystemTooltip = require('../../designSystem/designsystemtooltip'),
    wcagLoadSpinner = require('../../accessibleLoadingSpinner');

var shippingMethods;

function updateGiftTo(firstName, lastName) {
    var $giftRecipient = $('.gift-recipient');
    if (firstName.length > 0 || lastName.length > 0) {
        $giftRecipient.html('<div class="gift-to"><b>To:</b> <span>' + firstName + ' ' + lastName + '</span></div><div class="gift-from">(Gift messages will be sent from the name in the billing address.)</div>');
    } else {
        $giftRecipient.html('<div class="gift-to"><b>To:</b> (Gift recipients name will be provided from the shipping address.)</div>');
    }
}

function toggleGiftRecipient(isVisible) {
    var $firstName = $('#dwfrm_singleshipping_shippingAddress_addressFields_firstName');
    var $lastName = $('#dwfrm_singleshipping_shippingAddress_addressFields_lastName');

    if (isVisible) {
        if ($firstName.length > 0 || $lastName.length > 0) {
            updateGiftTo($firstName.val(), $lastName.val());
        }
        $firstName.add($lastName).on('change.giftbox', function() {
            updateGiftTo($firstName.val(), $lastName.val());
        });
    } else {
        $firstName.add($lastName).off('change.giftbox');
    }
}

/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    if ($('input[name$="_shippingAddress_isGift"]:checked').val() == 'true') {
        toggleGiftRecipient(true);
        if ($('.toggle-with-gift-checkbox').hasClass('hidden')) {
            $('.toggle-with-gift-checkbox').slideDown('fast').removeClass('hidden');
        }
        if ($('.gift-message-box').hasClass('hidden')) {
            $('.gift-message-box').slideDown('fast').removeClass('hidden');
        }
    } else {
        toggleGiftRecipient(false);
        if (!$('.toggle-with-gift-checkbox').hasClass('hidden')) {
            $('.toggle-with-gift-checkbox').slideUp('fast', function() {
                $('.toggle-with-gift-checkbox').addClass('hidden');
            });
        }
        if (!$('.gift-message-box').hasClass('hidden')) {
            $('.gift-message-box').slideUp('fast', function() {
                $('.gift-message-box').addClass('hidden');
            });
        }
    }

    // remove gift message if message box is hidden
    if ($('.gift-message-box').hasClass('hidden')) {
        $('.gift-message-box').find('textarea').val('');
    }
    if ($('input[name$="_shippingAddress_isGift"]:checked').val() == 'true') {
        if ('digitalData' in window) {
            window.digitalData.shippingGift = {isThisGift: 'Yes'}
        }
    } else {
        if ('digitalData' in window) {
            window.digitalData.shippingGift = {isThisGift: 'No'}
        }
    }

    if ($('input[name$="_shippingAddress_isGift"]:checked').val() == 'false' && $('input[name$="_includeGiftBox"]:checked').val() == 'true') {
        $('input[name$="_includeGiftBox"][value="false"]').prop('checked', true);
        $.post(window.Urls.checkoutRemoveGiftBox, function(){
            updateSummary();
        });
    }
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    var fakeCBtn = $summary.find('#fakeContinueBtn');
    var verify = $summary.find('.verify-field');

    // indicate progress
    wcagLoadSpinner.show($('.checkout-order-totals'));

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
        $summary.find('.fake-continue-row').empty().prepend(fakeCBtn).prepend(verify);
        designSystemTooltip();
        wcagLoadSpinner.init($('.checkout-order-totals'));
        minicart.checkForARProducts();
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    if (params.countryCode === 'CA') {
        params.stateCode = $form.find('select[id$="_stateCA"]').val();
    }
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

            // TODO the for loop below isn't doing anything?
            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    states.init();
    var $shippingMethodList = $('#shipping-method-list');

    util.maskZipCodeInit();

    var url = getShippingMethodURL(Urls.shippingMethodsJSON);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }

            //check CBD product shipments
            if (SitePreferences.ENABLE_CBD_RESTRICTION){

                var checkCBD = false;
                var CBDRestriction = null;
                if (SitePreferences.CBD_RESTRICTION_STATES){
                    CBDRestriction = JSON.parse(SitePreferences.CBD_RESTRICTION_STATES);
                }
                if ($('select[name$="_country"]').val() == 'CA' || (CBDRestriction && CBDRestriction.states.indexOf($('select[name$="_addressFields_states_state"]').val()) > -1)){
                    checkCBD = true;
                }

                if (checkCBD && $('.restricted-items.stop-checkout').length && $('.restricted-items.stop-checkout .js-checkcbd').length && shippingMethods != undefined && shippingMethods.toString() === data.toString()) {
                    // No need to update the UI.  The list has not changed.
                    return true;
                }
            } else {
                if (shippingMethods != undefined && shippingMethods.toString() === data.toString()) {
                    // No need to update the UI.  The list has not changed.
                    return true;
                }
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;

            // indicate progress
            wcagLoadSpinner.show($('.shipping-methods-wrap'));

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
                    selectShippingMethod($(this).val());
                    $('.outline').removeClass('active');
                    $(this).parents('.form-row').find('label .outline').addClass('active');

                });

                // update the summary
                updateSummary();
                wcagLoadSpinner.hide($('.shipping-methods-wrap'));
                var shippingToolTip = $('.shipping-tooltip');
                if (shippingToolTip.length > 0) {
                    shippingToolTip.tooltip({
                        items: '.shipping-tooltip',
                        track: false,
                        tooltipClass: 'shipping-tooltip-wrapper',
                        content: function () {
                            if ($(this).hasClass('withlink')) {
                                return $(this).prop('title');
                            } else {
                                if ($.trim($(this).find('.tooltip-content').html()).length) {
                                    return $(this).find('.tooltip-content').html();
                                } else {
                                    return false;
                                }
                            }
                        },
                        show: null,
                        position: {
                            my: ($(window).width() >= 768) ? 'left center' : 'bottom',
                            at: ($(window).width() >= 768) ? 'right+15 center' : 'bottom'
                        },
                        open: function(event, ui) {
                            if (typeof(event.originalEvent) === 'undefined') {
                                return false;
                            }
                            var $id = $(ui.tooltip).attr('id');
                            if ($(window).width() < 768) {
                                $(ui.tooltip).css('margin-left', ($(window).width() - $(ui.tooltip).width()) / 2)
                                             .css('margin-top', (-1) * ($(event.target).parents('.form-row').height() + 10));
                            }
                            // close any lingering tooltips
                            $('div.ui-tooltip').not('#' + $id).remove();

                        },
                        close: function(event, ui) {
                            ui.tooltip.hover(function() {
                                $(this).stop(true).fadeTo(1, 1);
                            },
                            function() {
                                $(this).fadeOut('400', function() {
                                    $(this).remove();
                                });
                            });
                        }
                    });
                }
                tooltip.init();
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                    $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                }
                $shippingMethodList.find('.input-radio:checked').parents('.form-row').find('label .outline').addClass('active');

                //Edit address functionality
                $('#edit-address-button').on('click', function(){
                    document.getElementById('js-heading-shipping-address').scrollIntoView();
                });

                //CBD show page level error message
                if ($('.restricted-items.stop-checkout').length){
                    $('.restricted-error-message').addClass('summary-error');
                    $('.item-removed-message').hide();
                } else {
                    $('.restricted-error-message').removeClass('summary-error');
                }
                //CBD products remove functionality.
                $('button.remove-restricted-products').on('click', function(e){
                    e.preventDefault();

                    var removeRestrictedProductsURL = Urls.removeRestrictedProductsURL;
                    var PIList = JSON.stringify($(this).data('restricted-lineitemids'));
                    var data = {restrictedLineItemIds : PIList};

                    $.ajax({
                        type: 'POST',
                        url: removeRestrictedProductsURL,
                        data: data,
                        success: function(data) {
                            if (data.success){
                                page.redirect(Urls.cartShow);
                            }
                        }
                    });
                });
                formPrepare.validateForm();
            });
        }
    });

    // check for restricted items
    if ($('#shipping-method-list .restricted-items').length) {
        //Paypal Express Removal Button to work properly
        if ($('.restricted-items.stop-checkout').length){
            $('.restricted-error-message').addClass('summary-error');
            $('.item-removed-message').hide();
        } else {
            $('.restricted-error-message').removeClass('summary-error');
        }
        $('button.remove-restricted-products').on('click', function(e){
            e.preventDefault();

            var removeRestrictedProductsURL = Urls.removeRestrictedProductsURL;
            var PIList = JSON.stringify($(this).data('restricted-lineitemids'));
            var data = {restrictedLineItemIds : PIList};

            $.ajax({
                type: 'POST',
                url: removeRestrictedProductsURL,
                data: data,
                success: function(data) {
                    if (data.success){
                        page.redirect(Urls.cartShow);
                    }
                }
            });
        });
    }
}

/**
 * Radial AVS for singleshipping
 */
function radialAVSInit (){
    $('.suggested-addresses-list input[type="radio"]').on('click', function () {
        var input = $(this);
        var address = input.data('address');
        $('input[name$="_address1"]').val(address.address1).trigger('change');
        $('input[name$="_address2"]').val(address.address2).trigger('change');
        $('input[name$="_city"]').val(address.city).trigger('change');
        $('input[name$="_postal"]').val(address.postalCode).trigger('change');
        $('input[name$="_country"]').val(address.countryCode).trigger('change');
        //Canada state
        if (address.countryCode.toLowerCase() == 'ca') {
            $('select[name$="_stateCA"]').val(address.stateCode).trigger('change');
        } else {
            $('select[name$="_state"]').val(address.stateCode).trigger('change');
        }
        if (input.attr('id') === 'use-my') {
            $('#skipAVSCheck').val('1');
        } else {
            $('#skipAVSCheck').val('0');
        }
    });
}

function phoneMask (){
    var phone = $('.address').find('input.phone');
    if (!phone.hasClass('error')) {
        var maskedVal = phone.val().replace(/[\-\. \(\)]/g,'')
            .replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        phone.val(maskedVal);
    }
}

function sendLoginAnalytics() {
    var status = $('h2').data('status');
    if (status) {
        window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-'+status+'-_-'+window.digitalData.page.pageInfo.pageID);
    }
}

exports.init = function () {
    states.init();
    radialAVSInit();
    sendLoginAnalytics();
    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector:'[id$="singleshipping_shippingAddress"]',
        fakeContinue:'[id$="fakeContinueBtn"]'
    });

    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);

    $('#password-reset').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                title: this.title,
                width: 388,
                dialogClass: 'resetpassword',
                open: function () {
                    validator.init();
                    var $requestPasswordForm = $('[name$="_requestpassword"]'),
                        $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                    $('.ui-dialog-titlebar-close').attr('alt','Close');
                    $submit.on('click', function (e) {
                        if (!$requestPasswordForm.valid()) {
                            return;
                        }
                        e.preventDefault();
                        dialog.submit(function(event) {
                            var url = $requestPasswordForm.attr('action'),
                                data = $requestPasswordForm.serializeArray(),
                                param1Name = $submit.attr('name'),
                                param1Value = $submit.attr('value');
                            data.push({name: param1Name, value: param1Value});
                            data.push({name: 'format', value: 'ajax'});
                            $.ajax({
                                type: 'POST',
                                url: url,
                                data: data,
                                success: function(data) {
                                    // check for error prior to writing HTML to dialog container.
                                    // note: bindings are lost once html overwrites dialog container.
                                    if (data.search('error-message') !== -1) {
                                        var $noEmailError = $('.foundMatch');
                                        var errorMsgWrapper = $('[id$="_requestpassword_email"]').closest('.field-wrapper');
                                        if ($noEmailError.length > 0){
                                            $noEmailError.empty().remove();
                                        }
                                        $('<span class="form-caption error-message foundMatch">' + Resources.VALIDATE_EMAILNOTFOUND + '</span>').appendTo(errorMsgWrapper);
                                    } else { // update dialog only when success message available
                                        $('#dialog-container').html(data);
                                        $('.request-password-button').find('a.button').on('click', function(ev) {
                                            ev.preventDefault();
                                            dialog.close();
                                            $('[name$="_login_username"]').focus();
                                        })
                                    }
                                }
                            })
                            event.preventDefault();
                        });
                    });
                }
            }
        });
    });

    // Validate phone number
    $('.input-text.phone, .input-text.email').on('focus change', function () {
        $(this).parent().addClass('focused');
    }).on('blur', function(){
        $(this).parent().removeClass('focused');
    });

    $('.see-gift-box').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                width: 306,
                dialogClass : 'gift-box-dialog'
            }
        });
    });

    if ($('#proceed-to-billing').size() > 0) {
        $('#proceed-to-billing').find('a').first().on('click', function(e) {
            e.preventDefault();
            //scrolldown page to "promotion codes" form if customer click "skip to Payment"
            $('.checkout-shipping').attr('action', $('.checkout-shipping').attr('action') + '#promocodes');
            $('button[name$="_shippingAddress_save"]').trigger('click');
            return false;
        });
    }
    $('#secondary').on('click','#fakeContinueBtn', function(e) {
        e.preventDefault();
        $('button[name$="_shippingAddress_save"]').trigger('click');
    });
    $('.address').on('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_country"], select[name$="_addressFields_states_state"], select[name$="_addressFields_statesCA_stateCA"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"]',
        updateShippingMethodList
    );
    if ($('.select-address').length > 0) {
        var title = $('.select-address select option:first').val();
        var selectedAddressName = $('.address').find('.selectedAddressName input');
        selectedAddressName.val(title);
        phoneMask();
        $('.select-address select').on('change', function() {
            title = $(this).val();
            selectedAddressName.val(title);
            phoneMask();
        });
    }

    giftMessageBox();
    updateShippingMethodList();
    $('input[name$="_includeGiftBox"]').on('click', function() {
        var input = $(this);
        if (input.val() === 'true') {
            $.post(window.Urls.checkoutAddGiftBox, function(){
                updateSummary();
            });
        } else {
            $.post(window.Urls.checkoutRemoveGiftBox, function(){
                updateSummary();
            });
        }
    });

    wcagLoadSpinner.init($('.checkout-order-totals'));
    wcagLoadSpinner.init($('.shipping-methods-wrap'));
    formPrepare.validateForm();
    util.maskPhoneInit();
    util.maskZipCodeInit();
};

exports.updateShippingMethodList = updateShippingMethodList;
