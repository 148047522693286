'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    util = require('../../util'),
    progress = require('../../progress'),
    validator = require('../../validator'),
    dialog = require('../../dialog'),
    states = require('./states'),
    securePayment = require('./securePayment'),
    minicart = require('../../minicart');

var SECUREPAYMENT_AVAILABLE_CC_OPTION = 'availableCC',
    SECUREPAYMENT_EVENT_SUCCESS = 'pciComplete',
    giftCardNumField = document.querySelector('input[name$="billing_giftCertCode"]');

var makeUrl = function (url, giftCertificateID) {
    if (giftCertificateID) {
        url = util.appendParamToURL(url, 'giftCertificateID', giftCertificateID);
    }
    return url;
};

var billingAddress = {
    save: function () {
        if (typeof(Storage) !== 'undefined') {
            var formData = $('fieldset.address-element').serialize();
            localStorage.setItem('billingaddress', formData);
        }
    },
    restore: function () {
        if (typeof(Storage) !== 'undefined') {
            var formData = localStorage.getItem('billingaddress');
            if (formData !== null) {
                $('fieldset.address-element').deserialize(formData);
                localStorage.removeItem('billingaddress');
            }
        }
    }
};

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="_creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('input[name$="_creditCard_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_creditCard_expiration_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_creditCard_expiration_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('input[name$="_creditCard_cvn"]').val('').trigger('change');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
        minicart.checkForARProducts();
    });
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    if (!Resources.ENABLE_EXPOSE_PAYPAL_BILLING || $('#is-SECURE_PAYMENT').length) {
        var $paymentMethods = $('.payment-method');
        $paymentMethods.removeClass('payment-method-expanded');

        var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
        if ($selectedPaymentMethod.length === 0) {
            $selectedPaymentMethod = $('[data-method="Custom"]');
        }
        $selectedPaymentMethod.addClass('payment-method-expanded');

        // ensure checkbox of payment method is checked
        $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
        $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');
        if (paymentMethodID == 'PayPal') {
            $('.continue-row').addClass('PayPal');
        } else {
            $('.continue-row').removeClass('PayPal');
        }

        if (paymentMethodID == 'SECURE_PAYMENT') {
            formPrepare.setCustomValidationEvent(SECUREPAYMENT_EVENT_SUCCESS);
        } else {
            formPrepare.removeCustomValidationEvent(SECUREPAYMENT_EVENT_SUCCESS);
        }
        if ($('#is-SECURE_PAYMENT').length && paymentMethodID == 'CREDIT_CARD' && $('#creditCardList').find('input[checked]').length === 0) {
            formPrepare.setCustomValidationEvent(SECUREPAYMENT_AVAILABLE_CC_OPTION);
        } else {
            formPrepare.removeCustomValidationEvent(SECUREPAYMENT_AVAILABLE_CC_OPTION);
        }

        formPrepare.validateForm();
    }
}

function selectCreditPayment() {
    // select credit card from list
    $('#creditCardList').find('input[type="radio"]').on('change', function () {
        var input = $(this);
        $('.billing-saved-card .card-cvn').addClass('hidden');
        if (input.attr('id') === 'billing-add-new-card') {
            if (input.prop('checked')) {
                $('.billing-new-card').removeClass('hidden');
            } else {
                $('.billing-new-card').addClass('hidden');
            }
            $('#creditCardList').find('input[type="radio"]').not('#billing-add-new-card').prop('checked', false);

            //truncate data in CC fields
            var typeVal = $('[data-method="CREDIT_CARD"]').find('input[name$="_creditCard_type"]').val();
            var emptyData = {holder:'', type:typeVal, maskedNumber:'',expirationMonth:'',expirationYear:''};
            $('input[name$="creditCard_cctoken"]').val('');
            setCCFields(emptyData);
        } else {
            $('.billing-new-card').addClass('hidden');
            $('#billing-add-new-card').prop('checked', false);
            $('input[name$="creditCard_cctoken"]').val(input.data('token'));
            $('input[name$="_creditCard_type"]').val(input.data('type'));
            var cardUUID = input.val();
            if (!cardUUID) {return;}
            populateCreditCardForm(cardUUID);
            input.parents('.billing-saved-card').find('.card-cvn').removeClass('hidden');
        }

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();

        // when PCI PAL is visible we need to manually hide PCI PAL options
        if ($('#is-SECURE_PAYMENT')){
            $('#securePaymentUI').removeClass('payment-method-expanded');
        }
    });

    $('.card-delete button').on('click', function(e) {
        e.preventDefault();
        //Restrict AutoRefresh User to remove default card
        var isARCustomer = $(this).closest('.billing-saved-card').find('input[name=isARCustomer]').val();
        var isARselected = $(this).closest('.billing-saved-card').find('input[name=isARSelected]').val();
        var isDefaultCard = $(this).closest('.billing-saved-card').find('.default-label').length;


        if ((isARCustomer == 'true' && SitePreferences.ORDERGROOVE_ENABLED && isDefaultCard) || (isARselected == 'true' && SitePreferences.ORDERGROOVE_ENABLED && isDefaultCard)) {
            if ($('.billing-saved-card p.error-text').length == 0) {
                $('.default-label').after('<p class=error-text>'+Resources.BILLING_AUTOREFRESH_DEFAULTCARD_ERROR+'</p>');
            }
            return;
        }
        if (window.User.isShopInApp) {
            var creditCardRemoveConfirmHtml = $('#creditcard-remove-confirm').html();
            var elementApp = $(this);
            var cardIDApp = elementApp.data('card');
            var csrfTokenIdApp = elementApp.data('csrf-token-id');
            dialog.open({
                html: creditCardRemoveConfirmHtml,
                options: {
                    width: 375,
                    dialogClass: 'creditcard-remove-dialog',
                    buttons: [{
                        text: Resources.CARD_DELETE,
                        'class': 'deletebutton',
                        click: function () {
                            var url = util.appendParamsToUrl(Urls.billingRemoveCC,{'creditCardUUID': cardIDApp,'csrf_token': csrfTokenIdApp});
                            ajax.getJson({
                                url: url,
                                callback: function (data) {
                                    if (!data) {
                                        window.alert(Resources.CC_REMOVE_ERROR);
                                        return false;
                                    }
                                    elementApp.parent().parent().slideUp();
                                    if (data.emptycard){
                                        $('.save-cc-container').find('label span').html(Resources.SAVE_CARD_MAKE_DEFAULT_LABEL);
                                    }

                                    if ($('.input-checkbox.save-cc').hasClass('disable-save-card')){
                                        $('.input-checkbox.disable-save-card').unbind();
                                        $('.input-checkbox.save-cc').removeClass('disable-save-card');
                                        $('.max-card-error').remove();
                                    }
                                }
                            });
                            $(this).dialog('close');
                        }
                    },
                    {
                        text: Resources.COUPON_REMOVE_CANCEL,
                        'class': 'cancelbutton',
                        click: function () {
                            $(this).dialog('close');
                        }
                    }]
                },
                close: function () {
                    $(this).dialog('close');
                }
            });
        } else if (confirm(Resources.CC_REMOVE_CONFIRM)) {
            var element = $(this);
            var cardID = element.data('card');
            var csrfTokenId = element.data('csrf-token-id');
            var url = util.appendParamsToUrl(Urls.billingRemoveCC,{'creditCardUUID': cardID,'csrf_token': csrfTokenId});
            ajax.getJson({
                url: url,
                callback: function (data) {
                    if (!data) {
                        window.alert(Resources.CC_REMOVE_ERROR);
                        return false;
                    }
                    element.parent().parent().slideUp();
                    if (data.emptycard){
                        $('.save-cc-container').find('label span').html(Resources.SAVE_CARD_MAKE_DEFAULT_LABEL);
                    }

                    if ($('.input-checkbox.save-cc').hasClass('disable-save-card')){
                        $('.input-checkbox.disable-save-card').unbind();
                        $('.input-checkbox.save-cc').removeClass('disable-save-card');
                        $('.max-card-error').remove();
                    }
                }
            });
        }
    });

    $('.card-cvn input').on('change keyup mouseup', function () {
        var input = $(this);
        $('input[name$="creditCard_cvn"]').val(input.val()).trigger('change');
        if ($('input[name$="creditCard_cvn"]').hasClass('error')) {
            input.addClass('error');
        } else {
            input.removeClass('error');
        }
        if ($('span[id$="creditCard_cvn-error"]').text().length > 0) {
            var errorText = $('span[id$="creditCard_cvn-error"]');
            input.parent().append(errorText);
        }
    }).on('blur', function () {
        var errorMessages = $(this).closest('div').find('span.error');
        if ($(this).hasClass('valid')) {
            errorMessages.remove();
        }
        if ($(this).val().length === 0 && $(this).closest('div').find('span.error').length <= 0) {
            $(this).removeClass('valid').addClass('error');
            $('<span class="error">'+ Resources.VALIDATE_REQUIRED +'</span>').insertAfter($(this));
        }
    });

    $('.paypalBtn').on('click', function(e) {
        e.preventDefault();
        window.cmCreatePageElementTag('PayPalStandard', 'Paypal');
        $('.address').find('.continue-row button').trigger('click');
    });
}

function phoneMask (){
    var phone = $('.address').find('input.phone');
    if (!phone.hasClass('error')) {
        var maskedVal = phone.val().replace(/[\-\. \(\)]/g,'')
            .replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        phone.val(maskedVal);
    }
}

function sendLoginAnalytics() {
    var status = $('h2').data('status');
    if (status) {
        window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-'+status+'-_-'+window.digitalData.page.pageInfo.pageID);
    }
}

/**
 * Function to prevent the customer from saving more than 10 cards.
 * Disable the save card checkbox.
 */
function saveCardInit() {
    $('.input-checkbox.disable-save-card').removeAttr('checked');
    $('.input-checkbox.disable-save-card').on('click', function(e){
        e.preventDefault();
    })
}
function paymentMethodMaxCard() {
    $('.disable-save-card').attr('disabled', 'true');
    $('.max-card-error').attr('tabindex','0');
}
function handleRequireBopisSMS() {
    if (($('input[id*="sms_contact"]').val() && $('input[id*="sms_contact"]').val().length || $('input[id*="sms_acknowledged"]').is(':checked')) && !$('input[id*="sms_acknowledged"]').hasClass('required') && !$('input[id*="sms_contact"]').hasClass('required')) {
        $('input[id*="sms_acknowledged"]').attr('aria-required', true).addClass('required');
        $('input[id*="sms_contact"]').attr('aria-required', true).addClass('required');
        $('label[for*="sms_contact"]').prepend('<span class="required-indicator">*</span>');
        $('<span class="required-indicator">*</span>').insertBefore('input[id*="sms_acknowledged"]');
    } else if ($('input[id*="sms_contact"]').val() === '' && !$('input[id*="sms_acknowledged"]').is(':checked')) {
        $('input[id*="sms_acknowledged"]').attr('aria-required', false).removeClass('required');
        $('input[id*="sms_contact"]').attr('aria-required', false).removeClass('required');
        $('label[for*="sms_contact"]').find('.required-indicator').remove();
        $('input[id*="sms_acknowledged"]').parent().find('.required-indicator').remove();
        $('[id*="sms_acknowledged-error"]').hide();
    }
    formPrepare.updateRequireInputs();
    formPrepare.validateForm();
}

// BOPIS SMS
function initBopisSMS() {
    $('input[id*="sms_contact"]').on('keyup blur', function() {
        handleRequireBopisSMS();
    });
    $('input[id*="sms_acknowledged"]').on('change', function() {
        handleRequireBopisSMS();
    });
    handleRequireBopisSMS();
}

function hideAddMail (that){
    var CurrCountry = (that).val();
    var addToMail = (that).parents('.checkout-billing').find('.form-row.addtoemail.checkbox');
    if (CurrCountry == 'US') {
        addToMail.hide()
            .find('.input-checkbox ').prop('checked', true);
    } else {
        addToMail.show()
            .find('.input-checkbox ').prop('checked', true);
    }
}

function initializeGiftCardRemoval(){
    $('.cancel-gift-card-removal').on('click', function (e) {
        e.preventDefault();
        dialog.close();
        $('.giftCard-remove-dialog').data('giftCardRemoveButton').focus();
    });
    $('.billing-coupon-code.gift-card.modal').find('a.remove').on('click', function(e) {
        e.preventDefault();
        dialog.close();
        var url = $(this).attr('href'),
            appliedGCList = $('.redemption.giftcert');
        $.getJSON(url, function (data) {
            if (data) {
                updateSummary();
                progress.show(appliedGCList);

                appliedGCList.load(Urls.appliedGCList, function () {
                    assignEventGiftCardRemoval();
                    if (appliedGCList.find('.success').length === 0) {
                        appliedGCList.html('<p>Gift Card has been removed.</p>').find('p').attr('tabindex','-1').focus();
                    } else {
                        appliedGCList.find('.success').last().attr('tabindex','-1').focus();
                    }
                    appliedGCList.fadeIn('fast');

                    if ($('#payment-methods').find('#noPaymentNeeded').val() == 'true') {
                        progress.show($('#payment-methods'));
                        $('#payment-methods').load(Urls.updatePaymentMethods, function () {
                            $('#payment-methods').fadeIn('fast');

                            $('.payment-method-options').on('click', 'input[type="radio"]', function () {
                                updatePaymentMethod($(this).val());
                            });

                            selectCreditPayment();
                            if ($('.billing-saved-card').length > 0) {
                                $('.billing-saved-card:first').find('.card-radio input[type="radio"]').trigger('click');
                            }

                            formPrepare.init({
                                formSelector: 'form[id$="billing"]',
                                continueSelector: '[name$="billing_save"]',
                                fakeContinue:'[id$="fakeContinueBtn"]'
                            });

                            if ($('#is-SECURE_PAYMENT').length){
                                securePayment.reInit();
                            }

                            if ($('#is-CREDIT_CARD').length) {
                                updatePaymentMethod('CREDIT_CARD');
                                var $ccOptions = $('#creditCardList input[type="radio"]:not([disabled])');
                                if ($ccOptions.length) {
                                    $($ccOptions[0]).trigger('change');
                                }
                            }
                        });
                    }

                });
            }
        });
    });
}

function assignEventGiftCardRemoval(){
    $('a.gift-remove').on('click', function(e) {
        e.preventDefault();
        var url = makeUrl(Urls.modalGCRemove,$(this).attr('id'));
        dialog.open({
            url: url,
            options: {
                width: 305,
                dialogClass : 'giftCard-remove-dialog',
                open: initializeGiftCardRemoval
            }
        });
        $('.giftCard-remove-dialog').data('giftCardRemoveButton', this);
    });
}

function initializeGiftCard() {
    var $modalGiftcert = $('.gift-card.modal');
    var $recaptchaCheckBalance = $modalGiftcert.find('.g-recaptcha-checkbalance');
    var recaptchaIDCheckBalance = window.grecaptcha ? window.grecaptcha.render($recaptchaCheckBalance.get(0), {'sitekey' : $recaptchaCheckBalance.data('sitekey')}) : '';

    $modalGiftcert.find('input[name$="_giftCertCode"]').val($('.billing-coupon-code').find('input[name$="_giftCertCode"]').val());
    $modalGiftcert.find('input[name$="_giftCertPin"]').val($('.billing-coupon-code').find('input[name$="_giftCertPin"]').val());
    $modalGiftcert.on('click', '#check-giftcert-modal', function (e) {
        e.preventDefault();
        var $balance = $modalGiftcert.find('.balance'),
            codeInput = $modalGiftcert.find('input[name$="_giftCertCode"]'),
            code = codeInput.val(),
            error = $modalGiftcert.find('.giftcert-error'),
            pinInput = $modalGiftcert.find('input[name$="_giftCertPin"]'),
            pin = pinInput.val(),
            captchaToken = (window.grecaptcha ? window.grecaptcha.getResponse(recaptchaIDCheckBalance) : '');

        codeInput.add(pinInput).addClass('valid').removeClass('error')
            .next('span.error').remove();
        error.empty();

        if (error.length === 0) {
            error = $('<span>').addClass('error').appendTo($balance);
        }
        if (code.length === 0) {
            codeInput.addClass('error').removeClass('valid');
            $('<span class="error">'+ Resources.GIFT_CERT_MISSING +'</span>')
                .insertAfter(codeInput);
            error.html(Resources.GIFT_CERT_MISSING);
            return;
        } else if (code.length < 15) {
            codeInput.addClass('error').removeClass('valid');
            $('<span class="error">'+ Resources.GIFT_CERT_INVALID_LENGTH +'</span>')
                .insertAfter(codeInput);
            return;
        }
        if (pin.length === 0) {
            pinInput.addClass('error').removeClass('valid');
            $('<span class="error">'+ Resources.GIFT_CERT_PIN_MISSING +'</span>')
                .insertAfter(pinInput);
            return;
        } else if (pin.length < 4) {
            pinInput.addClass('error').removeClass('valid');
            $('<span class="error">'+ Resources.GIFT_CERT_PIN_INVALID_LENGTH +'</span>')
                .insertAfter(pinInput);
            return;
        }
        if (code.length > 14 && pin.length > 3){
            error.empty();
        }
        progress.show($modalGiftcert);

        giftcard.checkBalance(code, pin, captchaToken, function (data) {
            if ((data && data.error) || (!data || !data.balance)) {
                $balance.html(Resources.BILLING_GIFT_CERT_INVALID).removeClass('success').addClass('error');
                $modalGiftcert.find('.wrap-balance-gc').removeClass('hidden').addClass('error');
                if (window.grecaptcha) {
                    window.grecaptcha.reset(recaptchaIDCheckBalance);
                }
                progress.hide();
                return;
            }
            $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.balance).removeClass('error').addClass('success');
            $modalGiftcert.find('.wrap-balance-gc').removeClass('hidden').removeClass('error');
            if (window.grecaptcha) {
                window.grecaptcha.reset(recaptchaIDCheckBalance);
            }
            progress.hide();
        });
    });
    applyGiftCard($('.gift-card.modal'), recaptchaIDCheckBalance);
}

function applyGiftCard(parentElem, recaptchaWidgetID) {
    parentElem.find('#add-giftcert').on('click', function (e) {
        e.preventDefault();
        $(giftCardNumField).unmask();

        var codeInput = parentElem.find('input[name$="_giftCertCode"]'),
            code = codeInput.val(),
            pinInput = parentElem.find('input[name$="_giftCertPin"]'),
            pin = pinInput.val(),
            $errortop = parentElem.find('.giftcert-error-top'),
            $errorbottom = parentElem.find('.giftcert-error-bottom'),
            appliedGCList = $('.redemption.giftcert'),
            captchaToken = (window.grecaptcha ? window.grecaptcha.getResponse(recaptchaWidgetID) : '');
        codeInput.add(pinInput).addClass('valid').removeClass('error-new')
            .next('span.error').remove();
        $errortop.empty();
        $errorbottom.empty();

        if (code.length === 0) {
            codeInput.addClass('error-new').removeClass('valid').attr('aria-invalid', 'true').attr('aria-describedby', codeInput.attr('id') + '-error');
            $('<span class="error" id =' + codeInput.attr('id') + '-error' + '>'+ Resources.GIFT_CERT_MISSING +'</span>')
                .insertAfter(codeInput);
            codeInput.focus();
            return;
        } else if (code.length < 15) {
            codeInput.addClass('error-new').removeClass('valid').attr('aria-invalid', 'true').attr('aria-describedby', codeInput.attr('id') + '-error');
            $('<span class="error" id =' + codeInput.attr('id') + '-error' + '>'+ Resources.GIFT_CERT_INVALID_LENGTH +'</span>')
                .insertAfter(codeInput);
            codeInput.focus();
            return;
        }

        if (pin.length === 0) {
            pinInput.addClass('error-new').removeClass('valid').attr('aria-invalid', 'true').attr('aria-describedby', pinInput.attr('id') + '-error');
            $('<span class="error" id =' + pinInput.attr('id') + '-error' + '>'+ Resources.GIFT_CERT_PIN_MISSING +'</span>')
                .insertAfter(pinInput);
            pinInput.focus();
            return;
        } else if (pin.length < 4) {
            pinInput.addClass('error-new').removeClass('valid').attr('aria-invalid', 'true').attr('aria-describedby', pinInput.attr('id') + '-error');
            $('<span class="error" id =' + pinInput.attr('id') + '-error' + '>'+ Resources.GIFT_CERT_PIN_INVALID_LENGTH +'</span>')
                .insertAfter(pinInput);
            pinInput.focus();
            return;
        }

        billingAddress.save();
        var data = {
            giftCertCode: code,
            giftCertPin: pin,
            reCaptchaToken: captchaToken,
            format: 'ajax'
        };

        var options = {
            url: util.ajaxUrl(Urls.giftCertAddToBasket),
            type: 'POST',
            data: data,
            cache: false
        };

        $.ajax(options).done(function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
                if (window.grecaptcha) {
                    window.grecaptcha.reset(recaptchaWidgetID);
                }
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
                if (window.grecaptcha) {
                    window.grecaptcha.reset(recaptchaWidgetID);
                }
            }
            if (fail) {
                if (data.overtwo === true) {
                    if (!$errorbottom.hasClass('blue')) {
                        $errorbottom.addClass('blue')
                    }
                    $errorbottom.html('<p>'+msg+'</p>');
                } else {
                    $errortop.removeClass('blue');
                    $errortop.html('<p tabindex="0">' + Resources.BILLING_GIFT_CERT_INVALID + '</p>').find('p').focus();
                    if (data.errortype == 'pin' || data.errortype == 'zerovalue' || data.errortype == 'invalidgc') {
                        $errortop.find('p').on('keydown', function(e) {
                            var keyCode = e.keyCode || e.which;
                            if (keyCode == 9) {
                                e.preventDefault();
                                if (data.errortype == 'pin') {
                                    pinInput.focus();
                                } else {
                                    codeInput.focus();
                                }
                            }
                        });
                    }
                }

                if (window.grecaptcha) {
                    window.grecaptcha.reset(recaptchaWidgetID);
                }

                return;
            } else {
                var selectedPaymentMethod = $('.payment-method-options').find(':checked').val();

                if (data.baskettotal === 0) {
                    if (window.grecaptcha) {
                        window.grecaptcha.reset(recaptchaWidgetID);
                    }
                    if (selectedPaymentMethod == 'SECURE_PAYMENT') {
                        formPrepare.removeCustomValidationEvent(SECUREPAYMENT_EVENT_SUCCESS);
                    } else if (selectedPaymentMethod == 'PayPal') {
                        $('.continue-row').removeClass('PayPal');
                    }
                    if ($('#is-SECURE_PAYMENT').length && selectedPaymentMethod == 'CREDIT_CARD' && $('#creditCardList').find('input[checked]').length === 0) {
                        formPrepare.removeCustomValidationEvent(SECUREPAYMENT_AVAILABLE_CC_OPTION);
                    }
                    progress.show($('#payment-methods'));
                    $('#payment-methods').load(Urls.updatePaymentMethods, function () {
                        $('#payment-methods').fadeIn('fast');
                        formPrepare.validateForm();
                    });
                    appliedGCList.load(Urls.appliedGCList, function () {
                        assignEventGiftCardRemoval();
                        $errortop.find('p').remove();
                        $errorbottom.find('p').remove();
                        appliedGCList.fadeIn('fast');
                    })
                    updateSummary();
                    //window.location.assign(Urls.billing);
                } else {
                    if (window.grecaptcha) {
                        window.grecaptcha.reset(recaptchaWidgetID);
                    }
                    updateSummary();
                    codeInput.val('');
                    pinInput.val('');
                    progress.show(appliedGCList);
                    appliedGCList.load(Urls.appliedGCList, function () {
                        assignEventGiftCardRemoval();
                        $errortop.find('p').remove();
                        $errorbottom.find('p').remove();
                        appliedGCList.fadeIn('fast');
                    })
                }
            }
        });
    });
}

function bindings() {
    var $checkoutForm = $('.checkout-billing');
    var $addGiftCert = $('#add-giftcert');
    var $addCoupon = $('#add-coupon');
    var $addCouponLoyalty = $('#add-coupon-loyalty');
    var $couponCode = $('input[name$="_couponCode"]');
    var $couponCodeLoyalty = $('input[name$="_couponCodeLoyalty"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var $creditCardNum = $('input[name$="creditCard_number"]');

    $checkoutForm.on('change', 'select[name$="_addressFields_country"]', function(){
        states.init();
        hideAddMail($(this));
        util.maskZipCodeInit();
    });

    $checkoutForm.on('click', '#check-giftcert', function (e) {
        e.preventDefault();
        $(giftCardNumField).unmask();

        dialog.open({
            url: Urls.modalGC,
            options: {
                title: 'Check Gift Card Balance',
                width: 385,
                dialogClass : 'giftCard-dialog',
                open: function() {
                    initializeGiftCard();
                    $('body').children().not('.ui-dialog, script').attr('aria-hidden', 'true');
                }.bind(this),
                close: function() {
                    $('body').children().not('.ui-dialog, script').attr('aria-hidden', 'false');
                }.bind(this)
            }
        });
    });

    $checkoutForm.on('submit', function (e) {
        if ('recaptchaCallback' in window === false && window.SitePreferences.BILLING_RECAPTCHA_ENABLED && $('#recaptcha-invisible').length > 0) {
            e.preventDefault();
            $('#recaptcha-gc').remove();
            if ('recaptchaBilling' in window && 'grecaptcha' in window) {
                window.grecaptcha.execute();
            }
        }
        $creditCardNum.unmask();
    });

    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        updatePaymentMethod($(this).val());
    });

    $('#secondary').on('click','#fakeContinueBtn', function(e) {
        e.preventDefault();
        $('button[name$="_billing_save"]').trigger('click');
    });

    $addCoupon.on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error.regular'),
            code = $couponCode.val(),
            $success = $('.redemption.coupon.regular');

        $success.find('.success').remove();

        $couponCode.val('');
        $couponCode.removeClass('error');

        if (code.length === 0) {
            $error.removeClass('blue-error-message');
            $error.html('<div class="error-msg">' + Resources.COUPON_CODE_MISSING + '</div>');
            $addCoupon.focus();
            $couponCode.addClass('error');
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                if (data.status == 'COUPON_CODE_ALREADY_IN_BASKET') {
                    msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                    msg = $('<div class="success">' + msg + '</div>');
                } else {
                    msg = data.message;
                    fail = true;
                }
            }
            if (fail) {
                $error.removeClass('blue-error-message');
                if (data.status == 'LOYALTY_TYPE_GUESTUSER' || data.status =='LOYALTY_TYPE_REGISTERED_ELIGIBLE') {
                    $error.addClass('blue-error-message');
                }
                $error.html('<div class="error-msg">' + msg + '</div>').show();
                $addCoupon.focus();
                $couponCode.attr('placeholder', code);
                $couponCode.addClass('error');
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success) {
                if (data.redirectToCart) {
                    window.location.href = Urls.cartShow;
                } else if (data.baskettotal === 0) {
                    window.location.assign(Urls.billing);
                } else {
                    updateSummary();
                    $error.hide();
                    progress.show($success);
                    $success.load(Urls.appliedCouponsList, function () {
                        $success.fadeIn('fast');
                    })
                }

                var couponData = {
                    couponCode: code.toUpperCase()
                }
                window._satellite.track('checkout-add-coupon', couponData);
            }  else if (data.success == false && data.status == 'COUPON_CODE_ALREADY_IN_BASKET') {
                $error.hide();
                $success.prepend('<div class="success">' + data.message + '</div>');
            }
        });
    });

    $addCouponLoyalty.on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error-loyalty'),
            code = $couponCodeLoyalty.val(),
            $success = $('.redemption.coupon.loyalty');

        $success.find('.success').remove();
        $couponCodeLoyalty.val('');

        if (code.length === 0) {
            $error.html('<p>' + Resources.COUPON_CODE_MISSING + '</p>');
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCouponLoyalty, {couponCodeLoyalty: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                if (data.status == 'LOYALTY_COUPON_ALREADY_APPLIED') {
                    msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                    msg = $('<div class="success">' + msg + '</div>');
                } else {
                    msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                    fail = true;
                }
            }
            if (fail) {
                $error.html('<p>' + msg + '</p>').show();
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success) {
                if (data.redirectToCart) {
                    window.location.href = Urls.cartShow;
                } else if (data.baskettotal === 0) {
                    window.location.assign(Urls.billing);
                } else {
                    updateSummary();
                    $error.hide();
                    progress.show($success);
                    var url = util.appendParamsToUrl(Urls.appliedCouponsList, {isLoyalty: true})
                    $success.load(url, function () {
                        $success.fadeIn('fast');
                    })
                }

                var couponData = {
                    couponCode: code.toUpperCase()
                }
                window._satellite.track('checkout-add-coupon', couponData);
            }  else if (data.success == false && data.status == 'LOYALTY_COUPON_ALREADY_APPLIED') {
                $error.hide();
                $success.prepend('<div class="success">' + data.message + '</div>');
            }
        });
    });

    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    }).on('focus', function() {
        $(this).removeClass('error')
        $(this).attr('placeholder', '')
        $(this).closest('.billing-coupon-code').find('.error-msg').empty().addClass('reset')
    });

    $couponCodeLoyalty.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCouponLoyalty.click();
        }
    });

    $(giftCardNumField).on('keyup', function (e) {
        util.maskGiftCardNum(giftCardNumField);

        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

    $creditCardNum.on('keyup', function() {
        util.maskCreditCardNum(this);
    });

    // Validate phone number
    $('.input-text.phone, .input-text.sms-number, .input-text.email').on('focus change', function () {
        $(this).parent().addClass('focused');
    }).on('blur', function(){
        $(this).parent().removeClass('focused');
    });

    $('.gift-card.applygf').on('blur','input[name$="_giftCertCode"], input[name$="_giftCertPin"]', function() {
        $(this).removeClass('error-new');
    });

    $('.current-shipping').on('click', '.edit-link', function(e){
        e.preventDefault();
        states.init();
        $(this).parents('.current-shipping').slideToggle('200');
        $('.billing-address-form').slideToggle('250').removeClass('hidden').focus();
    });

    $('.coupon-code-header, .gift-coupon-header').on('click keydown', function(){
        $(this).removeAttr('aria-selected');
    })

    $('.alternative-pickup-person-fields .pickup-person-radio-btn').on('change', function (e) {
        if ($(e.target).is(':checked')) {
            $('.alternative-pickup-person-fields .pickup-person-radio-btn').not($(e.target)).removeAttr('checked');
            $(e.target).attr('checked', 'checked');
        }
        var $someoneElsePickUpFormFields = $('.alternative-pickup-person-fields .alternative-pickup-person-details');
        $someoneElsePickUpFormFields.removeClass('show-details-form');
        $someoneElsePickUpFormFields.find('.form-row input').removeClass('required error');
        if ($('.alternative-pickup-person-fields .someone-else-pickup-input').is(':checked')) {
            $someoneElsePickUpFormFields.addClass('show-details-form');
            $someoneElsePickUpFormFields.find('.form-row input').addClass('required');
            if ($someoneElsePickUpFormFields.find('.form-row label .required-indicator').length === 0) {
                $someoneElsePickUpFormFields.find('.form-row label').append('<span class="required-indicator">*</span>');
            }
        }
        formPrepare.updateRequireInputs();
        formPrepare.validateForm();
    });

    $('#password-reset').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                title: this.title,
                width: 388,
                dialogClass: 'resetpassword',
                open: function () {
                    validator.init();
                    var $requestPasswordForm = $('[name$="_requestpassword"]'),
                        $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                    $('.ui-dialog-titlebar-close').attr('alt','Close');
                    $submit.on('click', function (e) {
                        if (!$requestPasswordForm.valid()) {
                            return;
                        }
                        e.preventDefault();
                        dialog.submit(function(event) {
                            var url = $requestPasswordForm.attr('action'),
                                data = $requestPasswordForm.serializeArray(),
                                param1Name = $submit.attr('name'),
                                param1Value = $submit.attr('value');
                            data.push({name: param1Name, value: param1Value});
                            data.push({name: 'format', value: 'ajax'});
                            $.ajax({
                                type: 'POST',
                                url: url,
                                data: data,
                                success: function(data) {
                                    // check for error prior to writing HTML to dialog container.
                                    // note: bindings are lost once html overwrites dialog container.
                                    if (data.search('error-message') !== -1) {
                                        var $noEmailError = $('.foundMatch');
                                        var errorMsgWrapper = $('[id$="_requestpassword_email"]').closest('.field-wrapper');
                                        if ($noEmailError.length > 0){
                                            $noEmailError.empty().remove();
                                        }
                                        $('<span class="form-caption error-message foundMatch">' + Resources.VALIDATE_EMAILNOTFOUND + '</span>').appendTo(errorMsgWrapper);
                                    } else { // update dialog only when success message available
                                        $('#dialog-container').html(data);
                                        $('.request-password-button').find('a.button').on('click', function(ev) {
                                            ev.preventDefault();
                                            dialog.close();
                                            $('[name$="_login_username"]').focus();
                                        })
                                    }
                                }
                            })
                            event.preventDefault();
                        });
                    });
                }
            }
        });
    });

    $('.login-form-in-billing').on('click', '.skip-to-payment-link', function() {
        $('html, body').animate({scrollTop: $('.skip-to-payment-reference').offset().top},800);
    });
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $('.checkout-billing');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
    var addedCards = $checkoutForm.find('.redemption.giftcert');
    var icons = {
        header: 'iconClosed',
        activeHeader: 'ui-icon-triangle-1-s'
    };

    states.init();
    bindings();
    sendLoginAnalytics();

    hideAddMail($('select[name$="_addressFields_country"]'));

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]',
        fakeContinue:'[id$="fakeContinueBtn"]'
    });

    selectCreditPayment();

    //initialize save card events
    saveCardInit()
    //initialize Payment Method Page event
    paymentMethodMaxCard()

    assignEventGiftCardRemoval();

    applyGiftCard($('.gift-card.applygf'), window.recaptchaIDApply);

    if (SitePreferences.ORDERGROOVE_ENABLED) {
        var defaultCardDefined = $('.billing-saved-card').find('.default-label'),
            arCustomer = $('.is-ARCustomer').length && $('.is-ARCustomer').val(),
            arSelected = $('.is-ARSelected').length && $('.is-ARSelected').val(),
            saveDefaultCardCheckbox = $('.form-row.save-cc-container.checkbox'),
            paymentMethodOption = $('#payment-methods .payment-method-options'),
            defaultCardInfoLength = saveDefaultCardCheckbox.find('.default-card-info').length,
            firstTimeUserPaymentMethod = $('.payment-method.payment-method-expanded').find('.billing-saved-card');


        if ((arCustomer == 'true' || arSelected == 'true') && defaultCardDefined.length == 0 && firstTimeUserPaymentMethod.length > 0) {
            $(paymentMethodOption).prepend('<div class="default-card-unset-error">'+Resources.PAYMENT_METHOD_DEFAULT_CARD_NOT_SELECTED+'</div>');
            $(paymentMethodOption).prepend('<input type="hidden" class="default-card-status" value="false" />');
        } else {
            $(paymentMethodOption).prepend('<input type="hidden" class="default-card-status" value="true" />');
        }

        if (arSelected == 'true' && firstTimeUserPaymentMethod.length == 0 && defaultCardInfoLength == 0) {
            saveDefaultCardCheckbox.find('.input-checkbox').addClass('overlay');
            saveDefaultCardCheckbox.find('label').addClass('overlay');
            $(saveDefaultCardCheckbox).find('span').append('<div class="default-card-info">'+Resources.PAYMENT_METHOD_DEFAULT_CARD_SELECTION+'</div>');
            saveDefaultCardCheckbox.find('.input-checkbox').click(function() {
                return false;
            });
        }

        $('#payment-methods .billing-saved-card').each(function() {
            var defaultCard = $(this).find('.default-label'),
                billingExp =  $(this).find('.billing-expired-card');
            if (arSelected == 'true' && defaultCard.length > 0 && billingExp.length) {
                (paymentMethodOption).prepend('<div class="default-card-unset-error">'+Resources.PAYMENT_METHOD_DEFAULT_CARD_NOT_SELECTED+'</div>');
            }
        });

        if ($('.default-card-unset-error').length) {
            $('.review_order_btn').attr('disabled', 'disabled');
        }
    }

    $('.billing-accordion').accordion({
        heightStyle: 'content',
        active: false,
        collapsible: true,
        icons: icons,
        animate: 300,
        activate: function() {
            $('.gift-coupon-header, .coupon-code-header').removeAttr('aria-selected');
            if ($('.gift-card.accordion-content-active').length == 0 && $('.redemption.giftcert .success').length > 0) {
                $('.giftcard-limit').show();
            } else if ($('.gift-card.accordion-content-active').length == 0 && $('.redemption.giftcert .success').length == 0) {
                $('.giftcard-limit').hide();
            } else if ($('.gift-card.accordion-content-active').length > 0) {
                $('.giftcard-limit').show();
            }
            if ($checkoutForm.find('.redemption.giftcert').length > 0) {
                $('.giftcard-limit').show();
            }
            if (!$(this).find('.ui-accordion-header').hasClass('ui-state-active')) {
                $(this).find('.ui-accordion-header').attr('aria-expanded', 'false');
            }
        },
        beforeActivate: function(event, ui) {
            var currHeader, currContent;
            // The accordion believes a panel is being opened
            if (ui.newHeader[0]) {
                currHeader  = ui.newHeader;
                currContent = currHeader.next('.ui-accordion-content');
            // The accordion believes a panel is being closed
            } else {
                currHeader  = ui.oldHeader;
                currContent = currHeader.next('.ui-accordion-content');
            }
            // Since we've changed the default behavior, this detects the actual status
            var isPanelSelected = currHeader.attr('aria-selected') == 'true';

            // Toggle the panel's header
            currHeader.toggleClass('ui-corner-all',isPanelSelected).toggleClass('accordion-header-active ui-state-active ui-corner-top',!isPanelSelected).attr('aria-selected',((!isPanelSelected).toString()));

            // Toggle the panel's icon
            currHeader.children('.ui-icon').toggleClass('ui-icon-triangle-1-e',isPanelSelected).toggleClass('ui-icon-triangle-1-s',!isPanelSelected);

            // Toggle the panel's content
            currContent.toggleClass('accordion-content-active',!isPanelSelected)
            if (isPanelSelected) { currContent.slideUp(); }  else { currContent.slideDown(); }

            //return false; // Cancel the default action
        },
        create : function(){
            //jQuery keeps setting the tabindex to -1
            $('.gift-coupon-header, .coupon-code-header').removeAttr('aria-selected');
            $('.gift-coupon-header, .coupon-code-header').attr('tabindex', '0');
            $('.coupon-code-header').on('keypress', function(e){
                if (e.key == 'Enter') {
                    $(this).trigger('click');
                    $('.gift-coupon-header, .coupon-code-header').attr('tabindex', '0');
                }
            });
            $('.gift-coupon-header').on('keypress', function(e){
                if (e.key == 'Enter') {
                    $(this).trigger('click');
                    $('.gift-coupon-header, .coupon-code-header').attr('tabindex', '0');
                }
            });
        }
    });

    // default payment method to 'CREDIT_CARD'
    if ($('.gift-cert-used').length <= 0){
        updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
    }

    if ($('.select-address').length > 0) {
        phoneMask();
        $('.select-address select').on('change', function() {
            phoneMask();
        });
    }

    if ($('.billing-address-form .error').length > 0){
        $('.current-shipping').slideToggle('200');
        $('.billing-address-form').slideToggle('250').removeClass('hidden');
        $('.billing-address-form .error').first().focus();
    }

    $('.coupon-code-header').each(function () {
        var elem = $(this);
        if (elem.data('hasitems')){
            elem.trigger('click');
        }
    });

    if ($('.success.giftcert-pi').length > 0){
        $('.billing-accordion').accordion('option', {active: 1});
        $('html, body').animate({scrollTop: $('.gift-card').offset().top - 80},800);
    }

    if (addedCards.find('.success').length === 0) {
        addedCards.hide();
    } else {
        $('.giftcard-limit').show();
    }

    if ($('.billing-saved-card').length > 0) {
        $('#creditCardList').find('input[checked]').trigger('change');
    }

    // In instance where there are no available payment methods and you are an oobo user.
    if ($('#is-SECURE_PAYMENT').length && selectedPaymentMethod == 'CREDIT_CARD' && $('#creditCardList').find('input[checked]').length === 0) {
        formPrepare.setCustomValidationEvent(SECUREPAYMENT_AVAILABLE_CC_OPTION);
    }

    if ($('.js-edit-pickup-info-mode').length > 0) {
        $('.js-edit-pickup-info-mode').focus();
        var scrollIntoViewPosition = $('.js-edit-pickup-info-mode').offset().top - 20;
        window.scrollTo(0, scrollIntoViewPosition);
    }

    billingAddress.restore();
    validator.init();
    formPrepare.validateForm();
    util.maskPhoneInit();
    util.maskZipCodeInit();

    initBopisSMS();
    securePayment.init();
    minicart.checkForARProducts();
};
