'use strict';

module.exports = {
    handleFormSubmission: function (e, successMessageFunction, form) {
        var userInfoForm = form;
        e.preventDefault(); // avoid to execute the actual submit of the form.
        var regexp = new RegExp(Resources.EmailRegex);
        var emailInput = userInfoForm.querySelector('[data-inputtype="email"]');
        if (emailInput && regexp.test(emailInput.value)) {
            $.post(Urls.footerSubscriptionUrl,
                {
                    email: emailInput.value,
                    location: userInfoForm.dataset.location != 'null' ? userInfoForm.dataset.location : 'content-slot'
                },
                function (data) {
                    if (data.success) {
                        userInfoForm.reset();
                        successMessageFunction(form);
                    }

                }, 'json');
        }
        return false;
    }
};
