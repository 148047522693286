'use strict';

var kiboClient = require('./kibo-client');

var userIDCookie = kiboClient.getCookie('mt.v');
var smoothScrollCarousel = $('.ds-smooth-scroll.kibo-recommendations');
var recommendationAmount = 8;
var trackedImpressions = [];

var kiboRecommendations = {
    addRecommendations: function(customStrategy, callback){
        var userID = userIDCookie ? '&userID=' + userIDCookie : '';
        var pageType = window.digitalData && window.digitalData.pageInstanceID ? window.digitalData.pageInstanceID : 'null'
        var strategyType = pageType;
        var searchSuccessful = window.digitalData.page.pageInfo.pageID=='SEARCH SUCCESSFUL';
        if (pageType === 'search') {
            strategyType = searchSuccessful ? 'category' : 'nosearch';
        }
        var productID = '';
        if (strategyType === 'product') {
            productID = window.digitalData && window.digitalData.product && window.digitalData.product[0] && window.digitalData.product[0].productInfo && window.digitalData.product[0].productInfo.productID ? '&productID=' + window.digitalData.product[0].productInfo.productID : '';
        }
        if (customStrategy) {
            strategyType = customStrategy
        }
        var requestUrl = '&requestUrl=' + window.location.href;
        var recommendationCount = '&recommendationCount=' + recommendationAmount;
        var getReqURL = window.Urls.kiboRecommendations + '?strategyType=' + strategyType + productID + userID + requestUrl + recommendationCount;

        $.ajax({
            url: getReqURL,
            method: 'GET',
            success: function (data) {
                var returnObj = JSON.parse(data.split('\x3C!--')[0]);

                if (customStrategy) {
                    var customRecommendations = strategyType;
                    kiboRecommendations.buildRecommendationContainer(returnObj[customRecommendations], 'sess_ai_rec', function() {
                        var spinner = document.querySelectorAll('.recommendations-modal-overlay .modal-loading-section')[0];
                        if (spinner)  {
                            spinner.remove();
                        }
                    });
                } else {
                    var recommendationsStrategy_1 = strategyType + '1_rr';
                    kiboRecommendations.buildRecommendationContainer(returnObj[recommendationsStrategy_1], recommendationsStrategy_1);

                    var recommendationsStrategy_2 = strategyType + '2_rr';
                    if (returnObj[recommendationsStrategy_2]){
                        kiboRecommendations.buildRecommendationContainer(returnObj[recommendationsStrategy_2], recommendationsStrategy_2);
                    }
                }

                kiboRecommendations.setCarousel();

                if (typeof callback === 'function') {
                    callback();
                }

            },
            error: function() {
                $('.kibo-recommendations-container').hide();
            }
        });
    },
    buildRecommendationContainer: function(recommendations, recommendationContainer, callback){
        var recommendationsStr = '';
        var i = 0;

        if (recommendations && recommendations.length) {
            recommendations.forEach(function(recommendation) {
                var title = recommendation.fragranceName || recommendation.descriptiveName || recommendation.title;

                recommendationsStr += kiboRecommendations.returnRecommendationLi(
                    i,
                    recommendation.id,
                    Number(recommendation.tealiumData.original_price),
                    Number(recommendation.salePrice),
                    recommendation.promotionCalloutMessage,
                    title,
                    recommendation.form,
                    recommendation.imageLink,
                    recommendation.tealiumData.url,
                    JSON.stringify(recommendation.tealiumData),
                    recommendation.recToken,
                    recommendation.productBadge,
                    recommendation.purchaseLimit,
                    recommendation.maxQtyAvailable,
                    recommendation.totalQty);

                i++;
            });

            $('#' + recommendationContainer + '.recommendations .kibo-recommendations').append(recommendationsStr);

            if (typeof callback === 'function') {
                callback();
            }
        } else {
            $('#' + recommendationContainer + '.recommendations').hide();
        }
    },
    returnRecommendationLi: function (
        i,
        itemID,
        price,
        salePrice,
        promotinCallout,
        title,
        description,
        imageLink,
        link,
        tealium,
        recToken,
        productBadge,
        purchaseLimit,
        maxQtyAvailable,
        totalQty) {
        var displayPrice = salePrice !== price ? '<span class="product-standard-price" aria-label="Standard Price" tabindex="0">$' + price.toFixed(2) + '</span>' +
        '<span class="product-sales-price" aria-label=“Sale Price" tabindex="0" content="' + salePrice.toFixed(2) + '">' + '$' + salePrice.toFixed(2) + '</span>' : '<span class="product-sales-price" itemprop="price" content="' + price.toFixed(2) + '">$' + price.toFixed(2) + '</span>';
        var promo = promotinCallout ? '<div class="promotion"><div class="promotion-callout"><div class="callout-message clearfix">' + promotinCallout + '</div></div></div>' : '';

        return '<div class="grid-tile recommendation-item" role="listitem" data-pid="' + itemID + '" data-productname="' + title + '" data-index="' + i + '" data-recToken="' + recToken + '">' +
            '<div class="ds-recommendations-product-tile">' +
                '<div class="product-tile" data-itemid="' + itemID + '">' +
                    '<div class="analytics capture-product-id" aria-hidden="true">' + itemID + '</div>' +
                    '<div class="product-outline-block">' +
                        '<div class="product-image">' +
                            '<div class="thumb-link  ">' +
                                '<div class="card">' +
                                    '<div class="zone_product-image" id="' + itemID + '_image-display" role="region">' +
                                        '<a data-productimage="' + link + '" href="' + link + '" class="product-tile-link suggestion-product-image" tabindex="0" title="Go to Product:' + title +'" data-name=" " data-pid="' + itemID + '" data-trackingid="">' +
                                            '<div class="zone_product-image_main">' +
                                                '<div class="front face" role="presentation">' +
                                                    '<div class="tile-image-wrapper main-tile-image-wrapper">' +
                                                        '<img src="' + imageLink + '" alt="' + title + '">' +
                                                    '</div>' +
                                                '</div>' +
                                            '</div>' +
                                        '</a>' +
                                    '</div>' +
                                '</div>' +
                            '</div>' +
                        '</div>' +
                        '<div class="product-badge">' + productBadge + '</div>' +
                        '<div class="product-cont">' +
                            '<a data-producttitle="' + link + '" href="' + link + '" class="product-tile-link suggestion-product-title" tabindex="0" title="Go to Product: " data-name="" data-pid="026397339" data-trackingid="">' +
                                '<h3 class="product-name" itemprop="name">' + title + '</h3>' +
                                '<p class="product-type" role="none">' + description + '</p>' +
                            '</a>' +
                        '</div>' +
                        '<div class="product-pricing">' +
                            displayPrice +
                        '</div>' +
                    '</div>' +
                    '<div class="product-promo">' + promo + '</div>' +

                    '<form method="post" action="' + window.location.origin + '/on/demandware.store/Sites-BathAndBodyWorks-Site/en_US/Cart-AddProduct" novalidate="novalidate">' +
                        '<fieldset>' +
                            '<input type="hidden" name="pid" value="' + itemID + '">' +
                            '<input type="hidden" name="maxQtyAvailable" value="' + maxQtyAvailable + '">' +
                            '<input type="hidden" name="purchaseLimit" value="' + purchaseLimit + '">' +
                            '<input type="hidden" id="totalQty" name="totalQty" value="' + totalQty + '">' +
                            '<input type="hidden" name="Quantity" value="1">' +
                            '<input type="hidden" name="forstorepickup" value="false">' +
                            '<input type="hidden" name="storename" value="">' +
                            '<div class="clearfix centered">' +
                                '<button type="submit" title="Add to Bag" value="Add to Bag" data-ibm="" data-tealium="' + tealium.replaceAll('"','&quot;') + '" class="add-to-cart" aria-label="">Add to Bag'  +
                                '</button>' +
                            '</div>' +
                            '<a class="view-product product-tile-link" href="' + link + '">View Product</a>' +
                        '</fieldset>' +
                    '</form>'  +
            '</div>' +
        '</div>' +
    '</div>';
    },
    setCarousel: function () {
        kiboRecommendations.syncTileHeights();
        kiboRecommendations.addToBagClick();
        kiboRecommendations.addActiveClassCarousel();
    },
    addActiveClassCarousel: function () {
        $(window).on('load', function() {
            kiboRecommendations.updateVisibleItems();

            smoothScrollCarousel.on('scroll', function() {
                kiboRecommendations.updateVisibleItems();
            });
        });
    },
    updateVisibleItems: function () {
        var container = $('kibo-recommendations.ds-smooth-scroll');
        var itemWidth = smoothScrollCarousel.find('.grid-tile').outerWidth() + 16;
        var containerLeft = smoothScrollCarousel.position().left;
        var containerRight = containerLeft + smoothScrollCarousel.width();
        var products = $('.grid-tile.recommendation-item');
        var visibleProducts = Math.floor(container.width()/itemWidth);

        products.each(function(index) {
            var item = $(this);
            var itemLeft = item.position().left;
            var itemRight = itemLeft + item.outerWidth();

            if (itemLeft >= containerLeft && itemRight <= containerRight){
                if (index >= visibleProducts && index < products.length - visibleProducts) {
                    item.addClass('active');
                    kiboRecommendations.recordRecImpressions();
                } else {
                    item.removeClass('active');
                }
            } else {
                item.removeClass('active');
            }
        });
    },
    addToBagClick: function(){
        $('.kibo-recommendations-container .add-to-cart').off('click').on('click', function () {
            var $parent = $(this).closest('.recommendation-item'),
                pid = $parent.data('pid'),
                productName = $parent.data('productname'),
                index = $parent.data('index'),
                recToken = $parent.data('rectoken'),
                containerID = $parent.closest('.recommendations').attr('id');

            kiboClient.recordRecClicks(recToken);
            /* eslint-disable */
            if ('cmCreateElementTag' in window) {
                cmCreateElementTag('Homepage ATB Click', containerID, pid + '-_-' + productName + '-_--_--_-' + index + '-_-' + window.digitalData.page.pageInfo.pageID);
            }
            /* eslint-enable */
        });
    },
    recordRecImpressions: function(){
        var recImpressions = [];
        var prevImpressions = [];
        smoothScrollCarousel.on('scroll', function(event){
            var target = $(event.target);
            var activeElements = target.find('.active.grid-tile');
            prevImpressions = recImpressions.slice();
            activeElements.each(function(index, item){
                var recToken = item.dataset.rectoken;
                if (trackedImpressions.indexOf(recToken) === -1){
                    recImpressions.push(recToken);
                    trackedImpressions.push(recToken);
                }
            });
            if (recImpressions.length && !kiboRecommendations.arraysAreEqual(recImpressions, prevImpressions)) {
                var events = [{
                    'eventType': 'monetate:record:RecImpressions',
                    'recImpressions': recImpressions
                }];
                kiboClient.postToKibo(events);
            }
        });
    },
    arraysAreEqual: function(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (var i = 0; i < arr1.length; i++) {
            if (arr1[i] === arr2[i]) {
                return true;
            } else {
                return false;
            }
        }
    },
    getTallestEl: function($selectEls) {
        var tallestElHeight = 0;
        $selectEls.each(function(i, el) {
            if (el.clientHeight > tallestElHeight) {
                tallestElHeight = el.clientHeight;
            }
        });
        return tallestElHeight;
    },
    syncTileHeights: function() {
        var elements = ['.product-badge', '.product-price', '.product-promo'];
        for (var i = 0; i < elements.length; i++){
            var $elements = smoothScrollCarousel.find(elements[i]);
            var maxHeight = kiboRecommendations.getTallestEl($elements);
            $elements.css('height', maxHeight);
        }
    }
}

module.exports = kiboRecommendations;
