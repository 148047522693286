'use strict';

/**
 * Display the Scent-Scription offer
 * @description Displays the Scent-Scription offer UI within the target product container. Also hides any non-AR offer UI.
 * @param {jQuery Object} $productContentUI - The #product-content element for the targeted product
 */
var displayAROffer = function ($productContentUI) {
    var $arPromotion = $productContentUI.find('.promotion .ar-promotion');
    var $standardPromotion = $productContentUI.find('.promotion .promotion-callout:not(.ar-promotion)');

    $arPromotion.parent().removeClass('display-none');
    $standardPromotion.parent().addClass('display-none');
};

/**
 * Hide the Scent-Scription offer
 * @description Hides the Scent-Scription offer UI within the target product container. Also displays any non-AR offer UI.
 * @param {jQuery Object} $productContentUI - The #product-content element for the targeted product
 */
var hideAROffer = function ($productContentUI) {
    var $arPromotion = $productContentUI.find('.promotion .ar-promotion');
    var $standardPromotion = $productContentUI.find('.promotion .promotion-callout:not(.ar-promotion)');

    $arPromotion.parent().addClass('display-none');
    $standardPromotion.parent().removeClass('display-none');
}

/**
 * Check for existing optins
 * @description Get the existing opted in products from OG and check to see if any of those products exist on the page, then set the promo messages
 */
var checkForExistingOptins = function () {
    try {
        var optins = window.OG.getOptins();
        for (var i in optins) {
            var pid = optins[i].product,
                $productContentUI = $('og-offer[product='+pid+']').closest('#product-content');
            if ($productContentUI.length) {
                displayAROffer($productContentUI);
            }
        }
    } catch (err) {
        console.log(err); // eslist-disable-line
    }
}

/**
 * Add a callback for the OG status change
 * @description Add a new callback function that will check for the optin status change and set the related promo message display
 */
var addOptinChangedCallback = function () {
    try {
        window.OG.addOptinChangedCallback(function (e) {
            var $productContentUI = $('og-offer[product='+e.productId+']').closest('#product-content');
            if (e.optedIn) {
                displayAROffer($productContentUI);
            } else {
                hideAROffer($productContentUI);
            }
        });
    } catch (err) {
        console.log(err); // eslist-disable-line
    }
}

/**
 * Init function
 * @description Initializes the UI events for the Scent-Scription promo message display/hide functionality
 */
var init = function () {
    if ($('#product-content .promotion .ar-promotion').length && window.OG) { // .ar-promotion is added to /product/components/promotions.isml if OrderGroove is enabled
        checkForExistingOptins();
        addOptinChangedCallback();
    }
}

module.exports = function () {
    init();
};
