'use strict';
// imports
var modalHelper = require('./helper');
var passHideShow = require('../../pass-hide-show');
var authModal = require('./authModal');

// const elements
var allModals = Array.from(document.querySelectorAll('.js-modal'));

// variable elements
var activeModal;
var previousActiveElement;
var secondaryModal = [{'modal': null, 'overlay': null}];

var handleFocus = function () {
    previousActiveElement = document.activeElement || document.body;
    var modalContent = activeModal.querySelector('.ds-modal-content');
    var focusableEls = modalContent.querySelectorAll('a, select, button, input');
    var firstFocusableEl = focusableEls[0];

    if (focusableEls.length) {
        if (document.documentElement.matches('.iphone.safari')) {
            // fix soft keyboard shift on mobile safari
            firstFocusableEl.scrollIntoView({behavior: 'smooth', block: 'center'});
            firstFocusableEl.focus({preventScroll: true});
        } else {
            firstFocusableEl.focus()
        }

    }
};

var openModal = function () {
    modalHelper.openModal(activeModal);
    modalHelper.setFocusTrap(activeModal);
    document.addEventListener('keydown', escapeCloseModal);
    handleFocus();
};

var closeModal = function (selectedModal) {
    var modalToClose = selectedModal ? selectedModal : activeModal;
    modalHelper.closeModal(modalToClose, previousActiveElement);

    if (secondaryModal.modal) secondaryModal.modal.style.display = 'block';
    if (secondaryModal.overlay) secondaryModal.overlay.style.display = 'block';
};

var escapeCloseModal = function (e) {
    if (e.key == 'Escape') {
        var openModals = Array.from(document.querySelectorAll('.ds-modal.open'));
        openModals.forEach(function (modal) {
            closeModal(modal);
        });
        document.removeEventListener('keydown', escapeCloseModal);
    }
};

var hideVisibleModals = function (triggerButton) {
    if (triggerButton && triggerButton.classList.contains('qv')) {
        secondaryModal.modal = document.querySelector('.ui-dialog.quickview');
        secondaryModal.overlay = document.querySelector('.ui-widget-overlay.ui-front');

        secondaryModal.modal.style.display = 'none';
        secondaryModal.overlay.style.display = 'none';
    } else {
        allModals.forEach(function (modal) {
            modalHelper.closeModal(modal, previousActiveElement);
        });
    }
}

// handle modal triggers from click events
var handleModalTrigger = function (e) {
    e.preventDefault();

    // suppress auth modal when in app
    var eventId = e.target.dataset.target;
    if (SitePreferences.APP_SUPPRESS_SIGN_IN_MODAL && eventId === 'auth-modal' && window.User.isShopInApp) {
        var event = new Event('dispatch-app-sign-in');
        window.dispatchEvent(event);
        return
    }

    var modalTrigger = e.target;
    previousActiveElement = modalTrigger;
    activeModal = document.getElementById(modalTrigger.dataset.target);

    if (activeModal.id === 'auth-modal') {
        handleAuthModal(modalTrigger.dataset.redirectLocation);
    }
    openModal();
};

// handle modal triggers from custom events
// this can be done with this custom event anywhere in the app
// var triggerModalEvent = new CustomEvent('triggerModal', {
//     detail: {
//         elem: lastFocusedElement or element you want focus to return to on close
//         modalName: modalId,
//         options: {
//             redirectLocation: '#' or any url
//         }
//     }
// });
// window.dispatchEvent(triggerModalEvent);
var triggerModal = function (modalTrigger, modalId, options) {
    // suppress auth modal when in app
    if (SitePreferences.APP_SUPPRESS_SIGN_IN_MODAL && modalId === 'auth-modal' && window.User.isShopInApp) {
        var event = new Event('dispatch-app-sign-in');
        window.dispatchEvent(event);
        return
    }

    options = options || {};
    previousActiveElement = modalTrigger;
    activeModal = document.getElementById(modalId);

    // adding options default for redirect
    var redirectLocation = options.redirectLocation || '#';

    if (modalId === 'auth-modal') {
        handleAuthModal(redirectLocation);
    }
    openModal();
};

var handleAutoModalTrigger = function (trigger) {
    previousActiveElement = trigger;
    activeModal = document.getElementById(trigger.dataset.target);
    openModal();
};

var handleModalClose = function (e) {
    e.preventDefault();
    closeModal(activeModal);
};

var handleAuthModal = function (redirectTarget) {
    passHideShow();
    authModal.modalSignIn(activeModal, redirectTarget);
};

var setupHandlers = function (setupScope) {
    var allModalTriggersString = '.js-modal-trigger';
    var scope = setupScope ? setupScope : document;

    // Includes class selector on grid if the loyalty button feature is enabled and is not QuickView.
    if (SitePreferences.SIGN_IN_MODAL_ENABLED_LOYALTY_EXCLUSIVE_BUTTON && (!$('#QuickViewDialog').is(':visible'))) {
        allModalTriggersString += ',.loyalty-exclusive-button';
    }

    var allModalTriggers = scope.querySelectorAll(allModalTriggersString);
    var allModalClose = scope.querySelectorAll('.js-modal-close');

    allModalTriggers.forEach(function (triggerButton) {
        var isSignUpPage = window.location.search.includes('dwcont') && window.location.href.includes('my-account');
        if (isSignUpPage && triggerButton.dataset.target === 'auth-modal') {
            triggerButton.classList.remove('js-modal-trigger');
            triggerButton.addEventListener('click', function (e) {
                e.preventDefault();
                var url = new URL(window.location.href);
                url.search = ''
                var redirectTo = url.toString();

                window.location.href = redirectTo;
            });
        } else if (triggerButton.dataset.target === 'international-redirect-modal') {
            var irmModal = document.getElementById(triggerButton.dataset.target);
            irmModal.addEventListener('open-modal', function () {
                triggerButton.classList.remove('js-modal-trigger');
                hideVisibleModals(triggerButton);
                handleAutoModalTrigger(triggerButton);
            });
            var event = new Event('request-open-modal');
            irmModal.dispatchEvent(event);
        } else {
            triggerButton.addEventListener('click', function (e) {
                hideVisibleModals(e.target);
                handleModalTrigger(e);
            });
        }
    });

    // get modal element, listen for an open modal event, call open modal, listen for openmodal event

    allModalClose.forEach(function (closeButton) {
        closeButton.addEventListener('click', function (e) {
            handleModalClose(e);
        });
    });
}

var init = function () {
    // add event handlers
    window.addEventListener('load', function () {
        setupHandlers(document);
    });

    window.addEventListener('triggerModal', function (e) {
        hideVisibleModals();
        var details = e.detail;
        triggerModal(details.target, details.modalName, details.options);
    });

    // hide all modals on load
    hideVisibleModals();
};

module.exports = {
    init: init,
    setupHandlers: setupHandlers,
    hideVisibleModals: hideVisibleModals,
    handleModalTrigger: handleModalTrigger
};
