'use strict';

var tealium = {
    eventCount: 0,
    triggerEvent: function (arr) {
        var utag = window.utag;
        window.tmpArr = arr;
        if (typeof (utag) !== 'undefined') {
            utag.link(arr);
            window.console.log('Tealium event triggered:', arr.event_name);
        }
    }
};

module.exports = tealium;
