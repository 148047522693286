'use strict';

var kiboRecommendations = require('./kiboRecommendations');
var addToCart = require('./pages/product/addToCart');

function listenForFifthClickEvent() {
    /*
        document.dispatchEvent(new
        CustomEvent('fifthClick'))
        in console to fire
    */
    var enableFifthClickEventTest = SitePreferences.ENABLE_FIFTH_CLICK_RECOMMENDATIONS_TEST;
    var enableFifthClickEvent = SitePreferences.ENABLE_FIFTH_CLICK_RECOMMENDATIONS;
    var targetEnableFifthClickEvent = window.digitalData && window.digitalData.page && window.digitalData.page.attributes && window.digitalData.page.attributes.targetflags && window.digitalData.page.attributes.targetflags.enableRecommendationsModal ? window.digitalData.page.attributes.targetflags.enableRecommendationsModal : false;
    var fifthClickEnabled = enableFifthClickEventTest ? targetEnableFifthClickEvent : enableFifthClickEvent;
    if (fifthClickEnabled) {
        document.addEventListener('fifthClick', function() {
            var html = document.querySelector('html');
            if (html && !html.classList.contains('dialog-modal-opened')) {
                returnModalContent(function() {
                    addModalRecsAndSetupModalHandlers();
                });
            }
        });
    }
}

function returnModalContent(callback) {
    var title = SitePreferences.FIFTH_CLICK_MODAL_TITLE;
    var spinnerSource = Urls.pageLoadingIcon;
    var modalContent = '<div class="recommendations-modal-overlay ds-modal-overlay visible" tabindex="0">' +
                '<div class="ds-modal open" tabindex="0">' +
                    '<button type="button" class="ds-close-button" data-analytics-trigger="modal-close" title="close" aria-label="close" tabindex="0"></button>' +
                    '<div class="ds-modal-content" tabindex="0">' +
                        '<h2 class="ds-heading">' + title + '</h2>' +
                        '<div class="modal-loading-section">' +
                            '<img class="modal-spinner" src="' + spinnerSource + '" alt="loading">' +
                        '</div>' +
                        '<div id="sess_ai_rec" class="recommendations kibo-recommendations-container fifth-click-modal-recs">' +
                            '<div class="kibo-recommendations ds-smooth-slide tiles-container"></div>' +
                        '</div>' +
                    '</div>' +
                '<div>' +
            '</div>';

    document.querySelector('body').insertAdjacentHTML('beforeend', modalContent);

    // track adobe event
    /* eslint-disable */
    s.tl(this, 'o', 'recs_modal', {
        events: 'event165',
        linkTrackVars: 'events',
        linkTrackEvents: 'event165'
    });
    /* eslint-enable */

    if (typeof callback === 'function') {
        callback();
    }
}

function addModalRecsAndSetupModalHandlers() {
    var html = document.querySelector('html');
    html.classList.add('dialog-modal-opened');

    kiboRecommendations.addRecommendations('SessionAi', function() {
        var modalATCButtons = document.querySelectorAll('.recommendations-modal-overlay .add-to-cart');
        modalATCButtons.forEach(function(modalATCButton){
            modalATCButton.addEventListener('click', function(){
                closeModal();
            });
        });

        setupSlickSlide();
    });
    addToCart.initModalAddToCart();

    addCloseModalHandlers();

    var recommendationModalContent = document.querySelector('.recommendations-modal-overlay .ds-modal');
    if (recommendationModalContent) {
        recommendationModalContent.focus();
    }
}

function addCloseModalHandlers() {
    var body = document.querySelector('body');
    body.addEventListener('keydown', function(e) {
        if (e.keyCode == 27) {
            closeModal();
        }
    });

    var modalOverlay = document.querySelectorAll('.recommendations-modal-overlay.ds-modal-overlay')[0];
    if (modalOverlay) {
        modalOverlay.addEventListener('click', function(e) {
            if (e.target === e.currentTarget) {
                closeModal();
            }
        });
        modalOverlay.addEventListener('keydown', function(e) {
            if (e.keyCode == 27) {
                closeModal();
            }
        });
    }

    var closeButton = modalOverlay.querySelectorAll('.ds-close-button')[0];
    if (closeButton) {
        closeButton.addEventListener('click', function() {
            closeModal();
        });
        closeButton.addEventListener('keydown', function(e) {
            if (e.keyCode == 32 || e.keyCode == 13) {
                closeModal();
            }
        });
    }
}

function closeModal() {
    var modalOverlay = document.querySelectorAll('.recommendations-modal-overlay.ds-modal-overlay')[0];
    if (modalOverlay) {
        modalOverlay.remove();
    }

    var html = document.querySelector('html');
    if (html && html.classList.contains('dialog-modal-opened')) {
        html.classList.remove('dialog-modal-opened');
    }

    var body = document.querySelector('body');
    if (body) {
        body.focus();
    }
}

function setupSlickSlide() {
    var chevron = Urls.chevron;
    $('.ds-smooth-slide').slick({
        slidesToShow: 1,
        infinite: false,
        prevArrow: '<button class="slick-prev slick-button" type="button">'
                        + '<img class="slick-chevron previous" src="' + chevron + '" alt="next">'
                        + '<span class="slick-sr-only">Previous</span>'
                    + '</button>',
        nextArrow: '<button class="slick-next slick-button" type="button">'
                        + '<img class="slick-chevron next" src="' + chevron + '" alt="next">'
                        + '<span class="slick-sr-only">Next</span>'
                    + '</button>'
    });

    syncTileHeights();
}

function getTallestEl($selectEls) {
    var tallestElHeight = 0;
    $selectEls.each(function(i, el) {
        if (el.clientHeight > tallestElHeight) {
            tallestElHeight = el.clientHeight;
        }
    });
    return tallestElHeight;
}

function syncTileHeights() {
    var elements = ['.product-badge', '.product-price', '.product-promo', '.product-cont'];
    for (var i = 0; i < elements.length; i++){
        var smoothScrollCarousel = $('.ds-smooth-slide');
        var $elements = smoothScrollCarousel.find(elements[i]);
        var maxHeight = getTallestEl($elements);
        $elements.css('height', maxHeight);
    }
}

module.exports = {
    listenForFifthClickEvent: listenForFifthClickEvent
}
