'use strict';

var formPrepare = require('./formPrepare');

var initialized = false,
    $securePaymentUI = $('#securePaymentUI'),
    $launchButtons = $('.launch-secure-payment'),
    $relaunchButtons = $('.relaunch-secure-payment'),
    $form = $('#embedSecurePaymentForm'),
    $securePaymentInputs = $('.secure-payment-active-ui input'),
    $paymentMethodRadioButtons = $('input[name$="_selectedPaymentMethodID"]'),
    timeoutInterval,
    timeoutExpires;

// Event constants
var SECUREPAYMENT_STATE_CHANGED = 'securePaymentStateChanged',
    SECUREPAYMENT_PREINIT = 'preinit',
    SECUREPAYMENT_INIT = 'initializing',
    SECUREPAYMENT_INITIALIZED = 'initialized',
    SECUREPAYMENT_FAILED = 'failed',
    SECUREPAYMENT_COMPLETE = 'complete',
    SECUREPAYMENT_EXPIRED = 'expired',
    SECUREPAYMENT_EVENT_SUCCESS = 'pciComplete';

function reInitSecurePayment() {
    initialized = false;
    $securePaymentUI = $('#securePaymentUI');
    $launchButtons = $('.launch-secure-payment');
    $relaunchButtons = $('.relaunch-secure-payment');
    $form = $('#embedSecurePaymentForm');
    $securePaymentInputs = $('.secure-payment-active-ui input');
    $paymentMethodRadioButtons = $('input[name$="_selectedPaymentMethodID"]');

    initSecurePayment();
}

/**
 * @function initSecurePayment
 * @description Inintializes the secure payment option prior to it being selected
 */
function initSecurePayment() {
    $securePaymentUI.children().each(function() {
        var $this = $(this),
            activeState = $this.data('securepaymentStatus').split(' ');

        $(document).on(SECUREPAYMENT_STATE_CHANGED, function(event, state) { // eslint-disable-line no-unused-vars
            $this.toggleClass('active', activeState.includes(state));
        });
    });
    $paymentMethodRadioButtons.change(function() {
        $securePaymentInputs.toggleClass('required', (this.value == 'SECURE_PAYMENT'));
        if (this.value == 'SECURE_PAYMENT') {
            $(document).trigger(SECUREPAYMENT_STATE_CHANGED, SECUREPAYMENT_PREINIT);
            resetSecurePaymentForm();
            formPrepare.updateRequireInputs();
            formPrepare.validateForm();
            initSecurePaymentUI();
        }
    });
    if ($('input#is-SECURE_PAYMENT').prop('checked')) {
        $paymentMethodRadioButtons.change();
    }
}

/**
 * @function initSecurePaymentUI
 * @description enables interaction with secure payment service for enabled CSRs
 */
function initSecurePaymentUI() {
    disableContinueBtn();
    if (!initialized) {
        $launchButtons.on('click', function(e){
            e.preventDefault();
            var agentRole = $(this).data('agentrole');
            launchSecurePaymentSession(agentRole);
        });
        $relaunchButtons.on('click', function(e){
            e.preventDefault();
            $('#pcipal').remove();
            $('.secure-payment-active-ui').prepend($('<iframe name="pcipal" id="pcipal"></iframe>'));
            resetSecurePaymentForm();
            $(document).trigger(SECUREPAYMENT_STATE_CHANGED, SECUREPAYMENT_PREINIT);
        });
        // set events for name and cvv input fields
        $securePaymentUI.find('input[name$="_creditCard_owner"]').change(function() {
            $('[data-method="CREDIT_CARD"]').find('input[name$="_creditCard_owner"]').val($(this).val()).trigger('change');
        });
        $securePaymentUI.find('input[name$="_creditCard_cvn"]').change(function() {
            $('[data-method="CREDIT_CARD"]').find('input[name$="_creditCard_cvn"]').val($(this).val()).trigger('change');
        });
    }
    initialized = true;
}

/**
 * @function launchSecurePaymentSession
 * @description Calls the secure payment service for enabled CSRs
 * @param {string} agentRole
 */
function launchSecurePaymentSession(agentRole) {
    $(document).trigger(SECUREPAYMENT_STATE_CHANGED, SECUREPAYMENT_INIT);
    $.get(window.Urls.SecurePaymentInit+'?agentRole='+agentRole, function () {}).done(function (data) {
        data = JSON.parse(data);
        $(document).trigger(SECUREPAYMENT_STATE_CHANGED, SECUREPAYMENT_INITIALIZED);
        $form.attr('action', data.action_url);
        $('input[name="X-BEARER-TOKEN"]', $form).val(data.access_token);
        $('input[name="X-REFRESH-TOKEN"]', $form).val(data.refresh_token);
        timeoutExpires = data.expires;
        timeoutInterval = setInterval(handleTimeout, 1000);
        $form.submit();
        window.addEventListener('message', function(event) {
            if (event.origin !== SitePreferences.SECURE_PAYMENT_EVENT_ORIGIN)
                return;
            handlePostMessages(event.data);
        },false);
    }).fail(function () {
        $(document).trigger(SECUREPAYMENT_STATE_CHANGED, SECUREPAYMENT_FAILED);
    });
}

/**
 * @function handlePostMessages
 * @description Handles the secure payment vendor's post messages
 * @param {object} data Contents of the post message
 */
function handlePostMessages(data) {
    var attempts = data.PaymentDetails.AttemptCount,
        success = data.PaymentDetails.SuccessCount,
        failure = data.PaymentDetails.FailureCount;

    if (success) {
        $(document).trigger(SECUREPAYMENT_STATE_CHANGED, SECUREPAYMENT_COMPLETE);
        securePaymentSuccess(data.VariableData);
    } else if (attempts == 3 || failure) {
        $(document).trigger(SECUREPAYMENT_STATE_CHANGED, SECUREPAYMENT_FAILED);
    }
}

/**
 * @function securePaymentSuccess
 * @description Handle the details of successful transmision of secure payment
 * @param {object} data Secure payment data
 */
function securePaymentSuccess(data) {
    var type = data.TenderType.Value,
        last4 = data.PaymentAccountNumber.Last4,
        mask = new Array((parseInt(data.PaymentAccountNumber.DigitsCollected, 10) - last4.length) + 1).join('*'),
        maskednumber = mask + last4,
        token = data.Token.Value,
        month = data.ExpirationMonth.Value,
        year = data.ExpirationYear.Value,
        $creditCard = $('[data-method="CREDIT_CARD"]');

    $('#securePayment-type').val(type);
    $('#securePayment-token').val(token);
    $('#securePayment-month').val(month);
    $('#securePayment-year').val(year);

    $creditCard.find('input[name$="_creditCard_type"]').val(type).trigger('change');
    $creditCard.find('input[name$="_creditCard_number"]').val(maskednumber).trigger('change');
    $creditCard.find('input[name$="_creditCard_cctoken"]').val(token).trigger('change');
    $creditCard.find('[name$="_creditCard_expiration_month"]').val(month).trigger('change');
    $creditCard.find('[name$="_creditCard_expiration_year"]').val(year).trigger('change');

    $(document).trigger(SECUREPAYMENT_EVENT_SUCCESS);
    formPrepare.validateForm();
}

/**
 * @function handleTimeout
 * @description FE solution for secure payment session silently expiring
 */
function handleTimeout() {
    var date = new Date(Date.now());
    if (date.toISOString() >= timeoutExpires && typeof(timeoutInterval) !== 'undefined') {
        $(document).trigger(SECUREPAYMENT_STATE_CHANGED, SECUREPAYMENT_EXPIRED);
        clearInterval(timeoutInterval);
        timeoutInterval = undefined;
        resetSecurePaymentForm();
        $('#pcipal').remove();
    }
}

/**
 * @function disableContinueBtn
 * @description Disables the continue button when secure payment is not ready
 */
function disableContinueBtn() {
    if ($('input#is-SECURE_PAYMENT').prop('checked')) {
        $('.continue-row button').attr('disabled', 'disabled');
    }
}

/**
 * @function resetSecurePaymentForm
 * @description Clears form fields for secure payment
 */
function resetSecurePaymentForm() {
    var $creditCard = $('[data-method="CREDIT_CARD"]');

    $creditCard.find('input[name$="_creditCard_type"]').val('');
    $creditCard.find('input[name$="_creditCard_number"]').val('');
    $creditCard.find('input[name$="_creditCard_cctoken"]').val('');
    $creditCard.find('[name$="_creditCard_expiration_month"]').val('');
    $creditCard.find('[name$="_creditCard_expiration_year"]').val('');

    $('#securePayment-type').val('');
    $('#securePayment-token').val('');
    $('#securePayment-month').val('');
    $('#securePayment-year').val('');
    $securePaymentUI.find('input[name$="_creditCard_owner"]').val('').trigger('change');
    $securePaymentUI.find('input[name$="_creditCard_cvn"]').val('').trigger('change');

    formPrepare.resetCustomValidationSuccess();
}

exports.init = initSecurePayment;
exports.reInit = reInitSecurePayment;
