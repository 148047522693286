'use strict';

var compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    ingredients = require('../ingredients'),
    progress = require('../progress'),
    util = require('../util'),
    certona = require('../certona'),
    tealiumSearch = require('../tealium/pages/search'),
    kibo = require('../kibo'),
    loyaltyExclusive = require('../loyalty-exclusive'),
    productAvailability = require('./product/productAvailability'),
    modal = require('../designSystem/modal/modal'),
    quantitySelector = require('../quantity-selector'),
    addToCart = require('./product/addToCart');

var isStoreLocatorOpenThroughToggle = false;
var storeUpdated = false;
var eventSource;

// Check to see if the bopis toggle is on and that bopis order limits data is available
function isBopisStoreAvailable(){
    return (document.getElementById('bopis_toggle') != null && document.getElementById('bopis_toggle').checked == true && 'isBopisStoreAvailable' in window) ? window.isBopisStoreAvailable : true;
}

// Hide the grid when bopis toggle is turned on and limit has been reached
function hideGridForBopisOrderLimits(){
    if (!isBopisStoreAvailable()){
        var el = document.querySelector('#search-result-items');
        el.className = 'hide';
    }
}

function initSignInModalsForLoyaltyExclusive() {
    if (SitePreferences.SIGN_IN_MODAL_ENABLED_LOYALTY_EXCLUSIVE_BUTTON) {
        var scope = document.querySelector('.search-result-items')
        modal.setupHandlers(scope);
    }
}

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');


        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            /* eslint-disable */
            var deferred = $.Deferred();

            var isLastGridItemOdd = $('.search-result-items li').last().hasClass('odd');

            var $markup = $('<div>' + html + '</div>');
            var loyaltyExclusiveItems = $markup[0].querySelectorAll('.loyalty-exclusive');
            var productidsArr = getProductIds($markup);
            $('.search-result-items').append($markup.find('li'));

            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');

            var infinteScrollPlaceHolder = $markup.find('.infinite-scroll-placeholder');
            if (infinteScrollPlaceHolder) {
                $('.search-result-content').append(infinteScrollPlaceHolder);
            }

            deferred.resolve(productidsArr, loyaltyExclusiveItems);

            return deferred.promise().done(function (productidsArr, loyaltyExclusiveItems) {
                productTile.init();
                loyaltyExclusive.updateLoyaltyExclusiveButtons(loyaltyExclusiveItems);
                var customerStatus = window && window.digitalData && window.digitalData.user && window.digitalData.user.attributes && window.digitalData.user.attributes.customer_status ? window.digitalData.user.attributes.customer_status : '';
                if (customerStatus === 'anonymous') {
                    loyaltyExclusiveItems.forEach(function(loyaltyExclusiveItem) {
                        loyaltyExclusiveItem.addEventListener('click', function (e) {
                            modal.hideVisibleModals(e.target);
                            modal.handleModalTrigger(e);
                        });
                    });
                }

                if (window.location.href.indexOf('availableForInStorePickup') > -1) {
                    getAvailableForStoreItems(productidsArr, isLastGridItemOdd);
                } else {
                    productAvailability.updateStoreAvailability(productidsArr, isLastGridItemOdd);
                }
                quantitySelector.checkIfItemInCart();
            });
            /* eslint-enable */
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);
            }
        });
    }
}

function resetInfiniteScroll($markup) {
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        if (SitePreferences.ENABLE_INFINITE_SCROLL_TEST && window.enableInfiniteScroll === 'false') {
            return;
        }

        var $currentInfiniteScrollPlaceHolder = $('.infinite-scroll-placeholder');
        if ($currentInfiniteScrollPlaceHolder.length) {
            $currentInfiniteScrollPlaceHolder.remove();
        }

        var $newInfinteScrollPlaceHolder = $markup.find('.infinite-scroll-placeholder');
        if ($newInfinteScrollPlaceHolder.length) {
            $('.search-result-content').append($newInfinteScrollPlaceHolder);
        }
    }
}

function getAvailableForStoreItems(productidsArr, isLastGridItemOdd) {
    var bopisStoreFilterUrl = window.Urls.showBopisProduct;
    var lineItemNumber = isLastGridItemOdd ? 2 : 1;

    $.get(util.appendParamsToUrl(bopisStoreFilterUrl, {'format': 'ajax', 'ajaxmeta': 'true','productidsArr': productidsArr}), function (data) {
        var productids = data;

        for (var key in productids) {
            if (productids.hasOwnProperty(key)) {
                var $productLi = $('.search-result-items').find('[data-itemid=' + key + ']').parent();
                var val = productids[key];
                var $inStoreAvailability = $productLi.find('.product-in-store-availability');

                if (val == false) {
                    $productLi.remove();
                    if (SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD) {
                        $inStoreAvailability.html(productAvailability.inStoreAvailabilityMarkup(false, key));
                    }
                } else {
                    $productLi.removeClass('even odd').addClass(lineItemNumber % 2 ? 'odd' : 'even').addClass('availinstore');
                    lineItemNumber++;
                    if (SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD) {
                        $inStoreAvailability.html(productAvailability.inStoreAvailabilityMarkup(true, key));
                    }
                }
            }
        }

        $('.tiles-container').each(function () {
            productTile.syncRowItemHeights($(this).find('.grid-tile'));
        });

    });
}

function getProductIds($markup) {
    var productidsArr = [];
    $markup.find('.product-tile').each(function () {
        var productid = $(this).data('itemid');
        productidsArr.push(productid);
    });

    return productidsArr;
}

function updateProductListingMarkup($markup, url, rawData, triggerType) {
    var ingredientsDiv = $('.ingredients-search-results');
    if (ingredientsDiv.length > 0) {
        ingredientsDiv.replaceWith($markup.find('.ingredients-search-results'));
    } else {
        if (typeof eventSource !== 'undefined' && eventSource.hasClass('load-more-btn')) {
            // Load More is on,so append to the page
            $markup.find('#search-result-items li:first-child').addClass('new-start');
            $('#search-result-items').append($markup.find('#search-result-items li.grid-tile'));
            $('#search-result-items .new-start .product-tile-link').first().focus();
            var urlObject = new URL(url);
            urlObject.searchParams.set('start' , '0');
            urlObject.searchParams.set('sz', $('#search-result-items .grid-tile:not(.grid-slot)').length);
            url = urlObject;
        } else {
            // Load More is off, so replace the grid
            $('#search-result-items').replaceWith($markup.find('#search-result-items'));
            // Hide bopis order limits unavailable messaging
            hideGridForBopisOrderLimits();
            if (isBopisStoreAvailable()){
                $('.bopis-orderlimits-unavailable').addClass('hide');
            }

        }
    }

    //execute js code from ajax response to fit Coremetrics cmFilterChanged event
    $(rawData).find('script').each(function () {
        try {
            eval(this.innerHTML);
        } catch (e) {
            // no op
        }
    });
    if (ingredientsDiv.length > 0) {
        ingredients.init();
    } else {
        compareWidget.init();
        productTile.init();
    }
    if (triggerType !== 'backHistory') {
        history.pushState('history', '', url);
    }

    updateSortOptionsURLParams();
    updateBopisToggleURLParams();
    updateFiltersURLParams();
    updateDFHURLParams();

    //update exploreAttributes in datalayer
    if ($markup.find('#filtered-exploreAttributes').length) {
        window.digitalData.page.attributes.exploreAttributes = $markup.find('#filtered-exploreAttributes').data('value');
    }
}
/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing($markup, url, rawData, triggerType) {
    if (!url || url === window.location.href && triggerType !== 'backHistory') {
        return;
    }
    updateProductListingMarkup($markup, url, rawData, triggerType)
}

function updateRefinements($markup) {
    var filterElemets = $('.filters .refinement');
    filterElemets.each(function () {
        var dataID = $(this).data('id');
        if (dataID == $(this).find('.ref-block').data('id')) {
            // Making sure that a filter refinement update should be made.
            if ($markup.find('.filters .refinement.' + dataID + ' .ref-block').length > 0){
                $(this).find('.ref-block').replaceWith($markup.find('.filters .refinement.' + dataID + ' .ref-block'));
                $('.refinement.' + dataID).removeClass('nonactive');
            } else {
                $('.refinement.' + dataID).addClass('nonactive');
            }

            $('.ref-toggle.expanded').siblings('.ref-block').css('display', 'block');
            if ($('.ref-block.filter .grouping').length == 0) {
                groupingByLetter();
            }

            //hide refs with only one item for filter
            var countItems = $('.refinement.' + dataID + ' li').length;
            var countSelectedItems = $('.refinement.' + dataID + ' li.selected').length;
            // Hide filter when there are less than two refinements and zero refinements selected
            if (countItems < 2 && countSelectedItems == 0) {
                $('.refinement.' + dataID).addClass('nonactive');
            }

            if ($(this).find('.selected-refinement').length == 0) {
                $('<span class="selected-refinement"></span>').insertAfter($(this).find('.ref-toggle'));
            }

            $(this).find('.selected-refinement').replaceWith($markup.find('.filters .refinement.' + dataID + ' .selected-refinement'));

            if ($('.filters').find('.selected').length < 1) {
                $('.filters').find('.clear-refinement').hide();
            }
            var description = $markup.filter('meta[name=description]').attr('content');
            var title = $markup.filter('title').text();
            $('head').find('meta[name=description]').attr('content', description);
            $('head').find('title').text(title);
        }
    });
}
function updateBopisRefinements($markup) {
    $('.boipsrefinement').replaceWith($markup.find('.boipsrefinement'));
    $('.no-bopis-item-container').replaceWith($markup.find('.no-bopis-item-container'));
    if ($('.no-bopis-item-container').is(':visible')) {
        $('.filter-show').hide();
        $('.filter-overflow').hide();
        $('.grid-sort-menu').hide();
        $('.results-hits').removeClass('hide-count');
    } else {
        $('.filter-show').css('display', '');
        $('.filter-overflow').css('display', '');
        $('.grid-sort-menu').css('display', '');
    }
}

function updateMarkupForStoreAvailability($markup, url, data) {
    var productidsArr = [];
    var productidsStr = '';
    productAvailability.updateStoreName();
    $markup.find('.product-tile').each(function () {
        var productid = $(this).data('itemid');
        productidsArr.push(productid);

        if (productidsStr == '') {
            productidsStr = productid;
        } else {
            productidsStr = productidsStr + ',' + productid
        }
    });

    var storeName = $('.bopis-store-name');
    var isStoreSet = false;
    if (storeName.length > 0) {
        isStoreSet = document.getElementById('bopis_toggle').checked == true;
    }

    window.PRODUCTS_ELIGIBLE_FOR_STORE_PICKUP = productidsStr;

    if (productidsArr.length == 0) {
        updateProductListing($markup, url, data);
        $('html, body').animate({
            scrollTop: $('body').offset().top
        }, 800);
        storeUpdated = false;
        progress.hide();
        $('.filter-show').hide();
        $('.filter-overflow').hide();
        $('.grid-sort-menu').hide();
        $('.results-hits').removeClass('hide-count');
        if ($('.shop-the-look').length) {
            $('#primary').find('.appendtext').remove();
        }
    } else {
        $(data).find('script').each(function () {
            try {
                eval(this.innerHTML);
            } catch (e) {
                // no op
            }
        });
        var bopisStoreFilterUrl = window.Urls.showBopisProduct;
        $.get(util.appendParamsToUrl(bopisStoreFilterUrl, {'format': 'ajax', 'ajaxmeta': 'true','productidsArr':productidsArr}), function (data) {
            var productids = data;
            var notAvailablInStoreCount = 0;
            var lineItemNumber = 1;

            if ($('.shop-the-look').length) {
                $markup = $('#primary');
            }
            for (var key in productids) {
                if (productids.hasOwnProperty(key)) {
                    var productLi = $markup.find('[data-itemid=' + key + ']').parent();
                    var val = productids[key];
                    var $inStoreAvailability = productLi.find('.product-in-store-availability');

                    if (val == false) {
                        $(productLi).remove();
                        notAvailablInStoreCount++;
                        if (SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD) {
                            $inStoreAvailability.html(productAvailability.inStoreAvailabilityMarkup(false, key));
                        }
                    } else {
                        var availableInStoreMsg = productLi.find('[data-itemid=' + key + ']').data('available-msg');
                        productLi.removeClass('even odd').addClass(lineItemNumber % 2 ? 'odd' : 'even').addClass('availinstore');
                        lineItemNumber++;
                        var $tempTarg = productLi.find('[data-itemid=' + key + ']');
                        if (!$tempTarg.find('.appendtext').length && !SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD) {
                            $tempTarg.append('<p class="appendtext"><span class="bopis-icon"></span><span class="available-msg" tab-index="0">' + availableInStoreMsg + '</span></p>');
                        }
                        if (SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD) {
                            $inStoreAvailability.html(productAvailability.inStoreAvailabilityMarkup(true, key));
                        }
                    }
                }
            }

            if (SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD) {
                $('.tiles-container').each(function () {
                    productTile.syncRowItemHeights($(this).find('.grid-tile'));
                });
            }

            if ($('.shop-the-look').length) {
                $('.grid-tile:not(.availinstore)').remove();
            }

            if (isStoreSet) {
                $('.results-hits').addClass('hide-count');
                $('.pagination').addClass('bopis-on');
            } else {
                $('.results-hits').removeClass('hide-count');
                $('.pagination').removeClass('bopis-on');
            }

            if (storeUpdated && url === window.location.href) {
                if (!$('.shop-the-look').length) {
                    updateProductListingMarkup($markup, url, data);
                }
            } else {
                updateProductListing($markup, url, data);
            }

            $('html, body').animate({
                scrollTop: $('body').offset().top
            }, 800);

            progress.hide();

            // Send new list of products to kibo when showing availability for Bopis
            kibo.sendKiboData('nonPageView');

            var loyaltyExclusiveItems = document.querySelectorAll('.loyalty-exclusive');
            loyaltyExclusive.updateLoyaltyExclusiveButtons(loyaltyExclusiveItems);
            initSignInModalsForLoyaltyExclusive();
            quantitySelector.checkIfItemInCart();
        });
    }
}
function updatePagination($markup, $container) {
    $container.find('.desktop-only .pagination').replaceWith($markup.find('.desktop-only .pagination'));
    $container.find('.bottom .pagination').replaceWith($markup.find('.bottom .pagination'));
    $container.find('.results-hits').replaceWith($markup.find('.results-hits'));
}

function updateMobileValues($markup, $container) {
    $container.find('.m-selected-values').replaceWith($markup.find('.m-selected-values'));
}

function groupingByLetter() {
    var targetElem = $('.ref-block.filter  ul');
    targetElem.addClass('grouping');
    var list = {
        letters: []
    };
    targetElem.children('li').each(function () {
        var itmLetter = $(this).text().trim().substring(0, 1).toUpperCase();
        if (!(itmLetter in list)) {
            list[itmLetter] = [];
            list.letters.push(itmLetter);
        }
        list[itmLetter].push($(this));
    });
    list.letters.sort();
    targetElem.empty();
    $.each(list.letters, function (i, letter) {
        list[letter].sort(function (a, b) {
            return $(a).text().toUpperCase().localeCompare($(b).text().toUpperCase());
        });
        var ul = $('<ul/>');
        $.each(list[letter], function (idx, itm) {
            ul.append(itm);
        });
        targetElem.append($('<li/>').append($('<a/>').attr('name', letter.toLowerCase()).addClass('title').html(letter)).append(ul));
    });
}
function updateAlphabetSpacing(deviceWidth) {
    var length = $('li.level-1:visible').length;
    var totalRemainingLastList = length%4 == 0 ? 4 : length%4;
    if (deviceWidth < 1024) {
        totalRemainingLastList = length%3 == 0 ? 3 : length%3;
    }
    $('li.level-1').removeClass('first second third fourth last-border');
    $('li.level-1:visible').each(function (i) {
        if (i % 4 == 0) {
            $(this).addClass('first');
        } else if (i % 4 == 1) {
            $(this).addClass('second');
        } else if (i % 4 == 2) {
            $(this).addClass('third');
        } else if (i % 4 == 3) {
            $(this).addClass('fourth');
        }
        if ((length - i) <= totalRemainingLastList) {
            $(this).addClass('last-border');
        }
    });
}
function filterInline(element, what) {
    var value = element.val();
    value = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
    if (value == '') {
        $(what + ' ul > li').show().parents('li').show();
        $('.filter-content .noresult').remove();
    } else {
        $(what + ' ul > li > a:not(:contains(' + value + '))').parent().hide().parents('li').hide();
        $(what + ' ul > li > a:contains(' + value + ')').parent().show().parents('li').show();
        $('.filter-content .noresult').remove();
        if ($(what + ' ul > li > a:contains(' + value + ')').length == 0) {
            $('.filter-content').append('<div class="noresult" aria-live="assertive"><strong>Oops! No scents match your search.</strong><br /> Please try another one.</div>');
        }
    }
    updateAlphabetSpacing($(window).width());
}

/**
 * @private
 * @function
 * @description Places Grid Slots if they exist
 */
function gridSlotPlacement(isMobile) {
    var $gridSlots = $('#main').find('[data-gridposition]');
    if ($gridSlots.length) {
        var $list = $('#search-result-items');
        // sort the grid-slots
        $gridSlots.sort(sort_li);
        // prevPosition is required for mobile views only
        var prevPosition = 0;
        $gridSlots.each(function() {
            var position = parseInt($(this).data('gridposition'), 10);
            if (isMobile) {
                // if mobile check to make sure there are an odd number of tiles between grid slots
                position = (position - prevPosition) % 2 ? position : position + 1;
            }
            var $targetLi = $list.children('li:eq(' + (position - 1) + ')')
            if ($targetLi.length) {
                // if the target grid location exists...
                $targetLi.before($(this));
            }
            $(this).addClass('active');
            prevPosition = position;
        });
    }
}
// sort function callback
function sort_li(a, b) {
    return (parseInt($(b).data('gridposition'), 10)) < (parseInt($(a).data('gridposition'), 10)) ? 1 : -1;
}

function updateGrid(target, e, triggerType) {
    $(target).parent('li').addClass('selected');
    $(target).parents('.filters').find('.clear-refinement').show();
    $(target).parent('.clear-refinement').hide();
    var $self = $(target),
        url = target.href;

    eventSource = $self;
    var paginationAnalytics= $self.is('.pagination a:not(.back-to-top-btn)');
    var filterByAnalytics = $self.is('.filters ul li a');
    var clearfilterAnalytics = $self.is('.clear-refinement a');
    var closeSelectedfilterAnalytics = $self.is('.selected-refinement a');

    // remove the focus-target class for load more
    $('#search-result-items li').removeClass('new-start');

    // don't intercept for category and folder refinements, as well as unselectable
    if ($(target).parents('.category-refinement').length > 0 || $(target).parents('.folder-refinement').length > 0 || $(target).parent().hasClass('unselectable')) {
        return;
    }

    progress.show('#main');
    e.preventDefault();

    if (url == undefined) {
        url = document.URL;
    }

    $.get(util.appendParamsToUrl(url, {
        'format': 'ajax', 'ajaxmeta': 'true'
    }), function (data) {
        var $markup = $(data);
        var $container = $('.primary-content');
        updateRefinements($markup);
        updateBopisRefinements($markup);
        updatePagination($markup, $container);
        updateMobileValues($markup, $container);

        updateSortOptionsURLParams();
        updateBopisToggleURLParams();
        updateFiltersURLParams();
        updateDFHURLParams();
        updateLoadMoreUrlParams();
        resetInfiniteScroll($markup);

        var windowurl = window.location.href;
        if (paginationAnalytics && '_satellite' in window) {
            window._satellite.track('paginationajaxpageload');
        }
        if ((filterByAnalytics || clearfilterAnalytics || closeSelectedfilterAnalytics) && '_satellite' in window) {
            window._satellite.track('filterbyajaxpageload');
        }
        if (windowurl.indexOf('availableForInStorePickup') > -1) {
            updateMarkupForStoreAvailability($markup, url, data);
        } else {
            updateProductListing($markup, url, data, triggerType);
            if (typeof eventSource !== 'undefined' && !eventSource.hasClass('load-more-btn')) {
                // If it isn't LOAD MORE, scroll to the top
                $('html, body').animate({
                    scrollTop: $('body').offset().top
                }, 800);
            }
            var productidsArr = getProductIds($container);
            productAvailability.updateStoreAvailability(productidsArr);
        }

        progress.hide();

        // Send new list of products to kibo when filters applied or new pagination in product list clicked on
        kibo.sendKiboData('nonPageView');

        var loyaltyExclusiveItems = document.querySelectorAll('.loyalty-exclusive');
        loyaltyExclusive.updateLoyaltyExclusiveButtons(loyaltyExclusiveItems);
        initSignInModalsForLoyaltyExclusive();
        quantitySelector.checkIfItemInCart();
    });
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var pageID = window && window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo && window.digitalData.page.pageInfo.pageID ? window.digitalData.page.pageInfo.pageID : '';
    if (pageID === 'SEARCH UNSUCCESSFUL'){
        addToCart();
    }

    //Init grouping fragrance list
    groupingByLetter();
    var $main = $('#main');

    $(window).on('popstate', function(e) {
        if (window.location.href.indexOf('#') < 0){
            updateGrid(this, e, 'backHistory');
        }
    });

    // Analytics for top offers banner
    if ($('#wrapper').hasClass('top-offers') &&
    $('.product-top-banner.loyalty-topoffers').length > 0 &&
    'digitalData' in window) {
        window.digitalData.page.loyaltyJoinAsset = true;
    }

    //hide refs with only one item for filter
    var filterElemets = $('.filters .refinement');
    filterElemets.each(function () {
        var countEl = $(this).find('.ref-block ul li').length;
        if (countEl < 2) {
            $(this).addClass('nonactive');
        }
    });
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });

    // handle toggle refinement blocks
    $main.on('click', '.filters .refinement h3', function () {
        if ($(this).hasClass('expanded')) {
            $(this).removeClass('expanded').siblings('.ref-block').slideUp();
            $(this).find('.fa').removeClass('rotate');
            $(this).parent().removeClass('open');
        } else {
            var checkAll = $('.filters .refinement h3').not($(this));
            checkAll.removeClass('expanded').siblings('.ref-block').slideUp();
            checkAll.find('.fa').removeClass('rotate');
            $(this).addClass('expanded').siblings('.ref-block').slideDown();
            $(this).find('.fa').addClass('rotate');
            $(this).parent().addClass('open');
        }

    });
    $main.on('click', '.close-button .button', function (e) {
        e.preventDefault();
        $(this).parents('.ref-block').slideUp().siblings('h3').removeClass('expanded').find('.fa').removeClass('rotate');
    });

    if ($('.clear-refinement').hasClass('kwsearch')) {
        // KW Search - apply hideFromSearch implicit refinement - /s?q=blue
        if (window.location.href.indexOf('q=') > -1) {
            $('.clear-refinement.kwsearch a').attr('href', window.location.href.split('&')[0] + '&prefn1=hideFromSearch&prefv1=No');
            $('.clear-refinement.kwsearch a').attr('data-non-bopisrefineclearallUrl', window.location.href.split('&')[0] + '&prefn1=hideFromSearch&prefv1=No');
            $('.clear-refinement.kwsearch a').attr('data-bopisrefineclearallUrl', window.location.href.split('&')[0] + '&prefn1=hideFromSearch&prefv1=No&prefn2=availableForInStorePickup&prefv2=true');
            // Fragrance Finder - /s-citrus
        } else if (window.location.href.indexOf('?') == -1) {
            $('.clear-refinement.kwsearch a').attr('href', window.location.href);
            $('.clear-refinement.kwsearch a').attr('data-non-bopisrefineclearallUrl', window.location.href);
            $('.clear-refinement.kwsearch a').attr('data-bopisrefineclearallUrl', window.location.href + '?prefn1=availableForInStorePickup&prefv1=true');
            // Fragrance Finder - /s?prefn1=fragranceName&prefv1=A%20Thousand%20Wishes
        } else {
            $('.clear-refinement.kwsearch a').attr('href', window.location.href.split('?')[0]);
            $('.clear-refinement.kwsearch a').attr('data-non-bopisrefineclearallUrl', window.location.href.split('?')[0]);
            $('.clear-refinement.kwsearch a').attr('data-bopisrefineclearallUrl', window.location.href.split('?')[0] + '?prefn1=availableForInStorePickup&prefv1=true');
        }
    } else {
        $('.clear-refinement a').attr('href', window.location.href);
        $('.clear-refinement a').attr('data-non-bopisrefineclearallUrl', window.location.href);
        $('.clear-refinement a').attr('data-bopisrefineclearallUrl', window.location.href + '?prefn1=availableForInStorePickup&prefv1=true');
    }

    // handle events for updating grid
    $main.on('click', '.filters ul li a, .pagination a:not(.back-to-top-btn), .selected-refinement a.remove-relax, .clear-refinement a, .filtered-header-slot a.filtered-header-link', function (e) {
        updateGrid(this, e, 'elementClick');
    });

    // analytics tagging
    $main.on('click', '.load-more-btn', function () {
        if ('cmCreateElementTag' in window) {
            /* eslint-disable */
            cmCreateElementTag(window.digitalData.page.pageInfo.pageID, 'Load More');
            /* eslint-enable */
        }
    });
    $main.on('click', '.back-to-top-btn', function () {
        if ('cmCreateElementTag' in window) {
            /* eslint-disable */
            cmCreateElementTag(window.digitalData.page.pageInfo.pageID, 'Back to Top');
            /* eslint-enable */
        }
    });
    $main.on('click', '.product-review', function () {
        var $productTile = $(this).closest('.product-tile');
        var $productID =  $productTile.attr('data-itemId');
        var $productName =  $productTile.find('.product-tile-link').attr('data-name');
        /* eslint-disable */
        if ('cmCreateElementTag' in window) {
            cmCreateElementTag(window.digitalData.page.pageInfo.pageID, 'Star Ratings', '-_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_-'+ $productID +'-_-'+ $productName);
        }
        /* eslint-enable */
    });

    $main.on('change', '.boipsrefinement .bopisToggle', function (e, skipAnalyticsUpdate) {
        e.preventDefault();
        eventSource = $(this);
        var url = $(this).attr('data-relaxurl');
        var storeName = $(this).attr('data-store-name');
        var isBopisToggleOn = $(this).is(':checked');
        $('.toggle-on-off-icon').removeClass('toggle-off-icon toggle-on-icon').addClass(isBopisToggleOn ? 'toggle-on-icon':'toggle-off-icon');
        $(this).parents('.show-availability-label').attr('aria-checked', isBopisToggleOn);
        var redirectUrl = $(this).data('redirect-url');
        if (isBopisToggleOn) {
            url = $(this).attr('data-refineurl');
            if (!skipAnalyticsUpdate) {
                tealiumSearch.toggleOn();
            }
        } else {
            if ($('.shop-the-look').length) {
                window.location.href = url;
            }
        }
        if (((!isBopisToggleOn && storeName && storeName != 'null') || !url) && redirectUrl) {
            window.location.href = redirectUrl;
            return false;
        }
        progress.show('#main');
        if (!storeName || (storeName && storeName === 'null')) {
            $(this).removeAttr('checked');
            $('.toggle-on-off-icon').removeClass('toggle-off-icon toggle-on-icon').addClass('toggle-off-icon');
            $(this).parents('.show-availability-label').attr('aria-checked', false);
            $('.selected-store-container .bopis-set-store').trigger('click', [true]);
            isStoreLocatorOpenThroughToggle = true;
            progress.hide();
            return false;
        }

        $.get(util.appendParamsToUrl(url, {
            'format': 'ajax', 'ajaxmeta': 'true'
        }), function (data) {
            var $markup = $(data);
            var $container = $('.primary-content');

            updateSortOptionsURLParams();
            updateBopisToggleURLParams();
            updateFiltersURLParams();
            updateDFHURLParams();
            updateLoadMoreUrlParams();

            updateRefinements($markup);
            updateBopisRefinements($markup);
            updatePagination($markup, $container);
            updateMobileValues($markup, $container);
            resetInfiniteScroll($markup);
            if (isBopisToggleOn) {
                updateMarkupForStoreAvailability($markup, url, data);
                var bopisrefineclearallUrl = $('.clear-refinement a').attr('data-bopisrefineclearallUrl')
                $('.clear-refinement a').attr('href', bopisrefineclearallUrl);
            } else {
                updateProductListing($markup, url, data);
                var nonbopisrefineclearallUrl = $('.clear-refinement a').attr('data-non-bopisrefineclearallUrl')
                $('.clear-refinement a').attr('href', nonbopisrefineclearallUrl);

                $('html, body').animate({
                    scrollTop: $('body').offset().top
                }, 800);

                progress.hide();

                // Send new list of products to kibo when Bopis is turned off
                kibo.sendKiboData('nonPageView');
                var productidsArr = getProductIds($container);
                productAvailability.updateStoreAvailability(productidsArr);

                var loyaltyExclusiveItems = document.querySelectorAll('.loyalty-exclusive');
                loyaltyExclusive.updateLoyaltyExclusiveButtons(loyaltyExclusiveItems);
                initSignInModalsForLoyaltyExclusive();
                quantitySelector.checkIfItemInCart();
            }
            if ('_satellite' in window) window._satellite.track('bopistoggleajaxpageload');
        });
    });

    // handle spacebar clicks for search items
    $main.on('keydown', '.boipsrefinement .show-availability-label, .filters .ref-toggle, .filters .ref-block li a, .filters .ref-block .close-button a, .filters .selected-refinement a, .clear-refinement a', function (e) {
        if (e.keyCode === 32) {
            e.preventDefault();
            $(this).trigger('click');
        }
    });

    // handle events of start filtering
    $main.on('keyup', '.filtering input', function () {
        filterInline($(this), '.filter ul');
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not(".quickview-rec-link, .product-review")', function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        var url = $(this).find('option:selected').attr('value');
        eventSource = $(this);
        e.preventDefault();

        if (typeof url !== 'undefined') {
            progress.show('#main');
            $(this).find('option').each(function() {
                if (typeof $(this).attr('value') === 'undefined') {
                    $(this).attr('disabled', 'disabled');
                }
            });
            $.get(util.appendParamToURL(url, 'format', 'ajax'), function (data) {
                var $markup = $(data);
                updateProductListing($markup, url);
                $('html, body').animate({
                    scrollTop: $('body').offset().top
                }, 800);
                progress.hide();

                var windowurl = window.location.href;
                if (windowurl.indexOf('availableForInStorePickup') > -1) {
                    updateMarkupForStoreAvailability($('.primary-content'), windowurl, '.primary-content');
                } else {
                    var productidsArr = getProductIds($('.primary-content'));
                    productAvailability.updateStoreAvailability(productidsArr);
                }

                updateLoadMoreUrlParams();
                updateBopisToggleURLParams();
                updateFiltersURLParams();
                updateDFHURLParams();
                updatePaginationURLParams();
                resetInfiniteScroll($markup);
                if ('_satellite' in window) window._satellite.track('sortbyajaxpageload');

                // Send new list of products to kibo when sort filter applied
                kibo.sendKiboData('nonPageView');

                var loyaltyExclusiveItems = document.querySelectorAll('.loyalty-exclusive');
                loyaltyExclusive.updateLoyaltyExclusiveButtons(loyaltyExclusiveItems);
                initSignInModalsForLoyaltyExclusive();
                quantitySelector.checkIfItemInCart();
            });
        }
    })
        .on('change', '.items-per-page select', function () {
            var refineUrl = $(this).find('option:selected').val();
            eventSource = $(this);
            if (refineUrl === 'INFINITE_SCROLL') {
                $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
            } else {
                $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
                progress.show('#main');

                $.get(util.appendParamToURL(refineUrl, 'format', 'ajax'), function (data) {
                    var $markup = $(data);
                    updateProductListing($markup, refineUrl);
                    $('html, body').animate({
                        scrollTop: $('body').offset().top
                    }, 800);
                    progress.hide();
                });
            }
        });

    if ($('.slot-grid-header *').length > 0) {
        $('.search-result-options').find('.search-result-title')
            .addClass('hidden');
    }

    if ($('.search-result-options').find('.refinement').length == 0 || !$('.search-result-options .refinement').is(':visible')) {
        // $('.search-result-options').find('.filter-title, .filter-show').hide();
        // $('.grid-sort-menu').hide();
        $('.filter-show').hide();
        // $('.filter-overflow').hide();
        $('.m-selected-values').hide();

        // DFH sort without filter bar styling
        if ($(window).width() < 900) $('.grid-sort-menu').css({'border-left': '1px solid #333', 'min-height': '40px'});
        else $('.grid-sort-menu').css({'border-left': '0', 'min-height': '40px'});
        $(window).resize(function() {
            if ($(window).width() < 900) $('.grid-sort-menu').css({'border-left': '1px solid #333', 'min-height': '40px'});
            else $('.grid-sort-menu').css({'border-left': '0', 'min-height': '40px'});
        });
    }

    //Init carousel
    $(window).on('load', function () {
        if ($('.horizontal-carousel').children().length > 1) {
            $('.horizontal-carousel').owlCarousel({
                loop: false,
                responsiveClass: true,
                mouseDrag: true,
                navSpeed: 1200,
                navRewind: false,
                dots: true,
                responsive: {
                    0: {
                        center: true,
                        items: 2,
                        margin: 30
                    },
                    768: {
                        nav: true,
                        items: 4,
                        margin: 80,
                        stagePadding: 50
                    }
                }
            });
        }
    });
    var wl = window.location;
    var param = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {}
    if (!('prefn1' in param)) {
        var resulthits = $('.hitcount').val();
        var resultHitsParsedVal = parseInt(resulthits, 10);
        if (resultHitsParsedVal < 20) {
            $('.filter-show').hide();
            $('.filter-overflow').hide();
        }
    }

    $('body').on('setstore:success', function() {
        if ($('.boipsrefinement').hasClass('refinement-bar-without-store')) {
            $('.boipsrefinement').removeClass('refinement-bar-without-store').addClass('refinement-bar-with-store')
            $('.boipsrefinement .bopisgrid_wrapper').removeClass('hide');
            $('.boipsrefinement .bopis-txt').find('.pick-up-in-store').html(Resources.SHOW_IN_STORE);
            $('.boipsrefinement .bopisToggle').prop('checked', true);
        }
        if (isStoreLocatorOpenThroughToggle) {
            $('.boipsrefinement .bopisToggle').prop('checked', true);
            isStoreLocatorOpenThroughToggle = false;
        }
        if ($('.boipsrefinement .bopisToggle').is(':checked')) {
            storeUpdated = true;
            var refineurl = $('.boipsrefinement .bopisToggle').attr('data-refineurl');
            if (refineurl == 'null' || refineurl == null) {
                var windowurl = window.location.href;
                $('.boipsrefinement .bopisToggle').attr('data-refineurl', windowurl);
            }
            $('.boipsrefinement .bopisToggle').trigger('change', [true]);
        } else if (SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD) {
            storeUpdated = true;
            $('.boipsrefinement .bopisToggle').trigger('change', [true]);
        }
    });

    //Trigger analytics element tag on shop now button for fragrance product hits page.
    $('.shop-now-cta .shop-now-icon').on('click', function(){
        var fragranceName = $('#results-products span').text();
        window.cmCreateElementTag('Shop Now', 'Fragrance Collections', '-_--_--_--_--_--_-' + fragranceName);
    });

    // Back to Top Button on Grid
    $main.on('click', '.back-to-top-btn', function (e) {
        e.preventDefault();
        $([document.documentElement, document.body]).animate({
            scrollTop: 0
        }, function() {
            $('#primary').removeAttr('tabindex');
            $('#primary .product-tile:first .product-image .product-tile-link').focus();
        });
    });

    if ('digitalData' in window && 'cmCreateElementTag' in window && 'privateSaleSignin' in window.digitalData.user.attributes) {
        //element tag after signin
        window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN PRIVATE SALE');
        //registration tag after private sale signin
        $.ajax({
            type: 'GET',
            url: Urls.getRewardsDataShort,
            dataType: 'json'
        }).done(function(data) {
            if (data.success === true && data.rewardsDataShort){
                if (data.rewardsDataShort != null) {
                    if (data.rewardsDataShort.loyaltyStatus == 'cancelled') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_canceled');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'cancelledgrace') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_canceled_grace');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled_grace';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'frozen') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_frozen');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_frozen';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'terminated') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_terminated');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'terminatedgrace') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_terminated_grace');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated_grace';
                    } else if (data.rewardsDataShort.loyaltyStatus == 'active') {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_active');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                    } else {
                        window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_active');
                        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                    }
                }
            }
        });
    }

}

function updateLoadMoreUrlParams() {
    // we need to update the Load More button to use the existing refinements
    if ($('.load-more-btn').length) {
        var $loadMoreButton = $('.load-more-btn'),
            moreURL = new URL($loadMoreButton.attr('href')),
            newURL = new URL(window.location.href);

        // pass the 'start' and 'sz' params from the existing Load More url to the current page url (post refinement)
        newURL.searchParams.set('start', moreURL.searchParams.get('start'));
        newURL.searchParams.set('sz', moreURL.searchParams.get('sz'));

        // set the Load More url to the updated page URL from above
        $loadMoreButton.attr('href', newURL.toString());
    }
}

function updateSortOptionsURLParams() {
    // we need to update the 'sort-by' select options to use the existing refinements
    if ($('.sort-by select').length) {
        var newURL = new URL(window.location.href);

        // for each option in the 'sort-by' select
        $('.sort-by select option').each(function () {
            // if the option has a value set
            if (typeof $(this).attr('value') !== 'undefined') {
                // grab the url value from the option and add it's 'srule' param to the one taken from the option
                var prevURL = new URL($(this).attr('value'));
                newURL.searchParams.set('srule', prevURL.searchParams.get('srule'));
                // update the option's url value
                $(this).attr('value', newURL.toString());
            }
        });
    }
}

function updateBopisToggleURLParams() {
    var $bopisToggle = $('.boipsrefinement .bopisToggle');
    if ($bopisToggle.length && $bopisToggle.data('refineurl') != '') {
        var newURL = new URL(window.location.href),
            refineURL = new URL($bopisToggle.data('refineurl'));

        newURL.searchParams.delete('prefn1');
        newURL.searchParams.delete('prefv1');
        $bopisToggle.attr('data-relaxurl', newURL.toString());

        newURL.searchParams.set('prefn1', refineURL.searchParams.get('prefn1'));
        newURL.searchParams.set('prefv1', refineURL.searchParams.get('prefv1'));
        $bopisToggle.attr('data-refineurl', newURL.toString());
    }
}

function updateFiltersURLParams() {
    var newURL = new URL(window.location.href);

    // iterate through the a tags
    $('.ref-block li a').each(function() {
        var $anchor = $(this),
            attrhref = $anchor.attr('href');
        // check to see if they have an href
        if (typeof attrhref !== 'undefined' && attrhref) {
            var oldURL = new URL(attrhref);
            // Display the key/value pairs
            newURL.searchParams.forEach(function (value, key) {
                // don't added pagination vals to the URLs
                if (key != 'sz' && key != 'start') {
                    oldURL.searchParams.set(key, value);
                }
            });
            $anchor.attr('href', oldURL.toString());
        }
    });
}

function updateDFHURLParams() {
    if ($('.filtered-header-slot').length) {
        var newURL = new URL(window.location.href);

        // iterate through the a tags
        $('.filtered-header-link').each(function() {
            var $anchor = $(this),
                attrhref = $anchor.attr('href');
            // check to see if they have an href
            if (typeof attrhref !== 'undefined' && attrhref) {
                var oldURL = new URL(attrhref);
                // Display the key/value pairs
                newURL.searchParams.forEach(function (value, key) {
                    // don't added pagination vals to the URLs
                    if (key != 'sz' && key != 'start') {
                        oldURL.searchParams.set(key, value);
                    }
                });
                $anchor.attr('href', oldURL.toString());
            }
        });
    }
}

function updatePaginationURLParams() {
    var newURL = new URL(window.location.href);

    // iterate through the a tags
    $('.pagination li a').each(function () {
        var $anchor = $(this),
            attrhref = $anchor.attr('href');
        // check to see if they have an href
        if (typeof attrhref !== 'undefined' && attrhref) {
            var oldURL = new URL(attrhref);
            // Display the key/value pairs
            newURL.searchParams.forEach(function (value, key) {
                // don't added pagination vals to the URLs
                if (key != 'sz' && key != 'start') {
                    oldURL.searchParams.set(key, value);
                }
            });
            $anchor.attr('href', oldURL.toString());
        }
    });
}

function updateATBButtonOnFilterChange() {
    var DOMObserverSelector = document.querySelector('.search-result-content');
    var DOMObserver = new MutationObserver(function(mutation) {
        mutation.forEach(function (mutation) {
            if (mutation.target.querySelectorAll('.add-to-cart:not(:disabled)').length) {
                window.autoRefreshTracking.updateATBButton(mutation.target.querySelectorAll('.add-to-cart:not(:disabled)'));
            }
        });
    });
    DOMObserver.observe(DOMObserverSelector, {attributes: false, childList: true, characterData: false, subtree: true})
}

if (document.querySelector('.search-result-content') != null && 'SitePreferences' in window && 'ORDERGROOVE_ENABLED' in window.SitePreferences && window.SitePreferences.ORDERGROOVE_ENABLED) {
    updateATBButtonOnFilterChange();
}

var startTime;
function checkIfInfiniteScrollEnabled() {
    if (!startTime) {
        startTime = new Date().getTime();
    }
    var currentTime = new Date().getTime();
    var timeElapsed = (currentTime - startTime) / 1000;

    if (window.enableInfiniteScroll === 'true') {
        $(window).on('scroll', infiniteScroll);
    } else if (window.enableInfiniteScroll === 'false' || timeElapsed >= 3) {
        var $infiniteScrollPlaceholder = $('.infinite-scroll-placeholder');
        if ($infiniteScrollPlaceholder) {
            $infiniteScrollPlaceholder.remove();
        }

        var $body = $('body');
        if ($body.length) {
            $body.addClass('infinite-scroll-disabled');
        }
    } else {
        window.setTimeout(checkIfInfiniteScrollEnabled, 200);
    }
}

exports.init = function () {
    certona.init();
    compareWidget.init();
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        if (SitePreferences.ENABLE_INFINITE_SCROLL_TEST) {
            checkIfInfiniteScrollEnabled();
        } else {
            $(window).on('scroll', infiniteScroll);
        }
    }

    var windowurl = window.location.href;
    if (windowurl.indexOf('availableForInStorePickup') > -1) {
        updateMarkupForStoreAvailability($('.primary-content'), windowurl, '.primary-content');
        hideGridForBopisOrderLimits();
    } else {
        var productidsArr = getProductIds($('.primary-content'));
        productAvailability.updateStoreAvailability(productidsArr);
    }
    updateAlphabetSpacing($(window).width());
    $(window).resize(function (){
        updateAlphabetSpacing($(window).width());
    });
    gridSlotPlacement(window.matchMedia('(max-width: 479px)').matches);
    productTile.init();
    initializeEvents();

    var loyaltyExclusiveItems = document.querySelectorAll('.loyalty-exclusive');
    loyaltyExclusive.updateLoyaltyExclusiveButtons(loyaltyExclusiveItems);
    quantitySelector.checkIfItemInCart();
};
