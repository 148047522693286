'use strict';

function updateSocialCommerceVisibility() {
    var displaySocialCommerceGalleryPreference = SitePreferences.PDP_DISPLAY_SOCIAL_COMMERCE_GALLERY;
    var displaySocialCommerceGalleryTest = SitePreferences.PDP_DISPLAY_SOCIAL_COMMERCE_GALLERY_TEST;
    var targetDisplaySocialCommerceGallery = window.digitalData && window.digitalData.page && window.digitalData.page.attributes && window.digitalData.page.attributes.targetflags && window.digitalData.page.attributes.targetflags.enableSocialCommerce ? window.digitalData.page.attributes.targetflags.enableSocialCommerce : false;
    var displaySocialCommerceGallery = displaySocialCommerceGalleryTest ? targetDisplaySocialCommerceGallery : displaySocialCommerceGalleryPreference;

    if (displaySocialCommerceGallery) {
        displaySocialCommerce();
    }
}

function displaySocialCommerce() {
    var socialCommerceContainer = document.querySelector('.social-commerce-container');
    if (socialCommerceContainer) {
        socialCommerceContainer.style.display = 'block';
    }

    // Following code is from BV and is needed for the social commerce to display
    /* eslint-disable */
    var CRL8_SITENAME = 'bbw-iuff0y';!function(){var e=window.crl8=window.crl8||{},n=!1,i=[];e.ready=function(e){n?e():i.push(e)},e.pixel=e.pixel||function(){e.pixel.q.push(arguments)},e.pixel.q=e.pixel.q||[];var t=window.document,o=t.createElement("script"),c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";o.async=!0,o.src=t.location.protocol+"//edge.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+c,o.onload=function(){n=!0,i.forEach(function(e){e()})};var r=t.getElementsByTagName("script")[0];r.parentNode.insertBefore(o,r.nextSibling)}();
    /* eslint-enable */
}

module.exports = {
    updateSocialCommerceVisibility: updateSocialCommerceVisibility
}
