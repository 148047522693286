'use strict'

// allows for custom triggers that allow for special interactions on click events
// by adding a data-js-alt-trigger="XXX" to that element
// you can add custom events with custom javascript

// for example
// <button data-js-alt="eventName">click me</button>
function altEventTrigger(elem) {
    var trigger = elem;
    var eventName = elem.getAttribute('data-js-alt-trigger');

    var triggerAltEvent = function() {
        // add custom triggers to events here

        // trigger open chat winodow without reloading the page
        if (eventName === 'chat') {
            if (window.$aivo) {
                window.$aivo.chat.open();
            } else {
                console.warn('avio chat is not available on this page');
            }
        } else if (eventName === 'sign-in') {
            var triggerModalEvent = new CustomEvent('triggerModal', {
                detail: {
                    elem: elem,
                    modalName: 'auth-modal',
                    options: {
                        redirectLocation: '#'
                    }
                }
            });
            window.dispatchEvent(triggerModalEvent);
        }
    }

    trigger.addEventListener('click', triggerAltEvent);
}

module.exports = function () {
    // grab all alt triggers
    var triggers = document.querySelectorAll('[data-js-alt-trigger]');

    triggers.forEach(function(elem) {
        altEventTrigger(elem);
    })
}
