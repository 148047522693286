'use strict';
var emailCookies = require('../email-dialog');
var modalHelper = require('./modalHelper');
var previousActiveElement;

var createCookieForModal = function (modalDialog) {
    var cookieName = modalDialog.dataset.cookiename;
    var cookieDuration = modalDialog.dataset.cookieduration;
    if (cookieName && cookieDuration) {
        emailCookies.createCookie(cookieName, 'true', cookieDuration);
    }
}

var closeModal = function (modalDialogToClose, previousActiveElement) {
    createCookieForModal(modalDialogToClose);
    modalHelper.closeModal(modalDialogToClose, previousActiveElement);
}

var escapeCloseModal = function (e) {
    if (e.key == 'Escape') {
        var openModals = document.getElementsByClassName('ds-modal-open');
        for (var i = 0; i < openModals.length; i++) {
            closeModal(openModals[i], previousActiveElement);
        }
        document.removeEventListener('keydown', escapeCloseModal);
    }
}

var linkSuppressed = function (modalDialog) {
    return (modalDialog.classList.contains('email-modal-dialog') && window.location.search.indexOf('mi_u=') !== -1);
}

var openEmailModal = function () {
    var modalDialogs = document.getElementsByClassName('ds-modal-dialog email-modal-dialog');
    for (var i = 0; i < modalDialogs.length; i++) {
        var modalDialog = modalDialogs[i];

        if (linkSuppressed(modalDialog)) {
            createCookieForModal(modalDialog);
        } else {
            var closeButtons = modalDialog.getElementsByClassName('ds-close-action');

            for (var j = 0; j < closeButtons.length; j++) {
                closeButtons[j].addEventListener('click', closeButtonClicked);
            }
            setTimeout(function () { openModal(modalDialog); }, 3000);
        }
    }

    document.addEventListener('keydown', escapeCloseModal);
}

var openModal = function (modalDialog) {
    if (modalDialog.dataset.cookiename && emailCookies.readCookie(modalDialog.dataset.cookiename) == null) {
        modalHelper.openModal(modalDialog);
        modalHelper.setFocusTrap(modalDialog);
        handleFocus();
    }
}

var closeButtonClicked = function () {
    var modalDialogToClose = this.closest('.ds-modal-dialog');
    closeModal(modalDialogToClose, previousActiveElement);
}

var handleFocus = function () {
    previousActiveElement = document.activeElement || document.body;
    var modalInput = document.querySelector('.ds-modal-open .input-text');
    if (modalInput) {
        modalInput.focus();
    }
}

var modalDialogSetup = function () {
    var modalDialogs = document.getElementsByClassName('ds-modal-dialog email-modal-dialog');
    var internationalModalDialogs = document.getElementsByClassName('ds-international-redirect');
    var userCountryCode = User.geolocation.countryCode;
    var internationalModalDisplayed = (internationalModalDialogs.length !== 0) && (userCountryCode !== 'US');

    if ((modalDialogs.length === 0) || (internationalModalDisplayed) || !SitePreferences.EMAIL_DIALOG_ENABLED) {
        return;
    } else {
        openEmailModal();
    }

    document.addEventListener('keydown', escapeCloseModal);
}

module.exports = {
    modalDialogSetup: modalDialogSetup,
    openEmailModal: openEmailModal
};
