'use strict';

var analyticsHelper = require('./analyticshelper');
/**
* Video Tracking:
*
* @description global video tracking module
*/

var videotracking = {
    init: function () {
        var videoObject = window.videojs;
        if (videoObject) {
            var myPlayer = $('video');
            try {
                for (var i = 0; i < myPlayer.length; i++) {
                    videoObject.getPlayer(myPlayer[i]).ready(function () {
                        var videoPlayer = this,
                            pageType = window.digitalData.page.pageInfo.pageID ? window.digitalData.page.pageInfo.pageID : '';

                        window.digitalData.page.video = window.digitalData.page.video || {};

                        //register events
                        videoPlayer.on('play', trackVideo);
                        videoPlayer.on('pause', trackVideo);
                        videoPlayer.on('ended', trackVideo);
                       
                        if (!videoPlayer.paused()) {
                            trackVideo({type: 'play'});
                        }

                        function trackVideo(e) {
                            var videoDuration = videoPlayer.mediainfo.duration;
                            var videoName = videoPlayer.mediainfo.name;
                            var videoEvent = {
                                'play': '2',
                                'pause': '1',
                                'ended': '3'
                            };

                            if ('cmCreateElementTag' in window) {
                                window.cmCreateElementTag(pageType, videoName, '-_--_--_--_--_--_--_--_--_--_--_--_-' + videoEvent[e.type] + '-_-' + videoPlayer.currentTime() + '-_-' + videoDuration + '-_-video');
                            }
                            //add values in datalayer for any event fired
                            window.digitalData.page.video.timestamp = videoPlayer.currentTime();
                            window.digitalData.page.video.eventName = e.type;
                            window.digitalData.page.video.name = videoName;
                            window.digitalData.page.video.length = videoDuration;
                            if (e.type == 'play') {
                                analyticsHelper.sendSatellite('videoplayed');
                            } else if (e.type == 'ended') {
                                analyticsHelper.sendSatellite('videocompleted');
                            }
                        }
                    });
                }
            } catch (e) {
                //window didn't have all the third-party info it needed
                //so catch the error to prevent execution from halting
            }
        }
    }
}

module.exports = videotracking;
