'use strict';

function checkIfItemInCart() {
    var enableQtySelector = SitePreferences.ENABLE_QTY_SELECTOR;
    var enableQtySelectorTest = SitePreferences.ENABLE_QTY_SELECTOR_TEST;
    var targetEnableQtySelector = window.digitalData && window.digitalData.page && window.digitalData.page.attributes && window.digitalData.page.attributes.targetflags && window.digitalData.page.attributes.targetflags.enableQtySelector ? window.digitalData.page.attributes.targetflags.enableQtySelector : false;
    var displayQtySelector = enableQtySelectorTest ? targetEnableQtySelector : enableQtySelector;

    var digitalDataPageType = window.digitalData && window.digitalData.pageInstanceID ? window.digitalData.pageInstanceID : 'null';
    var tileContainerString = digitalDataPageType === 'home' ? '.ds-shop-the-module-container' : '#search-result-items';
    var $productTiles = $(tileContainerString + ' .product-tile');


    $productTiles.each(function() {
        var $this = $(this);
        var pid = $this.find('input[name="pid"]').val();
        var sizedProductId = '012345678'
        if (pid === sizedProductId) {
            displayQtySelector = false
        }
    })

    if (displayQtySelector) {
        var cartLineItems = $('.mini-cart-link').attr('data-kiboCartData') ? JSON.parse($('.mini-cart-link').attr('data-kiboCartData')) : [];
        if (cartLineItems && cartLineItems.length) {
            $productTiles.each(function() {
                var $this = $(this);
                var productId = $this.find('input[name="pid"]').val();
                var productIdIndex = cartLineItems.findIndex(function(e) {return e.productId === productId});
                if (productIdIndex > -1) {
                    var currentQty = $this.find('#Quantity').get(0);
                    if (currentQty) {
                        currentQty.value = cartLineItems[productIdIndex].quantity;
                    }
                    $this.find('.add-to-cart-container').addClass('hide-element');
                    $this.find('.quantity-field').removeClass('hide-element');
                }
            });
        }
    }
}

function removeQtySelectorFromGrid(productID) {
    var qtySelectorToRemove = checkIfItemInGrid(productID);
    if (Object.keys(qtySelectorToRemove).length) {
        qtySelectorToRemove.addToCartContainer.removeClass('hide-element');
        qtySelectorToRemove.qtyField.find('#Quantity').val('1');
        qtySelectorToRemove.qtyField.addClass('hide-element');
    }
}

function replaceQtySelectorValue(productID, qty) {
    var qtySelectorToReplace = checkIfItemInGrid(productID);
    if (Object.keys(qtySelectorToReplace).length) {
        qtySelectorToReplace.qtyField.find('#Quantity').val(qty);
    }
}

function addToQtySelectorValue(productID, qty) {
    var qtySelectorToAddTo = checkIfItemInGrid(productID);
    if (Object.keys(qtySelectorToAddTo).length) {
        if (!qtySelectorToAddTo.addToCartContainer.hasClass('hide-element')) {
            qtySelectorToAddTo.addToCartContainer.addClass('hide-element');
        }
        if (qtySelectorToAddTo.qtyField.hasClass('hide-element')) {
            qtySelectorToAddTo.qtyField.removeClass('hide-element');
            qtySelectorToAddTo.qtyField.find('#Quantity').val(qty);
        } else {
            var newQty = Number(qtySelectorToAddTo.qtyField.find('#Quantity').val()) + Number(qty);
            qtySelectorToAddTo.qtyField.find('#Quantity').val(newQty);
        }
    }
}

function checkIfItemInGrid(productID) {
    var returnItemObj = {};
    var digitalDataPageType = window.digitalData && window.digitalData.pageInstanceID ? window.digitalData.pageInstanceID : 'null';
    var $tileContainer = digitalDataPageType === 'home' ? $('.ds-shop-the-module-container') : $('#search-result-items');
    if ($tileContainer.length) {
        var $updatedProductTile = $tileContainer.find('.product-tile[data-itemid="' + productID + '"]');
        if ($updatedProductTile.length) {
            var $addToCartContainer = $updatedProductTile.find('.add-to-cart-container');
            var $qtyField = $updatedProductTile.find('.quantity-field');

            returnItemObj = {addToCartContainer: $addToCartContainer, qtyField: $qtyField};
        }
    }

    return returnItemObj;
}

module.exports = {
    checkIfItemInCart: checkIfItemInCart,
    removeQtySelectorFromGrid: removeQtySelectorFromGrid,
    replaceQtySelectorValue: replaceQtySelectorValue,
    addToQtySelectorValue: addToQtySelectorValue
}
