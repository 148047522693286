/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('./dialog'),
    monitate = require('./monitate'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    videotracking = require('./videotracking'),
    searchsuggest = require('./searchsuggest'),
    hoverIntent = require('./hoverintent'),
    progress = require('./progress'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    tealium = require('./tealium/init'),
    calanding = require('./ca-landing'),
    ingredients = require('./ingredients'),
    customercare = require('./customercare'),
    emaildialog = require('./email-dialog'),
    iroll = require('./iroll'),
    fragrance = require('./fragrance'),
    smoothScroll =  require('./designSystem/smoothScroll'),
    timeoutDialog = require('./timeout-dialog'),
    setupTopBanner = require('./setupTopBanner'),
    altEventTrigger = require('./designSystem/altEventTrigger'),
    designSystemTooltip = require('./designSystem/designsystemtooltip'),
    visualNavigation = require('./designSystem/visualNavigation'),
    setupSlick = require('./designSystem/setupSlick'),
    setUpBrightcoveVideos = require('./designSystem/setupBrightcoveVideos'),
    setUpModalDialog = require('./designSystem/setupModalDialog'),
    mobileMenuAria = require('./designSystem/mobileMenuAria'),
    accordionSetup = require('./designSystem/accordion'),
    internationalModalDialog = require('./designSystem/internationalRedirect'),
    setupFulfillment = require('./designSystem/setupFulfillment'),
    kibo = require('./kibo'),
    kiboRecommendations = require('./kiboRecommendations'),
    recommendationsModal = require('./recommendationsModal'),
    quantitySelector = require('./quantity-selector'),
    modal = require('./designSystem/modal/modal'),
    bazaarVoiceCookieConsent = require('./bazaarvoice-cookie-consent'),
    oneTrust = require('./oneTrust-dns'),
    _ = require('lodash');


// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./slick');
require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('./unsupported')();
require('../../int_ordergroove/cartridge/client/default/js/optins')();
designSystemTooltip();
altEventTrigger();
require('./designSystem/setupCustomerInputForm')();
require('./designSystem/setupEmailModalForm')();
require('./designSystem/setupShopTheModule')();

(function ($, sr) {

    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
        var timeout;

        return function debounced () {
            var obj = this, args = arguments;
            function delayed () {
                if (!execAsap) {
                    func.apply(obj, args);
                }
                timeout = null;
            }

            if (timeout) {
                clearTimeout(timeout);
            } else if (execAsap) {
                func.apply(obj, args);
            }

            timeout = setTimeout(delayed, threshold || 500);
        };
    };

    // smartresize
    jQuery.fn[sr] = function (fn) {
        return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
    };

}(jQuery, 'smartresize'));

function initializeEvents() {

    var androidPort;
    window.onmessage = function (e) {
        window.console.log(e);
        if (e.ports && e.ports.length) {
            androidPort = e.ports[0];
            androidPort.postMessage('{\"ack\":\"true\"}');
        }
    };

    function sessionShopInAppInit() {
        timeoutDialog({
            sessionExpiredCallback: function () {
                logoutCustomer(window.Urls.logoutCustomer).then(function(result) {
                    if (result.success == true) {
                        var isShopInApp = window.User.isShopInApp;
                        var platform = window.User.isShopInAppPlatform;
                        if (isShopInApp && platform) {
                            var payload = {
                                'action': 'userLoggedOut'
                            };
                            window.console.log(payload);
                            if (platform == 'android' && androidPort) {
                                androidPort.postMessage(
                                    JSON.stringify(payload)
                                );
                            } else if (platform == 'ios') {
                                window.webkit.messageHandlers.userLoggedOut.postMessage(payload);
                            }
                        }
                    }
                });
            }
        });
    }

    if (window.User.isShopInApp) {
        sessionShopInAppInit();
    }

    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).parent().find('div.char-count').find('.char-remain-count').html(charsRemain);
        })
        .on('touchstart', function () {
            $(this).addClass('touch');
        })
        .on('change keyup paste', '.number-max-length input', function(){
            var maxlength = $(this).data('maxlength');
            if ($(this).val().length > maxlength) {
                var maxValueLength = $(this).val().slice(0, maxlength);
                $(this).val(maxValueLength);
            }
        })
        .on('change keydown paste', '.js-emoji-stripper', function(){
            /* Known bug in Safari where cursor position resets even if the value is unchanged, to mitigate this first we check for emoji and only replace the value, if found. */
            if ($(this).val().match(/([^\x00-\x80])/g)) {
                $(this).focus().val($(this).val().replace(/([^\x00-\x80])/g, ''));
            }
        })
        .on('blur', 'input.phone', function() {
            if ($(this).hasClass('valid') && !$(this).closest('.rewardspopup')) {
                var maskedVal = $(this).val().replace(/[\-\. \(\)]/g,'')
                    .replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                $(this).val(maskedVal);
            }
        });

    //Search for keywords within the user agent string.  When a match is found, add a corresponding class to the html element and return.
    function addUserAgentClass(keywords) {
        for (var i = 0; i < keywords.length; i++) {
            if (navigator.userAgent.indexOf(keywords[i]) != -1) {
                $('html').addClass(keywords[i].toLowerCase());
                return; //Once we find and process a matching keyword, return to prevent less "specific" classes from being added
            }
        }
    }

    addUserAgentClass(['Chrome', 'Firefox', 'MSIE', 'Safari', 'Opera', 'Mozilla']); //Browsers listed generally from most-specific to least-specific
    addUserAgentClass(['Android', 'iPhone', 'iPad', 'Linux', 'Mac', 'Windows']); //Platforms, a

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $('#navigation .header-search');
    var searchLabel = Resources.SIMPLE_SEARCH;
    var enableOnSiteSearch = SitePreferences.ENABLE_ONSITE_SERACH;
    if (enableOnSiteSearch){
        $searchContainer = $('.header-search');
        searchLabel = SitePreferences.ONSITE_SEARCHBOX_LABEL;
    }
    searchsuggest.init($searchContainer, searchLabel);

    // add show/hide navigation elements
    if ($('nav > .top-level').length <=0) {
        $('<span class="top-level toggle">' + Resources.CUSTOMER_CARE + '</span>')
            .insertBefore($('.secondary-navigation'));
    }
    $(document).on('click', 'nav > .top-level, .nav .top-level', function () {
        $(this).toggleClass('rotate').next('.secondary-navigation').slideToggle();
    });

    $('.showlabel ').on('click', function (e){
        e.preventDefault();
        $(this).toggleClass('active').find('.icon').toggleClass('rotate');
        $(this).parents('.footer-container').find('.sub-item').slideToggle();
        $('.maney-back').toggleClass('active');

    });
    //bizrate checkout modal close on click of overlay
    $('body').on('click', '.maskLayer', function(){
        $('.bizrateBanner').hide();
        $('.maskLayer').hide();
    });
    //header banner hover
    var hoverIcon = $('.header-promotion .regular');
    var regularIcon = $('.header-promotion .hover');

    hoverIcon.hide();
    $('.header-promotion a.firstlook').hover(function (){
        regularIcon.hide();
        hoverIcon.show();
        return false;
    }, function (){
        hoverIcon.hide();
        regularIcon.show();
        return false;
    });

    //Search actions
    var $main = $('#wrapper');
    var inputField = $('.header-search input[type=text], .header-search input[type="search"]');
    var $headerSearch = $('.header-search');

    inputField.focus(function() {
         /* eslint-disable */
         if('cmCreateElementTag' in window){
            cmCreateElementTag('Nav:Search', 'GB');
         }

         /* eslint-enable */
    });

    $main.keyup(function (e) {
        if (e.keyCode === 27) {
            closeSearchModalMobile();
        }
    });

    var $searchBtn = $('.search.mobile-only a');
    var $focusdest = $('.top-banner .wrap');
    var $headerSearchCancel = $headerSearch.find('.cancel');
    var $headerSearchInput = $headerSearch.find('input[type=search]');
    var $overlay = $('<div class="ui-widget-overlay ui-front mobile-search" style="z-index: 110;"></div>');
    $searchBtn.click(function (event) {
        event.preventDefault();
        $('body').append($overlay);
        $overlay.click(closeSearchModal);
        $focusdest.addClass('search-focus');
        $headerSearch.attr('aria-hidden', 'false');
        $searchBtn.attr('aria-expanded', 'true');
        $headerSearchInput.focus();
        $('body').addClass('nooverflow');
        mobileMenuAria.hideNonModal($headerSearch[0]);
    });

    $headerSearchCancel.click(closeSearchModal);//doesn't exist on desktop

    function closeSearchModalMobile(){
        mobileMenuAria.unhideNonModal($headerSearch[0]);
        closeSearchModal();
    }

    function closeSearchModal() {
        $overlay.remove();
        $focusdest.removeClass('search-focus');
        $headerSearch.attr('aria-hidden', 'true');
        $searchBtn.attr('aria-expanded', 'false');
        $('body').removeClass('nooverflow');
        mobileMenuAria.unhideNonModal($headerSearch[0]);
    }
    // prevent null header searches
    $('.header-search form').submit(function () {
        var searchVal = $.trim($headerSearchInput.val());
        if (searchVal === '' || searchVal === 'Search' || (SitePreferences.ENABLE_ONSITE_SERACH && searchVal === SitePreferences.ONSITE_SEARCHBOX_LABEL)) {
            return false;
        }
        if ($(window).width() < 768) {
            window.localStorage.setItem('searchViewport', 'Mobile global nav search');
        } else {
            window.localStorage.setItem('searchViewport', 'Desktop global nav search');
        }
    });

    $('#headerSearch').on('click', '.search-result-items .product-tile, .phrase-suggestions .hitgroup a, .phrase-suggestions .search-data-list a, .flyout-product-recommendation a', function() {
        if ($(window).width() < 768) {
            window.localStorage.setItem('searchViewport', 'Mobile global nav search');
        } else {
            window.localStorage.setItem('searchViewport', 'Desktop global nav search');
        }
    });

    // show on mobile viewport filters block
    $main.on('click', '.filter-show', function () {
        $(this).siblings('.filter-overflow').addClass('active');
        $('body').addClass('nooverflow');
    });
    $main.on('click', '.close-filters', function () {
        $(this).parents('.filter-overflow').removeClass('active');
        $('body').removeClass('nooverflow');
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // Banner grid details popup
    $('.details-pop-up').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(this).attr('href'),
            options: {
                title: false,
                dialogClass : 'banner-details',
                width: 380,
                open: function () {
                    var $titles = $('.ui-dialog-content .content-asset p:first-child');
                    $('.ui-dialog-title').html($titles.text());
                    $titles.hide();
                }
            }
        });
    });

    // subscribe email box
    var $subscribeEmail = $('#email-alert-signup');
    if ($subscribeEmail.length > 0) {
        $subscribeEmail.on('submit', function () {
            var footerForm = $(this);
            if ($subscribeEmail.valid() === false) {
                return false;
            }
            $.post(Urls.footerSubscriptionUrl,
                    {email: $('#email-alert-address').val()},
                    function (data,status){
                        if (data.success) {
                            $('.footer-email-success').remove();
                            footerForm.find('.signup-btn').parent().parent().append('<div class="footer-email-success">' + Resources.SUBSCRIBE_EMAIL_SUCCESS +status+ '</div>');
                            $('#email-alert-address').val('');
                            $('#confirm-email-alert-address').val('');
                            // Set analytics
                            if ('_satellite' in window) window._satellite.track('footeremailsubmitted');
                            setTimeout(function(){
                                if ($('.footer-email-success').length > 0) {
                                    $('.footer-email-success').fadeOut('slow').remove();
                                }
                            }, 5000)
                        }
                    },'json');
            return false;
        });
    }

    function hasClass(element, className) {
        return (' ' + element.className + ' ').indexOf(' ' + className+ ' ') > -1;
    }

    // **********************************
    // shop in app utils
    // **********************************
    var triggerShopInAppCall = function(payload) {
        var platform = window.User.isShopInAppPlatform;

        if (platform == 'android' && androidPort) {
            androidPort.postMessage(
                JSON.stringify(payload)
            );
        } else if (platform == 'ios') {
            window.webkit.messageHandlers.callNative.postMessage(payload);
        }
    }

    // **********************************
    // shop in app custom event listeners
    // **********************************
    window.addEventListener('dispatch-app-sign-in', function() {
        var dt = new Date();
        dt.setMinutes(dt.getMinutes() + 30);

        var payload = {
            'link': {
                'href': undefined,
                'origin': window.location.protocol + '//' + window.location.host,
                'external': false
            },
            'data': {
                'linkName': '',
                'pageId': 'sign-in'
            },
            'action': 'callNative',
            'session_expires': dt.getTime()
        };

        triggerShopInAppCall(payload)
    })

    // **********************************
    // legacy shop in app click
    // **********************************
    var shopInApp = function ($this, e) {

        var isShopInApp = window.User.isShopInApp;
        if (!isShopInApp) {
            return false;
        }

        var platform = window.User.isShopInAppPlatform;
        if (!platform) {
            return false;
        }

        var thisId = $this[0];
        if (thisId) {
            if ((thisId.closest('li') && thisId.className === 'ingredient-iln') || hasClass(thisId, 'pm-ignore')) {
                return false;
            }
        }

        var href = $this.attr('href');
        if (href === 'javascript:void(0)') {
            return false;
        }

      //Scent-Scription SHOP BBWRKS-6757
        var og_flag = false;
        if ($this.hasClass('og-upsell-button')) {
            og_flag = true;
            href = $this.attr('href').slice(0,19);
        }

        //Scent-Scription PDP BBWRKS-6756
        var og_pdpflag = false;
        if ($this.parent('h3.og-product-name').length > 0) {
            og_pdpflag = true;
        }

        //Scent-Scription Edit Bill link BBWRKS-6755
        var og_editflag = false;
        if ($this.hasClass('og-edit-payment')) {
            og_editflag = true;
        }

        var searchQuery = '';
        var $headerSearchInput = $('.header-search input[type=text], .header-search input[type="search"]');
        var $menuSearchInput = $('.menu-search').find('input[type=search]');
        if ($this[0].id === 'headersearch' && ($.trim($headerSearchInput.val()) !== '' && $.trim($headerSearchInput.val()) !== Resources.SIMPLE_SEARCH)) {
            href = util.appendParamToURL(Urls.search, 'q', $.trim($headerSearchInput.val()));
            searchQuery = $.trim($headerSearchInput.val());
        } else if ($this[0].id === 'menusearch' && ($.trim($menuSearchInput.val()) !== '' && $.trim($menuSearchInput.val()) !== Resources.SIMPLE_SEARCH)) {
            href = util.appendParamToURL(Urls.search, 'q', $.trim($menuSearchInput.val()));
            searchQuery = $.trim($menuSearchInput.val());
        }
        if ($this[0].id === 'headersearch' && ($.trim($headerSearchInput.val()) === '' || $.trim($headerSearchInput.val()) === Resources.SIMPLE_SEARCH)) {
            return false;
        } else if ($this[0].id === 'menusearch' && ($.trim($menuSearchInput.val()) === '' || $.trim($menuSearchInput.val()) === Resources.SIMPLE_SEARCH)) {
            return false;
        }

        if (util.isMobile() && href && href.indexOf('#') !== -1 && $this.parent('.has-children').length) {
            // is mobile and # exists in url and is a top nav link
            return false;
        } else if (util.isMobile() && $this.parent('.has-children').length) {
            // is mobile and is a top nav link
            return false;
        } else if ($this.className === 'pm-ignore' || $('a').parents('.pm-ignore').length != 0) {
            // is mobile and if class is pm-ignore or has pm-ignore as parents
            return false;
        } else if (href && href.length === 1 && href.indexOf('#') !== -1) {
            // is not mobile and only # exists in url
            return false;
        }

        var dt = new Date();
        dt.setMinutes(dt.getMinutes() + 30);

        var payload;

        /*	sameple HTML
        *	note not all data properies need to be used
            <a class="pm-override"
                href="someLink"
                data-hrefval="someHref"
                data-origin="someOrigin"
                data-external="Y"
                data-linkname="someLinkname"
                data-pageid="somePageid"
                data-action="someAction"> link thing </a>
        */

        if ($this.hasClass('pm-override')) {
            payload = {
                'link': {
                    'href': typeof $this.data('hrefval') !== 'undefined' ? $this.data('hrefval') : href,
                    'origin': typeof $this.data('origin') !== 'undefined' ? $this.data('origin') :  window.location.protocol + '//' + window.location.host,
                    'external': typeof $this.data('external') !== 'undefined' ? $this.data('external') :  true
                },
                'data': {
                    'linkName': '',
                    'pageId': typeof $this.data('pageid') !== 'undefined' ? $this.data('pageid') :  ''
                },
                'action': typeof $this.data('action') !== 'undefined' ? $this.data('action') :  'callNative',
                'session_expires': dt.getTime()
            };
        } else {
            payload = {
                'link': {
                    'href': href,
                    'origin': window.location.protocol + '//' + window.location.host,
                    'external': true
                },
                'data': {
                    'linkName': '',
                    'pageId': ''
                },
                'action': 'callNative',
                'session_expires': dt.getTime()
            };
        }
        var domainsWhitelist = JSON.parse(SitePreferences.SHOPINAPP_WHITELIST_DOMAINS);
        var matches = (href ? href.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i) : false);
        var domain = matches && matches[1];  // domain will be null if no match is found

        if ((domain && domainsWhitelist.indexOf(domain) !== -1) || href && href.indexOf('://') === -1) {
            payload.link.external = false;
        }

        var closestUl = $this[0].closest('ul');
        if (closestUl && closestUl.className === 'ingredient-links') {
            payload.link.external = true;
            if ($this[0].innerText === 'Safety Data Sheet') {
                payload.link.href = $('input[name="sdsAbsUrl"]').val();
            }
        }

        var pathName = '';
        if (searchQuery !== '') {
            pathName = searchQuery;
        } else if (og_flag) {
            var url = $this.attr('href');
            var path_name = url.substring(url.lastIndexOf('/') + 1);
            pathName = path_name;
            payload.data.linkName = 'SHOP';
        } else if (og_pdpflag) {
            payload.data.linkName = $this.text();
            pathName = 'pdp_subscription';
            payload.link.external = false;
        } else if (og_editflag) {
            pathName = 'edit-payment';
            payload.link.external = false;
        } else {
            payload.data.linkName = $this.text().replace(/(\r\n|\n|\r)/gm, '');
            pathName = (href && href !== null ? href.substring(href.lastIndexOf('/') + 1) : '');
            var array = ['?', '.', '#'];
            for (var i = 0; i < array.length; i++) {
                if (pathName !== null && pathName.indexOf(array[i]) !== -1) {
                    pathName = pathName.split(array[i])[0];
                }
            }
        }

        if ($this.hasClass('pm-override')) {

            if (typeof $this.data('linkname') !== 'undefined') {
                payload.data.linkName = $this.data('linkname');
            }
            if (typeof $this.data('external') !== 'undefined') {
                payload.data.external = ($this.data('external') === 'true');
            }
        }
        var keywordsWhitelist = JSON.parse(SitePreferences.SHOPINAPP_WHITELIST_KEYWORDS);
        for (var key in keywordsWhitelist) {
            if (keywordsWhitelist.hasOwnProperty(key)) {
                var regex = new RegExp(key, 'i');
                if (regex.test(pathName)) {
                    pathName = keywordsWhitelist[key];
                    break;
                }
            }
        }

        if (searchQuery !== '') {
            payload.link.href = util.appendParamToURL(Urls.search, 'q', pathName);
        }

        if (!$this.hasClass('pm-override') || typeof $this.data('pageid') === 'undefined') {
            payload.data.pageId = pathName.toLowerCase();
        }

        window.console.log(payload);

        e.preventDefault();

        if (platform == 'android' && androidPort) {
            androidPort.postMessage(
                JSON.stringify(payload)
            );
        } else if (platform == 'ios') {
            window.webkit.messageHandlers.callNative.postMessage(payload);
        }
    }

    $(document).on('click', 'a[role!="button"], button[id="headersearch"], button[id="menusearch"], .og-upsell-button, a[id^="item-public-id-"], .og-edit-payment', function (e) {
        shopInApp($(this), e);
    });

    var form = $('#firstlookForm');
    form.submit(function (e) {
        // prevent form submission
        e.preventDefault();
        $.post(Urls.firstLookEmailHash,
                {email: $('#email').val()},
                function (data){
                    if (data.success) {
                        // override jquery bound handler and submit form
                        form[0].submit();
                    }
                },'json');
        return false;
    });

    var $subscribeEmailCustom = $('#wb-email-alert');
    if ($subscribeEmailCustom.length > 0) {
        $subscribeEmailCustom.on('submit', function() {
            var form = $(this);
            if ($subscribeEmailCustom.valid() === false) {
                return false;
            }
            $.post(Urls.footerSubscriptionUrl,
                    {email: $('#wb-email-alert-address').val()},
                    function (data){
                        if (data.success) {
                            $('.email-success').remove();
                            form.find('.signup-btn').parent().parent().append('<div class="email-success">' + Resources.SUBSCRIBE_EMAIL_SUCCESS + '</div>');
                            $('#wb-email-alert-address').val('');
                            setTimeout(function(){
                                if ($('.email-success').length > 0) {
                                    $('.email-success').fadeOut('slow').remove();
                                }
                            }, 5000);
                        }
                    },'json');
            return false;
        });
    }
    var $firstLookSubscribeEmail = $('#firstlook-alert-signup');
    if ($firstLookSubscribeEmail.length > 0) {
        $firstLookSubscribeEmail.on('submit', function() {
            if ($firstLookSubscribeEmail.valid() === false) {
                return false;
            }
            $.post(Urls.footerSubscriptionUrl,
                    {email: $('#firstlook-alert-address').val()},
                    function (data){
                        if (data.success) {
                            $('.firstlook-signup').find('.firstlook-signup-form').hide();
                            $('.firstlook-signup').find('.firstlook-signup-success').show();
                        }
                    },'json');
            return false;
        });
    }
    $('.fake-privacy-policy').on('click', function (e) {
        e.preventDefault();
        $main.find('.privacy-policy').trigger('click');
    });
    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        window.open($(this).attr('href'));
    });
    $('label').on('click', '.why-link', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });
    $('.print-page').on('click', function () {
        window.print();
        return false;
    });
    $('.single-accordion').on('click', '.single-accordion-title, .accordion-chevron', function () {
        $(this).toggleClass('open')
            .next('.single-accordion-content').slideToggle('250');
    });

    $('.single-accordion-title').on('focus', function () {
        if ($(this).hasClass('open')) {
            $(this).attr('aria-expanded', 'true');
            $(this).parent().children('.single-accordion-content').attr('aria-hidden', 'false');
        } else {
            $(this).attr('aria-expanded', 'false');
            $(this).parent().children('.single-accordion-content').attr('aria-hidden', 'true');
        }
    });

    $('.single-accordion-title').on('click', function () {
        if ($(this).hasClass('open')) {
            $(this).attr('aria-expanded', 'false');
            $(this).parent().children('.single-accordion-content').attr('aria-hidden', 'true');
        } else {
            $(this).attr('aria-expanded', 'true');
            $(this).parent().children('.single-accordion-content').attr('aria-hidden', 'false');
        }
    });
    $('.single-accordion-title').on('keydown', function (e) {
        var code = e.keyCode || e.which;
        if (code == '13') {
            $(this).click();
            return false; // stop propagation of nested elements
        }
    });

    setupTopBanner();

    $('.shipping-truck').removeAttr('role');

    //top navigation block
    function TopNavigation($) {

        var TIER_1 = 'tier1';
        var TIER_2 = 'tier2';
        var TIER_3 = 'tier3';
        var MENU_STATE_CHANGED = 'menuStateChanged';
        var MENU_STATE_OPEN = 'menuStateOpen';
        var MENU_STATE_CLOSED = 'menuStateClosed';
        var MENU_STATE_CLOSEALL = 'menuStateCloseAll';

        var tier1MenuState = MENU_STATE_CLOSED;
        function setTier1MenuState(value) {
            if (tier1MenuState !== value) {
                tier1MenuState = value;
                $(window).trigger(TIER_1+MENU_STATE_CHANGED);
                if (tier1MenuState === TIER_1+MENU_STATE_CLOSED) {
                    $(window).trigger(MENU_STATE_CLOSEALL, TIER_1);
                }
            }
        }

        var tier2MenuState = -1;
        function setTier2MenuState(value) {
            if (tier2MenuState !== value) {
                tier2MenuState = value;
                $(window).trigger(TIER_2+MENU_STATE_CHANGED);
            }
        }

        function Navigation_tier1button($el) {
            function setActive() {
                setTier1MenuState(MENU_STATE_OPEN);
            }
            function setInactive() {
                setTier1MenuState(MENU_STATE_CLOSED);
                /* eslint-disable */
                if ('cmCreateElementTag' in window) {
                    cmCreateElementTag('Exit', 'FO');
                }
                /* eslint-enable */
            }
            $el.click(function() {
                if (tier1MenuState !== MENU_STATE_OPEN) {
                    setActive();
                } else {
                    setInactive();
                }
            });
            $(window).on(TIER_1+MENU_STATE_CHANGED, function() {
                if (tier1MenuState === MENU_STATE_OPEN) {
                    $el.addClass('active').attr('aria-expanded', 'true');
                    /* eslint-disable */
                    if('cmCreateElementTag' in window){
                        cmCreateElementTag('Nav-_-Hamburger', 'GB');
                    }
                    mobileMenuAria.hideNonModal($el[0]);
                    /* eslint-enable */
                } else {
                    $el.removeClass('active').attr('aria-expanded', 'false');
                    // also close all tier 2 menus on mobile
                    if (util.isMobile())
                        setTier2MenuState(-1);
                    mobileMenuAria.unhideNonModal($el[0]);
                }
            });
        }

        function Navigation_tier1closebutton($el) {

            function setInactive() {
                setTier1MenuState(MENU_STATE_CLOSED);
                /* eslint-disable */
                if ('cmCreateElementTag' in window) {
                    cmCreateElementTag('Exit', 'FO');
                }
                /* eslint-enable */
            }

            $el.click(function () {
                if (tier1MenuState === MENU_STATE_OPEN) {
                    setInactive();
                }
            });
            $(window).on(TIER_1+MENU_STATE_CHANGED, function () {
                if (tier1MenuState !== MENU_STATE_OPEN) {
                    $el.removeClass('active').attr('aria-expanded', 'false');
                    // also close all tier 2 menus on mobile
                    if (util.isMobile())
                        setTier2MenuState(-1);
                }
            });
        }

        document.addEventListener('keydown', function(e){
            if (e.key == 'Escape'){
                var activeDropdowns = document.querySelectorAll('.user-panel.active, li.has-children.active');
                for (var i = 0; i < activeDropdowns.length; i++) {
                    activeDropdowns[i].classList.remove('active');
                }
            }
        });

        function removeMenuOpenEvents() {
            $(window).off('keydown.mobileNavMenuOpen');
            $('.menu-close').off('keydown.mobileNavOpen');
        }

        function setMenuOpenEvents(){
            removeMenuOpenEvents();
            $(window).on('keydown.mobileNavMenuOpen', function (e) {
                if (tier1MenuState === MENU_STATE_OPEN) {
                    if (e.keyCode == 27 || e.key == 'Escape') {
                        setTier1MenuState(MENU_STATE_CLOSED);
                    }
                }
            });
            $('.menu-close').on('keydown.mobileNavMenuOpen', function(e) {
                if (tier1MenuState === MENU_STATE_OPEN) {
                    if (e.keyCode == 32 || e.keyCode == 13 || e.key == ' ' || e.key == 'Enter') {
                        setTier1MenuState(MENU_STATE_CLOSED);
                    }
                }
            });
        }

        function Navigation_tier1target($el) {
            $(window).on(TIER_1+MENU_STATE_CHANGED, function() {
                if (tier1MenuState === MENU_STATE_OPEN) {
                    $el.addClass('menu-active');
                    if (util.isMobile()) {
                        $('#navigation').attr('aria-hidden', 'false');
                        setMenuOpenEvents();
                        $('nav').focus();
                    }
                } else {
                    $el.removeClass('menu-active');
                    if (util.isMobile()) {
                        $('#navigation').attr('aria-hidden', 'true');
                        removeMenuOpenEvents();
                        $('.menu-toggle').focus();

                    }
                }
            });

            // set the aria-hidden status of the menu based on device size
            function init() {
                if (util.isDesktop()) {
                    $('#navigation').attr('aria-hidden', 'false');
                    $('.header-search').hide().attr('aria-hidden', 'false');
                } else {
                    $('#navigation').attr('aria-hidden', 'true');
                    $('.header-search').hide().attr('aria-hidden', 'true');
                }
            }

            init();

            $(window).smartresize(init);
        }

        function Navigation_tier2button($el, id) {
            var self = this,
                hoverconfig = {
                    over: setHoverActive,
                    out: setHoverInactive,
                    timeout: 600,
                    sensitivity: 1,
                    interval: 20
                };

            var tier3MenuState = -1;
            self.getTier3MenuState = function() {
                return tier3MenuState;
            }
            self.setTier3MenuState = function(value) {
                if (tier3MenuState !== value) {
                    tier3MenuState = value;
                    $(window).trigger(TIER_3+MENU_STATE_CHANGED+id);
                }
            }

            function setActive() {
                setTier2MenuState(id);

                if (util.isDesktop())
                    self.setTier3MenuState('0');
            }
            function setInactive() {
                if (tier2MenuState === id)
                    setTier2MenuState(-1);
                if (util.isDesktop())
                    self.setTier3MenuState(-1);
            }

            function setHoverActive() {
                if (util.isDesktop()) {
                    setActive();
                }
            }
            function setHoverInactive() {
                if (util.isDesktop()) {
                    setInactive();
                }
            }

            $el.hoverIntent(hoverconfig);

            $el.children('a').click(function (e) {
                if (tier2MenuState !== id) {
                    setActive();
                } else {
                    setInactive();
                }
                if (util.isMobile())
                    e.preventDefault();
            });

            $el.on('keydown', function (e) {
                if (e.keyCode === 27) {
                    setInactive();
                    e.preventDefault();
                }
            });

            $el.children('a').on('keydown', function (e) {
                if (e.keyCode === 13) {
                    if (tier2MenuState !== id) {
                        setActive();
                    } else {
                        setInactive();
                    }
                    e.preventDefault();
                }
            });
            $(window).on(TIER_2+MENU_STATE_CHANGED, function() {
                if (tier2MenuState === id) {
                    $el.addClass('active');
                    $el.children('a').attr('aria-expanded', 'true');
                    if ($el.offset().top < 0  && util.isMobile()) {
                        $('nav#navigation').scrollTop($('nav#navigation').scrollTop() + $el.offset().top);
                    }
                    setInViewport($el.position().top);
                } else if (tier2MenuState !== id && tier2MenuState !== -1) {
                    $el.removeClass('active').addClass('muted');
                    $el.children('a').attr('aria-expanded', 'false');
                    $(window).trigger(MENU_STATE_CLOSEALL, id);
                } else {
                    $el.removeClass('muted active');
                    $el.children('a').attr('aria-expanded', 'false');
                    $(window).trigger(MENU_STATE_CLOSEALL, id);
                }
            });

            // create the tier 3 buttons within this subcategory context
            /* eslint-disable */
            $el.find('.nav-lvl3-button.has-children').each(function() {
                new Navigation_button($(this), self, $(this).children('.nav-lvl3, .html-slot-container'), $(this).attr('data-id'), $(this).attr('data-sectionid'));
            });
            $el.find('.category-top-offers').each(function() {
                new CategoryTopOffers($(this), self, $(this).attr('data-sectionid'));
            });
            $el.find('.actions').each(function() {
                new ExploreAllButton($(this), self, -1);
            });
            /* eslint-enable */
        }

        function Navigation_tier2target($el, id) {
            $(window).on(TIER_2+MENU_STATE_CHANGED, function() {
                if (tier2MenuState === id) {
                    $el.addClass('active');
                    setColumnWidth();
                } else {
                    $el.removeClass('active');
                }
            });

            // this function sets the column width to match that of the last item (EXPLORE ALL ...)
            // only if we are on desktop
            function setColumnWidth() {
                if (util.isDesktop()) {
                    var padding = parseInt($el.find('.actions>.heading').css('padding-left'), 10) + parseInt($el.find('.actions>.heading').css('padding-right'), 10);
                    var width = $el.find('.actions>.heading>a').width() + padding;
                    $el.find('.nav-lvl2-container>ul').width(width);
                } else {
                    $el.find('.nav-lvl2-container>ul').css('width', '');
                }
            }
        }

        function Navigation_button($el, parent, $targetEl, id, subcatId) {
            var navigationTarget = new Navigation_target($targetEl, subcatId),
                active = false,
                hoverconfig = {
                    over: setHoverActive,
                    out: setHoverInactive,
                    timeout: 600,
                    sensitivity: 1,
                    interval: 20
                };

            function setHoverActive() {
                if (util.isDesktop()) {
                    parent.setTier3MenuState(id);
                }
            }

            function setHoverInactive() {
                // do nothing
            }

            function setActive() {
                active = true;
                $el.addClass('active');
                $el.children('.heading').attr('aria-expanded', 'true');
                navigationTarget.activate();
            }
            function setInactive() {
                active = false;
                $el.removeClass('active');
                $el.children('.heading').attr('aria-expanded', 'false');
                navigationTarget.deactivate();
            }

            if (!$el.hasClass('category-top-offers')) {
                $el.hoverIntent(hoverconfig);
            }

            $el.children('.heading').click(function(e) {
                e.preventDefault();
                if (!active) {
                    if (util.isMobile())
                        setActive();
                    else
                        setHoverActive();
                } else {
                    if (util.isMobile())
                        setInactive();
                }
            });

            $(window).on(TIER_3+MENU_STATE_CHANGED+subcatId, function() {
                if (parent.getTier3MenuState() === id) {
                    setActive();
                } else {
                    setInactive();
                }
            });

            $(window).on(MENU_STATE_CLOSEALL, function(e, eventId) {
                e.preventDefault();
                if (eventId === TIER_1) {
                    setTimeout(function() {
                        setInactive();
                    }, 500);
                }
            });

            $(window).on(TIER_2+MENU_STATE_CHANGED, function() {
                if (tier2MenuState !== subcatId)  {
                    setTimeout(function() {
                        setInactive();
                    }, 500);
                }
            });
        }

        function Navigation_target($el, subcatId) {
            var self = this;

            self.activate = function() {
                $el.addClass('active');
                setColumns($el);
            }
            self.deactivate = function() {
                $el.removeClass('active');
            }

            // this function sets the column width to match that of the last item (EXPLORE ALL ...)
            // only if we are on desktop
            function setColumns($el) {
                if (util.isDesktop()) {
                    if ($el.height() > $('.nav-lvl2-button.'+subcatId+' .nav-lvl2-container').height()) {
                        $el.addClass('two-column').height($('.nav-lvl2-button.'+subcatId+' .nav-lvl2-container').height());
                    }
                } else {

                    $el.css('height', '');
                }
            }

            return self;
        }

        function CategoryTopOffers($el, parent, subcatId) {
            var tier2Width = $('.nav-lvl2-button.'+subcatId+' .nav-lvl2-container>ul').width();

            if (!$el.children('.html-slot-container').length) {
                $el.hide();
            }

            setInactive();
            convertTagging();

            function setActive() {
                setColumns($el);
                if (util.isDesktop()) {
                    var left = tier2Width + $('li[data-sectionid='+subcatId+'][data-id='+parent.getTier3MenuState()+']>ul').width();
                    $el.css('left', left);
                } else {
                    $el.css('left', '');
                }
            }

            function setInactive() {
                if (util.isDesktop()) {
                    var left = tier2Width;
                    $el.css('left', left);
                } else {
                    $el.css('left', '');
                }
            }

            $(window).on(TIER_3+MENU_STATE_CHANGED+subcatId, function() {
                if (tier2MenuState === subcatId) {
                    setActive();
                }
            });

            $(window).smartresize(function() {
                if (util.isMobile()) {
                    $el.css('left', '');
                }
            });

            // tagging in this slot is set to work in the Left Nav (LN)
            // convert 'LN' in the tagging to 'FO' for this implementation
            function convertTagging() {
                $el.find('.html-slot-container a').each(function() {
                    var tag = $(this).attr('manual_cm_sp');
                    $(this).attr('manual_cm_sp', tag.replace(new RegExp('\\bLN\\b'), 'FO'));
                });
            }

            // this function sets the column width to match that of the last item (EXPLORE ALL ...)
            // only if we are on desktop
            function setColumns($el) {
                if (util.isDesktop()) {
                    if ($el.find('.html-slot-container').height() > $el.height()) {
                        $el.addClass('two-column');
                    }
                } else {

                    $el.css('height', '');
                }
            }
        }

        function ExploreAllButton($el, parent, id) {
            var hoverconfig = {
                over: setHoverActive,
                out: setHoverInactive,
                timeout: 600,
                sensitivity: 1,
                interval: 20
            };

            function setHoverActive() {
                if (util.isDesktop()) {
                    parent.setTier3MenuState(id);
                }
            }

            function setHoverInactive() {
                // do nothing
            }

            $el.hoverIntent(hoverconfig);
        }

        function SimpleSearch($el) {
            var $cancel = $el.find('.cancel');
            var $input = $el.find('input[type=search]');
            var $form = $el.find('form');
            var cancelTimeout = 0;

            $cancel.attr('tabIndex','-1');
            $cancel.css({display:'none'});

            var enableOnSiteSearch = SitePreferences.ENABLE_ONSITE_SERACH;
            if (!enableOnSiteSearch){
                searchsuggest.init($el, Resources.SIMPLE_SEARCH);
            }

            $input.focus(function() {
                $el.addClass('active');
                clearTimeout(cancelTimeout);
                $cancel.removeAttr('tabIndex');
                $cancel.css({display:'block'})

                /* eslint-disable */
                if('cmCreateElementTag' in window){
                    cmCreateElementTag('Hamburger:Search', 'FO');
                }
                /* eslint-enable */
            });
            $input.blur(function() {
                $el.removeClass('active');
                $cancel.attr('tabIndex','-1');
                cancelTimeout = setTimeout(function(){
                    $cancel.css({display:'none'})
                },300);
            });
            $cancel.click(function() {
                $input.val('');
            });

            $form.submit(function () {
                if ($.trim($input.val())  === '' || $.trim($input.val())  === 'Search') {
                    return false;
                }
                if ($el.hasClass('menu-search')) {
                    window.localStorage.setItem('searchViewport', 'Hamburger menu search bar');
                }
            });
        }

        // sets scrollTop to target element if the element is off the top of the screen
        function setInViewport(elementTop) {
            //var navigationTop = $('#navigation').scrollTop();
            setTimeout(function() {
                if (elementTop < 0) {
                    $('#navigation').animate({scrollTop: ($('#navigation').scrollTop() + elementTop)}, 'swing');
                }
            }, 1000);
        }

        $(document).ready(function() {
            /* eslint-disable */
            new Navigation_tier1button($('.menu-toggle'));
            new Navigation_tier1closebutton($('.menu-close'));
            new Navigation_tier1closebutton($('.menu-overlay'));
            new Navigation_tier1target($('#wrapper','html'));

            $('.nav-lvl2-button.has-children').each(function() {
                new Navigation_tier2button($(this), $(this).attr('data-id'));
            });
            $('.nav-lvl2').each(function() {
                new Navigation_tier2target($(this), $(this).attr('data-id'));
            });

            new SimpleSearch($('.menu-search'));
            /* eslint-enable */
        });

        this.Navigation_tier2button = Navigation_tier2button;
    }
    window.topNavigation = new TopNavigation(jQuery);

    $('#tooltipContactUs, #tooltipPrivacyPolicy').on('click', function(e) {
        shopInApp($(this), e);
    });

    // contact us page. Switch form fields depend on question
    var questions = ['Add to Direct Mail','How to Redeem In Store Offers', 'In Store Service Experience','Corporate Policy Inquiry','Personal Injury'];
    var formIdent = $('.contact-us');
    var selectEl = formIdent.find('select[id$=_contactus_myquestion]');

    selectEl.on('change', function() {
        var url = window.location.href;
        formIdent.find('.addition-fields').empty();
        var currentQ = $(this).find('option:selected').val();
        var lengthQ = $(this).find('option:selected').text().length;
        for (var item in questions) {
            if (questions[item] == currentQ){
                progress.show(formIdent);
                formIdent.find('.addition-fields').load(url+' .addition-fields.block', function(){
                    progress.hide();
                });
            }
        }
        $('.contact-us .select-style').innerWidth(lengthQ*7+100);
    });

    var $storeLocation = $('.store-location-container .store-location');
    if ($storeLocation.length > 1) {
        $storeLocation.syncHeight();
    }

    /*Registration page*/
    var registrationForm = $('#RegistrationForm');
    if (registrationForm.find('.error-message').length > 0 && registrationForm.parents('.single-accordion').length > 0) {
        registrationForm.parents('.single-accordion')
            .find('.single-accordion-title').trigger('click');
    }

    /*landing svg height fix*/
    function fixsvg() {
        var el = $('#main .top-offers').find('img');
        var heightImg = el.removeAttr('style').height();
        el.height(heightImg + 5);
    }

    $(window).smartresize(function () {
        fixsvg();
        if ($storeLocation.length > 1) {
            $storeLocation.removeAttr('style').syncHeight();
        }
    }).load(function() {
        fixsvg();
    });

    $('body').on('mousedown', '.quantity-button', function(e){ e.preventDefault(); });

    /**
     * @listener
     * @description White barn ticker rotations
     */

    var $ticker = $('.white_burn .wb-ticker li:first-child');
    var timer = 5000;
    var classes = 'animated flipInX active';
    $ticker.addClass(classes);
    setTimeout(autoAddClass, timer);

    function autoAddClass(){
        var next = $('.white_burn .wb-ticker .active').removeClass(classes).next();
        if (next.length)
            $(next).addClass(classes);
        else
            $ticker.addClass(classes);
        setTimeout(autoAddClass, timer);
    }

    $('.csr-bopis-toggle-form').on('change', '#bopis-experience, #bopis-experience-mobile', function () {
        var bopisChecked = $(this).is(':checked');
        var url = util.appendParamsToUrl(Urls.setCsrBopisExperienceURL, {csrBopisExperienceAllowed: bopisChecked});

        $.ajax({
            url : url,
            success: function() {
                if (!window) {
                    return;
                }
                var redirectURL = window.location.href;
                if (window.pageContext && window.pageContext.ns === 'search' && $('.boipsrefinement #bopis_toggle').length > 0) {
                    redirectURL = $('.boipsrefinement #bopis_toggle').attr('data-relaxurl');
                }

                window.location.href = redirectURL;
            }
        });
    });
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
    var selectedStoreNameInput = $('header.nav-down').find('.selected-store-name');
    if (selectedStoreNameInput.length) {
        var selectedStoreName = selectedStoreNameInput.val();
        var $setStoreLink = $('header.nav-down').find('.bopis-set-store');
        var $setBopisHomePage = $('.bopisHomePage .bopis-set-store');
        if (selectedStoreName) {
            $setStoreLink.addClass('bopis-store-name').html(selectedStoreName.toLowerCase());
        } else {
            $setStoreLink.html('Set Store');
        }
        if (selectedStoreName) {
            $setBopisHomePage.addClass('bopis-store-name').html(selectedStoreName.toLowerCase());
        } else {
            $setBopisHomePage.html('Set Store');
        }
    }

    var joinLoyaltycontainer = $('.my-account.mobile-only').find('.loyalty-welcome-message .join-loyalty-mobile-container');
    var bopisJoinLoyaltyContainer = $('.utility-links-container').find('.bopis-join-loyalty-container');
    if (joinLoyaltycontainer.length && bopisJoinLoyaltyContainer.length) {
        bopisJoinLoyaltyContainer.html(joinLoyaltycontainer.html());
        joinLoyaltycontainer.remove();
    }
}

var pages = {
    loyaltysignup: require('./pages/loyaltysignup'),
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    orderconfirmation: require('./pages/checkout/orderconfirmation'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    bopislocator: require('./pages/bopislocator'),
    giftcardpage: require('./pages/giftcardpage'),
    CreateYourPassword: require('./pages/createyourpassword'),
    hppromo: require('./pages/hp-promo')
};

/**
 * @private
 * @function
 * @description Track page visits with name and url.
 */

function pageTracking() {
    var previousPageURL;
    var globalSignup = $('.global-email-dialog');
    var KEYCODE_ESC = 27;

    if (navigator.appVersion.indexOf('MSIE') != -1) {
        localStorage.setItem('previousPageUrl', window.location.href);
    }

    if (navigator.appVersion.indexOf('MSIE') != -1 && localStorage.getItem('previousPageUrl')!='null') {
        previousPageURL = localStorage.getItem('previousPageUrl');
    } else {
        previousPageURL = document.referrer;
    }

    if ('digitalData' in window && 'page' in window.digitalData) {
        window.digitalData.page.previousPageURL = previousPageURL;
        window.digitalData.page.previousPageName = localStorage.previousPageName;
    }

    if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page && 'pageID' in window.digitalData.page.pageInfo) {
        localStorage.setItem('previousPageName', window.digitalData.page.pageInfo.pageID);
    }

    // Email subscription lightbox tracking
    if (globalSignup.length && (globalSignup.css('display') === 'block')) {
        if ('digitalData' in window && 'page' in window.digitalData) {
            window.digitalData.page.lightboxpopup = 'open' || '';
        }

        $(document).on('keyup', function(e) {
            if (e.keyCode == KEYCODE_ESC) {
                if ('digitalData' in window && 'page' in window.digitalData) {
                    window.digitalData.page.lightboxpopup = 'close' || '';
                }
            }
        });

        $('body').on('click', '.global-email-dialog .ui-icon-closethick', function() {
            if ('digitalData' in window && 'page' in window.digitalData) {
                window.digitalData.page.lightboxpopup = 'close' || '';
            }
        });
    }

    // Search tracking feature/AnalyticsImpl
    if ('searchViewport' in window.localStorage) {
        if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
            window.digitalData.page.pageInfo.searchViewport = window.localStorage.searchViewport;
        }
        delete window.localStorage.searchViewport;
    }

    // BOPIS unavailable product error tracking
    if ($('#pdpMain .bopis-pfs-with-store-container .availability-oos').length) {
        if ('digitalData' in window && 'page' in window.digitalData && 'attributes' in window.digitalData.page) {
            window.digitalData.page.attributes.storeAvailabilityError = true;
        }
    } else if ($('#pdpMain .bopis-pfs-with-store-container .bopis-product-available').length && window.digitalData.page.attributes.storeAvailabilityError) {
        if ('digitalData' in window && 'page' in window.digitalData && 'attributes' in window.digitalData.page) {
            delete window.digitalData.page.attributes.storeAvailabilityError;
        }
    }

    //Start of Product Finding Types tracking
    if ('digitalData' in window) {
        window.digitalData.pft = window.digitalData.pft || {};
    }
    //add event handler for assets type links
    $('body').on('click', 'a[manual_cm_re]', function() {
        localStorage.cmre = $(this).attr('manual_cm_re');
    });

    //add event handler for sp type links
    $('body').on('click', 'a[manual_cm_sp]', function() {
        var $this = $(this);
        if ($this.closest('.refinements').length) {
            localStorage.refinements = $this.attr('manual_cm_sp');
        } else if ($this.closest('#navigation').length) {
            localStorage.navigation = $this.attr('manual_cm_sp');
        } else if ($this.closest('.breadcrumb').length) {
            localStorage.breadcrumb = $this.attr('manual_cm_sp');
        }
    });

    //add datalayer values if exists in localstorage
    if (localStorage.cmre) {
        if ('digitalData' in window && 'pft' in window.digitalData) {
            window.digitalData.pft.asset = localStorage.cmre;
        }
        delete localStorage.cmre;
    }
    if (localStorage.refinements) {
        if ('digitalData' in window && 'pft' in window.digitalData) {
            window.digitalData.pft.leftNav = localStorage.refinements;
        }
        delete localStorage.refinements;
    }
    if (localStorage.navigation) {
        if ('digitalData' in window && 'pft' in window.digitalData) {
            window.digitalData.pft.flyout = localStorage.navigation;
        }
        delete localStorage.navigation;
    }
    if (localStorage.breadcrumb) {
        if ('digitalData' in window && 'pft' in window.digitalData) {
            window.digitalData.pft.breadcrumb = localStorage.breadcrumb;
        }
        delete localStorage.breadcrumb;
    }
    //End of Product Finding Types tracking

    //set localstorage value for POS signin trigger
    if ('digitalData' in window && 'user' in window.digitalData && 'attributes' in window.digitalData.user && 'loyaltySignupPOS' in window.digitalData.user.attributes) {
        localStorage.posFlagForSignin = true;
    }
}

function updatePageID() {
    var lastPartURL = window.location.href.split('/').pop(),
        trimQSURL = lastPartURL.split('?')[0],
        breadcrumbItem = $('div.breadcrumb a:last-child').text();

    switch (trimQSURL) {
        case 'my-account':
            if ('digitalData' in window && 'user' in window.digitalData && 'attributes' in window.digitalData.user && window.digitalData.user.attributes.customer_status === 'anonymous') {
                if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
                    window.digitalData.page.pageInfo.pageID = 'Sign In or Sign Up';
                }
            } else if ('digitalData' in window && 'user' in window.digitalData && 'attributes' in window.digitalData.user && window.digitalData.user.attributes.customer_status === 'logged in') {
                if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
                    window.digitalData.page.pageInfo.pageID = 'My Account';
                }
            }
            break;
        case 'order-status':
            if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
                window.digitalData.page.pageInfo.pageID = 'Order Tracking';
            }
            break;
        case 'reset':
            if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
                window.digitalData.page.pageInfo.pageID = 'Reset Password';
            }
            break;
        case 'Account-SetNewPasswordConfirm':
            if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
                window.digitalData.page.pageInfo.pageID = 'Success Password Reset';
            }
            break;
        case 'wallet':
            if ('digitalData' in window && 'user' in window.digitalData && 'attributes' in window.digitalData.user && window.digitalData.user.attributes.customer_status === 'logged in') {
                if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
                    window.digitalData.page.pageInfo.pageID = 'My Rewards';
                }
            }
            break;
        default: break;
    }

    if (lastPartURL === 'my-account?signin=true') {
        if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
            window.digitalData.page.pageInfo.pageID = 'My Account';
        }

        //set POS signin flag
        if ('posFlagForSignin' in localStorage) {
            if ('digitalData' in window && 'user' in window.digitalData && 'attributes' in window.digitalData.user) {
                window.digitalData.user.attributes.posSignin = true;
            }
            delete localStorage.posFlagForSignin;
        }
    }

    if (breadcrumbItem.length && breadcrumbItem === 'Create an Account') {
        if ('digitalData' in window && 'page' in window.digitalData && 'pageInfo' in window.digitalData.page) {
            window.digitalData.page.pageInfo.pageID = 'Create An Account';
        }
    }
}

function sessionDialogInit() {
    timeoutDialog({
        sessionExpiredCallback: function () {
            logoutCustomer(window.Urls.logoutCustomer).then(function(result) {
                if (result.success == true) {
                    $('#timeout-question').html('Heads up! Your session has ended.<br/>Please sign back in to pick up where you left off.').css('color', '#CC0000');
                    $('#timeout-continue span').html('Sign In');
                    $('#timeout-message').html('');
                    $('a#timeout-continue').attr('href', window.Urls.accountLanding);
                } else {
                    $('#timeout-question').html('Heads up! Your session has ended.<br/>Any unsaved information may have been lost. Please refresh the page to continue.').css('color', '#CC0000');
                    $('#timeout-continue span').html('Refresh');
                    $('#timeout-message').html('');
                }
            });
        }
    });
}

function logoutCustomer(url) {
    return new Promise(function(resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var r = JSON.parse(this.responseText);
            resolve(r);
        };
        xhr.onerror = reject;
        xhr.open('GET', url);
        xhr.send();
    });
}

if ('SitePreferences' in window && 'ORDERGROOVE_ENABLED' in window.SitePreferences && window.SitePreferences.ORDERGROOVE_ENABLED && 'OG' in window && 'getOptins' in window.OG) {
    var autoRefreshTracking = {
        updateATBButton: function (selector) {
            selector.forEach(function(item) {
                var dataTealium = JSON.parse(item.dataset.tealium);
                var productId = dataTealium.id;
                if (window.OG.getOptins().some(function (optin) {
                    if (optin.product === productId) {
                        return true;
                    } else {
                        return false;
                    }
                })) {
                    dataTealium.auto_refresh = 'y';
                } else {
                    dataTealium.auto_refresh = 'n';
                }
                item.dataset.tealium = JSON.stringify(dataTealium);
            });
        }
    }
    window.autoRefreshTracking = autoRefreshTracking;
}

var app = {
    init: function () {
        /**
           if cookies is empty and browser not compatibilty for cookies we are triggering a massage
        */
        if (!navigator.cookieEnabled && document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        hoverIntent.init();
        initializeDom();
        initializeEvents();

        // init specific global components
        monitate.init()
        countries.init();
        tooltip.init();
        validator.init();
        rating.init();
        searchplaceholder.init();
        emaildialog.init();
        ingredients.init();
        iroll.init();
        customercare.init();
        pages.bopislocator.init();
        videotracking.init();
        fragrance.init();

        if (!window.User.isShopInApp) {
            sessionDialogInit();
        }

        pageTracking();
        updatePageID();

        // BBWRKS-7231 added flag check
        if ('SitePreferences' in window && 'ORDERGROOVE_ENABLED' in window.SitePreferences && window.SitePreferences.ORDERGROOVE_ENABLED) {
            $('.bopis-shipping-option').click();
        }

        if ($('#email-dialog').length > 0) {
            emaildialog.init();
        }

        if (calanding) {
            var landingpage = $('#canada-lp').length > 0;

            // make sure that we are not on a landing page,
            // that we have not seen the popup,
            // that we are in CA or that the bypass is enabled,
            // and that the feature is enabled
            if (!landingpage &&
                    !window.SessionAttributes.LP_POPUP_COMPLETE &&
                    (window.User.geolocation.countryCode === 'CA' || window.SitePreferences.CA_LANDING_GEOIP_BYPASS) &&
                    window.SitePreferences.CA_LANDING_INTERCEPTOR_ENABLED) {
                calanding.popup();
            } else {
                // we are on a landing page, make sure session var is set and load page JS
                // none of the above conditions apply here because if a user ends up here, the page should work
                if (landingpage) {
                    $.getJSON(Urls.languageSetSessionVar + '?lppopupcomplete=true', function () {
                        // no op necessary
                    });
                    calanding.page();
                }
            }
        }

        var activeOnDemandHeader = $('header').length > 0 && $('.checkout-header').length == 0 && $('.pt_order-confirmation').length == 0 && $('header').hasClass('stickyOn');

        if (activeOnDemandHeader) {
            var elSelector        = 'header',
                $element        = $(elSelector);

            if (!$element.length) return true;

            var elHeight        = 0,
                elTop           = 0,
                $body           = $('body'),
                $window         = $(window),
                wScrollCurrent  = 0,
                wScrollBefore   = 0,
                wScrollDiff     = 0;

            $window.on('scroll', function(){
                elHeight        = $element.outerHeight();
                wScrollCurrent  = $window.scrollTop();
                wScrollDiff     = wScrollBefore - wScrollCurrent;
                elTop           = parseInt($element.css('top'), 10) + wScrollDiff;

                $element.addClass('header-fixed');
                $body.css('padding-top', elHeight);

                if (wScrollCurrent <= 0) // scrolled to the very top; element sticks to the top
                    $element.css('top', 0);

                else if (wScrollDiff > 0) // scrolled up; element slides in
                    $element.css('top', elTop > 0 ? 0 : elTop);

                else if (wScrollDiff < 0) {// scrolled down

                    if (-elHeight < elTop)// scrolled down; element slides out
                        $element.css('top', elTop);
                }

                wScrollBefore = wScrollCurrent;
            });
        }

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        pages.account.initHeaderRewardsSlot();


        tealium.init();

        //private sale page view call
        if ('digitalData' in window && window.digitalData.pageInstanceID === 'private-sale') {
            if (window.digitalData.page.attributes.privateSale) {
                window.cmCreatePageviewTag('private-sale', 'category', '', '', '-_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_-' + window.digitalData.page.attributes.inLoyaltyMarket);
            }
            if ($('.private-sale-login').length) {
                window.cmCreatePageviewTag('signin-private-sale', 'account', '', '', '-_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_--_-' + window.digitalData.page.attributes.inLoyaltyMarket);
            }
        }

        // Suppress live chat link for shop app users
        if (window.User.isShopInApp) {
            $('#myFootLinkContainer').hide();
        }

        if (document.querySelectorAll('.add-to-cart:not(:disabled)').length && 'SitePreferences' in window && 'OG' in window && 'autoRefreshTracking' in window && 'ORDERGROOVE_ENABLED' in window.SitePreferences && window.SitePreferences.ORDERGROOVE_ENABLED) {
            window.autoRefreshTracking.updateATBButton(document.querySelectorAll('.add-to-cart:not(:disabled)'));
        }
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();

    if (window.SessionAttributes.IS_REQUEST_IN_AVAILABLE_BOPIS_MARKET){
        if (!getCookie('allow_geolocation')){
            getlocationprompt();
        }
    }
    if ($('.order-confirmation-details').length === 0) {
        $('.confirmation_item_indicator').hide();
    }

    if ($('.order-summary-table').length > 0) {
        $('.freq_show').removeClass('display-none');
    }

    $('.order-confirmation-details .product-list-item .frequency_block').each(function() {
        if (SitePreferences.ORDERGROOVE_ENABLED) {
            $(this).find('.confirmation_item_indicator').hide();
            var attr = $(this).find('.jatc-freq').attr('frequency');
            if (typeof attr!== 'undefined' && attr !== false) {
                $(this).find('.confirmation_item_indicator').show();
            } else {
                $(this).find('.confirmation_item_indicator').hide();
            }
        }
    });

    $('.item-list.order-summary-table .product-list-item .frequency_block').each(function() {
        var attr = $(this).find('.jatc-freq').attr('frequency');
        if (typeof attr!== 'undefined' && attr !== false) {
            $(this).next('.confirmation_shipping_icon').hide();
        } else {
            $(this).next('.confirmation_shipping_icon').show();
        }
    });

    visualNavigation();
    smoothScroll();
    setupSlick($);
    setUpBrightcoveVideos();
    setupFulfillment();
    accordionSetup();
    internationalModalDialog();
    setUpModalDialog.modalDialogSetup();
    modal.init();

    //Only load mini cart content and run mini cart javascript on desktop
    if (util.isDesktop()) {
        loadMiniCart();
    } else {
        $(window).on('resize.miniCartLoad', _.debounce(function () {
            if (util.isDesktop()) {
                loadMiniCart();
                $(window).off('resize.miniCartLoad');
            }
        }, 250));
    }

    var keyboardTab = {
        previous: $(),
        current: $()
    }
    $('header :tabbable').on('focus', function (e) {
        keyboardTab.previous = keyboardTab.current;
        keyboardTab.current = $(e.currentTarget);

        // Minicart check
        if (keyboardTab.previous.closest('#mini-cart').length && !keyboardTab.current.closest('#mini-cart').length) {
            minicart.close();
        }
    });

    kibo.sendKiboData('pageView');

    var pagesWithRecommendations = ['product', 'cart', 'search', 'home', 'category'];
    var pageInstance = window && window.digitalData && window.digitalData.pageInstanceID ? window.digitalData.pageInstanceID : '';
    if (pagesWithRecommendations.includes(pageInstance) && SitePreferences.KIBO_CONTAINER_ENABLED) {
        kiboRecommendations.addRecommendations();
    }

    recommendationsModal.listenForFifthClickEvent();

    var pagesWithQtySelector = ['home', 'search', 'category'];
    if (pagesWithQtySelector.includes(pageInstance)) {
        quantitySelector.checkIfItemInCart();
    }

    bazaarVoiceCookieConsent.getCookieConsentAndSetBvConsent();
    if (SitePreferences.ONETRUST_DNS_SUBMISSION_ENABLED) {
        oneTrust.oneTrustDNSSubmit();
    }
});

function loadMiniCart() {
    if (!minicart.isLoaded) {
        var miniCartContentUrl = $('.mini-cart-total').data('minicart-content-url');
        if (miniCartContentUrl) {
            $.get(miniCartContentUrl).done(function(res){
                $('#mini-cart').append(res);
                minicart.init();
            });
        }
    }
}

/**BBWRKS-2532 geolocation prompt */
function getlocationprompt() {
    var geoOptions = {
        maximumAge: Infinity,
        enableHighAccuracy: false
    }
    var geoSuccess = function () {
        document.cookie = 'allow_geolocation=true;';
    };
    var geoError = function () {
        document.cookie = 'allow_geolocation=false;';
    };

    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions);
}
function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function addAccordionRole() {
    var accordionChevron = document.querySelector('.ds-accordion-heading .ds-chevron-icon');
    if ((accordionChevron) && (window.getComputedStyle(accordionChevron).display !== 'none')) {
        var accordionHeadings = document.getElementsByClassName('ds-accordion-heading');
        for (var i=0; i < accordionHeadings.length; i++){
            accordionHeadings[i].setAttribute('role', 'button');
        }
    }
}

window.addEventListener('DOMContentLoaded', addAccordionRole);
