'use strict';

var dialog = require('../../dialog'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    addToCart = require('./addToCart'),
    image = require('./image'),
    imagesLoaded = require('imagesloaded'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    recommendations = require('./recommendations'),
    variant = require('./variant'),
    wishlist = require('../wishlist'),
    certona = require('../../certona'),
    bopisProductDetails = require('./bopisProductDetails'),
    productQuantityUpdate = require('./pdpQuantityUpdate'),
    ordergroove = require('../../../../int_ordergroove/cartridge/client/default/js/pages/product/pdp'),
    autoRefreshOffer = require('./autoRefreshOffer'),
    stickybag = require('./stickyBag'),
    variantSizeSelection = require('./variantSizeSelection'),
    loyaltyExclusive = require('../../loyalty-exclusive'),
    socialCommerce = require('./socialCommerce');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    recommendations();
    tooltip.init();

    $('.fa-remove.close').click(function() {
        $('.og-tooltip-content').hide();
    });

    $('.tooltip-icon').click(function() {
        $('.og-tooltip-content').show();
    });
}

/**
 * @description Track bopis status on load.
 */
function bopisDeliveryOptions() {
    var bopisOption = $('.bopis-delivery-options input:checked').val(),
        notForPickup = $('.bopis-not-for-pickup'),
        i;

    var productLength = window && window.digitalData && window.digitalData.product && window.digitalData.product.length;

    for (i = 0; i < productLength; i++) {
        if (bopisOption === 'shipping') {
            window.digitalData.product[i].bopis = 'n';
        } else {
            window.digitalData.product[i].bopis = 'y';
        }

        if (notForPickup.length) {
            window.digitalData.product[i].bopis = 'n';
        }
    }
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    variantSizeSelection.initVariantSizeSelection();
    addToCart();
    variant();
    image();
    productSet();
    bopisProductDetails();
    productQuantityUpdate();
    wishlist.init();
    bopisDeliveryOptions();
    ordergroove();
    socialCommerce.updateSocialCommerceVisibility();
    var loyaltyExclusiveItems = document.querySelectorAll('.loyalty-exclusive');
    loyaltyExclusive.updateLoyaltyExclusiveButtons(loyaltyExclusiveItems);

    if (SitePreferences.DISPLAY_STICKY_ADD_TO_BAG) {
        stickybag.initStickyBag();
    }

    if (SitePreferences.ORDERGROOVE_ENABLED) {
        autoRefreshOffer();
        $('.bopis-shipping-option').click();

        var bopisOption = $('.bopis-pick-from-store-option'),
            shipitOption = $('.bopis-shipping-option'),
            pdpOGOffer = $('og-offer.pdp-offer'),
            pdpOGOfferToggleButton = pdpOGOffer.find('.toggle'),
            qvdialogOGOffer = $('#QuickViewDialog .quickview-offer'),
            qvdialogOGToggle = qvdialogOGOffer.find('.toggle');

        if (bopisOption.length) {
            bopisOption.click(function() {
                if (pdpOGOffer.height() > 0) {
                    pdpOGOffer.addClass('overlay');

                    if (pdpOGOfferToggleButton.filter('[subscribed]').length > 0) {
                        pdpOGOfferToggleButton.click();
                    }
                }
            });
        }

        if (shipitOption.length) {
            shipitOption.click(function() {
                if (pdpOGOffer.height() > 0) {
                    pdpOGOffer.removeClass('overlay');
                }
            });
        }

        if ($('.ui-dialog.quickview').css('display') == 'block') {
            if (bopisOption.length) {
                bopisOption.click(function() {
                    if (qvdialogOGOffer.height() > 0) {
                        qvdialogOGOffer.addClass('overlay');
                    }

                    if (qvdialogOGToggle.filter('[subscribed]').length > 0) {
                        qvdialogOGToggle.click();
                    }
                });
            }

            if (shipitOption.length) {
                shipitOption.click(function() {
                    if (qvdialogOGOffer.length) {
                        qvdialogOGOffer.removeClass('overlay');
                        $('.bopis-delivery-options span.bopis-recurring').remove();
                    }
                });
            }
        }
    }

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('.quantity-button.decrease').attr('aria-disabled', true);

    setTimeout(function () {
        $('.wish-list-link').removeAttr('role');
        $('.product-primary-image').find('a').attr('tabindex','-1');
    }, 500);

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });


    $('.quantity-field').on('mouseup', '.input-text', function () {
        $(this).select();
    });

    var $pcnam = $('.primary-content .not-available-msg');
    if ($pcnam.length) {
        var productForm = $pcnam.parents().find('.pdpForm').first();
        productForm.find('.product-add-to-cart').hide();
    }

    certona.init();

    //Init carousel
    $(window).on('load', function () {
        if ($('.horizontal-carousel').children().length > 1) {
            $('.horizontal-carousel').owlCarousel({
                loop: false,
                responsiveClass: true,
                mouseDrag: true,
                navSpeed: 1000,
                navRewind: false,
                dots: true,
                autoHeight: true,
                onResized: callback,
                onInitialized: callback,
                responsive: {
                    0: {
                        center: true,
                        items: 2
                        /*margin: 30*/
                    },
                    768: {
                        nav: true,
                        items: 4,
                        margin: 30,
                        stagePadding: 50,
                        mouseDrag: false
                    }
                }
            });
        }
        $('#shipping-restriction-accordion').find('button').attr('role','button');
    });
}
function callback() {
    var $tiles = $('.horizontal-carousel .product-tile');
    $tiles.find('.recommendation_image').syncHeight();
    $tiles.find('.product-name').syncHeight();
    $tiles.find('.sub-attribute').syncHeight();
}

if ('SitePreferences' in window && 'ORDERGROOVE_ENABLED' in window.SitePreferences && window.SitePreferences.ORDERGROOVE_ENABLED && 'OG' in window && 'addOptinChangedCallback' in window.OG) {
    var updateAutoRefreshTracking = function(selector, autoRefreshValue) {
        var dataTealium = JSON.parse(selector.dataset.tealium);
        dataTealium.auto_refresh = autoRefreshValue;
        selector.dataset.tealium = JSON.stringify(dataTealium);
    };
    var toggleAutoRefreshTracking = function(addToBagButton, event) {
        addToBagButton.each(function(index, button) {
            if (event.optedIn) {
                updateAutoRefreshTracking(button, 'y');
            } else {
                updateAutoRefreshTracking(button, 'n');
            }
        });
    };
    window.OG.addOptinChangedCallback(
        function(event) {
            if ($('form input[name="pid"][value="' + event.productId + '"]').closest('form').find('.add-to-cart:not(:disabled)').length) {
                toggleAutoRefreshTracking($('form input[name="pid"][value="' + event.productId + '"]').closest('form').find('.add-to-cart:not(:disabled)'), event);
            }

            //BBWRKS-7353 Added back-end productLineItem update on AR check.
            var ARUpdateBasketURL = Urls.ARUpdateBasket;
            progress.show('#pdpMain');
            $.ajax({
                type : 'POST',
                url : ARUpdateBasketURL,
                dataType : 'json',
                data: {'ARObject': JSON.stringify(event)}
            }).done(function() {
                progress.hide();
            });
        }
    );
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
      //product info accordion init
        var icons = {
            header: 'iconClosed',
            activeHeader: 'iconOpen'
        };

        $('#pdp-accordion').accordion({
            heightStyle: 'content',
            collapsible: true,
            icons: icons
        });

        $('#shipping-restriction-accordion').accordion({
            heightStyle: 'content',
            active: false,
            collapsible: true,
            icons: {
                header: 'iconOpen',
                activeHeader: 'iconClosed'
            }
        });
        $('dd.ui-accordion-content').attr('role', 'region');
        $('#pdp-accordion').find('h3').on('click keyup', function(e){
            //fixes known bug where aria-expanded doesn't toggle with jQuery accordion
            if (!$(this).hasClass('ui-state-active') || (!$(this).hasClass('ui-state-active') && e.keyCode == 32)){
                $(this).attr('aria-expanded', 'false');
            }
        });
        $('#shipping-restriction-accordion').find('button').on('click keyup', function(e){
            //fixes known bug where aria-expanded doesn't toggle with jQuery accordion
            if (!$(this).hasClass('ui-state-active') || (!$(this).hasClass('ui-state-active') && e.keyCode == 32)){
                $(this).attr('aria-expanded', 'false');
            }
        });
        var $tiles = $('.horizontal-carousel .product-tile');
        if ($tiles.length === 0) { return; }
        imagesLoaded('.horizontal-carousel').on('done', function () {
            $tiles.syncHeight()
                .each(function (idx) {
                    $(this).data('idx', idx);
                });
        });
    }
};
module.exports = product;
