'use strict';

var progress = require('./progress'),
    util = require('./util');

var loyalty = {
    init: function() {
        loyalty.showHide('order');
        loyalty.showHide('shipping');
        loyalty.removeCoupon();
    },
    showHide: function (discountType) {
        $(document).on('click', 'a.showall-' + discountType, function (e) {
            e.preventDefault();
            var link = $(this),
                items = $('.discount.loyalty-' + discountType).not('.header');

            if (items.is(':visible')) {
                link.text(Resources.HIDE_ALL);
            } else {
                link.text(Resources.SHOW_ALL);
            }
            link.parent().toggleClass('discounts-hidden');
            items.toggle();
        });
    },
    removeCoupon: function () {
        $(document).on('click keyup', '.coupon-container .remove', function (e) {
            if (!$(this).closest('.cart-coupon-code-wrap').length) {
                var key = e.which || e.keyCode;

                // Keycode 13 -> Enter
                if (e.type !== 'click' && key !== 13) {
                    return;
                }

                var couponCode = $(this).data('couponcode'),
                    isLoyalty = $(this).data('isloyalty'),
                    listContainer = $(this).closest('.coupon-list').parent();

                $.post(Urls.removeCouponCode, {code: couponCode}, function (data) {
                    if (data.success) {
                        if (window.pageContext.ns == 'cart') {
                            window.location.href = window.Urls.cartShow;
                        } else {
                            $('#coupon-' + couponCode).slideUp();
                            loyalty.updateSummaryBilling();
                            var url = util.appendParamsToUrl(Urls.appliedCouponsList, {isLoyalty: isLoyalty})
                            listContainer.load(url, function (){
                                listContainer.fadeIn('fast');
                            });
                        }

                        var couponData = {
                            couponCode: couponCode.toUpperCase()
                        }
                        window._satellite.track('checkout-remove-coupon', couponData);
                    }
                })
            }
        });
    },
    /**
     * @function
     * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
     */
    updateSummaryCart: function() {
        var $summary = $('.cart-order-totals');
        // indicate progress
        progress.show($summary);

        // load the updated summary area
        $summary.load(Urls.updateCartSummary, function () {
            // hide edit shipping method link
            $summary.fadeIn('fast');
        });
    },
    /**
     * @function
     * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
     */
    updateSummaryBilling: function() {
        var $summary = $('#secondary.summary');
        // indicate progress
        progress.show($summary);

        // load the updated summary area
        $summary.load(Urls.summaryRefreshURL, function () {
            // hide edit shipping method link
            $summary.fadeIn('fast');
            $summary.find('.checkout-mini-cart .minishipment .header a').hide();
            $summary.find('.order-totals-table .order-shipping .label a').hide();
        });
    }
}

module.exports = loyalty;
