'use strict';

var stickyBag = {
    initStickyBag: function() {
        /* eslint-disable */
        var stickyBagContainer = document.querySelector('.product-add-to-cart');
        var body = document.querySelector('body');
        var observer = new IntersectionObserver(handleIntersection);
        document.querySelectorAll('[data-hide-sticky]').forEach(function (target) {
            observer.observe(target);
        });
        function handleIntersection(entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
                    stickyBagContainer.classList.remove('sticky');
                    stickyBagContainer.classList.add('translateY');
                    if (body.classList.contains('hide-chat-bubble')) {
                        body.classList.remove('hide-chat-bubble');
                    }
                } else {
                    stickyBagContainer.classList.add('sticky');
                    body.classList.add('hide-chat-bubble');
                    if (stickyBagContainer.classList.contains('translateY')) {
                        setTimeout(function(){
                            stickyBagContainer.style.transition = 'transform .25s';
                            stickyBagContainer.classList.remove('translateY');

                            setTimeout(function(){
                                stickyBagContainer.style.transition = 'transform 0s';
                           }, 300);
                       }, 300);
                    }
                }
            })
        }
        /* eslint-enable */
    }
}

module.exports = stickyBag;

