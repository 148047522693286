/*jslint browser:true */
/*global document,google,console */
/*
 * All java script logic for google driven store locator.
 *
 * The code relies on the jQuery JS library to
 * be also loaded.
 *
 */

'use strict';

var MarkerWithLabel = require('./marker-with-label');

var StoreLocator = {
    // configuration parameters and required object instances
    initialLocation:	null,
    browserSupportFlag:	false,
    markerurl:			null,
    activemarkerurl:    null,
    queryurl:			null,
    cookieurl:			null,
    cookiename:			null,
    defaultlocation:	null,
    zoomradius:			{},
    markers:			[],
    infowindows:		[],
    radius:				50,
    map:				null,
    unit:				'km',
    timer:				null,
    maptype:			null,
    LatLngList:			[],
    isDetails:			($('.pt_store-locator-details').length) ? true : false,

    /*********************************************************
     * initialize the google map
     * @param - zoomradius : json object with radius settings for each google zoom level (0-20)
     * @param - markerurl : url for marker image
     * @param - queryurl : url for querying nearest stores
     * @param - cookieurl : url for setting preferred location cookie
     * @param - cookiename : name for preferred location cookie
     * @param - defaultlocation : default address for map if users geolocation can not be detected
     * @param - maptype : type of google map to display
     **********************************************************/

    init: function (zoomradius, markerurl, activemarkerurl, queryurl, cookieurl, cookiename, defaultlocation, maptype) {

        this.zoomradius = zoomradius;
        this.markerurl = markerurl;
        this.activemarkerurl = activemarkerurl;
        this.queryurl = queryurl;
        this.cookieurl = cookieurl;
        this.cookiename = cookiename;
        this.defaultlocation = defaultlocation;
        this.maptype = maptype;

        // Define the options for the Google Maps object
        var myOptions = {
                zoom: 7,
                mapTypeId: google.maps.MapTypeId[maptype],
                scrollwheel: false,
                scaleControl: false,
                disableDefaultUI: true,
                zoomControl: true,
                styles: [{'featureType':'landscape','stylers':[{'hue':'#FFBB00'},{'saturation':43.400000000000006},{'lightness':37.599999999999994},{'gamma':1}]},{'featureType':'road.highway','stylers':[{'hue':'#FFC200'},{'saturation':-61.8},{'lightness':45.599999999999994},{'gamma':1}]},{'featureType':'road.arterial','stylers':[{'hue':'#FF0300'},{'saturation':-100},{'lightness':51.19999999999999},{'gamma':1}]},{'featureType':'road.local','stylers':[{'hue':'#FF0300'},{'saturation':-100},{'lightness':52},{'gamma':1}]},{'featureType':'water','stylers':[{'hue':'#0078FF'},{'saturation':-13.200000000000003},{'lightness':2.4000000000000057},{'gamma':1}]},{'featureType':'poi','stylers':[{'hue':'#00FF6A'},{'saturation':-1.0989010989011234},{'lightness':11.200000000000017},{'gamma':1}]}]
            },
            self = this;

        // Create a new Google Maps object with the given options
        this.map = new google.maps.Map(document.getElementById('map-canvas'), myOptions);
        
        // Render Store Detail
        if ($('#storedetails-wrapper').size() > 0) {
            StoreLocator.getSearchPosition('onestore');
            // Check for cookie preference
        } else if (this.getCookieLatLng()) {
            this.initialLocation = this.getCookieLatLng();
            this.map.setCenter(this.initialLocation);
            // Try Demandware Geolocation
        } else if (window.User.geolocation) {
            this.initialLocation = new google.maps.LatLng(window.User.geolocation.latitude, window.User.geolocation.longitude);
            this.renderStores(window.User.geolocation.latitude, window.User.geolocation.longitude, $('#country').val(), $('#distanceunitpref').val(), $('#distance').val(), false, true);
            $('#address').val(User.geolocation.city);
            // Try W3C Geolocation (preferred for detection)
        } else if (google.loader.ClientLocation) {
            this.initialLocation = new google.maps.LatLng(google.loader.ClientLocation.latitude, google.loader.ClientLocation.longitude);
            this.map.setCenter(this.initialLocation);
            // Try Google Gears Geolocation
        } else if (google.gears) {
            this.browserSupportFlag = true;
            var geo = google.gears.factory.create('beta.geolocation');
            geo.getCurrentPosition(function (position) {
                this.initialLocation = new google.maps.LatLng(position.latitude, position.longitude);
                this.map.setCenter(this.initialLocation);
            }, function () {
                this.handleNoGeoLocation(this.browserSupportFlag);
                $('#stores').html('');
            });
            // Browser doesn't support Geolocation so geolocate the default
        } else {
            this.browserSupportFlag = false;
            this.handleNoGeolocation();
            $('#stores').html('');
        }
        function findGo() {
            StoreLocator.getSearchPosition();
        }

        $('#store-search-form').on('submit', function () {
            findGo();
            return false;
        });

        $('.refine-search .input-checkbox').on('change', function() {
            findGo();
            return false;
        });

        $('#distance').change(function () {
            if ($('#distance').val() !== '') {
                StoreLocator.radius = $('#distance').val();
            } else {
                StoreLocator.radius = StoreLocator.zoomradius[self.map.getZoom()];
            }
        });
        // hide maps on mobile by default
        $('#map-canvas').addClass('hidden');

        // Update the bounds of the store locator when the browser is resized
        $(window).smartresize(function () {
            if (StoreLocator.markers.length) {
                StoreLocator.updateBounds();
            }
        });
        
        var attempts = 0;
        
        var intTimer = setInterval(function () {
            var $mapcanvas = $('#map-canvas');
            if ($mapcanvas.find('iframe').length > 0) {
                clearInterval(intTimer);
                setTimeout(function(){
                    if ($mapcanvas.children('div').length > 1) {
                        $('#map-canvas-overlay p').show();
                    } else {
                        $('#map-canvas-overlay').fadeOut();
                    }
                }, 1000);
            } else if (attempts > 9) {
                clearInterval(intTimer);
            }
            attempts++;
        }, 1000);
        
    },

    /*********************************************************
     * function to close all open google InfoWindow objects
     **********************************************************/

    closeInfoWindows: function () {
        for (var i in this.infowindows) {
            if (typeof this.infowindows[i] === 'object') {
                this.infowindows[i].close();
            }
        }
        //reset icon to default for all markers
        for (var j in this.markers) {
            if (typeof this.markers[j] === 'object') {
                this.markers[j].setIcon(this.markerurl);
                var number = parseFloat(1000+j);
                this.markers[j].setZIndex(number)
            }
        }
    },

    /*********************************************************
     * function to create and position google Markers and
     * InfoWindows for a result set of Stores
     * @param - stores : a json object containing stores
     * @param - map : the map
     **********************************************************/
    populateStores: function (stores, noLoc, location, initLoad) {
        var noLocation	= noLoc || false,
            thisLoc		= location || null,
            image		= this.markerurl,
            activeimage	= this.activemarkerurl,
            distance	= 0,
            storeCount	= 0,
            hoverOffset = 80000,
            newStyle	= 0,
            storeLatLng	= '',
            self = this;

        // Function that fix borders
        function fixBorder () {

            $('.gm-style-iw').parent().css('background','#fff');
            var iwOuter = $('.gm-style-iw');
            var iwBackground = iwOuter.prev();

            // fix arrow
            iwBackground.children(':nth-child(1)').css({'border' : 'none'});

            // Remove the background shadow DIV
            iwBackground.children(':nth-child(2)').css({'display' : 'none'});

            // Remove the white background DIV
            iwBackground.children(':nth-child(4)').css({'display' : 'none'});
            iwBackground.children(':nth-child(3)').find('div').children().addClass('fixarrow');
            iwBackground.children(':nth-child(3)').find('div:nth-child(1)').children().css('transform','skewX(45deg)');
            iwBackground.children(':nth-child(3)').find('div:nth-child(2)').children().css('transform','skewX(-45deg)');

            //set InfoWindow down
            iwOuter.parent().css('margin-top','10px');
        }
        // Function that is called when a map marker is clicked
        function markerClick (storeid, marker) {
            return function () {
                StoreLocator.closeInfoWindows();
                StoreLocator.infowindows[storeid + 'Info'].open({map:self.map, anchor:self.marker});

                fixBorder();
                marker.setIcon(activeimage);
                $('#' + storeid + ' .headinfo').click();
                $('.mapContent').parent().parent().parent().parent().addClass('mapContent-parent');
                marker.setZIndex(google.maps.Marker.MAX_ZINDEX);
            };
        }

        // Function that is called when a store's link is clicked
        function storeClick () {
            StoreLocator.closeInfoWindows();
            var storeid = $(this).find('a').attr('class'),
                infowindow = StoreLocator.infowindows[storeid + 'Info'];
            infowindow.open({map:self.map, anchor:self.marker, shouldFocus: false});
            self.map.setCenter(infowindow.position);
            fixBorder();
            for (var j in self.markers) {
                if (typeof self.markers[j] === 'object') {
                    if (self.markers[j].storeid == storeid) {
                        self.markers[j].setIcon(activeimage);
                        self.markers[j].setZIndex(google.maps.Marker.MAX_ZINDEX);
                    }
                }
            }
        }

        if (this.markers.length > 0) {
            for (var j in this.markers) {
                if (typeof this.markers[j] === 'object') {
                    this.markers[j].setMap(null);
                    //var number = parseFloat(1000+j);
                    //this.markers[j].setZIndex(number);
                }
            }
            this.markers.length = 0;
        }
        this.closeInfoWindows();
        this.infowindows.length = 0;
        this.LatLngList = [];
        $('#stores').addClass('noStores').html('<div class="stores-container"></div>');

        //create array of store IDs and add distance if available
        var storesArray = [];
        for (var thisStore in stores) {
            var tempArray = [thisStore];
            if (!noLocation) {
                if (thisLoc && stores[thisStore].latitude && stores[thisStore].longitude) { //calculate distance from search location
                    storeLatLng = new google.maps.LatLng(stores[thisStore].latitude, stores[thisStore].longitude);
                    distance	= google.maps.geometry.spherical.computeDistanceBetween(thisLoc, storeLatLng, 3959).toFixed(1);
                    tempArray.push(distance);
                }
            }
            storesArray.push(tempArray);
        }

        //if we have stores with distances we sort the array of store IDs
        if (storesArray.length && storesArray[0].length > 1) {
            storesArray.sort(function (a, b) {return a[1] - b[1];});
        }

        for (var i = 0; i < storesArray.length; i++) {
            var store = storesArray[i][0];

            storeCount++;

            //format the address
            var formattedAddress = '';
            formattedAddress = (stores[store].address1) ? formattedAddress + stores[store].address1 : formattedAddress;
            formattedAddress = (stores[store].city) ? formattedAddress + ', ' + stores[store].city : formattedAddress;
            formattedAddress = (stores[store].stateCode)  ? formattedAddress + ', ' + stores[store].stateCode : formattedAddress;
            formattedAddress = (stores[store].postalCode) ? formattedAddress + ' ' + stores[store].postalCode : formattedAddress;

            //URL encode the address
            var encodedAddress = encodeURIComponent(formattedAddress);

            if (!noLocation) {
                //build the store info HTML for right column
                var storeinfo = '<div class="store" id="' + store + '">';


                var secondaryName = stores[store].storeSecondaryName;
                if (secondaryName !== '') {
                    secondaryName = '(' + secondaryName + ')';
                }

                storeinfo += '<h3 class="headinfo">';
                storeinfo += '<div class="storename"><span>'+ storeCount +'.</span><a class="'+ store +'" href="javascript:void(0)"><span class="primaryName">' + stores[store].name + '</span> <span class="secondaryName">' + secondaryName + '</span></a></div>';
                if (thisLoc && stores[store].latitude && stores[store].longitude) { //calculate distance from search location
                    storeLatLng = new google.maps.LatLng(stores[store].latitude, stores[store].longitude);
                    distance	= google.maps.geometry.spherical.computeDistanceBetween(thisLoc, storeLatLng, 3959).toFixed(1);
                    storeinfo += '<div class="distance"><span>Distance </span>' + distance + ' ' + $('#distanceunitpref').val() + '</div>';
                }
                storeinfo += '</h3><div class="bodyinfo">';
                storeinfo += '<div class="distance content-distance"><span>Distance </span>' + distance + ' ' + $('#distanceunitpref').val() + '</div>';
                storeinfo += '<div class="address1">' + stores[store].address1 + '</div>';
                storeinfo += '<div class="address2">' + stores[store].address2 + '</div>';
                storeinfo += '<div class="cityStateZip">' + stores[store].city + ', ' + stores[store].stateCode + ' ' + stores[store].postalCode + '</div>';
                storeinfo += '<div class="phone">' + stores[store].phone + '</div>';
                storeinfo += '<div class="directions"><a href="https://maps.google.com/maps?daddr=' + encodedAddress + '" class="directionslink" target="_blank">Get Directions</a></div>';
                storeinfo += '<div class="events">' + stores[store].storeEvents + '</div>';
                storeinfo += '<ul class="hours">' + stores[store].storeHours + '</ul>';
                storeinfo += '</div></div>';
                $('#stores .stores-container').append(storeinfo);
                if ($('.map-view').hasClass('active')) {
                    $('.stores-container').css('margin-top','-15px');
                }
            }

            newStyle = parseFloat(hoverOffset + 1);

            var icon = {
                url: image, // url
                size: new google.maps.Size(30, 34),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(15, 34)
            };
            var markerOptions = {
                position: new google.maps.LatLng(stores[store].latitude, stores[store].longitude),
                map: this.map,
                title: stores[store].name + ' ' + stores[store].address1 + ' ' + stores[store].city + ', ' + stores[store].stateCode + ' ' + stores[store].postalCode + stores[store].phone,
                icon: icon,
                labelStyle: {zIndex:newStyle},
                zIndex: newStyle,
                storeid: store
            };

            if (!noLocation) {
                $.extend(markerOptions, {
                    labelContent: storeCount,
                    labelAnchor: (storeCount > 9) ? new google.maps.Point(9, 30) : new google.maps.Point(5, 30),
                    labelClass: 'markerLabel',
                    labelStyle: {zIndex:newStyle},
                    labelInBackground: false,
                    zIndex: newStyle
                });
            }

            //create map marker object
            var marker = new MarkerWithLabel(markerOptions);
            marker.setZIndex(hoverOffset);
            hoverOffset += 2;

            marker.setMap(this.map);
            this.markers.push(marker);

            if (!noLocation || (noLocation && stores[store].countryCode === 'US')) {
                //add store's coordinates to array for setting zoom and centering map later
                StoreLocator.LatLngList.push(marker.position);
            }

            //build the store info HTML for tooltip
            var contentString = '<div class="mapContent">' +
                '<h1>' + stores[store].name + '</h1>' +
                '<div class="contentBody"><div>' +
                stores[store].address1 + '</div><div>' +
                stores[store].address2 + '</div><div>' +
                stores[store].city + ', ' +
                stores[store].stateCode + ' ' +
                stores[store].postalCode +
                '</div><div class="phone">' + stores[store].phone +'</div>';

            contentString += '<p><a target="_blank" href="https://maps.google.com/maps?daddr=' + encodedAddress + '">Get Directions</a></p></div></div>';

            var infowindowPosition = -35;
            if ($('#storedetails-wrapper').size() > 0) {
                infowindowPosition = -5;
            }

            //populate store info into tooltip object
            StoreLocator.infowindows[store + 'Info'] = new google.maps.InfoWindow({
                content: contentString,
                position: marker.position,
                maxWidth: 265,
                pixelOffset: new google.maps.Size(0, infowindowPosition, 'px', 'px')
            });

            google.maps.event.addListener(marker, 'click', markerClick(store, marker));
            google.maps.event.addListener(StoreLocator.infowindows[store + 'Info'], 'closeclick', function(){
                for (var j in self.markers) {
                    if (typeof self.markers[j] === 'object') {
                        self.markers[j].setIcon(image);
                        var number = parseFloat(1000+j);
                        self.markers[j].setZIndex(number);
                    }
                }
            });

            $('#' + store + ' .headinfo').on('click', storeClick);

            if ($('#storedetails-wrapper').size() > 0) {
                var storeId = $('#storeId').val();
                if (storeId == store) {
                    StoreLocator.infowindows[storeId + 'Info'].open({
                        map: this.map,
                        shouldFocus: false
                    });
                }
            }
        }

        if (!noLocation && storeCount > 0) {
            //var addressMessage = null;

            /*if (customerAddresses.length > 0) {
             if (!isNaN(customerAddresses)) {
             addressMessage = 'zip code ' + customerAddresses;
             } else {
             addressMessage = customerAddresses;
             }
             } else if (stores[Object.keys(stores)[0]].postalCode.length > 0) {
             addressMessage = 'zip code ' + stores[Object.keys(stores)[0]].postalCode;
             } else {
             addressMessage = stores[Object.keys(stores)[0]].name;
             }*/

            var storetext = (storeCount > 1) ? 'There are ' +storeCount + ' stores near you'
                    : 'There is ' +storeCount + ' store near you ',
                titleString = '<div class="stores-header">' + storetext + '</div>';
            $('#stores').removeClass('noStores').prepend(titleString);

            //app.scrollbar.init('.stores-container', null);
        }

        if (initLoad && storeCount < 1) {
            $('#stores').addClass('no-stores').html('<div class="stores-header no-stores">Oops! let&#39;s try again</div><div class="stores-container"><div class="store empty">Check to make sure the City & State or ZIP Code are correct.</div></div>');
            $('#address-error').show().text('Try again! Enter a valid City & State or ZIP Code.');
            $('.mobile-only .stores-header').html($('.stores-wrapper .stores-header').clone());
            this.map.setCenter(location);
        } else {
            StoreLocator.updateBounds();
        }

        var $firstStore = $('.stores-container .store').eq(0);
        $firstStore.addClass('active');
        $firstStore.find('a').trigger('click').trigger('focus');
        $('.stores-container .headinfo').on('click', function(){
            if (($(this).parent().index() == 0) && ($('.stores-container .store:eq(0)').hasClass('active'))) {
                $('.stores-container .store:eq(0)').removeClass('active');
            } else {
                $(this).parent().closest().removeClass('active');
                if ($(this).parent().hasClass('active')) {
                    $(this).parent().removeClass('active');
                } else {
                    $('.stores-container .store').removeClass('active');
                    $(this).parent().addClass('active');
                }

            }
        });
        //$('#map-canvas').addClass('hidden');
        $('.list-view').on('click', function(e){
            e.preventDefault();
            $('#map-canvas').addClass('hidden');
            $('#map-canvas-overlay').addClass('hidden');
            $(this).addClass('active');
            $(this).prev().removeClass('active');
            $('.stores-container').css('margin-top','-285px');
        });
        $('.map-view').on('click', function(e){
            e.preventDefault();
            $('#map-canvas').removeClass('hidden');
            $('#map-canvas-overlay').removeClass('hidden');
            $(this).addClass('active');
            $(this).next().removeClass('active');
            $('.stores-container').css('margin-top','-15px');
        });

        $('.stores-container .store').each(function(){
            var hours = $(this).find('.hours');
            if (hours.text().length !== 0) {
                var hoursText = hours.html();
                var lines = hoursText.split('<br>');
                var phrase = Array();
                var line;
                for (line in lines) {
                    var subline = lines[line].split(':');
                    switch (subline[0]) {
                        case 'MON':
                            subline[0] = 'Monday';
                            break;
                        case 'TUES':
                            subline[0] = 'Tuesday';
                            break;
                        case 'WED':
                            subline[0] = 'Wednesday';
                            break;
                        case 'THURS':
                            subline[0] = 'Thursday';
                            break;
                        case 'FRI':
                            subline[0] = 'Friday';
                            break;
                        case 'SAT':
                            subline[0] = 'Saturday';
                            break;
                        case 'SUN':
                            subline[0] = 'Sunday';
                            break;
                        default :
                    }
                    var lineItem;
                    switch (subline.length) {
                        case 1:
                            break;
                        case 3:
                            lineItem = '<li class="days">' +subline[0]+ '<span class="time">' +subline[1]+':'+subline[2]+'</span>' + '</li>';
                            phrase.push(lineItem);
                            break;
                        case 4:
                            lineItem = '<li class="days">' +subline[0]+ '<span class="time">' +subline[1]+':'+subline[2]+':'+subline[3]+'</span>' + '</li>';
                            phrase.push(lineItem);
                            break;
                        default :
                            lineItem = '<li class="days">' +subline[0]+ '<span class="time">' +subline[1]+'</span>' + '</li>';
                            phrase.push(lineItem);
                    }
                }
                hours.html(phrase);
            }
        });
        $('.store.empty, .stores-wrapper .stores-header.no-stores').hide();
        if (screen.width < 767) {
            $('.mobile-only .stores-header').html($('.stores-wrapper .stores-header'));

            //accardion height head fix
            $('.stores-container .store').each(function(){
                var height = $(this).find('.primaryName').height();
                var storeCount = $(this).find('.storename > span').text().length;
                if (height > 25) {
                    $(this).css('max-height', '130px').find('.distance').css('padding-top', '20px');
                }
                if (storeCount > 3) {
                    $(this).find('.storename > span').css('width', '25px');
                    $(this).find('.distance').css('padding-left', '12px');
                }

            });
        }
        $('#address').trigger('blur').removeClass('error').next('#address-error').hide();


    },

    updateBounds: function () {
        //  Create a new viewpoint bound
        var bounds = new google.maps.LatLngBounds();
        //  Go through each...
        for (var i = 0, LtLgLen = StoreLocator.LatLngList.length; i < LtLgLen; i++) {
            //  And increase the bounds to take this point
            bounds.extend(StoreLocator.LatLngList[i]);
        }
        //  Fit these bounds to the map
        this.map.fitBounds(bounds);
        // Set zoom
        //if (this.map.getZoom() >= 17) { this.map.setZoom(16); }
    },

    /*********************************************************
     * function to collect search data and retrieve a position
     **********************************************************/

    getSearchPosition: function (type) {
        var address = $('#address').val(),
            radius  = $('#distance').val();
        function scrollToStoreTiles() {
            if (screen.width < 767) {
                $('html, body').animate({scrollTop: $('.stores-header').offset().top - 200},800);
            }
        }
        if ($.trim(address) !== '') {
            this.geoCode(address, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    var location = results[0].geometry.location,
                        lat		= location.lat(),
                        lng		= location.lng();
                    if (type === 'onestore') {
                        var storeId = $('#storeId').val();
                        StoreLocator.renderOneStore(storeId, lat, lng, $('#country').val(), $('#distanceunitpref').val(), radius);
                    } else {
                        StoreLocator.renderStores(lat, lng, $('#country').val(), $('#distanceunitpref').val(), radius);
                    }
                    $('.zero-result').hide();
                    scrollToStoreTiles();
                } else {
                    //window.console.error('Geocode was not successful for the following reason: ' + status);
                    $('.zero-result').show().text('We couldn\'t find a store near you. Try again');
                }
            });
        }
    },

    /*********************************************************
     * function to perform a google geocode (address -> LatLng)
     * @param - address : an address string to geocode
     * @param - callback : a callback function to handle the result
     **********************************************************/

    geoCode: function (address, callback) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': address}, function (results, status) { callback(results, status);});
    },

    /*********************************************************
     * function to perform a nearest stores query
     * @param - zip : a postal code
     * @param - country : a country code
     * @param - unit : a distance unit (mi/km)
     * @param - radius : the radius to display stores from
     **********************************************************/

    renderStores: function (latitude, longitude, country, unit, radius, noLoc, initLoad) {
        var requestMessage = {
            'latitude': latitude,
            'longitude': longitude,
            'countryCode': country,
            'distanceUnit': unit,
            'maxdistance': radius
        };
        var refineSearch = ['BBW','BBWO','WBC'];
        for (var i = 0; i < refineSearch.length; i++) {
            var storeID = refineSearch[i];
            if ($('#' + storeID).prop('checked') === true) {
                requestMessage[storeID] = 1;
            }
        }
        var xhr = $.getJSON(
            this.queryurl,
            requestMessage,
            function (data) {
                var size = 0,
                    key;
                for (key in data.stores) {
                    if (data.stores.hasOwnProperty(key)) { size++; }
                }

                if (size > 0 || initLoad) {
                    var location = new google.maps.LatLng(latitude, longitude);
                    var milesAround = $('#distance').val();
                    var customerAddresses = $('#address').val();
                    StoreLocator.populateStores(data.stores, noLoc, location, initLoad, milesAround, customerAddresses);
                } else {
                    StoreLocator.handleNoGeolocation();
                    $('#stores').addClass('no-stores').html('<div class="stores-header no-stores">Oops! let&#39;s try again</div><div class="stores-container"><div class="store empty">Check to make sure the City & State or ZIP Code are correct.</div></div>');
                    $('#address-error').show().text('Try again! Enter a valid City & State or ZIP Code.');
                    $('.mobile-only .stores-header').html($('.stores-wrapper .stores-header').clone());
                }
            }
        );

        return xhr;
    },

    /*********************************************************
     * function to perform a nearest stores query
     * @param - zip : a postal code
     * @param - country : a country code
     * @param - unit : a distance unit (mi/km)
     * @param - radius : the radius to display stores from
     **********************************************************/

    renderOneStore: function (storeId, latitude, longitude, country, unit, radius, noLoc, initLoad) {
        var self = this,
            xhr = $.getJSON(
                this.queryurl,
                {'latitude': latitude, 'longitude': longitude, 'countryCode': country, 'distanceUnit': unit, 'maxdistance': radius},
                function (data) {
                    var size = 0,
                        key;
                    for (key in data.stores) {
                        if (data.stores.hasOwnProperty(key)) { size++; }
                    }
                    if (size > 0 || initLoad) {
                        var store = {};
                        store[storeId] = data.stores[storeId];

                        var location = new google.maps.LatLng(latitude, longitude);
                        var milesAround = $('#distance').val();
                        var customerAddresses = $('#address').val();
                        StoreLocator.populateStores(store, noLoc, location, initLoad, milesAround, customerAddresses);
                        self.map.setZoom(16);
                    } else {
                        StoreLocator.handleNoGeolocation();
                        $('#stores').html('');
                    }
                }
            );

        return xhr;
    },

    /*********************************************************
     * function to perform a reverse geocode (LatLng -> address)
     * @param - position : the google LatLng position
     * @param - callback : a callback function to handle the results
     **********************************************************/

    reverseGeocode: function (position, callback) {
        var geocoder = new google.maps.Geocoder();
        var location = geocoder.geocode({'latLng': position}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                callback(results);
            } else {
                // debug google requests
                //console.error('Geocoder failed due to: ' + status);
            }
        });

        return location;
    },

    /*********************************************************
     * function handles case where geodata can't be found
     **********************************************************/

    handleNoGeolocation: function () {
        if (this.markers.length > 0) {
            for (var i in this.markers) {
                if (typeof this.markers[i] === 'object') {
                    this.markers[i].setMap(null);
                }
            }
            this.markers.length = 0;
            this.closeInfoWindows();
        }
    },

    /*********************************************************
     * function to parse cookie value and instantiate LatLng object
     **********************************************************/

    getCookieLatLng: function () {
        if (!this.readCookie(this.cookiename)) { return null; }
        var position = this.readCookie(this.cookiename).split(',');
        var latlngpref = new google.maps.LatLng(position[0], position[1]);
        return latlngpref;
    },

    /*********************************************************
     * function read cookie value
     * @param - name : name of the cookie to retrieve value for
     **********************************************************/

    readCookie: function (name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
            if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length + 1, c.length - 1); }
        }
        return null;
    }
}; // end storelocator

module.exports = StoreLocator;
