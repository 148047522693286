'use strict';
module.exports = function ($) {
    var $designSystemCarousels = $('.ds-carousel');

    var slickCarouselSwipeAnalyticsCall = function (direction) {
        if (direction == 'right') {
            if ('_satellite' in window) window._satellite.track('leftswipe');
        } else if (direction == 'left') {
            if ('_satellite' in window) window._satellite.track('rightswipe');
        }
    }

    function removeSkeletonClass() {
        $(this).closest('.ds-loading-skeleton').removeClass('ds-loading-skeleton');
    }

    function setupSingleSlick($carousel) {
        var $carouselParent = $carousel.parent();

        $carousel.on('init', removeSkeletonClass);

        $carousel.slick({
            arrows: true,
            nextArrow: $carouselParent.find('.ds-carousel-next-button button'),
            prevArrow: $carouselParent.find('.ds-carousel-prev-button button'),
            appendDots: $carouselParent.find('.ds-carousel-dots'),
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 300,
            cssEase: 'cubic-bezier(0.19, 1, 0.22, 1)',
            infinite: false
        }).on('swipe', function (event, slick, direction) {
            window.digitalData.page.modulename = 'carousel';
            slickCarouselSwipeAnalyticsCall(direction);
        });
    }

    $designSystemCarousels.each(function () {
        var $carousel = $(this);
        setupSingleSlick($carousel);
    });

}
