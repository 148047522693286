'use strict';

var dialog = require('./dialog');

function initializeEvents() {
    var searchIngredients = $('.ingredients-search-results');
    if (!$.trim($('.ingredients-search-results').html())) {
        $('#qi').focus(); // focus the search bar
    } else {
        setTimeout(function() {
            $('.ingredients-h1').focus();
        }, 1000);
    }
    if (searchIngredients.length > 0) {
        if ($('.accordion').data('first-page') === true && localStorage.getItem('collapsibleState') !== 'false') {
            $('.accordion').accordion({
                collapsible: true,
                active: 0,
                autoHeight: false,
                heightStyle: 'content',
                disabled: true,
                header: '.accordion-header',
                create: function() {
                    $('.ui-accordion-header-icon').first().toggleClass('iconOpen');
                    $('.item-ingredient').first().toggleClass('active');
                    $('.ui-accordion-header-icon').each(function() {
                        var ariaText = $(this).closest('.accordion-header').find('.ingredient-name').text() + $(this).closest('.accordion-header').find('.ingredient-type').text()
                        $(this).attr('aria-label', ariaText);
                        $(this).attr('tabindex', 0);
                    });
                }
            });
        } else {
            $('.accordion').accordion({
                collapsible: true,
                active: false,
                autoHeight: false,
                heightStyle: 'content',
                disabled: true,
                header: '.accordion-header'
            });
        }
        $('.accordion .accordion-header.ui-accordion-header').click(function() {
            var _this = $(this);
            $('.ui-accordion-header-icon', _this).toggleClass('iconOpen');
            _this.next().slideToggle();
            _this.parent().toggleClass('active');
            setTimeout(setCollapsibleState(_this.parent()),500);
            return false;
        });
        $('.accordion .accordion-header.ui-accordion-header').click(function () {
            var isAllOpen = true;
            $('.accordion .item-ingredient').each(function () {
                isAllOpen = isAllOpen && $(this).hasClass('active');
            });
            if (isAllOpen) {
                $('.accordion-collapse-all').show();
                $('.accordion-expand-all').hide();
            } else if (!$('.accordion-expand-all').is(':visible')) {
                $('.accordion-collapse-all').hide();
                $('.accordion-expand-all').show();
            }
        });
        $('.accordion-expand-all').click(function () {
            var headers = $('.accordion .accordion-header.ui-accordion-header');
            $('.ui-accordion-header-icon', headers).addClass('iconOpen');
            headers.next().slideDown();
            $('.accordion-collapse-all').show().focus();
            $(this).hide();
            $('.accordion .item-ingredient').each(function () {
                $(this).addClass('active');
                setTimeout(setCollapsibleState($(this)),500);
            });
        });
        $('.accordion-collapse-all').hide().click(function () {
            var headers = $('.accordion .accordion-header.ui-accordion-header');
            $('.ui-accordion-header-icon', headers).removeClass('iconOpen');
            headers.next().slideUp();
            $('.accordion-expand-all').show().focus();
            $(this).hide();
            $('.accordion .item-ingredient').each(function () {
                $(this).removeClass('active');
                setTimeout(setCollapsibleState($(this)),500);
            });
        });
        // initialize ADA 
        $('.accordion-expand-all, .accordion-collapse-all, .accordion-header, .ui-accordion-header-icon').on('keydown', function (e) {
            var code = e.keyCode || e.which;
            if (code == '13') {
                $(this).click();
                return false; // stop propagation of nested elements
            }
        });
        $('.accordion-header').attr('tabIndex', '0');
        // Initialize ILN Dialog
        $('.what-is-this').on('click', function (e) {
            e.preventDefault();
            dialog.open({
                target: $('#dialog-iln'),
                url: this.href,
                options: {
                    width: 570,
                    dialogClass: 'dialog-ingredients-iln',
                    open: function () {
                        var $modal = $('.dialog-ingredients-iln');
                        $modal.attr('aria-modal','true'); 
                        $('.top-banner, .breadcrumb, footer, .under-footer').attr('aria-hidden','true');
                        $('.under-footer iframe').hide();
                    },
                    close: function() {
                        $('.top-banner, .breadcrumb, footer, .under-footer').attr('aria-hidden','false');
                        $('.under-footer iframe').show(); 
                    }
                }
            });
            $('.ui-dialog-titlebar-close').attr('title', 'close window');
        });
        $(document).on('click', '.ui-widget-overlay', function () {
            dialog.close();
        });
    }

    disableSearchIcon();
    
    var searchForm = $('.ingredients-search');
    if (searchForm.length > 0) {
        if (searchIngredients.length === 0) {
            setTimeout(function () {
                searchForm.find('input[type="search"]').focus();
            });
        }
        searchForm.find('button[type="reset"]').on('click', function (e) {
            if (searchForm.hasClass('results')) {
                e.preventDefault();
                searchForm.removeClass('results');
                searchForm.find('input[type="search"]').val('').focus();
            }
        });
        searchForm.find('input[type="search"]').on('keyup', function () {
            if (searchForm.hasClass('results') && $(this).val().length === 0) {
                searchForm.removeClass('results');
            }
        })

        // handle aria attribute updates on click of an individual section
        $('.accordion .accordion-header.ui-accordion-header').on('click keyup', function(){
            if (!$(this).parent().hasClass('active')){
                $(this).attr('aria-expanded', 'false');
                $(this).attr('aria-selected', 'false');
                $(this).parent().children('.accordion-body').attr('aria-hidden', 'true');
            } else {
                $(this).attr('aria-expanded', 'true');
                $(this).attr('aria-selected', 'true');
                $(this).parent().children('.accordion-body').attr('aria-hidden', 'false');
            }
        });

        // handle aria attribute updates on click of the expand/collapse all buttons
        $('.category-header a').on('click keyup', function(){
            $($(this).parent().parent().find('div.item-ingredient').children('.accordion-header')).each(function(index, value) {
                if (!$(value).parent().hasClass('active')){
                    $(value).attr('aria-expanded', 'false');
                    $(value).attr('aria-selected', 'false');
                    $(value).parent().children('.accordion-body').attr('aria-hidden', 'true');
                } else {
                    $(value).attr('aria-expanded', 'true');
                    $(value).attr('aria-selected', 'true');
                    $(value).parent().children('.accordion-body').attr('aria-hidden', 'false');
                }
            });
        });

        $(document).on('click', '#button-submit', function() {
            localStorage.setItem('collapsibleState', 'true');
        });
        
    }
}

function setCollapsibleState(item) {
    if (item.data('index') === 1) {
        localStorage.setItem('collapsibleState', item.hasClass('active') ? 'true' : 'false');
    }
}

function disableSearchIcon() {
    var searchInput = $('#qi');
    searchInput.on('change', function() {
        if (searchInput.val().length>0) {
            $('#button-submit').removeAttr('disabled');
        } else {
            $('#button-submit').attr('disabled', 'disabled');
        }
    })
}

var ingredients = {
    init: function () {
        initializeEvents();
        if ($('.pt_ingredients').length === 0) {
            localStorage.setItem('collapsibleState', 'true');
        }
    }
}

module.exports = ingredients;
