'use strict'

function passHideShow() {
    var hideShowButton = $('.hide-show'), hideShowSpan = hideShowButton.find('span');

    hideShowButton.show();
    hideShowSpan.addClass('show');
    hideShowButton.off('click.hide-show-password').on('click.hide-show-password', function () {
        var hideShowThisButton = $(this), hideShowThisSpan = hideShowThisButton.find('span');

        if (hideShowThisSpan.hasClass('show')) {
            hideShowThisSpan.text('Hide');
            hideShowThisButton.attr('aria-label', 'Hide Password');
            hideShowThisButton.siblings('input[name$="password"], input[name$="currentpassword1"]').attr('type', 'text').focus();
            hideShowThisSpan.removeClass('show');
        } else {
            hideShowThisSpan.text('Show');
            hideShowThisButton.attr('aria-label', 'Show Password');
            hideShowThisButton.siblings('input[name$="password"], input[name$="currentpassword1"]').attr('type', 'password').focus();
            hideShowThisSpan.addClass('show');
        }
    });
    $('form button[type="submit"]').on('click', function () {
        hideShowSpan.text('Show').addClass('show');
        hideShowButton.attr('aria-label', 'Show Password');
        hideShowButton.parent().find('input[name$="_password"]').attr('type', 'password');
    });
}

module.exports = passHideShow;
