/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var tealium = require('../tealium');

var giftcardpage = {
    init: function () {
        $('#pdpMain').off('click', '#AddToBasketButton', giftcardpage.cartAdd);
        $('#pdpMain').on('click', '#AddToBasketButton', giftcardpage.cartAdd);
    },
    cartAdd: function () {
        var button = $(this);
        var data = button.data('ibm');
        var price = $('#giftcard-details-form').find('select[name$="purchase_amount"]').val();
        if (price === '') {
            return '';
        }

        //gift cards always have qty 1
        var qty = '1';
        var cartData = {
            'cart_product_id': [],
            'cart_product_price': [],
            'cart_product_quantity': [],
            'cart_product_sku': [],
            'cart_total_items': '0',
            'cart_total_value': '0'
        };

        if ($('.tealium-cart-data').size() > 0) {
            cartData = $('.tealium-cart-data').data('tealium');
        }
        tealium.eventCount += 1;
        var obj = {
            'cart_product_id' : cartData.cart_product_id,
            'cart_product_price' : cartData.cart_product_price,
            'cart_product_quantity' : cartData.cart_product_quantity,
            'cart_product_add_location' : window.digitalData.page.type,
            'cart_product_sku' : cartData.cart_product_sku,
            'cart_total_items' : cartData.cart_total_items + '',
            'cart_total_value' : cartData.cart_total_value + '',
            'ecommerce_action' : 'Cart Add',
            'event_category' : 'Product',
            'event_action' : 'Cart Add',
            'event_label' : 'Cart Add',
            'event_name' : 'cart_add',
            'event_value' : tealium.eventCount + '',
            'product_brand': '',
            'product_category': [data.category.primaryCategory],
            'product_id': [data.productInfo.productID],
            'product_name': [data.productInfo.productName],
            'product_image_url': [data.productInfo.productImage],
            'product_original_price': [price],
            'product_price': [price],
            'product_promo_code': '',
            'product_quantity': [qty],
            'product_sku': [data.productInfo.productID],
            'product_subcategory': '',
            'product_discount_amount': ''
        };
        tealium.triggerEvent(obj);
        
        data.quantity = qty;
        data.price = price;
        window.digitalData.cart.item.push(data);
        window.cmAddToCart();
    }
}

module.exports = giftcardpage;

