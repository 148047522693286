'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    addedProductsView = require('../../justaddtocart'),
    addedProductsViewRecommendations = require('../../justaddtocartrecommendations'),
    page = require('../../page'),
    util = require('../../util'),
    TPromise = require('promise'),
    _ = require('lodash'),
    tealiumProduct = require('../../tealium/pages/product'),
    kibo = require('../../kibo'),
    snackbarQueue = [],
    showSnackbar = true;

function addItemToCart(form) {
    var requestData = getCartUpdateRequestData(form);
    return updateCart(requestData);
}

function updateCartItemQuantity(form) {
    var requestData = getCartUpdateRequestData(form) + '&setQty=true';
    return updateCart(requestData);
}

function getCartUpdateRequestData(form) {
    var $form = $(form);
    var $qty = $form.find('input[name="Quantity"]');
    var requestData = $form.serialize();
    var frequencyCounter;
    var frequencyTime;
    var isAutoRefreshSubscribedItem;
    var pid = $form.find('input#pid').val() !== undefined ? $form.find('input#pid').val() : $form.find('input[name="pid"]').val();

    if (window.OG) {
        $(window.OG.getOptins()).each(function() {
            if (this.product == pid) {
                frequencyCounter = this.tracking_override.every;
                frequencyTime = this.tracking_override.every_period;
                isAutoRefreshSubscribedItem = true;
            }
        });
    }

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }

    if ('pageInstanceID' in window.digitalData && 'home' == window.digitalData.pageInstanceID){
        requestData = requestData.concat('&piID='+window.digitalData.pageInstanceID);
    }

    if (frequencyCounter != null || frequencyTime != null ||  isAutoRefreshSubscribedItem != null) {
        requestData = requestData.concat('&frequencyCounter='+frequencyCounter + '&frequencyTime='+frequencyTime + '&isAutoRefreshSubscribedItem='+isAutoRefreshSubscribedItem);
    }

    requestData = requestData.concat('&pageInstanceId='+window.digitalData.pageInstanceID);

    return requestData;
}

function updateCart(requestData) {
    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: requestData
    }));
}


var updateCartQuantity = function ($form) {
    updateCartItemQuantity($form).then(function (response) {
        if (SitePreferences.ENABLE_JATC_SNACKBARS && $form.find('#Quantity.input-text').val() != $form.find('input[name*="purchaseLimit"]').val() - 1) {
            initSnackbar(response, false);
        }
        $('#mini-cart').html(response);
        minicart.init();
        tealiumProduct.addToCartButton = $(this);
        tealiumProduct.cartAdd();
        kibo.sendKiboData('nonPageView');
    }.bind(this));
};

function addSnackbarToQueue(snackbarText, fulfillmentType, successOrError, snackbarTimer) {
    var newSnackbar = {snackbarText: snackbarText, fulfillmentType: fulfillmentType, successOrError: successOrError, snackbarTimer: snackbarTimer};
    snackbarQueue.push(newSnackbar);
}

function addSnackbarToDom (snackbarText, fulfillmentType, successOrError, snackbarTimer) {
    var $gridContainer = $('body');
    $gridContainer.append('<div class="jact-snackbar jact-snackbar-' + successOrError + '"><span>' + snackbarText + fulfillmentType + '</span></div>');

    $('.jact-snackbar').fadeIn(400);
    $('.jact-snackbar').delay(snackbarTimer).fadeOut(400, function() {
        $('.jact-snackbar').remove();
        if (snackbarQueue.length > 0) {
            snackbarQueue.shift();
            if (snackbarQueue.length > 0) {
                addSnackbarToDom(snackbarQueue[0].snackbarText, snackbarQueue[0].fulfillmentType, snackbarQueue[0].successOrError, snackbarQueue[0].snackbarTimer);
            }
        }
    });
}

function isSnackbarDuplicate (fulfillmentType) {
    var isDuplicate = false;
    for (var i = 1; i < snackbarQueue.length; i++) {
        if (snackbarQueue[i].successOrError == 'success' && snackbarQueue[i].fulfillmentType == fulfillmentType) {
            if (isNaN(snackbarQueue[i].snackbarText.charAt(0))) {
                snackbarQueue[i].snackbarText = '2 ' + snackbarQueue[i].snackbarText.slice(0, 4) + 's' + snackbarQueue[i].snackbarText.slice(4);
            } else {
                var countIndex = snackbarQueue[i].snackbarText.indexOf('I') - 1,
                    currentCount = Number(snackbarQueue[i].snackbarText.slice(0, countIndex)) + 1;

                snackbarQueue[i].snackbarText = currentCount + snackbarQueue[i].snackbarText.slice(countIndex);
            }
            isDuplicate = true;
            break;
        }
    }
    return isDuplicate;
}

function createSnackbar (snackbarText, fulfillmentType, successOrError, snackbarTimer) {
    var snackbarExists = $('.jact-snackbar').length > 0,
        queueSnackbar = false;
    if ($('.jact-snackbar-error').length > 0) {
        queueSnackbar = true;
    }

    if (snackbarQueue.length > 0 || queueSnackbar) {
        if (successOrError == 'error' || snackbarQueue.length == 1) {
            addSnackbarToQueue(snackbarText, fulfillmentType, successOrError, snackbarTimer);
        } else {
            var isDuplicate = isSnackbarDuplicate(fulfillmentType);
            if (!isDuplicate) {
                addSnackbarToQueue(snackbarText, fulfillmentType, successOrError, snackbarTimer);
            }
        }
    } else {
        if (snackbarExists) {
            $('.jact-snackbar').fadeOut(400, function() {
                $('.jact-snackbar').remove();
                addSnackbarToDom(snackbarText, fulfillmentType, successOrError, snackbarTimer);
            });
        } else {
            addSnackbarToDom(snackbarText, fulfillmentType, successOrError, snackbarTimer);
        }
    }
}

function initSnackbar (response, justAdded, isGiftCard) {
    if (showSnackbar){
        var $response = $(response),
            $miniCart = $response.find('.mini-cart-products'),
            newQty = $miniCart.data('newqty'),
            newFulfillment = $miniCart.data('newfulfillment');

        if (newQty > 0 && justAdded) {
            if (isGiftCard) {
                var isEGiftCard = ' ';
                if (newFulfillment == 'egc') {
                    isEGiftCard = ' E-';
                }
                createSnackbar('$' + newQty + isEGiftCard + Resources.SNACKBAR_GIFTCARD_SUCCESS_MESSAGE, '', 'success', SitePreferences.SNACKBAR_SUCCESS_TIMER);
            } else {
                var fulfillmentType = ' shipping';
                if (newFulfillment) {
                    fulfillmentType = ' pickup';
                }
                createSnackbar(Resources.SNACKBAR_SUCCESS_MESSAGE, fulfillmentType, 'success', SitePreferences.SNACKBAR_SUCCESS_TIMER);
            }
        } else if (newQty == 0) {
            createSnackbar(Resources.SNACKBAR_ERROR_MESSAGE, '', 'error', SitePreferences.SNACKBAR_ERROR_TIMER);
        }
    } else {
        showSnackbar = true;
    }
}

function isSizedProductWithoutSelectedSize(productId, sizedProductId, masterSku) {
    var sizeButtons = document.querySelectorAll('.size-button');

    if (sizeButtons.length === 0) { return false }

    var isSizedProduct = productId === sizedProductId;
    var isMasterVariantSku = productId === masterSku;
    var isAnySizeSelected = Array.from(sizeButtons).some(function(button) {
        return button.classList.contains('selected');
    });

    return isSizedProduct || isMasterVariantSku && !isAnySizeSelected;
}

var addToCart = function (e) {
    e.preventDefault();
    var $form = $(this).closest('form');
    var $forstorepickup = $form.find('input[name="forstorepickup"]').val();
    var productId = $form.find('input[name="pid"]').val();
    var masterSku = $form.find('input[name="masterSku"]').val();
    var isProductMasterVariant = $form.find('input[name="isProductMasterVariant"]').val();
    var sizedProductId = '012345678';

    var enableQtySelector = SitePreferences.ENABLE_QTY_SELECTOR;
    var enableQtySelectorTest = SitePreferences.ENABLE_QTY_SELECTOR_TEST;
    var targetEnableQtySelector = window.digitalData && window.digitalData.page && window.digitalData.page.attributes && window.digitalData.page.attributes.targetflags && window.digitalData.page.attributes.targetflags.enableQtySelector ? window.digitalData.page.attributes.targetflags.enableQtySelector : false;
    var displayQtySelector = enableQtySelectorTest ? targetEnableQtySelector : enableQtySelector;

    if (displayQtySelector) {
        var $productTile = $form.closest('.product-tile ');
        var $loadingSpinner = $productTile.find('.loading-spinner');
        var $atcText = $productTile.find('.add-to-cart-text');

        $loadingSpinner.removeClass('hide-element');
        $atcText.addClass('hide-element');
    }

    var buttonClassList = this.classList;
    if (buttonClassList.contains('loyalty-exclusive-button')) {
        var windowLocation = window.location.href;
        var pid = $form.find('input[name="pid"]').val();
        var productTileID = $('.product-tile[data-itemid="' + pid + '"]').attr('id');
        if (pid === sizedProductId || isProductMasterVariant === 'true' && $(this).data('targeturl')) {
            windowLocation = $(this).data('targeturl');
        } else {
            windowLocation = productTileID ? windowLocation + '#' + productTileID : windowLocation;
        }

        sessionStorage.setItem('signinTarget', windowLocation);
        if (!SitePreferences.SIGN_IN_MODAL_ENABLED_LOYALTY_EXCLUSIVE_BUTTON || $form.parents('#QuickViewDialog').length) {
            window.location = window.Urls.accountLanding;
        }

        return;
    }

    // if no size selected then do not add to bag and throw error
    if (isSizedProductWithoutSelectedSize(productId, sizedProductId, masterSku)) {
        $('#sizeNotSelectedError').show();
        return;
    }

    // turning qty selector off for sized product
    if (productId === sizedProductId || isProductMasterVariant === 'true' && !buttonClassList.contains('loyalty-exclusive-button')) {
        displayQtySelector = false;
        window.location.href = $(this).data('targeturl');
        return;
    }

    $('.ui-dialog .frequency_block.freq_mini-cart').hide();
    var maxAvailableQtyInput = $form.find('input[name="maxQtyAvailable"]'),
        maxAvailableQty = 1,
        $qtyField = $form.find('input[name="Quantity"]'),
        qty = parseInt($qtyField.val(), 10);

    if (maxAvailableQtyInput.size() > 0) {
        maxAvailableQty = parseInt(maxAvailableQtyInput.val(), 10);
    }

    if (qty > maxAvailableQty) {
        $('.maxvalue').remove();
        $('#Quantity-error').remove();
        $qtyField.val(maxAvailableQty);
        $('<span class="error maxvalue">'+Resources.ERROR_MAX_QTY + ' ' + maxAvailableQty +'</span>').insertAfter($qtyField);
        $('.product-add-to-cart').addClass('error-state');
    } else {
        addItemToCart($form).then(function (response) {
            var showSnackbarOnAddToCart;
            var showJATCModalOnAddToCart;
            if (enableQtySelectorTest) {
                showSnackbarOnAddToCart = targetEnableQtySelector;
                showJATCModalOnAddToCart = !targetEnableQtySelector;
            } else {
                showSnackbarOnAddToCart = SitePreferences.ENABLE_JATC_SNACKBARS;
                showJATCModalOnAddToCart = SitePreferences.LISTING_ADDTOCARD_POPUP;
            }
            if (showSnackbarOnAddToCart) {
                initSnackbar(response, true);
            }

            var $uuid = $form.find('input[name="uuid"]');
            var $productTile = $form.closest('.product-tile ');

            if ($uuid.length > 0 && $uuid.val().length > 0) {
                page.refresh();
            } else {
                // do not close quickview if adding individual item that is part of product set
                // @TODO should notify the user some other way that the add action has completed successfully
                if (!$(this).hasClass('sub-product-item')) {
                    dialog.close();
                }
                if (window.pageContext && window.pageContext.ns && window.pageContext.ns === 'cart') {
                    window.location.href = Urls.cartShow;
                } else {
                    if (showJATCModalOnAddToCart) {
                        var pid = $form.find('input[name="pid"]').val();
                        var addedForPickUpInStore = false;
                        var storeName = window.SessionAttributes.BOPIS_SELECTED_STORE_NAME;
                        var selectorClass = (window && window.SessionAttributes && window.SessionAttributes.BOPIS_SELECTED_STORE_ID) ? '.bopis-pick-from-store-option':'.bopis-eligible-for-pickup .bopis-shipping-option';
                        if ($form.parents('.pdp-ql-main-container').length) {
                            addedForPickUpInStore = $('.pdp-ql-main-container').find('input[name="forstorepickup"]').val() === 'true';
                            $('.pdp-main-container').find(selectorClass).trigger('click');
                        } else if ($form.parents('.pdp-main-container').length) {
                            addedForPickUpInStore = $('.pdp-main-container').find('input[name="forstorepickup"]').val() === 'true';
                            updateDatalayerBOPISValues('pdp', pid, addedForPickUpInStore);
                        } else if ($('.boipsrefinement .bopisToggle').is(':checked')) {
                            addedForPickUpInStore = true;
                            updateDatalayerBOPISValues('plp', pid);
                        }

                        var addedForShipping;
                        if (SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD && !addedForPickUpInStore) {
                            addedForShipping = $forstorepickup === 'false' ? true : false;
                        } else {
                            addedForShipping = !addedForPickUpInStore;
                        }
                        if (SitePreferences.RECOMMENDATIONS_IN_JATC) {
                            addedProductsViewRecommendations.show(response, pid, storeName, addedForShipping);
                        } else {
                            addedProductsView.show(response, pid, storeName, addedForShipping);
                        }

                    } else {
                        minicart.show(response);
                    }

                    if (displayQtySelector) {
                        var $quantityField = $productTile.find('.quantity-field');
                        var $addToCartContainer = $form.find('.add-to-cart-container');
                        var $loadingSpinner = $productTile.find('.loading-spinner');
                        var $atcText = $productTile.find('.add-to-cart-text');
                        var $incrementButton = $productTile.find('.quantity-button.increase');

                        $addToCartContainer.addClass('hide-element');
                        $quantityField.removeClass('hide-element');
                        $loadingSpinner.addClass('hide-element');
                        $atcText.removeClass('hide-element');
                        $incrementButton.focus();
                    }
                }
            }
            tealiumProduct.addToCartButton = $(this);
            tealiumProduct.cartAdd();

            // send updated cart rows to kibo
            kibo.sendKiboData('nonPageView');
            //#853671 - [iPad & iPhone] [Safari]: ATB on PDP Malfunction -
            //after a user clicks add to cart, qty field should default back to 1
            $qtyField.val('1');
        }.bind(this));
    }
};

/**
 * @description Handler to handle increment and decrement button on grid pages
 */
var updateItemCountTimer = {};

var updateItemCount = function () {
    var $qtyBtn = $(this);
    var $form = $qtyBtn.closest('form'),
        $productTile = $qtyBtn.closest('.product-tile'),
        $addToCartContainer = $productTile.find('.add-to-cart-container'),
        $qtyField = $productTile.find('.quantity-field'),
        $qtyIncreaseButton = $productTile.find('.quantity-button.increase'),
        $quantity = $productTile.find('#Quantity').get(0),
        isIncrease = $qtyBtn.hasClass('increase'),
        productID = $form.find('input[name="pid"]').val(),
        totalQty = parseInt($form.find('input[name="totalQty"]').val(), 10),
        maxQtyAvailable = parseInt($form.find('input[name="maxQtyAvailable"]').val(), 10);

    var canIncrease = isIncrease && $quantity.value < maxQtyAvailable && $quantity.value < totalQty;
    var canDecrease = !isIncrease && $quantity.value > 0;

    if (canIncrease) {
        $quantity.value++;
    } else if (isIncrease) {
        createSnackbar(Resources.SNACKBAR_ERROR_MESSAGE, '', 'error', SitePreferences.SNACKBAR_ERROR_TIMER);
    } else if (canDecrease) {
        $quantity.value--;

        if ($quantity.value == 0) {
            $qtyField.addClass('hide-element');
            $addToCartContainer.removeClass('hide-element');
            $addToCartContainer.find('button').focus();
            showSnackbar = false;
        }
    }

    $qtyIncreaseButton.toggleClass('disabled', $quantity.value == maxQtyAvailable);

    if (updateItemCountTimer[productID]) {
        clearTimeout(updateItemCountTimer[productID])
    }
    updateItemCountTimer[productID] = (function(){
        return setTimeout(function() {
            updateCartQuantity($form);
        }, 500)
    })();
}

var updateItemCountFromKeyPress = function(e) {
    if (e.keyCode == 32 || e.keyCode == 13) {
        e.preventDefault();
        updateItemCount.call($(this));
    }
}

/**
 * @description Add BOPIS related data in datalayer on add to cart
 */
var updateDatalayerBOPISValues = function (page, pid, addedForBOPIS) {
    if (page == 'plp') {
        if (window.digitalData) {
            window.digitalData.plp = window.digitalData.plp || {};
            window.digitalData.plp[pid] = {
                'pid' : pid,
                'bopis' : 'y'
            };
        }
    } else if (page == 'pdp' && addedForBOPIS) {
        if (window.digitalData && window.digitalData.product) {
            for (var i in window.digitalData.product) {
                if (window.digitalData.product[i].productInfo.productID == pid) {
                    window.digitalData.product[i].bopis = 'y';
                }
            }
        }
    }
}

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    TPromise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};

var generateErrMsg = function (errorFor, isQuickLook) {
    var selectedStoreId = $('input[name="storeid"]').val();
    var AvailableInStore = $('.bopis-delivery-options .is-product-available-in-selected-store').val();
    var changeStoreURL = $('.change-store-url').val();
    if (errorFor === 'shipping') {
        if (selectedStoreId) {
            if (AvailableInStore === 'true') {
                return Resources.PDP_QL_ERROR_FOR_ITEM_OOSONLINE_AND_AVAILABLEINSTORE;
            } else {
                var oosonlineAndUnAvailableinStore = isQuickLook ? Resources.QL_ERROR_FOR_ITEM_OOSONLINE_AND_UNAVAILABLEINSTORE : Resources.PDP_ERROR_FOR_ITEM_OOSONLINE_AND_UNAVAILABLEINSTORE;
                return oosonlineAndUnAvailableinStore.replace('{0}', changeStoreURL);
            }
        }
    } else if (errorFor === 'pickupinstore') {
        var UnAvailableinStore = isQuickLook ? Resources.QL_ERROR_FOR_ITEM_UNAVAILABLEINSTORE : Resources.PDP_ERROR_FOR_ITEM_UNAVAILABLEINSTORE;
        return UnAvailableinStore.replace('{0}', changeStoreURL);
    }

}

var toggleErrorMessage = function ($insertAfterEle, errorFor) {
    var errorMsg = generateErrMsg(errorFor, $insertAfterEle.parents('.pdp-main').hasClass('pdp-ql-main-container'));
    if (errorMsg) {
        if (!($insertAfterEle.parents('.pdp-main').find('.pdp-quantity-error-msg').length)) {
            $('<div id="pdpQuantityErrMsg" class="pdp-quantity-error-msg" role="alert"></div>').insertAfter($insertAfterEle);
        }
        $insertAfterEle.next('.pdp-quantity-error-msg').html(errorMsg);
    } else {
        $insertAfterEle.parents('.pdp-main').find('.pdp-quantity-error-msg').remove();
    }
}
var autoRefreshOutOfStock = {
    init : function () {
        var autoOutStock = $('.product-detail .availability-msg .not-available-bopismsg')[0];
        if (SitePreferences.ORDERGROOVE_ENABLED) {
            if (typeof(autoOutStock) != 'undefined' && autoOutStock != null) {
                $('.bopis-enabled-add-to-cart-form og-optin-toggle').removeAttr();
                $('.bopis-enabled-add-to-cart-form og-optin-toggle > button').attr('disabled','disabled');
                $('.bopis-enabled-add-to-cart-form .div-btn-2').addClass('disabled');
            }
        }
    }
}
var setAddToCartInfo = function () {
    var input = $(this);
    var $formField = $(this).parents('.pdp-main').find('.bopis-enabled-add-to-cart-form');
    var pid = $formField.find('input[name="pid"]').val();
    var cgid = $formField.find('input[name="cgid"]').val();
    var quantityinput = $formField.find('input[name="Quantity"]');
    var inputVal = input.val();
    var isAnyVariantOrderableOnWeb = input.parents('.pdp-main').find('.is-any-variant-orderable-on-web').val();
    var isAnyVariantAvailInSelectedStore = input.parents('.pdp-main').find('.bopis-delivery-options .is-any-variant-available-in-selected-store').val();
    var isMasterVariant = $formField.find('input[name="isMasterVariant"]').val();
    var i;

    if ($('#wrapper').hasClass('pt_product-search-result')) {
        if (inputVal === 'shipping') {
            window.digitalData.product.bopis = 'n';
        } else {
            window.digitalData.product.bopis = 'y';
        }
    } else if ($('#wrapper').hasClass('pt_product-details')) {
        for (i = 0; i < window.digitalData.product.length; i++) {
            if (window.digitalData.product[i].productInfo.productID == $('#pid').val() && inputVal === 'shipping') {
                window.digitalData.product[i].bopis = 'n';
            } else {
                window.digitalData.product[i].bopis = 'y';
            }
        }
    }

    if (inputVal === 'shipping') {
        $.get(util.appendParamsToUrl(window.Urls.WebAndStoreAvailability, {'format': 'ajax', 'ajaxmeta': 'true','pid':pid,'cgid':cgid}), function (response) {
            $formField.find('input[name="forstorepickup"]').val(false);
            $formField.find('input[name="maxQtyAvailable"]').val(response.availableCount);
            $formField.find('input[name="totalQty"]').val(response.totalQty);
            $formField.next('.pdp-quantity-error-msg').remove();
            $('.pdp-ql-main-container .pdp-show-bopis-store-detail').addClass('hide');
            $('.pdp-ql-main-container .bopis-pdp-change-store').addClass('hide');
            if (response.availableCount > 0 && response.forceOOS != true && !response.deactivateInventory) {
                if (isMasterVariant === 'true' && isAnyVariantOrderableOnWeb === 'true') {
                    $formField.find('.quantity-field').addClass('qty');
                    $formField.find('#add-to-cart').removeClass('add-to-cart-disabled');
                    $formField.find('#add-to-cart').addClass('add-to-cart');
                    $formField.find('#add-to-cart').attr('disabled', false);
                    $formField.find('input[name="Quantity"]').attr('disabled', false);
                    $formField.find('input[name="Quantity"]').addClass('valid');
                    quantityinput.attr('data-available', response.availableCount);
                } else if (isMasterVariant === 'true' && isAnyVariantOrderableOnWeb === 'false') {
                    $formField.find('.quantity-field').removeClass('qty');
                    $formField.find('#add-to-cart').removeClass('add-to-cart');
                    $formField.find('#add-to-cart').addClass('add-to-cart-disabled');
                    $formField.find('#add-to-cart').attr('disabled', 'disabled');
                    $formField.find('input[name="Quantity"]').attr('disabled', true);
                    $formField.find('input[name="Quantity"]').removeClass('valid');
                    quantityinput.attr('data-available', response.availableCount);
                    toggleErrorMessage($formField, 'shipping');
                } else {
                    $formField.find('.quantity-field').addClass('qty');
                    $formField.find('#add-to-cart').removeClass('add-to-cart-disabled');
                    $formField.find('#add-to-cart').addClass('add-to-cart');
                    $formField.find('#add-to-cart').attr('disabled', false);
                    $formField.find('input[name="Quantity"]').attr('disabled', false);
                    $formField.find('input[name="Quantity"]').addClass('valid');
                    quantityinput.attr('data-available', response.availableCount);
                }
            } else {
                $formField.find('.quantity-field').removeClass('qty');
                $formField.find('#add-to-cart').removeClass('add-to-cart');
                $formField.find('#add-to-cart').addClass('add-to-cart-disabled');
                $formField.find('#add-to-cart').attr('disabled', 'disabled');
                $formField.find('input[name="Quantity"]').attr('disabled', true);
                $formField.find('input[name="Quantity"]').removeClass('valid');
                quantityinput.attr('data-available', response.availableCount);
                toggleErrorMessage($formField, 'shipping');
            }
        });
    } else {
        var storeid = $('input[name="storeid"]').val();
        if ((!storeid || (storeid && storeid === 'null'))) {
            input.parents('.pdp-main').find('.bopis-delivery-options .bopis-set-store').trigger('click', [true]);
        } else {
            $.get(util.appendParamsToUrl(window.Urls.WebAndStoreAvailability, {'format': 'ajax', 'ajaxmeta': 'true','pid':pid,'storeid':storeid,'cgid':cgid}), function (response) {
                $('.pdp-ql-main-container .pdp-show-bopis-store-detail').removeClass('hide');
                $('.pdp-ql-main-container .bopis-pdp-change-store').removeClass('hide');
                var isQuickLook = input.parents('.pdp-main').hasClass('pdp-ql-main-container');
                input.parents('.pdp-main').find('.bopis-pfs-option-main-container .availability-novariation').remove();
                $formField.find('input[name="forstorepickup"]').val(true);
                $formField.find('input[name="maxQtyAvailable"]').val(response.effectiveStoreInventory);
                $formField.find('input[name="totalQty"]').val(response.storeTotalQty);
                $formField.next('.pdp-quantity-error-msg').remove();

                var genericUnavailableMessage = SitePreferences.BOPIS_GENERIC_UNAVAILABLE_MESSAGE ? SitePreferences.BOPIS_GENERIC_UNAVAILABLE_MESSAGE : Resources.PICKUP_INSTORE_UNAVAILABLE_ALERT;
                var genericAvailableMessage = SitePreferences.BOPIS_GENERIC_AVAILABLE_MESSAGE ? SitePreferences.BOPIS_GENERIC_AVAILABLE_MESSAGE : Resources.PICKUP_INSTORE_AVAILABLE_ALERT;

                if (response.effectiveStoreInventory > 0) {
                    input.parents('.pdp-main').find('.bopis-delivery-options .is-product-available-in-selected-store').val(true);
                    $formField.find('.quantity-field').addClass('qty');
                    $formField.find('#add-to-cart').removeClass('add-to-cart-disabled');
                    $formField.find('#add-to-cart').addClass('add-to-cart');
                    $formField.find('#add-to-cart').attr('disabled', false);
                    $formField.find('input[name="Quantity"]').attr('disabled', false);
                    $formField.find('input[name="Quantity"]').addClass('valid');
                    quantityinput.attr('data-available', response.effectiveStoreInventory);
                    input.parents('.pdp-main').find('.bopis-pfs-option-main-container').append('<div class="availability-novariation bopis-product-available" id="' +(isQuickLook ? 'ql-ada-product-availability' : 'pdp-ada-product-availability')+ '">' + genericAvailableMessage + '</div>');
                    if (window.digitalData.page.attributes.storeAvailabilityError) {
                        delete window.digitalData.page.attributes.storeAvailabilityError;
                    }
                } else {
                    if (isMasterVariant === 'true' && isAnyVariantAvailInSelectedStore === 'true') {
                        input.parents('.pdp-main').find('.bopis-delivery-options .is-any-variant-available-in-selected-store').val(true);
                        $formField.find('.quantity-field').addClass('qty');
                        $formField.find('#add-to-cart').removeClass('add-to-cart-disabled');
                        $formField.find('#add-to-cart').addClass('add-to-cart');
                        $formField.find('#add-to-cart').attr('disabled', false);
                        $formField.find('input[name="Quantity"]').attr('disabled', false);
                        $formField.find('input[name="Quantity"]').addClass('valid');
                        input.parents('.pdp-main').find('.bopis-pfs-option-main-container').append('<div class="availability-novariation bopis-product-available" id="' +(isQuickLook ? 'ql-ada-product-availability' : 'pdp-ada-product-availability')+ '">' + genericAvailableMessage + '</div>');
                    } else {
                        input.parents('.pdp-main').find('.bopis-delivery-options .is-product-available-in-selected-store').val(false);
                        $formField.find('.quantity-field').removeClass('qty');
                        $formField.find('#add-to-cart').removeClass('add-to-cart');
                        $formField.find('#add-to-cart').addClass('add-to-cart-disabled');
                        $formField.find('#add-to-cart').attr('disabled', 'disabled');
                        $formField.find('input[name="Quantity"]').attr('disabled', true);
                        $formField.find('input[name="Quantity"]').removeClass('valid');
                        quantityinput.attr('data-available', response.effectiveStoreInventory);
                        input.parents('.pdp-main').find('.bopis-pfs-option-main-container').append('<div class="availability-novariation availability-oos" id="' +(isQuickLook ? 'ql-ada-product-availability' : 'pdp-ada-product-availability')+ '">' + genericUnavailableMessage + '</div>');
                        toggleErrorMessage($formField, 'pickupinstore');
                        window.digitalData.page.attributes.storeAvailabilityError = true;
                    }
                }
            });
        }

    }
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    // TODO: simplify this below by generalizing selectors to attach the events with less JS
    $('.product-detail').off('click', '.add-to-cart', addToCart);
    $('.product-detail').on('click', '.add-to-cart', addToCart);
    $('#add-all-to-cart').on('click', addAllToCart);
    $('#search-result-items').off('click', '.add-to-cart', addToCart);
    $('#search-result-items').on('click', '.add-to-cart', addToCart);
    $('.hp-module').off('click', '.add-to-cart', addToCart);
    $('.hp-module').on('click', '.add-to-cart', addToCart);
    $('.bopis-delivery-option-input').off('click', setAddToCartInfo);
    $('.bopis-delivery-option-input').on('click', setAddToCartInfo);
    $('.product-tile .quantity-button').off('click', updateItemCount);
    $('.product-tile .quantity-button').on('click', updateItemCount);
    $('.product-tile .quantity-button').off('keydown', updateItemCountFromKeyPress);
    $('.product-tile .quantity-button').on('keydown', updateItemCountFromKeyPress);
    // JATC
    $('body').off('click', '.jatc-recommendations .add-to-cart', addToCart);
    $('body').on('click', '.jatc-recommendations .add-to-cart', addToCart);
    //Design system
    $('.ds-shop-the-module').off('click', '.add-to-cart', addToCart);
    $('.ds-shop-the-module').on('click', '.add-to-cart', addToCart);
    //Recommendations
    $('.kibo-recommendations-container').off('click', '.add-to-cart', addToCart);
    $('.kibo-recommendations-container').on('click', '.add-to-cart', addToCart);


    autoRefreshOutOfStock.init();
    $('a#quickviewbutton').on('click', autoRefreshOutOfStock.init());

};
module.exports.initSnackbar = function (response, justAdded, isGiftCard) {
    initSnackbar(response, justAdded, isGiftCard);
}
module.exports.initModalAddToCart = function () {
    $('.fifth-click-modal-recs').off('click', '.add-to-cart', addToCart);
    $('.fifth-click-modal-recs').on('click', '.add-to-cart', addToCart);
}
