'use strict';

/* This loader solves and issue we were seeing with NVDA reader on windows
    where the reader was unable to detect aria-live regions that were
    inserted into html.
*/

/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The container on where the loader lives
 */
var show = function ($container) {
    if ($container) {
        $container.find('.loader-text').html('Loading');
        $container.find('.loader').show();
    }
};
/**
 * @function
 * @description Hides an AJAX-loader
 * @param {Element} container The container on where the loader lives
 */
var hide = function ($container) {
    if ($container) {
        $container.find('.loader-text').empty();
        $container.find('.loader').hide();
    }
};

/**
 * @function
 * @description Sets up the container where we will add our text to be read by screen readers
 * @param {Element} container The container on where the loader lives
 */
var init = function ($container) {
    if ($container && !$container.find('.loading-wrapper').length) {
        var $loader = $('<div class="loading-wrapper">' +
                        '<div class="loader" aria-live="polite">' +
                            '<div class="loader-indicator"></div>' +
                        '</div>' +
                        '<div class="loader-text visually-hidden" aria-live="polite"></div>' +
                    '</div>');
        $loader.appendTo($container).find('.loader').hide();
    }
}

exports.show = show;
exports.hide = hide;
exports.init = init;
