'use strict';

var util = require('./util'),
    ajax = require('./ajax'),
    bonusProductsView = require('./bonus-products-view'),
    quantitySelector = require('./quantity-selector'),
    kibo = require('./kibo');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    isLoaded: false,
    init: function () {
        $('.pt_cart #mini-cart').addClass('disabled');
        this.setVars();
        if (window.OG && window.digitalData) {
            if (!(window.digitalData.page.pageInfo.pageID == 'cart' || window.digitalData.page.pageInfo.pageID == 'shipping')) {
                this.checkForARProducts();
            }
        }
        this.events();
        this.isLoaded = true;
    },
    checkForARProducts: function () {
        // check to see if item is OG subscribed
        try {
            var optins = window.OG.getOptins();
            for (var i in optins) {
                var pid = optins[i].product,
                    $miniCartProductUI = $('.mini-cart-product[data-productid="'+pid+'"]').find('.mini-cart-pricing');
                if ($miniCartProductUI.length) {
                    $miniCartProductUI.children('.promotion').each(function() {
                        if ($(this).find('.ar-promotion').length) {
                            $(this).removeClass('display-none');
                        } else {
                            $(this).addClass('display-none');
                        }
                    });
                }
            }
        } catch (err) {
            console.error(err);
        }
    },
    events: function (){
        var wrapperClass = document.getElementById('wrapper').classList;
        if (wrapperClass.contains('pt_cart') || wrapperClass.contains('pt_checkout')) {
            return;
        }
        // events
        this.$el.on('mouseenter', function () {
            this.open();
        }.bind(this));

        this.$el.on('mouseleave', function () {
            this.close();
        }.bind(this));

        $('.mini-cart-link').on('focus', function () {
            this.open();
        }.bind(this));

        this.$content.on('keyup', function (event) {
            var key = event.which || event.keyCode;

            if (key === 27) {
                this.close();
                event.stopPropagation();
            }
        }.bind(this));

        //Remove item from cart
        $('#mini-cart .remove-item .remove').on('click', function () {
            var item = $(this),
                idValue = item.data('id'),
                typeValue = item.data('type'),
                qtyValue = null;
            if (typeValue === 'freeproduct') {
                qtyValue = item.data('freeqty');
            }
            // BBWRKS-6605 scRemove Updates
            var productItemIndex = item.closest('li').index();
            var tealiumCartData = item.closest('li').siblings('.adobe-analytics-cart-data').data('tealium');
            // BBWRKS-6605
            var url = util.appendParamsToUrl(Urls.removeItemFromMiniCart, {type: typeValue, id: idValue, qty: qtyValue, format:'ajax'});
            ajax.load({
                url: url,
                target: $('#mini-cart'),
                callback: function (data) {
                    minicart.show(data);
                    // BBWRKS-6605 scRemove Updates
                    window.digitalData.scRemove = {};
                    window.digitalData.scRemove.item = [];
                    var scRemoveItem = {
                        productInfo: {'productID' : tealiumCartData.cart_product_id[productItemIndex]},
                        attributes : {'cart_product_base_price': tealiumCartData.cart_product_unit_price[productItemIndex]},
                        quantity: tealiumCartData.cart_product_quantity[productItemIndex],
                        price : tealiumCartData.cart_product_price[productItemIndex],
                        bopis : tealiumCartData.cart_product_bopis[productItemIndex]
                    }
                    if (SitePreferences.ORDERGROOVE_ENABLED) {
                        scRemoveItem.attributes.cart_product_auto_refresh = tealiumCartData.cart_product_auto_refresh[productItemIndex];
                    }
                    window.digitalData.scRemove.item.push(scRemoveItem);
                    if ('_satellite' in window) window._satellite.track('scRemove');
                    // BBWRKS-6605

                    // Update Kibo that item removed from cart
                    kibo.sendKiboData('nonPageView');

                    var enableQtySelector = SitePreferences.ENABLE_QTY_SELECTOR;
                    var enableQtySelectorTest = SitePreferences.ENABLE_QTY_SELECTOR_TEST;
                    var targetEnableQtySelector = window.digitalData && window.digitalData.page && window.digitalData.page.attributes && window.digitalData.page.attributes.targetflags && window.digitalData.page.attributes.targetflags.enableQtySelector ? window.digitalData.page.attributes.targetflags.enableQtySelector : false;
                    var displayQtySelector = enableQtySelectorTest ? targetEnableQtySelector : enableQtySelector;
                    if (displayQtySelector) {
                        var isRemoveProductTileQtySelector = true;
                        var qty = 0;
                        var $miniCartItems = $('.mini-cart-product');
                        if ($miniCartItems.length) {
                            $miniCartItems.each(function() {
                                var $this = $(this);
                                if ($this.data('productid') === idValue) {
                                    qty += Number($this.find('.qty-block .value').text());
                                    isRemoveProductTileQtySelector = false;
                                }
                            });
                        }
                        if (isRemoveProductTileQtySelector) {
                            quantitySelector.removeQtySelectorFromGrid(idValue);
                        } else {
                            quantitySelector.replaceQtySelectorValue(idValue, qty)
                        }
                    }
                }
            });
        })
    },
    open: function() {
        if (this.$content && this.$content.not(':visible')) {
            this.$content.prev().addClass('opened');
            this.$content.attr('aria-hidden', false);
            this.$miniCartLink.attr('aria-expanded', true);
        }
    },
    unbind: function(){
        this.init();
        this.$el.off();
        this.$content.off();
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        if (!this.isLoaded) {
            this.init();
        }
        this.$el.html(html);
        this.unbind();
        this.setVars();
        this.events();
        this.preview();
        bonusProductsView.loadBonusOption();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    preview: function () {
        timer.clear();
        if (util.isDesktop()) {
            // show the item
            this.open();
            timer.start(3000, this.close.bind(this));
        }
    },
    /**
     * @function
     * @description Closes the mini cart with given duration
     */
    close: function () {
        timer.clear();
        if (this.$content && this.$content.is(':visible')) {
            this.$content.prev().removeClass('opened');
            this.$content.attr('aria-hidden', true);
            this.$miniCartLink.attr('aria-expanded', false);
        }
    },

    setVars: function () {
        this.$el = $('#mini-cart').not('.disabled');
        this.$content = this.$el.find('.mini-cart-content');
        this.$miniCartLink = $('.mini-cart-link');
    }
};

module.exports = minicart;
