'use strict';

/******* app.header-banner ********/
/**
 * @function
 * @Animated Block of code related Header Banner's banner keep session
 * 
 * TODO: rename this file header-banner
 */

exports.init = function () {
    function setCookie(cName,value,exdays, sameSite) {
        var exdate=new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var cValue=escape(value) + ((exdays==null) ? '' : '; expires='+exdate.toUTCString());
        document.cookie=cName + '=' + cValue + ';SameSite=' + sameSite;
    }
    function getCookie(cName) {
        var name = cName + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
    function checkCookie() {
        var currentCookie = getCookie('hideHeaderPromoBanner');
        if (currentCookie!=null && currentCookie!='') {
            $('.promo-banner-container').removeClass('visible').hide();
            $('body').find('.addOn').removeClass('addOn');
        } else {
            $('.promo-banner-container').addClass('visible').show();
            $('.promo-banner-container').find('.promo-banner-click-layer').attr('aria-describedby','promo-banner-1');
        }
    }
    $('.promo-banner-container .close').click(function () {
        $('body').find('.addOn').removeClass('addOn');
        $(this).parent().slideUp().removeClass('visible').hide();
        setCookie('hideMonitate','hidden',365,'Lax');
    });
    checkCookie();

    if ($('.promo-banner-container .promo-banner').length === 0) {
        $('.promo-banner-container').removeClass('visible').hide();
        $('body').find('.addOn').removeClass('addOn');
    }

};
