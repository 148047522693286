'use strict';
var helper = require('./EmailInputHelper');
module.exports = function () {

    var displaySuccessMessage = function (form) {
        var successMessages = form.querySelectorAll('.ds-success-message');
        for (var j = 0; j < successMessages.length; j++) {
            var element = successMessages[j];
            element.style.display = 'block';
        }
    }

    var handleFormSubmission = function (e) {
        helper.handleFormSubmission(e, displaySuccessMessage, this);
    }

    function hideSuccessMessage(event) {
        var successMessages = event.currentTarget.parentForm.querySelectorAll('.ds-success-message');
        for (var j = 0; j < successMessages.length; j++) {
            var element = successMessages[j];
            element.style.display = 'none';
        }
    }

    var userInfoForms = document.querySelectorAll('.ds-user-info-collection .ds-user-info-collection-form');
    for (var i = 0; i < userInfoForms.length; i++) {
        var userInfoForm = userInfoForms[i];
        userInfoForm.addEventListener('submit', handleFormSubmission);
        var inputs = userInfoForm.querySelectorAll('input');
        for (var j = 0; j < inputs.length; j++) {
            var element = inputs[j]
            if (element.tagName.toUpperCase() === 'INPUT' && element.type.toUpperCase() !== 'BUTTON' && element.type.toUpperCase() !== 'SUBMIT') {
                element.addEventListener('focus', hideSuccessMessage);
                element.addEventListener('keyup', hideSuccessMessage);
                element.parentForm = userInfoForm;
            }
        }
    }
};
