'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    flyInDialog = require('../flyin-dialog'),
    giftcert = require('../giftcert'),
    certona = require('../certona'),
    loyalty = require('../loyalty'),
    iroll = require('../iroll'),
    dialog = require('../dialog'),
    validator = require('../validator'),
    util = require('../util'),
    designSystemTooltip = require('../designSystem/designsystemtooltip'),
    modal = require('../designSystem/modal/modal'),
    pages = {
        account: require('./account')
    },
    _ = require('lodash');

var ordergroove = require('../../../int_ordergroove/cartridge/client/default/js/pages/cart');

/**
 * @private
 * @function
 * @description Adjust DOM to avoid problems caused by forms structure
 */
function initializeDOM() {
    var windowWidth = $(window).width();
    if (windowWidth >= 768 && $('.cart-coupon-code.loyalty').length > 0) {
        $('#cart2_rr').css('margin-top', $('.cart-footer').height() - $('.cart-coupon-code-wrap').height());
    }
    if (windowWidth < 768) {
        $('#cart-table').not('.bopis-item-list').find('.cart-row').each(function(i,el){
            var children = $(el).find('td');
            var height = 0;
            $.each(children, function(i,td){
                if (!$(td).hasClass('item-image') && !$(td).hasClass('item-remove')) {
                    height = height + $(td).outerHeight();
                }
            });

            $(el).find('.item-image').css('height', height);
        });
    }
}

function addRemoveQtyError ($this, addError, $qtyField) {
    var $error = $this.closest('.item-quantity').find('.error');
    var $qtyBtn = $this.closest('.item-quantity').find('.quantity-button.increase');
    $error.remove();
    if (addError && typeof $qtyField !== 'undefined') {
        $('<span class="error">' + Resources.CAT_LIMIT_MESSAGE + '</span>').insertAfter($qtyField.parent());
        $qtyBtn.addClass('disabled').html('');
    } else {
        $qtyBtn.removeClass('disabled').html('+');
    }
}

function showLoginModal() {
    dialog.open({
        url : util.appendParamsToUrl(Urls.accountLanding, {isOverlay: true}),
        options : {
            dialogClass : 'bocpopup loyalty-error-msg-flow',
            width : 400,
            close : function () {
                $('body').removeClass('open-modal');
            },
            open : function() {
                $('body').addClass('open-modal');
                $('.loyalty-error-msg-flow .ui-dialog-titlebar .ui-button-text').text('Button to close Sign in Modal');
                setTimeout(function() {
                    $('.loyalty-error-msg-flow').focus();
                }, 500);

                $('.ui-widget-overlay').hide().fadeIn().on('click', function() {
                    $('.ui-dialog-titlebar-close').trigger('click');
                });
                function fireLoyaltyEvent(isloginError, elemId, reload) {
                    if (isloginError) {
                        return;
                    }
                    $.ajax({
                        type: 'GET',
                        url: Urls.getRewardsDataShort,
                        dataType: 'json'
                    }).done(function (data) {
                        var loyaltyStatus = 'rewardsDataShort' in data && data.rewardsDataShort.loyaltyStatus ? data.rewardsDataShort.loyaltyStatus : '';
                        if ('cmCreateElementTag' in window) {
                            switch (loyaltyStatus) {
                                case 'cancelled':
                                    window.loyaltyStatusValue = 'loyl_acct_canceled';
                                    break;
                                case 'cancelledgrace':
                                    window.loyaltyStatusValue = 'loyl_acct_canceled_grace';
                                    break;
                                case 'frozen':
                                    window.loyaltyStatusValue = 'loyl_acct_frozen';
                                    break;
                                case 'terminated':
                                    window.loyaltyStatusValue = 'loyl_acct_terminated';
                                    break;
                                case 'terminatedgrace':
                                    window.loyaltyStatusValue = 'loyl_acct_terminated_grace';
                                    break;
                                case 'active':
                                    window.loyaltyStatusValue = 'loyl_acct_active';
                                    break;
                                case 'inactive':
                                    window.loyaltyStatusValue = 'loyl_acct_inactive';
                                    break;
                                default :
                                    window.loyaltyStatusValue = 'bbw_acct';
                            }

                            if ($('#wrapper').hasClass('pt_checkout')) {
                                window.cmCreateElementTag((elemId === 'SIGN IN'? 'COMPLETE' : elemId), 'SIGN IN', '-_--_--_-'+window.digitalData.page.pageInfo.pageID);
                            } else {
                                window.cmCreateElementTag(elemId, 'ACCOUNT SIGN IN', '-_--_-'+window.loyaltyStatusValue+'-_-'+window.digitalData.page.pageInfo.pageID);
                            }

                            if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = window.loyaltyStatusValue;

                            if (elemId === 'SIGN IN' && data.success) {
                                window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, data.rewardsDataShort.address.city, data.rewardsDataShort.address.stateProvince, data.rewardsDataShort.address.postalCode, data.rewardsDataShort.country, '-_--_--_--_--_--_--_--_-' + data.rewardsDataShort.loyaltyID + '-_-' + data.rewardsDataShort.count + '-_-' + data.rewardsDataShort.currentSpend + '-_--_--_-'+window.loyaltyStatusValue);
                            }

                            if (reload && data.success) {
                                if ($('#wrapper').hasClass('pt_checkout')) {
                                    var formData = $('fieldset.address-element').find(':input').serialize();
                                    localStorage.setItem('billingaddress', formData);
                                    window.location.href = Urls.billing;
                                } else {
                                    window.location.href = Urls.continueUrl;
                                }
                            }
                        }
                    });
                }
                function initloginForm(isloginError) {
                    validator.init();
                    pages.account.initPopupLogin();
                    var dialogContainer = $('#dialog-container');
                    var dialogForm = dialogContainer.find('.loginform-new');
                    var dialogSubmit = dialogForm.find('button[type="submit"]');
                    fireLoyaltyEvent(isloginError, 'POP-UP', false);
                    dialogForm.on('submit', function(e) {
                        e.preventDefault();
                        var submitButton = dialogSubmit.attr('name');
                        var data = $(this).serialize() + '&' + submitButton + '=x';
                        $.ajax({
                            type: 'POST',
                            url: $(this).attr('action'),
                            data: data,
                            success: function(response) {
                                var responceHtml = '<div>'+ response +'</div>';
                                if ($(responceHtml).find('.error-form.login-error').length > 0 || $(responceHtml).find('.error').length > 0) {
                                    dialogContainer.html(response);
                                    initloginForm(true);
                                } else {
                                    fireLoyaltyEvent(false, 'SIGN IN', true);
                                }
                            }
                        });
                    });
                }
                initloginForm(false);

                $(window).resize(function() {
                    $('#dialog-container').dialog('option', 'position', {my: 'center', at: 'center', of: window, collision: 'flipfit'});
                });
            }
        }
    });
}

function sendQtyRequest() {
    cartAjaxCall(Urls.UpdateCart);
}

function displayApplePay(retryCounter) {
    if ($('.dw-apple-pay-button').length > 0) {
        $('.cart-actions-top').addClass('applepay');
        $('.cart-actions-bottom').addClass('applepay');
        $('.dw-apple-pay-button').show().css('opacity', '1');
    } else {
        if (retryCounter < 5) {
            setTimeout(function() {
                displayApplePay(++retryCounter);
            }, 500);
        }
    }
}

function cartAjaxInit(e, qtyChangeTimer) {
    e.preventDefault();
    clearTimeout(qtyChangeTimer);
}

function cartAjaxCall(url, overrideData, successCallback) {
    var data;
    if (typeof overrideData !== 'undefined') {
        data = overrideData;
    } else {
        data = $('#cart-items-form').serialize();
    }
    $.ajax({
        url: url,
        data: data,
        method:'POST',
        success: function (response, status, xhr) {
            //Not getting header needed, need to fix this later when header is coming through.
            var emptyBasket = xhr.getResponseHeader('X-SF-CC-EmptyBasket');
            if (emptyBasket) {
                window.location.reload();
            }
            var htmlObject = $($.parseHTML(response));
            $('#cartBanners').html(htmlObject.filter('#cartBanners').html());
            $('#cartContainer').html(htmlObject.filter('#cartContainer').html());
            $('#cartGiftContainer').html(htmlObject.filter('#cartGiftContainer').html());
            $('.cart-order-totals').html(htmlObject.filter('#cart-order-totals').html());
            $('#fulfillmentHeader').html(htmlObject.filter('#fulfillmentHeader').html());
            $('#checkoutButtons').html(htmlObject.filter('#checkoutButtons').html());
            $('#couponCodes').html(htmlObject.filter('#couponCodes').html());
            $('.minicart-quantity').html(htmlObject.filter('#miniCartSum').data('qty'));
            initializeEvents();
            designSystemTooltip();
            if (document.getElementById('pp-btn-container') !== null) {
                initPaypalButtons(true, true); // eslint-disable-line no-undef
            }
            if (successCallback) {
                successCallback();
            }
        },
        error: function () {
            window.location.reload();
        }
    });
}

function buildAdobeQtyData($qtyField, isQtyIncrease, adjustmentType) {
    var $deliveryOptions = $qtyField.closest('.delivery-options-with-price-and-qty-box');
    var tealiumData = $qtyField.data('tealium');
    var ibmData = $qtyField.data('ibm');

    if (tealiumData && ibmData) {
        var basePrice = tealiumData.original_price == '' ? $('#dwfrm_giftcert_purchase_amount').val() : tealiumData.original_price;
        var data = {
            id: tealiumData.id,
            quantity: $qtyField.find('.input-text').val(),
            basePrice: basePrice,
            price: $qtyField.data('sales-price') ? $qtyField.data('sales-price') : basePrice,
            isBopis: $deliveryOptions.find('.delivery-option:checked').val() == 'store' ? 'y' : 'n',
            isAutoRefresh: $deliveryOptions.find('.cart-offer').attr('subscribed') !== undefined ? 'y' : 'n',
            type: adjustmentType
        }

        if ('productInfo' in ibmData && 'productID' in ibmData.productInfo) {
            data.id = ibmData.productInfo.productID;
        }

        if (isQtyIncrease) {
            data.eventData = 'scAdd,event12,event13,event51,event95';
        } else {
            data.eventData = 'scRemove,event12,event13,event51,event95';
        }

        return data;
    }
    return null;
}

$('body').on('click', '.loyaltyflowsignin', function(e) {
    e.preventDefault();
    showLoginModal();
});

$(document).ready(function() {
    if (SitePreferences.ORDERGROOVE_ENABLED) {
        $('.delivery-option.pick-up-delivery-option').each(function() {
            if ($(this).filter('[checked]').length == 1) {
                $(this).closest('.cart-line-item').find('.cart-offer').addClass('overlay');
            }
            if ($('.bopis-recurring').length == 0 && $(this).closest('.cart-line-item').find('.cart-offer').height() > 0) {
                $(this).closest('.shipping-method').append('<span class="bopis-recurring">'+Resources.BOPIS_AUTOREFRESH+'</span>');
            }
        });
    }
    if ($('.order-confirmation-details').length === 0) {
        $('.confirmation_item_indicator').hide();
    }
    if (SitePreferences.ORDERGROOVE_ENABLED) {
        if (window.OG) {
            try {
                window.OG.addOptinChangedCallback(
                    function(event) {
                        var cartLineItem, cartLineItemFreq, cartOfferLine = $('.cart-offer');
                        $(cartOfferLine).each(function() {
                            cartLineItem = $(this).closest('.cart-row.cart-line-item');
                            cartLineItemFreq = $(this).attr('frequency');
                            if ($(this).attr('product') == event.productId) {
                                cartLineItem.find('.ar-feature').val('true');
                                cartLineItem.find('.ar-feature-frequency').val(cartLineItemFreq);
                                if ($(this).closest('.cart-row.cart-line-item').find('.cart-offer').filter('[subscribed]').length == 0) {
                                    cartLineItem.find('.ar-feature').val('false');
                                }
                                cartLineItem.closest('form').trigger('submit');
                            }
                        });
                    }
                );
            } catch (err) {
                console.error(err);
            }
        }

        $('.cart-line-item').on('click', '.cart-offer .optin-btn', function() {
            var parentLineItem = $(this).closest('.cart-row.cart-line-item'),
                OG_STATE_Obj = JSON.parse(localStorage.OG_STATE);
            if ($(this).closest('.cart-offer').filter('[subscribed]').length > 0) {
                var arrProductList = $(OG_STATE_Obj.optedin).map(function() {
                    return this.id;
                }).get();
                var productId = parentLineItem.find('.cart-offer').attr('product');
                var indexOfProduct = jQuery.inArray(productId, arrProductList);
                var frequencyFetch = OG_STATE_Obj.optedin[indexOfProduct].frequency;
                parentLineItem.find('.ar-feature').val('true');
                parentLineItem.find('.ar-feature-frequency').val(frequencyFetch);
            } else {
                parentLineItem.find('.ar-feature').val('false');
                parentLineItem.find('.ar-feature-frequency').val('');
            }
            parentLineItem.closest('form').trigger('submit');
        });
    }
});

$('body').on('click', '.delivery-option.ship-it-delivery-option', function(e) {
    if (SitePreferences.ORDERGROOVE_ENABLED) {
        e.returnValue = false;
        var currentTargetShipit = e.target,
            OGCartShipitOffer = $(currentTargetShipit).closest('.desktop-delivery-options').siblings('.cart-offer');

        if (OGCartShipitOffer.height() > 0) {
            OGCartShipitOffer.removeClass('overlay');
            if ($('.bopis-recurring').length > 0) {
                $(this).find('.bopis-recurring').remove(); //append('<span class="bopis-recurring">'+Resources.BOPIS_AUTOREFRESH+'</span>');
            }
        }
    }
});

$('body').on('click', '.delivery-option.pick-up-delivery-option', function(e) {
    if (SitePreferences.ORDERGROOVE_ENABLED) {
        e.returnValue = false;
        var currentTargetBopis = e.target,
            parentLineItem = $(this).closest('.cart-row.cart-line-item'),
            OGCartOffer = $(currentTargetBopis).closest('.desktop-delivery-options').siblings('.cart-offer'),
            notUpsell = OGCartOffer.find('og-when og-when').attr('test'),
            OGCartOfferToggleButton;
        if (notUpsell && notUpsell == 'upsellEligible') {
            OGCartOfferToggleButton = OGCartOffer.find('og-when og-when').siblings('.toggle');
        } else {
            OGCartOfferToggleButton = OGCartOffer.find('.toggle');
        }

        if (OGCartOffer.filter('[subscribed]').length > 0) {
            OGCartOfferToggleButton.click();
            OGCartOffer.addClass('overlay');
            parentLineItem.find('.ar-feature').val('true');
        } else {
            parentLineItem.find('.ar-feature').val('false');
        }
        e.returnValue = true;
    }
});

$('body').on('click', '.button-text.remove-item', function(e) {
    var $removeBtn = $(e.currentTarget);

    if ($removeBtn.data('giftcardid')) {
        var giftCardPrice = $removeBtn.data('price')
        var giftCardData = {
            id: $removeBtn.data('giftcardid'),
            quantity: 1,
            basePrice: giftCardPrice,
            price: giftCardPrice,
            isBopis: 'n',
            isAutoRefresh: 'n'
        }
        window._satellite.track('cartRemoveItem', giftCardData);
    } else {
        var tealiumData = $removeBtn.data('tealium')
        var ibmData = $removeBtn.data('ibm');

        if (tealiumData && ibmData) {
            var quantity = $removeBtn.closest('.product-main-details-with-image').find('.quantity-field .input-text').val();
            var $deliveryOptions = $removeBtn.closest('.product-main-details-with-image').find('.delivery-options-with-price-and-qty-box');
            var basePrice = tealiumData.original_price == '' ? $('#dwfrm_giftcert_purchase_amount').val() : tealiumData.original_price;
            var data = {
                id: tealiumData.id,
                quantity: quantity,
                basePrice: basePrice,
                price: $removeBtn.data('sales-price') ? $removeBtn.data('sales-price') : basePrice,
                isBopis: $deliveryOptions.find('.delivery-option:checked').val() == 'store' ? 'y' : 'n',
                isAutoRefresh: $deliveryOptions.find('.cart-offer').attr('subscribed') !== undefined ? 'y' : 'n'
            }
            window._satellite.track('cartRemoveItem', data);
        }
    }
});

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    loyalty.init();
    iroll.init();
    modal.setupHandlers();
    initStickyDrawer();

    var $addCoupon = $('#add-coupon'),
        $addCouponLoyalty = $('.add-coupon-loyalty');

    //#857241 - [Shopping Bag] Top Cart Content Slot
    if ($('.cart-top-banner').children().length > 0) {
        $('#cart1_rr').remove();
    }

    $('.cart-actions-top .expressbutton').on('click', function() {
        if ('digitalData' in window) {
            window.digitalData.otherBillingMethod = {payPalExpress: 'Top'}
        }
    });

    $('.cart-actions-bottom:not(.sticky-drawer-btns) .expressbutton').on('click', function() {
        if ('digitalData' in window) {
            window.digitalData.otherBillingMethod = {payPalExpress: 'Bottom'}
        }
    });

    $('.cart-actions-top').on('click', function(e) {
        var el = $(e.target);
        if (el.hasClass('dw-apple-pay-button')) {
            if ('digitalData' in window) {
                window.digitalData.otherBillingMethod = {applePay: 'Top'}
            }
        }
    });

    $('.cart-actions-bottom:not(.sticky-drawer-btns)').on('click', function(e) {
        var el = $(e.target);
        if (el.hasClass('dw-apple-pay-button')) {
            if ('digitalData' in window) {
                window.digitalData.otherBillingMethod = {applePay: 'Bottom'}
            }
        }
    });

    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart',
            callback: function() {
                giftcert.init();
            }
        });
    })
    .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    }).on('click', '.quantity-button', function () {
        var $this = $(this);
        var $qtyField = $this.parent().find('.input-text');
        var quantityValue = Number($qtyField.val());
        var freeQtyValue = Number($this.parent().find('[data-name="freeQty"]').val());
        var $totalQty = $this.parent().find('[data-name="totalQty"]');
        var maxQtyAvailable = parseInt($this.parent().parent().find('[data-name="maxQtyAvailable"]').attr('value'), 10);
        var purchaseLimit = parseInt($this.parent().parent().find('[data-name="purchaseLimit"]').attr('value'), 10);
        var maxLineItemQty = parseInt($this.parent().find('.input-text').attr('data-available'), 10);

        //if category limit is enabled on site preferences, the logic to calculate the quantity fields.
        if (SitePreferences.CATEGOTYLIMITS_ENABLED){
            var categoryLimitData = JSON.parse($this.parent().parent().find('[data-name="categoryLimitData"]').attr('value'));
            var cartCategoryQuantity = 0;

            $.each(categoryLimitData.applicableProductsLineItemArray, function(index, uuid) {
                var cartLineItem = '.cart-line-item[data-uuid="' + uuid + '"]';
                $(cartLineItem + ' input[name=visibleQty], ' + cartLineItem + ' input[name=freeitem], ' + cartLineItem + ' input[name=visibleQtyDiscounted]').each(function(index, qty) {
                    cartCategoryQuantity = cartCategoryQuantity + Number(qty.value);
                });
            });

            if ($this.hasClass('increase')) {
                $this.parent().parent().find('.error').remove();

                if (cartCategoryQuantity < categoryLimitData.totalQtyLimit) {
                    quantityValue++;
                    $qtyField.val(quantityValue);
                    $qtyField.attr('value', quantityValue);
                    $totalQty.val(quantityValue + freeQtyValue);
                    if (quantityValue == categoryLimitData.totalQtyLimit) {
                        addRemoveQtyError($this, true, $qtyField);
                    }
                } else {
                    addRemoveQtyError($this, true, $qtyField);
                }

            } else if ($this.hasClass('decrease') && quantityValue > 1) {
                quantityValue--;
                $qtyField.val(quantityValue);
                $qtyField.attr('value', quantityValue);
                $totalQty.val(quantityValue + freeQtyValue);
                if ($this.parent().parent().find('.quantity-button.increase').hasClass('disabled')) {
                    addRemoveQtyError($this, false);
                }
            }


        } else {

            if ($this.hasClass('increase') && quantityValue <= maxLineItemQty) {
                quantityValue++;
                $qtyField.val(quantityValue);
                $qtyField.attr('value', quantityValue);
                $totalQty.val(quantityValue + freeQtyValue);
            } else if ($this.hasClass('decrease') && quantityValue > 1) {
                quantityValue--;
                $qtyField.val(quantityValue);
                $qtyField.attr('value', quantityValue);
                $totalQty.val(quantityValue + freeQtyValue);
                if ($this.parent().parent().find('.quantity-button.increase').hasClass('disabled')) {
                    addRemoveQtyError($this, false);
                }
            }


            if (Number($totalQty.val()) > purchaseLimit && purchaseLimit != maxQtyAvailable) {
                quantityValue--;
                $qtyField.val(quantityValue);
                $qtyField.attr('value', quantityValue);
                $this.parent().parent().find('.error').remove();
                $totalQty.val(quantityValue + freeQtyValue);
                addRemoveQtyError($this, true, $qtyField);
            } else if (Number($totalQty.val()) >= maxQtyAvailable) {
                $qtyField.val(maxLineItemQty);
                $qtyField.attr('value', quantityValue);
                $this.parent().parent().find('.error').remove();
                $totalQty.val(quantityValue + freeQtyValue);
                if (SitePreferences.STORE_PICKUP) {
                    $('<span class="error">'+ Resources.ERROR_LOW_INVENTORY_QTY.replace('qty', maxQtyAvailable) +'</span>').insertAfter($qtyField.parent());
                } else {
                    $('<span class="error">'+ Resources.ERROR_LOW_INV.replace('maxqty', maxQtyAvailable) +'</span>').insertAfter($qtyField.parent());
                }
            } else if ($totalQty.val() == purchaseLimit) {
                addRemoveQtyError($this, true, $qtyField);
            }
        }

        var adobeData = buildAdobeQtyData($this.closest('.quantity-field'), $this.hasClass('increase'), $this.hasClass('increase') ? 'increase' : 'decrease');
        adobeData.adjustmentAmount = 1;
        if (adobeData) {
            window._satellite.track('cart-item-quantity-change', adobeData);
        }
    }).on('change keyup blur', '.quantity-field .input-text', function () {
        var $qtyField = $(this);
        var $this = $(this);
        var quantityValue = Number($qtyField.val());
        var $totalQty = $qtyField.parent().parent().find('[data-name="totalQty"]');
        var prevQty = Number($qtyField.closest('.quantity-field').data('prevqty'));
        var maxQtyAvailable = parseInt($qtyField.parent().parent().find('[data-name="maxQtyAvailable"]').attr('value'), 10);
        var purchaseLimit = parseInt($qtyField.parent().parent().find('[data-name="purchaseLimit"]').attr('value'), 10);
        var freeQty = parseInt($qtyField.parent().parent().find('[data-name="freeQty"]').attr('value'), 10);
        var maxLineItemQty = parseInt($qtyField.attr('data-available'), 10);

        //if category limit is enabled on site preferences, the logic to calculate the quantity fields.
        if (SitePreferences.CATEGOTYLIMITS_ENABLED){
            var categoryLimitData = JSON.parse($this.parent().parent().find('[data-name="categoryLimitData"]').attr('value'));
            var cartCategoryQuantity = 0;
            var cartPrevCategoryQuantity = 0;

            if (quantityValue <= 0) {
                $qtyField.val(0);
                $qtyField.attr('value', 0);
                return
            }

            $.each(categoryLimitData.applicableProductsLineItemArray, function(index, uuid) {
                var cartLineItem = '.cart-line-item[data-uuid="' + uuid + '"]';
                $(cartLineItem + ' input[name=visibleQty], ' + cartLineItem + ' input[name=freeitem], ' + cartLineItem + ' input[name=visibleQtyDiscounted]').each(function(index, qty) {
                    cartPrevCategoryQuantity += Number(qty.getAttribute('value'));
                    cartCategoryQuantity += Number(qty.value);
                });
            });

            // Increase
            if (prevQty < quantityValue) {
                $this.parent().parent().find('.error').remove();

                if (cartCategoryQuantity <= categoryLimitData.totalQtyLimit) {
                    $qtyField.val(quantityValue);
                    $qtyField.attr('value', $qtyField.val());
                    if ($qtyField.val() == categoryLimitData.totalQtyLimit) {
                        addRemoveQtyError($this, true, $qtyField);
                    }
                } else {
                    var valueToSet = (categoryLimitData.totalQtyLimit - cartPrevCategoryQuantity) + prevQty;
                    $qtyField.val(valueToSet);
                    $qtyField.attr('value', valueToSet);
                    $totalQty.val(valueToSet);

                    addRemoveQtyError($this, true, $qtyField);
                }
            } else {
                $qtyField.val($qtyField.val());
                $qtyField.attr('value', $qtyField.val());
                if ($this.parent().parent().find('.quantity-button.increase').hasClass('disabled') && $qtyField.val() < categoryLimitData.totalQtyLimit) {
                    addRemoveQtyError($this, false);
                }
            }

        } else {

            $qtyField.attr('value', $qtyField.val());
            $totalQty.val(quantityValue + freeQty);

            if (($qtyField.val() > (purchaseLimit - freeQty)) && purchaseLimit != maxQtyAvailable) {
                $qtyField.val(purchaseLimit - freeQty);
                $qtyField.attr('value', $qtyField.val());
                $qtyField.parent().parent().find('.error').remove();
                $totalQty.val(quantityValue + freeQty);
                addRemoveQtyError($this, true, $qtyField);
            } else if ($qtyField.val() >= maxQtyAvailable) {
                $qtyField.val(maxLineItemQty - freeQty);
                $qtyField.attr('value', $qtyField.val());
                $qtyField.parent().parent().find('.error').remove();
                $totalQty.val(quantityValue + freeQty);
                if (SitePreferences.STORE_PICKUP) {
                    $('<span class="error">'+ Resources.ERROR_LOW_INVENTORY_QTY.replace('qty', maxQtyAvailable) +'</span>').insertAfter($qtyField.parent());
                } else {
                    $('<span class="error">'+ Resources.ERROR_LOW_INV.replace('maxqty', maxQtyAvailable) +'</span>').insertAfter($qtyField.parent());
                }
            } else if ($totalQty.val() == purchaseLimit) {
                addRemoveQtyError($this, true, $qtyField);
            } else {
                if ($this.parent().parent().find('.quantity-button.increase').hasClass('disabled')) {
                    addRemoveQtyError($this, false);
                }
            }
        }
    });

    $('input[name="visibleQty"]').on('change', function() {
        var item = $(this);
        var freeQty = parseInt(item.parent().find('input[name$="freeQty"]').val(), 10);
        var nonFreeQty = parseInt(item.val(), 10);
        item.parent().find('input[data-name="totalQty"]').val((nonFreeQty + freeQty));
    });
    $('input[name="visibleQtyDiscounted"]').on('change', function() {
        var item = $(this);
        var nonFreeQty = parseInt(item.val(), 10);
        item.parent().find('input[data-name="totalQty"]').val(nonFreeQty);
    });

    $('.quantity-field').on('mouseup', '.input-text', function () {
        $(this).select();
    });

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) {
            return false;
        } else if (e.which === 13 && $(this).val().length > 0) {
            e.preventDefault();
            $addCoupon.click();
        }
    }).on('focus', function() {
        $(this).removeClass('error')
        $(this).attr('placeholder', '')
        $(this).closest('.cart-coupon-code').find('.error-form').empty().addClass('reset')
    });

    $('form input[name$="_couponCodeLoyalty"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) {
            return false;
        } else if (e.which === 13 && $(this).val().length > 0) {
            e.preventDefault();
            $addCouponLoyalty.click();
        }
    });

    certona.init();

    flyInDialog.init();

    // Analytics for order confirmation banner
    if ($('#wrapper').hasClass('pt_cart') && 'digitalData' in window && 'page' in window.digitalData && $('.order-confirm-promo').length > 0) {
        window.digitalData.page.loyaltyJoinAsset = true;
    }

    $(window).on('load', function(){
        //Init carousel
        if ($('.horizontal-carousel').children().length > 1) {
            $('.horizontal-carousel').owlCarousel({
                loop: false,
                responsiveClass: true,
                mouseDrag: true,
                navSpeed: 1000,
                navRewind: false,
                dots: false,
                responsive: {
                    0: {
                        center: true,
                        items: 2,
                        margin: 30
                    },
                    768: {
                        nav: true,
                        items: 4,
                        margin: 15,
                        stagePadding: 15,
                        mouseDrag: false
                    }
                }
            });
        }
    });

    function storageAvailable(type) {
        var storage;
        try {
            storage = window[type];
            var x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }  catch (e) {
            return e instanceof DOMException && (e.code === 22 || e.code === 1014 || e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') && (storage && storage.length !== 0);
        }
    }

    function scAddscRemoveObj(event, productID, basePrice, quantity, price, bopis, autoRefresh) {
        var scAddscRemoveItem = {
            productInfo: {'productID' : productID},
            attributes : {'cart_product_base_price': String(basePrice)},
            quantity: String(quantity),
            price: String(price),
            bopis: String(bopis)
        };
        if (SitePreferences.ORDERGROOVE_ENABLED) {
            scAddscRemoveItem.attributes.cart_product_auto_refresh = String(autoRefresh);
        }
        if (event == 'scAdd') {
            if (!('scAdd' in window.digitalData)) {
                window.digitalData.scAdd = {};
                window.digitalData.scAdd.item = [];
            }
            window.digitalData.scAdd.item.push(scAddscRemoveItem);
        }
        if (event == 'scRemove') {
            if (!('scRemove' in window.digitalData)) {
                window.digitalData.scRemove = {};
                window.digitalData.scRemove.item = [];
            }
            window.digitalData.scRemove.item.push(scAddscRemoveItem);
        }
    }

    function cartInfo(cartObject) {
        var cartInfoArr = [];
        cartObject.cart_product_id.forEach(function (value, index) {
            if (!cartInfoArr.some(function (obj) {
                if (obj.id == value) {
                    obj.price.push(Number(cartObject.cart_product_price[index]));
                    obj.quantity.push(Number(cartObject.cart_product_quantity[index]));
                    obj.unit_price.push(Number(cartObject.cart_product_unit_price[index]));
                    obj.bopis.push(cartObject.cart_product_bopis[index]);
                    if (SitePreferences.ORDERGROOVE_ENABLED) {
                        obj.autoRefresh.push(cartObject.cart_product_auto_refresh[index]);
                    }
                    return true;
                }
            })) {
                var cartInfoArrObj = {
                    id: value,
                    price: [Number(cartObject.cart_product_price[index])],
                    quantity: [Number(cartObject.cart_product_quantity[index])],
                    unit_price: [Number(cartObject.cart_product_unit_price[index])],
                    bopis: [cartObject.cart_product_bopis[index]]
                };
                if (SitePreferences.ORDERGROOVE_ENABLED) {
                    cartInfoArrObj.autoRefresh =  [];
                    cartInfoArrObj.autoRefresh.push(cartObject.cart_product_auto_refresh[index]);
                }
                cartInfoArr.push(cartInfoArrObj);
            }
        });
        return cartInfoArr;
    }

    function triggerscRemovescAdd(loadCartQty, dataCartQty, productId, unitPrice, price, bopis, autoRefresh) {
        if (loadCartQty != dataCartQty) {
            if (loadCartQty > dataCartQty) {
                scAddscRemoveObj('scRemove', productId, unitPrice, (loadCartQty - dataCartQty), price, bopis, autoRefresh);
            } else {
                scAddscRemoveObj('scAdd', productId, unitPrice, (dataCartQty - loadCartQty), price, bopis, autoRefresh);
            }
        }
    }

    function couponAddCallback(code) {
        var isSuccess = false
        $('#couponList .coupon-container[id]').each(function(index, coupon) {
            if ($(coupon)[0].id.split('-')[1] === code) {
                isSuccess = true
            }
        })
        if (isSuccess) {
            var data = {
                couponCode: code
            }
            window._satellite.track('checkout-add-coupon', data);
        }
    }

    function couponRemoveCallback(code) {
        var isSuccess = true
        $('#couponList .coupon-container[id]').each(function(index, coupon) {
            if ($(coupon)[0].id.split('-')[1] === code) {
                isSuccess = false
            }
        })
        if (isSuccess) {
            var data = {
                couponCode: code
            }
            window._satellite.track('checkout-remove-coupon', data);
        }
    }

    if (storageAvailable('localStorage')) {

        $(function() {

            if (window.localStorage.getItem('loadCart') != null && $('#mini-cart .adobe-analytics-cart-data').length != 0) {

                var loadCartArray = JSON.parse(window.localStorage.getItem('loadCart'));
                var dataCartArray = cartInfo($('#mini-cart .adobe-analytics-cart-data').data('tealium'));

                var removedItems = [];
                removedItems = loadCartArray.filter(function (el) {
                    return !dataCartArray.find(function (element) {
                        return element.id === el.id;
                    });
                });

                var isAutoRefreshKey = function(obj) {
                    return 'autoRefresh' in obj;
                }

                if (removedItems.length) {
                    removedItems.forEach(function(value) {
                        scAddscRemoveObj('scRemove', value.id, value.unit_price, value.quantity, value.price, value.bopis, (value.autoRefresh === undefined) ? '' : value.autoRefresh);
                    });
                }

                loadCartArray.filter(function (loadCart) {
                    dataCartArray.find(function (dataCart) {
                        if (loadCart.id === dataCart.id) {
                            if (JSON.stringify(loadCart.quantity) != JSON.stringify(dataCart.quantity)) {
                                if (loadCart.quantity.length == dataCart.quantity.length) {
                                    loadCart.quantity.forEach(function (loadCartQty, loadCartQtyIndex) {
                                        if ((loadCartQty != dataCart.quantity[loadCartQtyIndex])) {
                                            if (loadCartQty > dataCart.quantity[loadCartQtyIndex]) {
                                                scAddscRemoveObj('scRemove', dataCart.id, dataCart.unit_price[loadCartQtyIndex], (loadCartQty - dataCart.quantity[loadCartQtyIndex]), dataCart.price[loadCartQtyIndex], dataCart.bopis[loadCartQtyIndex], (isAutoRefreshKey(dataCart)) ? dataCart.autoRefresh[loadCartQtyIndex] : '');
                                            } else {
                                                scAddscRemoveObj('scAdd', dataCart.id, dataCart.unit_price[loadCartQtyIndex], (dataCart.quantity[loadCartQtyIndex] - loadCartQty), dataCart.price[loadCartQtyIndex], dataCart.bopis[loadCartQtyIndex], (isAutoRefreshKey(dataCart)) ? dataCart.autoRefresh[loadCartQtyIndex] : '');
                                            }
                                        }
                                    });
                                } else if (loadCart.quantity.length > dataCart.quantity.length) {
                                    for (var i = 0; i < dataCart.quantity.length; i++) {
                                        triggerscRemovescAdd(loadCart.quantity[i], dataCart.quantity[i], dataCart.id, dataCart.unit_price[i], dataCart.price[i], dataCart.bopis[i], (isAutoRefreshKey(dataCart)) ? dataCart.autoRefresh[i] : '');
                                    }
                                    for (var j = dataCart.quantity.length; j < loadCart.quantity.length; j++) {
                                        scAddscRemoveObj('scRemove', loadCart.id, loadCart.unit_price[j], loadCart.quantity[j], loadCart.price[j], loadCart.bopis[j], (isAutoRefreshKey(loadCart)) ? loadCart.autoRefresh[j] : '');
                                    }
                                } else {
                                    for (var k = 0; k < loadCart.quantity.length; k++) {
                                        triggerscRemovescAdd(loadCart.quantity[k], dataCart.quantity[k], dataCart.id, dataCart.unit_price[k], dataCart.price[k], dataCart.bopis[k], (isAutoRefreshKey(dataCart)) ? dataCart.autoRefresh[k] : '');
                                    }
                                    for (var l = loadCart.quantity.length; l < dataCart.quantity.length; l++) {
                                        scAddscRemoveObj('scAdd', dataCart.id, dataCart.unit_price[l], dataCart.quantity[l], dataCart.price[l], dataCart.bopis[l], (isAutoRefreshKey(dataCart)) ? dataCart.autoRefresh[l] : '');
                                    }
                                }
                            }
                        }
                    });
                });

                window.localStorage.removeItem('loadCart');
            }

            if (window.localStorage.getItem('loadCart') != null && $('#mini-cart .adobe-analytics-cart-data').length == 0) {
                var cartEmptyItems = JSON.parse(window.localStorage.getItem('loadCart'));
                cartEmptyItems.forEach(function(value) {
                    scAddscRemoveObj('scRemove', value.id, value.unit_price, value.quantity, value.price, value.bopis, (value.autoRefresh === undefined) ? '' : value.autoRefresh);
                });
                window.localStorage.removeItem('loadCart');
            }

        });

    }

    $('#cart-items-form').one('submit', function() {
        if (storageAvailable('localStorage')) {
            window.localStorage.setItem('loadCart', JSON.stringify(cartInfo($('#mini-cart .adobe-analytics-cart-data').data('tealium'))));
        }
        return true;
    });

    if (window.ApplePaySession && document.getElementById('ap-btn-container') !== null) {
        displayApplePay(0);
    }

    //Update cart without refresh remove item button.
    var qtyChangeTimer;
    var qtyChangeInterval = SitePreferences.QTY_FORM_INTERVAL;
    loadCouponsEvents(qtyChangeTimer);
    $('.remove-item').off('click.updateNS').on('click.updateNS', function(e) {
        cartAjaxInit(e, qtyChangeTimer);
        cartAjaxCall(Urls.DeleteItem+'?id='+$(this).attr('name'));
    });

    //Update cart without refresh quantity stepper.
    $('.quantity-button.increase, .quantity-button.decrease').off('click.updateNS').on('click.updateNS', function(e) {
        cartAjaxInit(e, qtyChangeTimer);
        qtyChangeTimer = setTimeout(sendQtyRequest, qtyChangeInterval);
    });

    //Update cart without refresh quantity number input.
    $('.quantity-field input[type="number"]').off('change.updateNS').on('change.updateNS', function(e) {
        cartAjaxInit(e, qtyChangeTimer);
        qtyChangeTimer = setTimeout(sendQtyRequest, qtyChangeInterval);

        var $qtyField = $(this);
        var quantityValue = Number($qtyField.val());
        var prevQty = Number($qtyField.closest('.quantity-field').data('prevqty'));

        if (prevQty !== quantityValue) {
            var isQtyIncrease = prevQty < quantityValue;
            var adobeData = buildAdobeQtyData($qtyField.closest('.quantity-field'), isQtyIncrease, 'number');
            adobeData.adjustmentAmount = Math.abs(quantityValue - prevQty);
            if (adobeData) {
                window._satellite.track('cart-item-quantity-change', adobeData);
            }
        }
    }).keydown(function(e){
        if (e.keyCode == 13) {
            e.preventDefault();
            $('.quantity-field input[type="number"]').trigger('blur');
        }
    });

    $('#update-cart').off('click.updateNS').on('click.updateNS', function(e) {
        if (qtyChangeTimer != null) {
            cartAjaxInit(e, qtyChangeTimer);
            cartAjaxCall(Urls.UpdateCart);
        }
    });

    //Update cart without refresh on visibility change if timer is running
    $(document).off('visibilitychange.updateNS').on('visibilitychange.updateNS', function(e) {
        if (document.visibilityState === 'hidden' && qtyChangeTimer != null) {
            cartAjaxInit(e, qtyChangeTimer);
            cartAjaxCall(Urls.UpdateCart);
        }
    });

    $('#add-coupon').off('click.updateNS').on('click.updateNS', function(e) {
        cartAjaxInit(e, qtyChangeTimer);
        var couponCodeTxt = $('#dwfrm_cart_couponCode').val();
        cartAjaxCall(Urls.AddPromoCode+'?couponCode='+couponCodeTxt+'&isLoyalty='+false, undefined, couponAddCallback.bind(this, couponCodeTxt.toUpperCase()));
    });

    //Update cart without refresh add loyalty/rewards coupon.
    $('#add-coupon-loyalty').off('click.updateNS').on('click.updateNS', function(e) {
        cartAjaxInit(e, qtyChangeTimer);
        var data = $('#cart-items-form').serialize();
        var couponCodeTxt = $('#dwfrm_cart_couponCodeLoyalty').val();
        cartAjaxCall(Urls.AddPromoCode+'?couponCode='+couponCodeTxt+'&isLoyalty='+true, data, couponAddCallback.bind(this, couponCodeTxt.toUpperCase()));
    });

    $('.removeCouponCode').off('click.updateNS').on('click.updateNS', function(e){
        cartAjaxInit(e, qtyChangeTimer);
        var couponCodeTxt = $(e.target).data('couponcode');
        cartAjaxCall(Urls.DeletePromoCode+'?couponCode='+$(this).data('couponcode'), undefined, couponRemoveCallback.bind(this, couponCodeTxt.toUpperCase()));
    });

    $('.cart-line-item').on('change', '.delivery-option', function (e) {
        var cartLineItem = $(this).closest('.cart-row');
        var storePickup = $(this).val() === 'store';

        var data = {
            plid: cartLineItem.data('uuid'),
            isStorePickup: storePickup
        }
        cartAjaxInit(e, qtyChangeTimer);
        cartAjaxCall(Urls.switchDeliveryOptionsC, data);
    });
    relocateDeliveryOptions();
}

/**
 * @private
 * @function
 * @description Events for loyalty offers/rewards accordion
 */
function loadCouponsEvents(qtyChangeTimer) {
    var icons = {
        header: 'iconClosed',
        activeHeader: 'iconOpen'
    };

    var loyaltyAccordions = $('.accordion-loyalty').accordion({
        heightStyle: 'content',
        active: false,
        collapsible: true,
        icons: icons
    }).on('click', function () {
        loyaltyAccordions.not(this).accordion('option', 'active', false);
        var nextAccordion = loyaltyAccordions.not(this).find('.loadCoupons');
        var nextAccordionTitle = loyaltyAccordions.not(this).find('.loadCoupons').find('.title');
        nextAccordionTitle.text(nextAccordionTitle.attr('data-show'));
        if (nextAccordion.hasClass('loaded') && nextAccordion.hasClass('opened')) {
            if (nextAccordion.hasClass('offer')) {
                window.cmCreateElementTag('CLOSE OFFERS', 'SHOPPING BAG');
                nextAccordion.removeClass('opened');
            } else if (nextAccordion.hasClass('reward')) {
                window.cmCreateElementTag('CLOSE REWARDS', 'SHOPPING BAG');
                nextAccordion.removeClass('opened');
            }
        }
    });

    $('.loadCoupons').off('click.updateNS').on('click.updateNS', function() {
        var title = $(this).find('.title');
        var icon = $(this).find('.ui-icon');
        if ($(this).hasClass('ui-state-active')) {
            title.text(title.attr('data-hide'));
            icon.addClass('iconOpen').removeClass('iconClosed');
            $(this).attr('aria-expanded','true');
        } else {
            title.text(title.attr('data-show'));
            icon.addClass('iconClosed').removeClass('iconOpen');
            $(this).attr('aria-expanded','false');
        }
        if ($(this).hasClass('offer') && 'cmCreateElementTag' in window) {
            if ($(this).hasClass('ui-state-active')) {
                window.cmCreateElementTag('SHOW OFFERS', 'SHOPPING BAG');
                $(this).addClass('opened');
            } else {
                window.cmCreateElementTag('CLOSE OFFERS', 'SHOPPING BAG');
                $(this).removeClass('opened');
            }
        } else if ($(this).hasClass('reward') && 'cmCreateElementTag' in window) {
            if ($(this).hasClass('ui-state-active')) {
                window.cmCreateElementTag('SHOW REWARDS', 'SHOPPING BAG');
                $(this).addClass('opened');
            } else {
                window.cmCreateElementTag('CLOSE REWARDS', 'SHOPPING BAG');
                $(this).removeClass('opened');
            }
        }
        loadCouponsAjax($(this),qtyChangeTimer);
    });
    $('.loadCoupons').keypress(function(e) {
        if (e.which == 13) {
            $(this).click();
        }
    });
}
function loadCouponsAjax ($el,qtyChangeTimer) {
    if (!$el.hasClass('loaded')) {
        var promoType = $el.hasClass('offer') ? 'offer' : 'reward';
        var url = util.appendParamToURL(Urls.rewardsCoupons, 'promotype', promoType);
        var $couponsList = $el.parents('.accordion-loyalty').find('.wrapper-coupons');
        var $thisAccordion = $el.parents('.accordion-loyalty');
        var resMessage = (promoType == 'offer') ? Resources.LOYALTY_GATHRING_OFFERS : Resources.LOYALTY_GATHRING_REWARDS;
        $.ajax({
            type : 'GET',
            url : url,
            dataType : 'html',
            beforeSend : function() {
                $couponsList.html('<div class="icon-wrapper">' +
                        '<img src="' + Urls.spinningIcon + '" alt="' + Resources.LOYALTY_PAGE_LOADING + '" aria-label="' + Resources.LOYALTY_PAGE_LOADING + '"/>' +
                        '<div class="text">' + resMessage + '</div>' +
                    '</div>');
            }
        }).done(function(data) {
            $thisAccordion.find('.icon-wrapper').remove();
            $couponsList.empty().append(data);
            $thisAccordion.find('form.cart-coupon-codes').attr('action', $('#cart-items-form').attr('action'));
            if (promoType == 'offer') {
                $thisAccordion.find('.add-coupon').on('click', function(e){
                    var couponCodeTxt = $(this).data('code');
                    cartAjaxInit(e,qtyChangeTimer);
                    cartAjaxCall(Urls.AddPromoCode+'?couponCode='+couponCodeTxt+'&isLoyalty='+false);
                });
            } else if (promoType == 'reward') {
                $thisAccordion.find('.add-coupon-loyalty').on('click', function(e){
                    var couponCodeTxt = $(this).data('code');
                    cartAjaxInit(e,qtyChangeTimer);
                    cartAjaxCall(Urls.AddPromoCode+'?couponCode='+couponCodeTxt+'&isLoyalty='+true);
                });
            }
            var errorLoad = $thisAccordion.find('a.loadCoupons');
            if (errorLoad.length > 0) {
                if ('digitalData' in window) {
                    window.digitalData.user.attributes.loyaltyError = true;
                }
                errorLoad.on('click', function(e){
                    e.preventDefault();
                    cartAjaxInit(e,qtyChangeTimer);
                    loadCouponsAjax(errorLoad,qtyChangeTimer);
                })
            }
        });
        $el.addClass('loaded');
    }
}
/**
 * @private
 * @function
 * @description Initializes sticky drawer functionality on cart page
 */
function initStickyDrawer() {
    // Not initialized on the empty cart page
    if (!$('.cart-empty').length) {
        var sd = $('#sticky-drawer'),
            sdbg = $('#sticky-drawer-bg'),
            button = sd.find('.drawer-button'),
            drawer = sd.find('.drawer'),
            offset = $('#main-actions').offset().top,
            body = $('body'),
            cookiecta = $('footer .cookie-notice-wrapper').eq(0);

        if (sd.is(':visible')) {

            if (cookiecta.is(':visible')) {
                sd.css('bottom', '70px');
            }
            button.on('click', function(e) {
                e.preventDefault();
                $(this).toggleClass('open');

                if ($(this).hasClass('open')) {
                    body.unbind('touchmove');
                    sd.css('z-index', '1000');
                    drawer.addClass('expanded');
                    sdbg.fadeIn();
                    body.css({
                        'overflow-y': 'scroll',
                        'position': 'fixed',
                        'max-width': '100%'
                    });
                    button.attr('aria-expanded', 'true');
                } else {
                    body.bind('touchmove', function(e){e.preventDefault()});
                    drawer.removeClass('expanded');
                    sdbg.fadeOut();
                    sd.css('z-index', '101')
                    body.css({
                        'position': 'static'
                    });
                    button.attr('aria-expanded', 'false');
                }
            });
            $('#sticky-drawer-bg').on('click', function() {
                button.trigger('click');
            });

            $(window).scroll(_.throttle(function() {
                var ua = window.navigator.userAgent;
                var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
                var webkit = !!ua.match(/WebKit/i);
                var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
                if (offset - window.innerHeight <= $(window).scrollTop()) {
                    sd.css('bottom', '-120px');
                }

                if (offset - window.innerHeight > $(window).scrollTop()) {
                    // landscape view
                    if (window.innerHeight < window.innerWidth && iOSSafari){

                        // when cookie alert bar shows
                        switch (cookiecta.is(':visible')){
                            case true:
                                sd.css('bottom','70px');
                                if ($(window).scrollTop()<=0) {
                                    sd.css('bottom','70px')
                                }
                                var lastScrollTop = 0;
                                var st = $(window).scrollTop();
                                if (st > lastScrollTop){

                               // downscroll code
                                } else {
                                    sd.css('bottom','70px');
                              // upscroll code
                                }
                                lastScrollTop = st;
                                break;

                            case false:
                                sd.css('bottom','15px')
                                if ($(window).scrollTop()<=0) {
                                    sd.css('bottom','70px')
                                }
                                var lastScrollTop2 = 0;
                                var st2 = $(window).scrollTop();
                                if (st2 > lastScrollTop2){
                                    sd.css('bottom','15px !important');

                               // downscroll code
                                } else {
                                // sd.css('bottom', '15px');
                              // upscroll code
                                }
                                lastScrollTop2 = st2;
                                break;
                            default:

                        }
                    } else {
                        if (cookiecta.is(':visible')) {
                            sd.css('bottom', '70px');
                        } else {
                            sd.css('bottom', '15px');
                        }
                    }

                }
            }, 500));
        }
    }
}

/**
 * @private
 * @function
 * @description Save the Radial RDFUID to session.
 */
function saveRdfuid () {
    var $form = $('#rdfuid-form');
    var interval = setInterval(function () {
        if ($form.find('#RDFUID').val() != '') {
            clearInterval(interval)
            $.ajax({
                data: $form.serialize(),
                type: $form.attr('method'),
                url: $form.attr('action')
            });
        }
    }, 400);
}

//Function to toggle the store details
function toggleStoreDetails() {
    if ($(this).hasClass('active')) {
        $('.cart-show-bopis-store-detail-hidden-info .cart-show-collapsed-info').removeClass('hide').addClass('hide');
        $(this).removeClass('active');
        $(this).attr('aria-expanded',false);
    } else {
        $(this).addClass('active');
        $('.cart-show-bopis-store-detail-hidden-info .cart-show-collapsed-info').removeClass('hide');
        $(this).attr('aria-expanded',true);
    }
}

var handleKeyChangesOnStoreDetails = function (event) {
    if (event.keyCode === 13 || event.keyCode == 32) {
        if ($(this).hasClass('active')) {
            $('.cart-show-bopis-store-detail-hidden-info .cart-show-collapsed-info').removeClass('hide').addClass('hide');
            $(this).removeClass('active');
            $(this).attr('aria-expanded',false);
        } else {
            $(this).addClass('active');
            $('.cart-show-bopis-store-detail-hidden-info .cart-show-collapsed-info').removeClass('hide');
            $(this).attr('aria-expanded',true);
        }
    }
}


var relocateDeliveryOptions = function() {
    $('.cart-line-item').each(function() {
        if ($(this).find('.desktop-delivery-options').length === 0) return;
        var moveHTMLContentFromMobileToDesktop = $(this).attr('data-move-content-mob-to-desk') === 'true';
        if ($(window).width() < 768 && !moveHTMLContentFromMobileToDesktop) {
            $(this).find('.mobile-delivery-options').html($(this).find('.desktop-delivery-options').html());
            $(this).find('.desktop-delivery-options').html('');
            $(this).attr('data-move-content-mob-to-desk', 'true');
        } else if ($(window).width() >= 768 && moveHTMLContentFromMobileToDesktop) {
            $(this).attr('data-move-content-mob-to-desk', 'false');
            $(this).find('.desktop-delivery-options').html($(this).find('.mobile-delivery-options').html());
            $(this).find('.mobile-delivery-options').html('');
        }
    });
}

exports.init = function () {
    //we need giftcert.init for possibility to edit gift card from Cart page
    initializeDOM();
    initializeEvents();
    loadCouponsEvents();
    saveRdfuid();
    relocateDeliveryOptions();


    ordergroove();
    account.initCartLogin();
    account.initMyAccountRewardsPopup();
    account.initBonusActivate();
    /**
     * on success apply coupon - scroll window to coupon form
     */
    if ($('#firescroll').length > 0) {
        if ($('.error-form').length <= 0){
            $('html,body').animate({scrollTop: $('.cart-footer').offset().top - 100});
        }
    }

    //BBWDP-9218 - Ensures Cart URL is /cart and set it if it is not
    if (!window.location.href.includes('/cart')){
        window.history.pushState(null, null, Urls.cartShow);
    }

    //Click and Key events for store details
    $('body').off('click', '.cart-show-bopis-store-detail .cart-show-selected-store-details-link', toggleStoreDetails);
    $('body').on('click', '.cart-show-bopis-store-detail .cart-show-selected-store-details-link', toggleStoreDetails);
    $('body').off('keyup', '.cart-show-bopis-store-detail .cart-show-selected-store-details-link', handleKeyChangesOnStoreDetails);
    $('body').on('keyup', '.cart-show-bopis-store-detail .cart-show-selected-store-details-link', handleKeyChangesOnStoreDetails);
    $(window).resize(function() {
        relocateDeliveryOptions();
    });
    setTimeout(function () {
        $('.pt_cart').removeClass('dom-loading');
    }, 0);
};
/*BBWRKS-6514-Customers-required-to-be-a-logged in user for checkout*/
$('.cart-action-checkout, #checkout-proceed-pay, #sticky-drawer #checkout').on('click', function (e) {
    var loginAuthentication = false;
    $('.cart-offer og-optin-toggle').each(function () {
        var attr = $(this).attr('subscribed');
        if (typeof attr!== 'undefined' && attr !== false) {
            if (!window.User.authenticated && !window.User.registered) {
                loginAuthentication = true;
                return false;
            }
        }
    })

    if (SitePreferences.ORDERGROOVE_ENABLED) {
        if (loginAuthentication!='') {
            e.preventDefault();
            window.setTimeout(function(){
                $('html,body').animate({
                    scrollTop: $('.unsubscribe-error').offset().top-300
                },100);
            });
            $('.unsubscribe-error').show();
            $('#sticky-drawer-bg').hide();
            return false;
        }
    }
})

/*This is used by the cart merge dismissible toast message, but can be reused for any dismissible toast message, it only sets
the listener if the toast message exists to limit resource usage.*/
if (('.js-toast-dismiss').length > 0) {
    $('.js-toast-dismiss').on('click', function () {
        $(this).parents('.toast-message-container').remove();
    })
}
