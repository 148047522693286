'use strict';

var oneTrustDNS = {
    oneTrustDNSSubmit: function() {
        var isGPCEnabledInBrowser = window.navigator.globalPrivacyControl;
        if (isGPCEnabledInBrowser) {
            var oneTrustDNSSubmitURL = window.Urls.oneTrustDNSSubmit;

            $.ajax({
                url: oneTrustDNSSubmitURL,
                method: 'GET'
            });
        }
    }
}

module.exports = oneTrustDNS;
