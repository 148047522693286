'use strict';

var toggleErrorMessage = function ($insertAfterEle, errorMsg) {
    if (errorMsg) {
        if (!($insertAfterEle.parents('.pdp-main').find('.pdp-quantity-error-msg').length)) {
            $('<div id="pdpQuantityErrMsg" class="pdp-quantity-error-msg" role="alert"></div>').insertAfter($insertAfterEle);
        }
        $insertAfterEle.next('.pdp-quantity-error-msg').html(errorMsg);
    } else {
        $insertAfterEle.parents('.pdp-main').find('.pdp-quantity-error-msg').remove();
    }
}

/**
 * @listener
 * @description Listens for the click even on the quantity decrease button to update the quantity
 */

var updateQuantityOnChange = function () {
    var $qtyField = $(this);
    var $qtyForm = $qtyField.parents('form');
    var maxValue = parseInt($qtyForm.find('input[name="maxQtyAvailable"]').val(), 10);
    var totalQty = parseInt($qtyForm.find('input[name="totalQty"]').val(), 10); 
    var purchaseLimit = parseInt($qtyForm.find('input[name="purchaseLimit"]').val(), 10);
    var $atbButton = $qtyForm.find('#add-to-cart');
    
    if (parseInt($qtyField.val(), 10) <= 0) {
        $qtyField.val(1);
    }

    if ($qtyField.val() >= maxValue && totalQty == maxValue){
        $qtyField.val(maxValue);
        var errorMsg = Resources.ERROR_LOW_INV.replace('maxqty', maxValue);
        if ($qtyForm.parents('.pdp-main').find('.bopis-pick-from-store-option').is(':checked')) {
            errorMsg = Resources.PICK_UP_INSTORE_LOW_INV_ERROR;
        }
        toggleErrorMessage($atbButton, errorMsg);
        // Max Qty Scenarios
    } else if ($qtyField.val() > maxValue && maxValue == purchaseLimit){
        $qtyField.val(maxValue);
        toggleErrorMessage($atbButton, Resources.MAX_QTY_ADJUSTED.replace('maxqty', purchaseLimit));
    } else if ($qtyField.val() < maxValue){
        toggleErrorMessage($atbButton, '');
    }
    // Update quantity input value attribute
    $qtyField.attr('value', $qtyField.val());
}

    /**
     * @listener
     * @description Listens for the click even on the quantity increase button to update the quantity
     */

var handleIncreaseQtyClick = function () {

    var $this = $(this);
    var $qtyForm = $this.parents('form');
    var $qtyField = $this.parents('form').find('input[name="Quantity"]');
    var totalQty = parseInt($this.parents('form').find('input[name="totalQty"]').val(), 10);
    var maxValue = parseInt($this.parents('form').find('input[name="maxQtyAvailable"]').val(), 10);
    var purchaseLimit = parseInt($this.parents('form').find('input[name="purchaseLimit"]').val(), 10);
    var AddtoBagCountPlus = maxValue;
    var $atbButton = $qtyForm.find('#add-to-cart');

    if ($qtyField.val() <= maxValue) {
        AddtoBagCountPlus = Number($qtyField.val()) + 1;
        $qtyField.val(AddtoBagCountPlus);
    }
    
    if (parseInt($qtyField.val(), 10) <= 0) {
        $qtyField.val(1);
    }
    if (AddtoBagCountPlus > 1) {
        $('.quantity-button.decrease').removeAttr('aria-disabled');
    }
    
    toggleErrorMessage($atbButton, '');
    
    if ($qtyField.val() >= maxValue && totalQty == maxValue){
        $qtyField.val(maxValue);
        var errorMsg = Resources.ERROR_LOW_INV.replace('maxqty', maxValue);
        if ($qtyForm.parents('.pdp-main').find('.bopis-pick-from-store-option').is(':checked')) {
            errorMsg = Resources.PICK_UP_INSTORE_LOW_INV_ERROR;
        }
        toggleErrorMessage($atbButton, errorMsg);
        // Max Qty Scenarios
    } else if ($qtyField.val() > maxValue && maxValue == purchaseLimit){
        $qtyField.val(maxValue);
        toggleErrorMessage($atbButton, Resources.MAX_QTY_ADJUSTED.replace('maxqty', purchaseLimit));
    } else if ($qtyField.val() < maxValue){
        toggleErrorMessage($atbButton, '');
    }
    
    /******** need to clean up next 2 lines *********/
    if ($('button[name$="updateCart"]').length && $this.parents().find('input[name="isQuickView"]').val() == 'false') {
        $('button[name$="updateCart"]').trigger('click');
    }
    // Update quantity input value attribute
    $qtyField.attr('value', $qtyField.val());
}

var handleDecreaseQtyClick = function () {
    var $this = $(this);
    var $qtyField = $this.parents('form').find('input[name="Quantity"]');
    var maxValue = parseInt($this.parents('form').find('input[name="maxQtyAvailable"]').val(), 10);
    if ($qtyField.val() > 1) {
        var AddtoBagMinus = Number($qtyField.val()) - 1;
        $qtyField.val(AddtoBagMinus);
        $qtyField.trigger('change');
        
        /******** need to clean up next 2 lines *********/
        if ($('button[name$="updateCart"]').length && $this.parents().find('input[name="isQuickView"]').val() == 'false') {
            $('button[name$="updateCart"]').trigger('click');
        }
        if (AddtoBagMinus === 1) {
            $this.attr('aria-disabled', true);
        }
    }
    /******** need to clean up next 2 lines *********/
    if ($qtyField.val() >= maxValue) {
        $qtyField.val(maxValue);
    }
    // Update quantity input value attribute
    $qtyField.attr('value', $qtyField.val());
}

module.exports = function() {
    $('body').off('keyup change', '.quantity-field .input-text', updateQuantityOnChange);
    $('body').on('keyup change', '.quantity-field .input-text', updateQuantityOnChange);
    $('body').off('click', '.product-add-to-cart .qty .quantity-button.increase', handleIncreaseQtyClick);
    $('body').on('click', '.product-add-to-cart .qty .quantity-button.increase', handleIncreaseQtyClick);
    $('body').off('click', '.product-add-to-cart .qty .quantity-button.decrease', handleDecreaseQtyClick);
    $('body').on('click', '.product-add-to-cart .qty .quantity-button.decrease', handleDecreaseQtyClick);
};

