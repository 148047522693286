/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var tealium = require('../tealium');

var common = {
    init: function (){
        common.ibmTriggers();

        //Email sign up points
        $('#email-alert-signup').on('submit', function() {
            var email = $('#email-alert-address').val();
            common.emailSignUp(email);
        });
        $('input[name$="profile_customer_addtoemaillist"]').on('change', function() {
            if ($(this).prop('checked')) {
                var email = (window.utag_data.user) ? window.utag_data.user.profile.attributes.customer_email : $('input[name$="profile_customer_email"]').val();
                common.emailSignUp(email);
            }
        });
        $('input[name$="billing_billingAddress_addToEmailList"]').on('change', function() {
            if ($(this).prop('checked')) {
                var email = window.utag_data.user.profile.attributes.customer_email;
                common.emailSignUp(email);
            }
        });
        common.browserInfoDetect(window.navigator.userAgent);
        window.digitalData.page.attributes.page_url = window.location.href;
    },
    ibmTriggers: function (){
        window.cmAddToCart = function (){
            window.console.log('Coremetrics event triggered: cmAddToCart');
            return true;
        }
        window.cmFilterChanged = function (exploreAttributes){
            window.digitalData.page.attributes.exploreAttributes = exploreAttributes;
            window.console.log('Coremetrics event triggered: cmFilterChanged');
            return true;
        }
    },
    browserInfoDetect: function(UA) {
        var md = new window.MobileDetect(UA);
        var attributes = window.digitalData.user.attributes || {};
        if (md.mobile() || md.tablet()) {
            attributes.mobile_device_vendor = window.navigator.vendor;
            attributes.mobile_device_model = md.phone() || md.tablet();
            attributes.mobile_device_name = md.phone() || md.tablet();
            attributes.mobile_device_network = ''; // need clarification on this
            attributes.mobile_device_type = md.phone() ? 'phone' : md.tablet() ? 'tablet' : '';
        }
        attributes.operating_system = window.navigator.platform + '';
        attributes.javascript_version = common.getJsVersion();
        attributes.java_enabled = window.navigator.javaEnabled() + '';
        attributes.flash_support = common.isFlashEnabled() + '';
        attributes.cookie_support = window.navigator.cookieEnabled + '';
        attributes.color_depth = window.screen.colorDepth + '';
        attributes.touch_screen = common.isTouchScreen();
        attributes.screen_resolution = window.screen.width + 'x' + window.screen.height;
        attributes.video_3gp_support = common.isSupportedVideoFormat('3gp');
        attributes.video_mp4_support = common.isSupportedVideoFormat('mp4');
        attributes.video_wmv_support = common.isSupportedVideoFormat('wmv')

        window.digitalData.user.attributes = attributes;
    },
    isFlashEnabled: function(){
        return window.navigator.plugins['Shockwave Flash'] !== undefined;
    },
    isTouchScreen: function(){
        return !!('ontouchstart' in window) || !!('msmaxtouchpoints' in window.navigator);
    },
    isSupportedVideoFormat: function(format){
        var formats = {
            'mp4': 'video/mp4',
            '3gp': '', // seems like there is not browser that can play 3gp
            'wmv': '' //seems like there is not browser that can play wmv
        }
        var elem = document.createElement('video');
        return elem.canPlayType(formats[format]) !== '';
    },
    getJsVersion: function(){
        var jsv = {
            versions: ['1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9', '2.0']
        };
        var d = document;
        for (var i = 0; i < jsv.versions.length; i++) {
            var g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
            g.setAttribute('language', 'JavaScript' + jsv.versions[i]);
            g.text = 'window.javascriptVersion="' + jsv.versions[i] + '";';
            s.parentNode.insertBefore(g, s);
        }
        return window.javascriptVersion;
    },
    emailSignUp: function (email) {
        tealium.eventCount += 1;
        var obj = {
            'customer_email' : email,
            'event_value' : tealium.eventCount + '',
            'event_category' : 'Email Signup',
            'event_action' : 'email signup',
            'event_label' : 'email signup',
            'event_name' : 'email_signup'
        }
        var dd_obj_profileInfo = {
            'profileID' : email,
            'profileEmail' : email
        }
        window.digitalData.user.profile.profileInfo = dd_obj_profileInfo;
        tealium.triggerEvent(obj);
    },

    getPageId: function () {
        var pageID = '';
        if ('digitalData' in window && window.digitalData.page && window.digitalData.page.pageInfo && window.digitalData.page.pageInfo.pageID) {
            pageID = window.digitalData.page.pageInfo.pageID;
        }
        return pageID;
    },

    bopisSetStorePopUp : function (elementId) {
        if ('cmCreateElementTag' in window) {
            window.cmCreateElementTag(elementId, 'BOPIS Set Store', '-_--_--_-' + common.getPageId());
        }
    },
    returnTrackingParams: function(values) {
        var delimiter = '-_-',
            params = [],
            unqiueID = [];
        // Sort array by index 1 -> 50
        values = values.sort(function(a,b){return a.index - b.index});
        values.forEach(function(param) {
            // Prevent indexes greater than 50 and negate duplicate indexes
            if (param.index <= 50 && unqiueID.indexOf(param.index) == -1){
                unqiueID.push(param.index);
                params.push(param.value);
            }
        });
        return delimiter+params.join(delimiter);
    }
}

exports.init = common.init;
exports.getPageId = common.getPageId;
exports.bopisSetStorePopUp = common.bopisSetStorePopUp;
exports.returnTrackingParams = common.returnTrackingParams;
