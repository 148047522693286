'use strict';


var giftcert = require('../giftcert'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    validator = require('../validator'),
    states = require('./checkout/states'),
    progress = require('../progress'),
    pwHadFocus = false,
    wcagLoadSpinner = require('../accessibleLoadingSpinner');

var pagination = document.querySelector('.js-pagination-select');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel,
 *              delete)
 * @param {Element}
 *            form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $('#edit-address-form');

    $form.find('input[name="format"]').remove();
    tooltip.init();
    // $("<input/>").attr({type:"hidden", name:"format",
    // value:"ajax"}).appendTo(form);

    states.init();
    $form.on('change', 'select[name$="_country"]', states.init);
    $form.on('click', '.apply-button', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof (data) !== 'string') {
                if (data.success) {
                    var loyaltyStatus = $('h1.login-name-header').data('loyalty');
                    var loyaltyID = $('h1.login-name-header').data('idloyalty');
                    fireAnalyticsData(loyaltyID, loyaltyStatus, window.digitalData.user.profile.profileInfo.profileEmailHash);
                    window.location = window.location.href + '?showstatusmsg=true';
                } else {
                    window.alert(data.message);
                    return false;
                }
            } else {
                $('#dialog-container').html(data);
                account.init();
                tooltip.init();
                initializeAddressForm();
            }
        });
    }).on('click', '.cancel-button, .close-button', function (e) {
        e.preventDefault();
        dialog.close();
    }).on('click', '.delete-button', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            var url = util.appendParamsToUrl(Urls.deleteAddress, {
                AddressID: $form.find('#addressid').val(),
                format: 'ajax'
            });
            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    window.location = window.location.href + '?showstatusmsg=true';
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                    window.location = window.location.href + '?showstatusmsg=true';
                } else {
                    window.location = window.location.href + '?showstatusmsg=true';
                }
            });
        }
    });
    validator.init();
}

/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder() {
    $('.order-items').find('li.hidden:first').prev('li').append('<a class="toggle">View All</a>').children('.toggle').click(function () {
        $(this).parent().siblings('li.hidden').show();
        $(this).remove();
    });
}

/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) {
        return;
    }

    addresses.on('click', '.address-edit, .address-create', function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
                title: this.title,
                width: 540,
                dialogClass: 'address-dialog',
                open: initializeAddressForm
            }
        });
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    window.location = window.location.href + '?showstatusmsg=true';
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    });
}

/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });
    
    //remove the stored payment links from my account dashboard if the sitepreferences is disabled.
    if (!SitePreferences.STORED_PAYMENT_ENABLED){
        $('li.js-stored-payment-link').remove();
    }

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) {
        return;
    }
    
    $('input[name*="selectedBillingAddressID"]').on('click change', function() {
        $('input[name*="selectedBillingAddressID"]').each(function(){
            $(this).closest('div.form-row').removeClass('errorSaveAddress');
            $(this).closest('div.form-row').removeClass('activeSaveAddress');
            if ($(this).is(':checked') === true) {
                $(this).closest('div.form-row').addClass('activeSaveAddress');
            }
        });
    });
    
    $('form[name="saved-card-actions"] button.delete').on('click', function (e) {
        e.preventDefault();
        
        var isDefaultCard = $($(this).closest('form#creditcards_defaultcard')).length;
        var deleteCardButton = $(this);

        if (SitePreferences.ORDERGROOVE_ENABLED) {
            $.ajax({
                url: Urls.setOGSubscriptionFlag,
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    var isARSubscribedUser = data.arSubscribed;
                    if (isDefaultCard && isARSubscribedUser == true) {
                        //Restrict AutoRefresh User to remove default card
                        if ($('.payment-list p.error-text').length == 0) {
                            $('.default-card').after('<p class=error-text>' + Resources.AUTOREFRESH_DEFAULTCARD_ERROR + '</p>');
                            $('.default-card').addClass('errorMsg');
                        }
                        return;
                    } else {
                        progress.show($('.paymentslist'));
                        // override form submission in order to prevent refresh issues
                        var button = $(deleteCardButton);
                        $('<input/>').attr({
                            type: 'hidden',
                            name: button.attr('name'),
                            value: button.attr('value')
                        }).appendTo($(deleteCardButton));
                        var formdata = $(deleteCardButton).closest('form').serialize();
                        var deleteUrl = $(deleteCardButton).data('url');

                        dialog.open({
                            url: Urls.savedCardDeletePopup,
                            options: {
                                dialogClass: 'payment-method-delete',
                                width: 540,
                                close: function () {

                                },
                                open: function () {
                                    $('.delete-card-button').on('click', function () {
                                        $.ajax({
                                            type: 'POST',
                                            url: deleteUrl,
                                            data: formdata
                                        }).done(function (data) {
                                            if (data.success) {
                                                progress.hide();
                                                var redirectURL = Urls.paymentsList;
                                                if (isDefaultCard) {
                                                    redirectURL = util.appendParamToURL(redirectURL, 'defaultCardDeleted', 'true');
                                                } else {
                                                    redirectURL = util.appendParamToURL(redirectURL, 'cardDeleted', 'true');
                                                }
                                                page.redirect(redirectURL);
                                            }
                                        });
                                    });

                                    $('.delete-popup-cancel').on('click', function () {
                                        dialog.close();
                                    });
                                }
                            }
                        });
                    }
                }
            });
        } else { // if OG is off then do not make ajax call; retain delete card functionality
            progress.show($('.paymentslist'));
            // override form submission in order to prevent refresh issues
            var button = $(deleteCardButton);
            $('<input/>').attr({
                type: 'hidden',
                name: button.attr('name'),
                value: button.attr('value')
            }).appendTo($(deleteCardButton));
            var formdata = $(deleteCardButton).closest('form').serialize();
            var deleteUrl = $(deleteCardButton).data('url');

            dialog.open({
                url: Urls.savedCardDeletePopup,
                options: {
                    dialogClass: 'payment-method-delete',
                    width: 540,
                    close: function () {

                    },
                    open: function () {
                        $('.delete-card-button').on('click', function () {
                            $.ajax({
                                type: 'POST',
                                url: deleteUrl,
                                data: formdata
                            }).done(function (data) {
                                if (data.success) {
                                    progress.hide();
                                    var redirectURL = Urls.paymentsList;
                                    if (isDefaultCard) {
                                        redirectURL = util.appendParamToURL(redirectURL, 'defaultCardDeleted', 'true');
                                    } else {
                                        redirectURL = util.appendParamToURL(redirectURL, 'cardDeleted', 'true');
                                    }
                                    page.redirect(redirectURL);
                                }
                            });
                        });

                        $('.delete-popup-cancel').on('click', function () {
                            dialog.close();
                        });
                    }
                }
            });
        }
    });
    
    $('form[name="saved-card-actions"] button.edit, form[name="saved-card-actions"] button.set-default').on('click', function (e) {
        e.preventDefault();
        //progress.show($('.paymentslist'));
        
        $('.add-edit-headline').text(Resources.EDIT_CARD_HEADLINE);
        $('#CreditCardForm').find('.form-credit-card-section').attr('disabled','disabled');
        
        var button = $(this);
        var isMakeDefault = $(button).hasClass('set-default');
        
        // override form submission in order to prevent refresh issues
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value')
        }).appendTo($(this));
        var formdata = $(this).closest('form').serialize();
        var editUrl = $(this).data('url');
        $.ajax({
            type: 'POST',
            url: editUrl,
            data: formdata
        }).done(function (data) {
            if (data.success){
                //Populate the form field values
                $('input[name*=newcreditcard_owner]').val(data.owner);
                $('select[name*=newcreditcard_type]').val(data.cardType);
                $('input[name*=newcreditcard_number]').val(data.cardNumber);
                $('select[name*=newcreditcard_expiration_month]').val(data.expMonth);
                $('select[name*=newcreditcard_expiration_year]').val(data.expYear);
                
                if (data.defaultCard || isMakeDefault){
                    $('.save-default-card').prop('checked', true);
                } else {
                    $('.save-default-card').prop('checked', false);
                }
                
                
                //remove all the input fields attached to the event
                $('input[name*="makeDefault"]').remove();
                $('input[name*="PIUUID"]').remove();
                
                $('<input/>').attr({
                    type: 'hidden',
                    name: 'makeDefault',
                    value: $('.save-default-card').is(':checked')
                }).appendTo($('.add-new-section-cta #credit-card-edit'));
                $('<input/>').attr({
                    type: 'hidden',
                    name: 'PIUUID',
                    value: data.UUID
                }).appendTo($('.add-new-section-cta #credit-card-edit'));
                
                //Add disable class to the form
                $('#CreditCardForm').addClass('disabled-form');
                
                $('#credit-card-edit').show();
                $('#credit-card-create').hide();
                
                //make the saved billing address checked in the form 
                if (data.addressID){
                    var $selectedAddress = document.getElementById('is-'+data.addressID);
                    $($selectedAddress).prop('checked', true);
                    $($selectedAddress).closest('div.form-row').addClass('activeSaveAddress');
                }
                
                //Expand the address form fields
                $('.add-new-card-cta button').hide();
                $('.my-other-cards .payment-list').hide();
                $('.my-payment-add-new-card-form').show();
                
                //Clear previous validation errors
                $('#CreditCardForm').valid();
                $('.form-validation-error-message').hide();
                $('.billing-address-error-message').hide();
                $('.saved-addresses .radio-select input[name*="selectedBillingAddressID"]').each(function(){
                    $(this).closest('div.form-row').removeClass('errorSaveAddress');
                });
                
            }
        });
        
    });
    
    initCreditCardSubmit();
    validator.init();
    
    //ADA attribute for Make Default card check box
    $('.show-hide-field-wrapper label span').attr({'aria-label':'Make Default Card', 'role':'checkbox'});
    
    //handle the states field functionality
    var $form = $('#CreditCardForm');
    $form.on('change', 'select[name$="_country"]', states.init);
    
}

/**
 * @private
 * @function
 * @description initialize the credit card submit events
 */
function initCreditCardSubmit(){
    //credit card form submit handle
    $('.add-new-section-cta button#credit-card-create, .add-new-section-cta button#credit-card-edit').on('click', function(e){
        e.preventDefault();
        var button = $(this);
        //validate the address radio button check if the add new address form is collapsed.
        if (!$('.stored-payment-billing-address').is(':visible')){
            var addressSeletced = false;
            $('input[name*="selectedBillingAddressID"]').each(function(){
                if ($(this).is(':checked') && !addressSeletced){
                    addressSeletced = true;
                }
            });
            if ($('#CreditCardForm').valid() && addressSeletced){
                $('<input/>').attr({
                    type: 'hidden',
                    name: button.attr('name'),
                    value: button.attr('value')
                }).appendTo($(this));
                $(this).closest('form').submit();
            } else {
                if (!$('#CreditCardForm').valid()){
                    $('.form-validation-error-message').show();
                }
                if (!addressSeletced) {
                    $('input[name*="selectedBillingAddressID"]').each(function(){
                        $(this).closest('div.form-row').addClass('errorSaveAddress');
                    });
                    $('.billing-address-error-message').show();
                }
            }
        } else {
            if (!$('#CreditCardForm').valid()){
                $('.form-validation-error-message').show();
                $('.billing-address-error-message').show();
                setTimeout(function(){
                    $('.form-validation-error-message').attr('tabindex', 0).focus();
                }, 1000);
            } else {
                $('<input/>').attr({
                    type: 'hidden',
                    name: button.attr('name'),
                    value: button.attr('value')
                }).appendTo($(this));
                
                $(this).closest('form').submit();
            }
        }
    });
}

/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initRegistrationTag() {
    $('#dwfrm_login').on('submit', function (e) {
        e.preventDefault();
        if (!$(this).valid()) {
            return false;
        }
        var errorDiv = $(this).find('#account-error-response');
        var usernameInput = $(this).find('input[name$="_login_username"]');
        var passwordInput = $(this).find('input[name$="_login_password"]');
        var bothInputFields = usernameInput.add(passwordInput);
        errorDiv.empty();
        var submitButton = $(this).find('button[type="submit"]').attr('name');
        var data = $(this).serialize() + '&' + submitButton + '=x' + '&format=ajax';
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        }).done(function (response) {
            if (response.emailHash != null) {
                window.redirecturl = ('targetUrl' in response && response.targetUrl) ? response.targetUrl : Urls.accountLanding;
                $.ajax({
                    type: 'GET',
                    url: Urls.getRewardsDataShort,
                    dataType: 'json'
                }).done(function (data) {
                    if ('cmCreateElementTag' in window) {
                        var loyaltyStatus = data.rewardsDataShort.loyaltyStatus ? data.rewardsDataShort.loyaltyStatus : '';
                        var pageID = window.digitalData.page.pageInfo.pageID ? window.digitalData.page.pageInfo.pageID : '';
                        var postalCode = data.rewardsDataShort.postalCode ? data.rewardsDataShort.postalCode : '';
                        if (loyaltyStatus != '') {
                            if (loyaltyStatus == 'cancelled') {
                                window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-loyl_acct_canceled-_-'+pageID);
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled';
                            } else if (loyaltyStatus == 'cancelledgrace') {
                                window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-loyl_acct_canceled_grace-_-'+pageID);
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled_grace';
                            } else if (loyaltyStatus == 'frozen') {
                                window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-loyl_acct_frozen-_-'+pageID);
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_frozen';
                            } else if (loyaltyStatus == 'terminated') {
                                window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-loyl_acct_terminated-_-'+pageID);
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated';
                            } else if (loyaltyStatus == 'terminatedgrace') {
                                window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-loyl_acct_terminated_grace-_-'+pageID);
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated_grace';
                            } else if (loyaltyStatus == 'active') {
                                window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-loyl_acct_active-_-'+pageID);
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                            } else if (loyaltyStatus == 'inactive') {
                                window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-loyl_acct_inactive-_-'+pageID);
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_inactive';
                            } 
                        } else if (isCAPostalCode(postalCode)) {
                            window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-bbw_acct_ineligible-_-'+pageID);
                            if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'bbw_acct_ineligible';
                        } else {
                            window.cmCreateElementTag('SIGN IN', 'ACCOUNT SIGN IN', '-_--_-bbw_acct-_-'+pageID);
                            if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'bbw_acct';
                        }
                        if (loyaltyStatus == 'inactive' || loyaltyStatus == 'pointscapped' || loyaltyStatus == 'active')  {
                            window.redirecturl = response.targetUrl.length > 10 ? response.targetUrl : Urls.accountLanding;
                        }
                    }
                    var targetUrl = ('targetUrl' in response && response.targetUrl.indexOf(Urls.accountLanding) > 0) ? response.targetUrl + '?signin=true' : response.targetUrl;

                    if (sessionStorage.getItem('signinTarget')) {
                        targetUrl = sessionStorage.getItem('signinTarget');
                        sessionStorage.removeItem('signinTarget');
                    }
                    window.location = targetUrl;
                });
            } else if (response.success === false && response.error !== null) {
                errorDiv.html(response.error);
                passwordInput.val('');

                var bindEvent = 'focus.loginerror change.loginerror keydown.loginerror';
                bothInputFields.addClass('loginerror').on(bindEvent, function () {
                    bothInputFields.removeClass('loginerror').off(bindEvent);
                });
            } else {
                window.location.reload();
            }
        });
    });
}


/**
 * check if zip code is Canadian
 * @param {postalCode} : String
 * @returns {boolean}
 */
function isCAPostalCode(postalCode) {
    var retVal = (/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/).test(postalCode);
    return  retVal;
}

/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initLoginPage() {
    // o-auth binding for which icon is clicked
    $('.oAuthIcon').bind('click', function () {
        $('#OAuthProvider').val(this.id);
    });
}

/**
 * @private
 * @function
 * @description Show/Hide the password for login
 */
function passHideShow() {

    var hideShowButton = $('.hide-show'), hideShowSpan = hideShowButton.find('span');

    hideShowButton.show();
    hideShowSpan.addClass('show');
    hideShowButton.off('click.hide-show-password').on('click.hide-show-password', function () {
        var hideShowThisButton = $(this), hideShowThisSpan = hideShowThisButton.find('span');

        if (hideShowThisSpan.hasClass('show')) {
            hideShowThisSpan.text('Hide');
            hideShowThisButton.attr('aria-label', 'Hide Password');
            hideShowThisButton.siblings('input[name$="password"], input[name$="currentpassword1"]').attr('type', 'text').focus();
            hideShowThisSpan.removeClass('show');
        } else {
            hideShowThisSpan.text('Show');
            hideShowThisButton.attr('aria-label', 'Show Password');
            hideShowThisButton.siblings('input[name$="password"], input[name$="currentpassword1"]').attr('type', 'password').focus();
            hideShowThisSpan.addClass('show');
        }
    });
    $('form button[type="submit"]').off('click.hide-show-password').on('click.hide-show-password', function () {
        hideShowSpan.text('Show').addClass('show');
        hideShowButton.attr('aria-label', 'Show Password');
        hideShowButton.parent().find('input[name$="_password"]').attr('type', 'password');
    });
}

/**
 * @private
 * @function
 * @description Initialize rewards page logic
 */
function initRewardsPopupEvents(rewardsForm, pageFrom) {
    //desktop analytics for popup 
    if ($(window).width() >= 768) {
        if (pageFrom == 'header') {
            window.cmCreatePageElementTag('POP-UP', 'LOYALTY_SIGNUP_ACCT_CREATE');
        } else if ($('#RegistrationForm').length > 0) {
            if ($('.prospect-asset[data-page="myinfo"][data-status-popup="true"]').length > 0) {
                window.cmCreateElementTag('POP-UP', 'LOYALTY_SIGNUP_MYINFO', '-_--_-loyl_acct_prospect');
            } else if ($('.cancelled-asset[data-page="myinfo"][data-status-popup="true"]').length > 0) {
                window.cmCreateElementTag('POP-UP', 'LOYALTY_SIGNUP_MYINFO', '-_--_-loyl_acct_canceled');
                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled';
            } else if ($('.cancelled-asset').attr('data-status-popup', 'true').length > 0) {
                window.cmCreateElementTag('POP-UP', 'LOYALTY_SIGNUP_ACCT_CREATE', '-_--_-loyl_acct_canceled');
                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled';
            } else {
                window.cmCreateElementTag('POP-UP', 'LOYALTY_SIGNUP_ACCT_CREATE', '-_--_-loyl_acct_prospect');
            }
        } else if ($('a#join-rewards').length > 0 && !(pageFrom === 'cart' ||  pageFrom === 'billing')) {
            if (window.User.loyaltyCancelled) {
                window.cmCreateElementTag('POP-UP', 'LOYALTY_SIGNUP_MYACCT', '-_--_-loyl_acct_canceled');
                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled';
            } else {
                window.cmCreateElementTag('POP-UP', 'LOYALTY_SIGNUP_MYACCT', '-_--_-loyl_acct_prospect');
            }
        }
    }
    if ($(window).width() < 768) {
        if (pageFrom == 'myacc') {
            window.cmCreatePageElementTag('POP-UP', 'LOYALTY_SIGNUP_ACCT_CREATE');
        }
    }
    if (pageFrom == 'cart') {
        window.cmCreateElementTag('POP-UP', 'LOYALTY_SIGNUP_CART', '-_--_--_-cart');
    }
    if (pageFrom == 'billing') {
        window.cmCreateElementTag('POP-UP', 'LOYALTY_SIGNUP_BILLING', '-_--_--_-billing');
    }
    var rewardsInput = $('#join-rewards-form input');
    var rewardsButton = $('button[name$="_joinrewards"]');
    var rewardsCheck = $('input[name$="_addtoemaillist"]');
    var joinButtonTextEnabled = 'Join My Bath and Body Works Button is Enabled';
    var joinButtonTextDisabled = 'Join My Bath and Body Works Button is Disabled';
    rewardsInput.next('label').remove();
    rewardsInput.on('blur', function () {
        if (!rewardsInput.hasClass('error') && rewardsCheck.is(':checked')) {
            rewardsButton.removeAttr('disabled');
            rewardsButton.attr('aria-disabled', 'false').attr('aria-label', joinButtonTextEnabled);
        } else {
            rewardsButton.attr('aria-disabled', 'true');
            rewardsButton.attr('disabled', 'disabled').attr('aria-label', joinButtonTextDisabled);
        }
    });
    rewardsCheck.on('change', function () {
        if (!rewardsInput.hasClass('error') && $(this).is(':checked')) {
            rewardsButton.removeAttr('disabled');
            rewardsButton.attr('aria-disabled', 'false').attr('aria-label', joinButtonTextEnabled);
        } else {
            rewardsButton.attr('aria-disabled', 'true');
            rewardsButton.attr('disabled', 'disabled').attr('aria-label', joinButtonTextDisabled);
        }
    })
    rewardsForm.on('submit', function (e) {
        e.preventDefault();
        var isFormValid = $('#join-rewards-form').valid();
        if (!isFormValid) {
            return
        }
        var birthday = $(this).find('.birthday').val();
        var birthdayPlaceholder = birthday;
        if (birthday) {
            birthday = birthday.replace(/\s+/g, '');
            birthday += '/1990';
            $(this).find('.birthday').val(birthday);
            $(this).find('.birthday').removeAttr('maxlength');
        }
        var submitButton = $(this).find('button[type="submit"][name$="_joinrewards"]').attr('name');
        var data = $(this).serialize() + '&' + submitButton + '=x';
        $(this).find('.birthday').val(birthdayPlaceholder);
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        }).done(function (response) {
            if (response.success === true) {
                if (pageFrom == 'cart') {
                    window.cmCreateElementTag('SIGNUP COMPLETE', 'LOYALTY_SIGNUP_CART','-_--_--_-cart');
                    $('body').addClass('joinsuccess');
                    showThankyouModal();
                }
                if (pageFrom == 'billing') {
                    window.cmCreateElementTag('SIGNUP COMPLETE', 'LOYALTY_SIGNUP_BILLING','-_--_--_-billing');
                    $('body').addClass('joinsuccess');
                    showThankyouModal();
                }
                $.ajax({
                    type: 'GET',
                    url: Urls.getRewardsDataShort,
                    dataType: 'json'
                }).done(function(data) {
                    if (data.success === true && data.rewardsDataShort){
                        if (data.rewardsDataShort != null) {
                            if (data.rewardsDataShort.loyaltyStatus == 'cancelled') {
                                window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_canceled');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled';
                            } else if (data.rewardsDataShort.loyaltyStatus == 'cancelledgrace') {
                                window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_canceled_grace');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled_grace';
                            } else if (data.rewardsDataShort.loyaltyStatus == 'frozen') {
                                window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_frozen');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_frozen';
                            } else if (data.rewardsDataShort.loyaltyStatus == 'terminated') {
                                window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_terminated');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated';
                            } else if (data.rewardsDataShort.loyaltyStatus == 'terminatedgrace') {
                                window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+ '-_--_--_-loyl_acct_terminated_grace');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated_grace';
                            } else if (data.rewardsDataShort.loyaltyStatus == 'active') {
                                window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_active');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                            } else {
                                window.cmCreateRegistrationTag(data.rewardsDataShort.emailHash, data.rewardsDataShort.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.customer_country, '-_--_--_--_--_--_--_--_-'+data.rewardsDataShort.loyaltyID+'-_-'+data.rewardsDataShort.count+'-_-'+data.rewardsDataShort.currentSpend+'-_--_--_-loyl_acct_active');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                            }
                        }
                    }
                });
            }
            //desktop analytics for popup only
            if ($(window).width() >= 768) {
                if (pageFrom == 'header') {
                    window.cmCreatePageElementTag('SIGNUP COMPLETE BBW ACCT', 'LOYALTY_SIGNUP_ACCT_CREATE');
                } else if ($('#RegistrationForm').length > 0) {
                    window.cmCreatePageElementTag('Signup complete', 'LOYALTY_SIGNUP_ACCT_CREATE');
                } else if ($('a#join-loyalty-header').length > 0) {
                    window.cmCreatePageElementTag('POP-UP', 'LOYALTY_SIGNUP_ACCT_CREATE');
                } else if ($('a#join-rewards').length > 0 && !(pageFrom === 'cart' || pageFrom === 'billing')) {
                    window.cmCreatePageElementTag('Signup complete', 'LOYALTY_SIGNUP_MYACCT');
                }
            } else {
                if (pageFrom == 'myacc') {
                    window.cmCreatePageElementTag('SIGNUP COMPLETE BBW ACCT', 'LOYALTY_SIGNUP_ACCT_CREATE');
                } else if (pageFrom == 'create') {
                    window.cmCreatePageElementTag('Signup complete', 'LOYALTY_SIGNUP_ACCT_CREATE');
                    window.cmCreatePageElementTag('POP-UP', 'LOYALTY_SIGNUP_ACCT_CREATE');
                }
            }
            
            if (pageFrom == 'myinfo') {
                dialog.close();
            } else if (!(pageFrom == 'cart' || pageFrom == 'billing')) {
                window.location = Urls.accountLanding + '?registration=true';
            }
        });
    });
    rewardsForm.find('button[name$="_joinrewards"]').on('click', function () {
        if (!rewardsForm.valid()) {
            return;
        }

    });
}

/**
 * @private
 * @function
 * @description Initialize rewards page logic for the mobile design
 */
function initRewardsMobile() {
    var rewardsForm = $('form#join-rewards-form');
    var pageParam;
    if (rewardsForm.length > 0 && $(window).width() < 768) {
        pageParam = new URL(window.location.href).searchParams.get('page');
        initRewardsPopupEvents(rewardsForm, pageParam);
    }
}

function initAccordion() {
    if ($('.orders-list .year-group').length > 1) {
        var icons = {
            header: 'iconClosed',
            activeHeader: 'iconOpen'
        };
        $('.orders-list').accordion({
            header: '> div.year-group > .headinfo-year',
            collapsible: true,
            heightStyle: 'content',
            icons: icons,
            activate: function() {
                $('.year-group .bodyinfo').attr('role', 'list');
                $('.headinfo-year').attr('role','heading');
            },
            create: function() {
                $('.year-group .bodyinfo').attr('role', 'list');
                $('.headinfo-year').attr('role','heading');
            }
        });
        $('.headinfo-year').attr('role','heading');
        $('.year-group .bodyinfo').attr('role', 'list');
        $('.orders-list .year-group:eq(0)').addClass('active');
        $('.orders-list .headinfo-year').on('click', function () {
            $('.orders-list .year-group').removeClass('active');
            if ($(this).hasClass('ui-state-active')) {
                $(this).parent().addClass('active');
            }
        });
    }
}

/**
 * @private
 * @function
 * @description account nav actions
 */
function updateAccountNav() {
    var currentPage = $('#wrapper').data('pageid');
    if (currentPage) {
        $('.secondary-navigation a').each(function () {
            var linkID = $(this).data('id');
            if (linkID == currentPage) {
                $(this).addClass('current').attr('aria-current', 'page');
            }
        });
        $('.under-menu a').each(function () {
            if ($(this).data('anchor') == currentPage) {
                var href = $(this).attr('href');
                var anchor = '#' + href.substring(href.indexOf('#') + 1);
                $(this).attr('href', anchor);
            }
        });
    }
}

function anchors() {
    if (document.location.href.indexOf('#') > -1) {
        var url = window.location.href;
        var target = '#' + url.substring(url.indexOf('#') + 1);
        history.pushState('', document.title, window.location.pathname);
        var hHeight = $('header.stickyOn').outerHeight();
        if ($(target)) {
            var offset = $(target).offset().top - hHeight - 20;
            $('html, body').animate({
                scrollTop: offset
            }, 800);
        }
    }
}

/**
 * @private
 * @function
 * @description Turns off "Sticky header" functionality for the page scroll event after clicking on the anchor link.
 */
function anchorClick() {
    $('a').click(function () {
        if ($(this).attr('href').indexOf('#') > -1) {
            $('header').addClass('stickyPaused');
            setTimeout(function () {
                $('header').removeClass('stickyPaused');
            }, 300);
        }
    });
    $('.next a').click(function (e) {
        e.preventDefault();
        var eleId = $(this).attr('href');
        var headerPosition = $('header.header-fixed')[0].style.top || '';
        var headerVisibleHeight = $('header.header-fixed').height() + (parseInt(headerPosition.replace('px', ''), 10));
        window.scrollTo(0, $(eleId).offset().top - headerVisibleHeight - 20);
    });
    $('.prev a').click(function (e) {
        e.preventDefault();
        var eleId = $(this).attr('href');
        var headerVisibleHeight = $('header.header-fixed').height();
        window.scrollTo(0, $(eleId).offset().top - headerVisibleHeight - 20);
    });
}

/**
 *
 * @returns
 */

function contactUsFormInit() {
    //Corporate sales page form validation
    activateCorporateGiftSubmitBtn();
    corporateGiftInputTester();

    //Corporate sales page form captcha validation
    $('#CorporateSalesForm.contact-us').on('submit', function (e) {
        e.preventDefault();
        var self = this;
        var form = $('#CorporateSalesForm.contact-us');
        var captcha = form.find('.recaptcha');
        var $submit = $('#CorporateSalesForm.contact-us').find('[name$="contactus_send"]');
        var captchaToken = window.grecaptcha.getResponse();
        var options = {
            url: Urls.contactusCaptcha,
            data: {
                'g-recaptcha-response': captchaToken
            },
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (data.success == true) {
                var input = $('<input>').attr('type', 'hidden').attr('name', $submit.attr('name')).val('Send');
                $('#CorporateSalesForm.contact-us').append($(input));
                self.submit();
                // Set satellite analytics tag 
                if ('_satellite' in window) window._satellite.track('corporategiftinquiry');
            } else {
                captcha.append('<span class="error CG-reacptcha-error">reCaptcha validation failed</span>');
                $('html, body').animate({
                    scrollTop: $('.field-wrapper.recaptcha').offset().top - 200
                }, 500);
            }
        });
    });
    //mask the zip code
    $('#CorporateSalesForm .postal').mask('00000')
}
/**
 * @private
 * @function
 * @description render the phone number on account information page as (xxx)
 *              xxx-xxxx
 */
function initPhoneNumber() {
    var phoneNumber = $('#RegistrationForm').find('.form-row.uneditable input.phone');
    if (phoneNumber.length > 0) {
        var maskedVal = phoneNumber.val().replace(/[\-\. \(\)]/g, '').replace(/^(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        phoneNumber.val(maskedVal);
    }
}

/**
 * @private
 * @function
 * @description Check Loyalty Offer Avalibility
 */
function checkLoyaltyOffer() {
    $('.postal').off('change');
    $('.postal').on('change', function () {
        var rgx = /^\d{5}(-\d{4})?$/;
        var value = $(this).val();
        var isValid = rgx.test($.trim(value));
        var zipInput = $('input[name$="_postalCode"]').val();
        var zip =  (zipInput != undefined) ? zipInput.substring(0, 5) : '';
        if ($('#loyaltyOffer').length > 0) {
            var excludedZipArray = SitePreferences.EXCLUDED_ZIP_CODES_LOYALTY;
            if (isValid && excludedZipArray.indexOf(zip) == -1 && util.isUSPostalCode(zip)) {
                $('#loyaltyOfferSorry').hide();

                if ($('#loyaltyOffer').hasClass('dimmed')) {
                    $('#loyaltyOffer').removeClass('dimmed');
                    $('#loyaltyOffer input').removeAttr('disabled');
                }
            } else {
                $('#loyaltyOffer').addClass('dimmed');
                $('#loyaltyOffer input').attr('disabled');
                $('#loyaltyOfferSorry').show();
            }
        }
        activateSubmitBtn();
    });
}


/**
 * @private
 * @function
 * @description Set analytics data on user login
 */
function fireAnalyticsData(loyaltyID, loyaltyStatus, emailHash) {
    if (emailHash) {
        $.ajax({
            type: 'GET',
            url: Urls.getRewardsDataShort,
            dataType: 'json'
        }).done(function (data) {
            var count = '';
            var currentSpend = '';
            loyaltyID = typeof loyaltyID !== 'undefined' ? loyaltyID : '';

            if (data.success === true && data.rewardsDataShort) {
                count = data.rewardsDataShort.count;
                currentSpend = data.rewardsDataShort.currentSpend;
            }

            if (loyaltyStatus) {
                if (loyaltyStatus == 'cancelled') {
                    window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + count + '-_-' + currentSpend + '-_--_--_-loyl_acct_canceled');
                    if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled';
                } else if (loyaltyStatus == 'cancelledgrace') {
                    window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + count + '-_-' + currentSpend + '-_--_--_-loyl_acct_canceled_grace');
                    if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_canceled_grace';
                } else if (loyaltyStatus == 'frozen') {
                    window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + count + '-_-' + currentSpend + '-_--_--_-loyl_acct_frozen');
                    if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_frozen';
                } else if (loyaltyStatus == 'terminated') {
                    window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + count + '-_-' + currentSpend + '-_--_--_-loyl_acct_terminated');
                    if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated';
                } else if (loyaltyStatus == 'terminatedgrace') {
                    window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + count + '-_-' + currentSpend + '-_--_--_-loyl_acct_terminated_grace');
                    if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_terminated_grace';
                } else if (loyaltyStatus == 'active') {
                    window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + count + '-_-' + currentSpend + '-_--_--_-loyl_acct_active');
                    if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                } else {
                    window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + count + '-_-' + currentSpend + '-_--_--_-loyl_acct_active');
                    if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
                }
            } else if (loyaltyID) {
                window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + count + '-_-' + currentSpend + '-_--_--_-loyl_acct_active');
                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
            } else {
                window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-0-_-0-_-0-_--_--_-bbw_acct');
                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'bbw_acct';
            }
        });
    }
}

/**
 * @private
 * @function
 * @description validate email to specs in BBWS-672
 */
function validatePassword() {
    var submitButton = $('#RegistrationForm').find('button[type="submit"][name$="_confirm"]').add($('#ChangePasswordForm').find('button[type="submit"][name$="_changepassword"]')).add($('#RegistrationFormBoc').find('button[type="submit"]'));
    $('input#dwfrm_profile_login_password, input#dwfrm_resetpassword_password, input#dwfrm_profile_login_newpassword').on('input', function () {
        var inputText = $(this).val();
        var inputForm = $(this).parents('form');
        var passRequirments = inputForm.find('#password-requirements');
        var eightToFifty = /^(?=.{8,50})/;
        var numerical = /^(?=.*[0-9])/;
        var capital = /^(?=.*[A-Z])/;
        var lowercase = /^(?=.*[a-z])/;
        var noSpaces = /^(?!.*\s)/;
        var errorMessageMet = '';
        var errorMessageNotMet = '';
        if (passRequirments.css('display') == 'none') {
            passRequirments.show();
        }
        if (inputText.match(eightToFifty)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_LENGTH + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.rule8to50char').removeClass('pw-error');
            inputForm.find('.rule8to50char').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_LENGTH + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.rule8to50char').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.rule8to50char').addClass('pw-error');
            }
        }

        if (inputText.match(numerical)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_NUMBER + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.rule1number').removeClass('pw-error');
            inputForm.find('.rule1number').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_NUMBER + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.rule1number').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.rule1number').addClass('pw-error');
            }
        }

        if (inputText.match(capital)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_UPPERCASE + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.rule1uppercase').removeClass('pw-error');
            inputForm.find('.rule1uppercase').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_UPPERCASE + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.rule1uppercase').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.rule1uppercase').addClass('pw-error');
            }
        }

        if (inputText.match(lowercase)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_LOWERCASE + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.rule1lowercase').removeClass('pw-error');
            inputForm.find('.rule1lowercase').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_LOWERCASE + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.rule1lowercase').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.rule1lowercase').addClass('pw-error');
            }
        }

        if (inputText.match(noSpaces)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_SPACE + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.ruleNoSpaces').removeClass('pw-error');
            inputForm.find('.ruleNoSpaces').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_SPACE + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.ruleNoSpaces').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.ruleNoSpaces').addClass('pw-error');
            }
        }

        if (inputText.match(eightToFifty) && inputText.match(numerical) && inputText.match(capital) && inputText.match(lowercase) && inputText.match(noSpaces)) {
            submitButton.removeAttr('data-disallow');
            $('#pwreq-error').remove();
        } else {
            submitButton.attr('data-disallow', 'true');
        }

        if (inputText.length >= 1) {
            inputForm.find('#password-requirments-aria').text(passRequirments.find('.success').length + ' ' + Resources.PASSWORD_CONDITIONS_5 + ' ' + errorMessageNotMet + errorMessageMet);
            if (inputForm.find('#password-requirements').attr('aria-hidden') !== 'true') {
                inputForm.find('#password-requirements').attr('aria-hidden', 'true');
                inputForm.find('#password-requirements').find('span').attr('aria-hidden', 'true');
            }
        }

        activateSubmitBtn();
    });
    submitButton.on('click', function (e) {
        var $pwReqError = $('.pwreq-error');
        var thisForm = $(this).parents('form');
        var passRequirments = thisForm.find('#password-requirements');
        var errorMsgWrapper = thisForm.find('input[id$="password"]').last().closest('.field-wrapper');

        if ($pwReqError.length > 0) {
            $pwReqError.empty().remove();
        }
        if (thisForm.find('input[id$="password"]').val().trim() === '') {
            return;
        }
        if ($(this).attr('data-disallow') === 'true') {
            e.preventDefault();
            $('<span class="pwreq-error" id="pwreq-error">' + Resources.VALIDATE_REQPASSWORD + '</span>').appendTo(errorMsgWrapper);
            thisForm.find('input[id$="password"]').last().focus();
            passRequirments.hide();
            setTimeout(function() {
                $('#RegistrationFormBoc').find('input[id$="password"]').addClass('error');
                $('#RegistrationFormBoc').find('#pwreq-error').addClass('error');
            }, 100);
        } else {
            if ($pwReqError.length > 0) {
                $pwReqError.empty().remove();
            }
            if (thisForm.attr('id') === 'ChangePasswordForm') {
                var loyaltyStatus = $('h1.login-name-header').data('loyalty');
                var loyaltyID = $('h1.login-name-header').data('idloyalty');
                fireAnalyticsData(loyaltyID, loyaltyStatus, window.digitalData.user.profile.profileInfo.profileEmailHash);
            }
        }
    });
}
/**
 * @private
 * @function
 * @description switch grey status to red on blur of password field in BBWS-672
 */
function switchClassOnPasswordRules() {
    $('input#dwfrm_profile_login_password, input#dwfrm_resetpassword_password, input#dwfrm_profile_login_newpassword').on('focus', function () {
        var inputForm = $(this).parents('form');
        if (inputForm.find('#password-requirements').css('display') == 'none') {
            inputForm.find('#password-requirements').show();
        }
    });
    $('input#dwfrm_profile_login_password, input#dwfrm_resetpassword_password, input#dwfrm_profile_login_newpassword').on('blur', function () {
        pwHadFocus = true;
        var inputForm = $(this).parents('form');
        if (!$(this).val()) {
            inputForm.find('#password-requirements').hide();
        }
        if ($(this).val() && inputForm.find('#password-requirements').css('display') == 'none') {
            inputForm.find('#password-requirements').show();
        }
        if (!inputForm.find('.rule8to50char').hasClass('success')) {
            inputForm.find('.rule8to50char').addClass('pw-error');
        }
        if (!inputForm.find('.rule1number').hasClass('success')) {
            inputForm.find('.rule1number').addClass('pw-error');
        }
        if (!inputForm.find('.rule1uppercase').hasClass('success')) {
            inputForm.find('.rule1uppercase').addClass('pw-error');
        }
        if (!inputForm.find('.rule1lowercase').hasClass('success')) {
            inputForm.find('.rule1lowercase').addClass('pw-error');
        }
        if (!inputForm.find('.ruleNoSpaces').hasClass('success')) {
            inputForm.find('.ruleNoSpaces').addClass('pw-error');
        }
    });
}
/**
 * @private
 * @function
 * @description check if user exists with given email
 */
function isUserExist() {
    var $customerEmail = $('input[name$="_profile_customer_email"]').not($('#RegistrationFormBoc').find('input[name$="_profile_customer_email"]')), errorstring, errorMsg;
    var regexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    $customerEmail.on('change', function () {
        // pwHadFocus = true;
        if ($customerEmail.val() != '' && regexp.test($customerEmail.val())) {
            $.post(Urls.userExists, {
                email: $customerEmail.val()
            }, function (data) {
                if (data.success) {
                    $customerEmail.addClass('error');
                    errorstring = data.errorMessage;
                    errorMsg = $('input[name$="_profile_customer_email"]').closest('.field-wrapper');
                    $('.foundMatch').remove();
                    $('<span tabindex="0" class="foundMatch" aria-label="' + errorstring.replace('<a href=\'/s/BathAndBodyWorks/my-account\'>', '').replace('</a>', '') + '">' + errorstring + '</span>').appendTo(errorMsg);
                    $('.foundMatch').focus();
                } else {
                    $('.foundMatch').remove();
                    $customerEmail.removeClass('error');
                }
            }, 'json');
        } else {
            $('.foundMatch').remove();
        }
    });
}
/**
 * @private
 * @function
 * @description stop form submit if user already exists
 */
function preventFormSubmitIfUserExists() {
    $('#RegistrationForm, #ChangeMailForm').find('button[type="submit"]').on('click', function (e) {
        if ($('.foundMatch').length > 0) {
            e.preventDefault();
            $('.foundMatch').focus();
        }
    });
}
/**
 * @private
 * @function
 * @description sets error message on page load for user existing with email
 *              given
 */
function userExistOnLoad() {
    if ($('#dwfrm_profile_customer_email').closest('.required.error').find('.error-message').length > 0) {
        if ($('body').find('.foundMatch').length == 0) {
            if ($('body').find('.misMatch').length == 0) {
                $('<span class="foundMatch"></span>').appendTo($('#dwfrm_profile_customer_email').closest('.field-wrapper'));
                $('#dwfrm_profile_customer_email').closest('.required.error').find('.error-message').hide();
            }
        }
        $.post(Urls.userExists, {
            email: $('input[name$="_profile_customer_email"]').val()
        }, function (data) {
            if (data.success) {
                var errorstring = data.errorMessage;
                $('.foundMatch').html(errorstring);
            } else {
                $('.foundMatch').hide();
            }
        }, 'json');
    }
}
/**
 * @private
 * @function
 * @description mask of Birthday Input
 */
function maskBirthdayInit() {
    $('.birthday').mask('00 / 00');
}
function maskPhoneInit() {
    $('.phone').mask('(000) 000-0000');
}

/**
 * @private
 * @function
 * @description changes text in accordion header and aria attribute (Account-MyWallet)
 */
function accordionChangeText(el) {
    var title = el.find('.title-blue');
    if (el.hasClass('ui-state-active')) {
        title.text(title.attr('data-hide'));
        el.attr('aria-expanded', 'true');
        el.find('.hidden-description').attr('aria-hidden', 'false');
        //analytics on open show my rewards card accordion
        window.cmCreatePageElementTag('SHOW CARD', 'MY REWARDS');
    } else {
        title.text(title.attr('data-show'));
        el.attr('aria-expanded', 'false');
        el.find('.hidden-description').attr('aria-hidden', 'true');
    }
}

/**
 * @private
 * @function
 * @description Initialize Barcode Js
 */
function initBarcodeToggle() {
    var accordionSelector = $('.blue-box-container');
    var barcodeSvg = accordionSelector.find('svg');
    var barcodeSvgText = barcodeSvg.attr('aria-label');
    var barcodeSvgOffers = $('.barcode-container').find('svg');
    var icons = {
        header: 'iconClosed',
        activeHeader: 'iconOpen'
    };
    accordionSelector.on('click', '.title', function () {
        accordionChangeText($(this));
    });
    accordionSelector.on('keydown', '.title', function (e) {
        var keyCode = e.keyCode || window.event.keyCode;
        // check for an ENTER or SPACE
        if (keyCode === 13 || keyCode === 32) {
            accordionChangeText($(this));
        }
    });
    accordionSelector.accordion({
        header: '.title',
        collapsible: true,
        active: false,
        heightStyle: 'content',
        icons: icons
    });
    accordionSelector.find('.title').attr('aria-live', 'polite');
    barcodeSvg.prepend('<title>' + barcodeSvgText + '</title>');
    barcodeSvgOffers.prepend('<title>' + barcodeSvgOffers.attr('aria-label') + '</title>');
}


/**
 * @private
 * @function
 * @description Initializes counter for rewards progressbag
 */
function initRewardAmountCounter(ms) {
    var amountSpent = $('#amount-next-reward');
    amountSpent.each(function () {
        $(this).prop('Counter', 0).animate({
            Counter: $(this).attr('data-amount')
        }, {
            duration: ms,
            easing: 'linear',
            step: function (now) {
                now = parseFloat(now.toFixed(0)).toString();
                if (now.length > 3) {
                    now = now.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
                $(this).text(now);
                var comma = now.indexOf(',');
                if (comma > -1) {
                    comma = 1;
                    var nowLength = now.length - comma;
                    if (nowLength > 4) {
                        $(this).addClass('reduce-size');
                    }
                    $(this).html(now.replace(/,/g, '<span class="comma">,</span>'));
                }
            }
        });
    });
}

/**
 * @private
 * @function
 * @description Initializes Progress bar on my wallet page
 */
function initializeProgress() {
    if ($('#container-db').length) {
        // progressbar.js@1.0.0 version is used
        // Docs: http://progressbarjs.readthedocs.org/en/1.0.0/
        var animationDuration = 1000;
        var nextReward = $('.next-reward').attr('rel');
        var barDbContainer = $('#container-db');
        var barDbConColor = barDbContainer.data('color');
        var barDbConTrailColor = barDbContainer.data('trailcolor');
        var barDb = new window.ProgressBar.SemiCircle('#container-db', {
            strokeWidth: 1,
            color: barDbConColor,
            trailColor: barDbConTrailColor,
            trailWidth: 1,
            easing: 'easeInOut',
            duration: animationDuration,
            svgStyle: null,
            text: {
                value: '',
                alignToBottom: false
            }
            // Set default step function for all animate calls
        });

        var barMbContainer = $('#container-mb');
        var barMbConColor = barMbContainer.data('color');
        var barMbConTrailColor = barMbContainer.data('trailcolor');
        var barMb = new window.ProgressBar.SemiCircle('#container-mb', {
            strokeWidth: 6,
            color: barMbConColor,
            trailColor: barMbConTrailColor,
            trailWidth: 6,
            easing: 'easeInOut',
            duration: animationDuration,
            svgStyle: null,
            text: {
                value: '',
                alignToBottom: false
            }
            // Set default step function for all animate calls
        });

        var barLbContainer = $('#container-lb');
        var barLbConColor = barLbContainer.data('color');
        var barLbConTrailColor = barLbContainer.data('trailcolor');
        var barLb = new window.ProgressBar.SemiCircle('#container-lb', {
            strokeWidth: 3,
            color: barLbConColor,
            trailColor: barLbConTrailColor,
            trailWidth: 3,
            easing: 'easeInOut',
            duration: animationDuration,
            svgStyle: null
        });
        barMbContainer.css('position', 'absolute');
        barDb.animate(1, function () {

            if (nextReward > 0 && nextReward <= 1) {
                initRewardAmountCounter(animationDuration * 2);
                barMb.animate(nextReward, function () {
                    barMb.trail.parentNode.removeChild(barMb.trail);//IE11 fix for remove()
                    barLb.set(nextReward);
                    barLb.animate(1);
                });
            } else {
                barMb.destroy();
                initRewardAmountCounter(animationDuration);
                barLb.animate(1);
            }
        });
        var spent = $('.spent-amount').text(),
            spentNegative = spent.indexOf('-');
        if (spentNegative > -1) {
            spentNegative = 1;
        }
        var spentLength = spent.length - spentNegative;
        if (spentLength > 4) {
            $('.spent-amount').html(spent.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        }
    }
}

/**
 * @private
 * @function
 * @description Join rewards event on the account landing page
 */
function initMyAccountRewardsPopup() {
    $('#main').on('click', '#join-rewards', function (e) {
        var isPromoFlow = $(this).hasClass('cart-promo-msg-link'); 
        e.preventDefault();
        if ($(window).width() < 768 && !isPromoFlow) {
            window.location = util.appendParamToURL(Urls.showRewardsDialog, 'page', 'myacc');
        } else {
            var rewardsUrl = Urls.showRewardsDialog;
            dialog.open({
                url: rewardsUrl,
                options: {
                    dialogClass: 'rewardspopup',
                    width: 425,
                    close: function () {
                        $('body').removeClass('open-modal');
                        if ($('.ui-dialog').hasClass('rewardspopupimg')) {
                            if ($('#wrapper').hasClass('pt_checkout')) {
                                var formData = $('fieldset.address-element').find(':input').serialize();
                                localStorage.setItem('billingaddress', formData);
                                window.location.href = Urls.billing;
                            } else {
                                window.location.href = Urls.continueUrl;
                            }
                        }
                    },
                    open: function () {
                        $('body').addClass('open-modal');
                        // Set satellite analytics tag 
                        if ('_satellite' in window) window._satellite.track('loyaltyjoinpopup');
                        $('.rewardspopup .ui-dialog-titlebar .ui-button-text').text('Button to close Join Rewards Modal');
                        $('.ui-widget-overlay').hide().fadeIn().on('click', function() {
                            $('.ui-dialog-titlebar-close').trigger('click');
                        });
                        setTimeout(function() {
                            $('.rewardspopup').focus();
                        }, 500);
                        validator.init();
                        var rewardsForm = $('form#join-rewards-form');
                        var pageType = $('#wrapper').hasClass('pt_checkout') ? 'billing' : 'cart';
                        if (($('#wrapper').hasClass('pt_cart') || $('#wrapper').hasClass('pt_checkout')) && $('#join-rewards').hasClass('cart-promo-msg-link')) {
                            initRewardsPopupEvents(rewardsForm, pageType);
                        } else {
                            initRewardsPopupEvents(rewardsForm, 'myacc'); 
                        }

                        $('#cancel-rewards').on('click', function (e) {
                            e.preventDefault();
                            $('.ui-dialog-titlebar-close').trigger('click');
                        });

                        maskBirthdayInit();
                        maskPhoneInit();

                        $(window).resize(function() {
                            $('#dialog-container').dialog('option', 'position', {my: 'center', at: 'center', of: $('#wrapper.info').length ? '#join-rewards' : window, collision: 'flipfit'});
                        });
                    },
                    position: {
                        //positioning the modal based on the window on the my info page causes the modal to be below the footer
                        of: $('#wrapper.info').length ? '#join-rewards' : window
                    }
                }
            });
        }
    });
}

function setAmountOnHowRewardsWorkPage() {
    var howRewardsWork = $('.howrewardswork');
    if (howRewardsWork.length == 0) {
        return;
    }
    $.ajax({
        type: 'GET',
        url: Urls.getThreshold,
        dataType: 'json',
        beforeSend: function () {
            howRewardsWork.find('.rewards-point').hide();
            howRewardsWork.find('.rewards-error').hide();
            howRewardsWork.find('.rewards-animation').show();
        }
    }).done(function (data) {
        if (data.success == true && data.threshold) {
            $('#rewards-count').text(data.threshold);
            howRewardsWork.find('.rewards-point').css('display', 'inline-block');
            howRewardsWork.find('.rewards-error').hide();
            howRewardsWork.find('.rewards-animation').hide();
        } else {
            howRewardsWork.find('.rewards-animation').hide();
            howRewardsWork.find('.rewards-error').show();
            howRewardsWork.find('.rewards-error').html('<div class="error-loyalty">' +
                '<p class="error-text" aria-label="' + Resources.LOYALTY_NETWORK_ERROR + '">' + Resources.LOYALTY_NETWORK_ERROR + '</p>' +
                '<a href="javascript:void(0)" class="threshold-retry">' + Resources.LOYALTY_NETWORK_RETRY + '</a>' +
                '</div>');
            howRewardsWork.find('.threshold-retry').on('click', function (e) {
                e.preventDefault();
                setAmountOnHowRewardsWorkPage();
            })
        }
    });

}

/**
 * @private
 * @function
 * @description initialize events on the My Wallet page
 */
function initMyWalletEvents() {
    if ($('.mywallet-page').length == 0) {
        return;
    }
    $.ajax({
        type: 'GET',
        url: Urls.walletDetails,
        dataType: 'html',
        beforeSend: function () {
            var myWalletWrapper = $('.mywallet-page');
            myWalletWrapper.find('.icon').show();
            setTimeout(function () {
                myWalletWrapper.find('.animation-text').show();
            }, 3000);
        }
    }).done(function (data) {
        $('.mywallet-page').html(data);

        //analytics if error page returned
        if ($('#loyalty-request-error').length > 0) {
            window.cmCreatePageElementTag('FAILED_REWARDS', 'LOYALTY_WALLET');
            window.digitalData.user.attributes.loyaltyError = true;
        }

        initializeProgress();
        initBarcodeToggle();
        account.initBonusActivate();
        setAmountOnHowRewardsWorkPage();

        var accordionSelector = $('.my-wallet-accordion');
        var copyCode = $('.copy-code');
        var icons = {
            header: 'iconClosed',
            activeHeader: 'iconOpen'
        };
        accordionSelector.accordion({
            header: '.reward-promotion',
            collapsible: true,
            active: false,
            heightStyle: 'content',
            icons: icons
        }).on('click', function () {
            accordionSelector.not(this).accordion('option', 'active', false);
        });

        accordionSelector.find('.reward-promotion').on('click keyup', function (e) {
            var offerNumber = $(this).attr('data-counter');
            var offerType = $(this).attr('data-type');
            var offerTitle = $(this).attr('data-title');
            //analytics for accordion
            window.cmCreatePageElementTag(offerType + offerNumber + ' | ' + offerTitle, 'MY REWARDS');

            //fixes known bug where aria-expanded doesn't toggle with jQuery accordion 
            if (!$(this).hasClass('ui-state-active') || (!$(this).hasClass('ui-state-active') && e.keyCode == 32)) {
                $(this).attr('aria-expanded', 'false');
            }
        });

        // copying to clipboard
        copyCode.on('click', function (e) {
            e.preventDefault();
            var code = $(this);
            if (!code.hasClass('copied')) {
                if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
                    var el = code.siblings('input').get(0);
                    var editable = el.contentEditable;
                    var readOnly = el.readOnly;
                    el.contentEditable = true;
                    el.readOnly = false;
                    var range = document.createRange();
                    range.selectNodeContents(el);
                    var sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                    el.setSelectionRange(0, 999999);
                    el.contentEditable = editable;
                    el.readOnly = readOnly;
                } else {
                    code.siblings('input').select();
                }
                document.execCommand('copy');
                code.focus();
                code.text(Resources.LOYALTY_CODE_COPIED);
                code.addClass('copied');
                code.attr('role', 'alert');
                //analytics for copy code button
                var offerType = code.attr('data-type');
                window.cmCreatePageElementTag(offerType + ' COPY CODE', 'MY REWARDS');
                setTimeout(function () {
                    code.removeAttr('role');
                    code.removeClass('copied');
                    code.text(Resources.LOYALTY_COPY_CODE);
                }, 3000);
            }
        });

        // Details popup on click
        $('.details-link').on('click', function (e) {
            e.preventDefault();
            var detailsContent = $(this).siblings('.details-dialog').find('> div').html();
            dialog.open({
                html: detailsContent,
                options: {
                    modal: true,
                    width: 400,
                    minHeight: 'auto',
                    zIndex: 10000,
                    closeOnEscape: true,
                    draggable: false,
                    resizable: false,
                    dialogClass: 'details-popup'
                },
                callback: function () {
                    $('.ui-dialog.details-popup').focus();
                    $('.ui-widget-overlay').on('click', function () {
                        dialog.close();
                    });
                }
            });
        });
    });

}

function initSubmitAccountInformationForm() {
    $('#RegistrationForm').on('submit', function (e) {
        if (!$('#RegistrationForm').valid()) {
            return false;
        }

        var birthday = $(this).find('.birthday').val();
        var birthdayPlaceholder = birthday;
        if (birthday) {

            e.preventDefault();
            birthday = birthday.replace(/\s+/g, '');
            birthday += '/1990';
            $(this).find('.birthday').val(birthday);
            $(this).find('.birthday').removeAttr('maxlength');

        }

        var url = $(this).attr('action'),
            data = $(this).serialize(),
            submitButton = $(this).find('button[type="submit"]').attr('name');
        if (birthday) {
            $(this).find('.birthday').val(birthdayPlaceholder);
        }

        if ($(this).find('button[name$="_changename"]').length) {
            e.preventDefault();
        }

        if ($(this).find('button[name$="_profile_confirm"]').hasClass('ajax-submit')) {
            e.preventDefault();
        }

        $.ajax({
            type: 'POST',
            url: url,
            data: data + '&' + submitButton + '=x' + '&format=ajax',
            success: function (data) {
                if (data.success === true) {
                    //open dialog
                    if (data.loyalty === false && data.zipmatch === true) {
                        var rewardsUrl = Urls.showRewardsDialog;
                        dialog.open({
                            url: rewardsUrl,
                            options: {
                                dialogClass: 'rewardspopup',
                                width: 425,
                                open: function () {
                                    validator.init();
                                    var rewardsForm = $('form#join-rewards-form');
                                    initRewardsPopupEvents(rewardsForm, 'myinfo');
                                    $('#cancel-rewards').on('click', function (e) {
                                        e.preventDefault();
                                        dialog.close();
                                    });
                                    $('.ui-widget-overlay').on('click', function () {
                                        dialog.close();
                                    });
                                },
                                close: function () {
                                    setTimeout(function () {
                                        window.location = Urls.accountEditProfile + '?showstatusmsg=true';
                                    }, 500);
                                }
                            }
                        });
                    } else if (data.pageName == 'register') {
                        //Creating new account
                        if (data.loyaltyData.loyaltyStatus != null) {
                            if (data.loyaltyData.loyaltyStatus == 'cancelled') {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + data.loyaltyData.loyaltyID + '-_-' + data.loyaltyData.count + '-_-' + data.loyaltyData.currentSpend + '-_--_--_-loyl_acct_canceled');
                            } else if (data.loyaltyData.loyaltyStatus == 'cancelledgrace') {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + data.loyaltyData.loyaltyID + '-_-' + data.loyaltyData.count + '-_-' + data.loyaltyData.currentSpend + '-_--_--_-loyl_acct_canceled_grace');
                            } else if (data.loyaltyData.loyaltyStatus == 'frozen') {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + data.loyaltyData.loyaltyID + '-_-' + data.loyaltyData.count + '-_-' + data.loyaltyData.currentSpend + '-_--_--_-loyl_acct_frozen');
                            } else if (data.loyaltyData.loyaltyStatus == 'terminated') {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + data.loyaltyData.loyaltyID + '-_-' + data.loyaltyData.count + '-_-' + data.loyaltyData.currentSpend + '-_--_--_-loyl_acct_terminated');
                            } else if (data.loyaltyData.loyaltyStatus == 'terminatedgrace') {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + data.loyaltyData.loyaltyID + '-_-' + data.loyaltyData.count + '-_-' + data.loyaltyData.currentSpend + '-_--_--_-loyl_acct_terminated_grace');
                            } else if (data.loyaltyData.loyaltyStatus == 'active') {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + data.loyaltyData.loyaltyID + '-_-' + data.loyaltyData.count + '-_-' + data.loyaltyData.currentSpend + '-_--_--_-loyl_acct_active');
                            } else {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + data.loyaltyData.loyaltyID + '-_-' + data.loyaltyData.count + '-_-' + data.loyaltyData.currentSpend + '-_--_--_-loyl_acct_active');
                            }

                            // send element tag when a Loyalty Customer signs up for account
                            window.cmCreateElementTag('Signup complete', 'LOYALTY_SIGNUP_ACCT_CREATE');
                        } else if (data.loyaltyData.loyaltyID != null) {
                            window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.address.city, window.digitalData.user.profile.address.stateProvince, window.digitalData.user.profile.address.postalCode, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + data.loyaltyData.loyaltyID + '-_-' + data.loyaltyData.count + '-_-' + data.loyaltyData.currentSpend + '-_--_--_-loyl_acct_active');

                            // send element tag when a Loyalty Customer signs up for account
                            window.cmCreateElementTag('Signup complete', 'LOYALTY_SIGNUP_ACCT_CREATE');

                        } else {
                            var customerCountry = (data.loyaltyData.country != null) ? data.loyaltyData.country : window.digitalData.user.profile.attributes.country_code;
                            var customerPostalCode = (data.loyaltyData.postalCode != null) ? data.loyaltyData.postalCode : window.digitalData.user.profile.attributes.customer_postal_code;
                            if (customerCountry == 'US') {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.dma, customerPostalCode, customerCountry, '-_--_--_--_--_--_--_--_-0-_-0-_-0-_--_--_-bbw_acct');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'bbw_acct';
                            } else if (customerCountry == 'CA') {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.dma, customerPostalCode, customerCountry, '-_--_--_--_--_--_--_--_--_--_--_--_--_-bbw_acct_ineligible');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'bbw_acct_ineligible';
                            } else {
                                window.cmCreateRegistrationTag(data.loyaltyData.emailHash, data.loyaltyData.emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.dma, customerPostalCode, customerCountry, '-_--_--_--_--_--_--_--_-0-_-0-_-0-_--_--_-bbw_acct');
                                if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'bbw_acct';
                            }

                            // send the element tag when a Non-Loyalty Customer signs up for account
                            window.cmCreateElementTag('Signup complete', 'SIGNUP_ACCT_CREATE');
                        }

                        window.location = Urls.accountLanding + '?registration=true';
                    } else {
                        // My Account > My Info Page
                        var profileEmailHash = window.digitalData.user.profile.profileInfo.profileEmailHash;
                        var city = '';
                        var state = '';
                        var zip = '';
                        var country = '';

                        if (window.digitalData.user.profile.address) {
                            city = window.digitalData.user.profile.address.city;
                            state = window.digitalData.user.profile.address.stateProvince;
                            zip = window.digitalData.user.profile.address.postalCode;
                            country = window.digitalData.user.profile.address.country;
                        }
                        if (data.loyalty === true) {
                            var loyaltyID = window.digitalData.user.profile.profileInfo.loyaltyID;
                            var rewardsCount = '';
                            var loyaltyBalance = '';
                            var loyaltyStatus = $('h1.login-name-header').data('loyalty');
                            var loyaltyAnalyticsStatus;

                            if (window.digitalData.user.profile.profileInfo.rewardsCount) {
                                rewardsCount = window.digitalData.user.profile.profileInfo.rewardsCount;
                            }
                            if (window.digitalData.user.profile.profileInfo.currentSpend) {
                                loyaltyBalance = window.digitalData.user.profile.profileInfo.currentSpend;
                            }

                            switch (loyaltyStatus) {
                                case 'cancelled':
                                    loyaltyAnalyticsStatus = 'loyl_acct_canceled';
                                    break;
                                case 'cancelledgrace':
                                    loyaltyAnalyticsStatus = 'loyl_acct_canceledgrace';
                                    break;
                                case 'frozen':
                                    loyaltyAnalyticsStatus = 'loyl_acct_frozen';
                                    break;
                                case 'terminated':
                                    loyaltyAnalyticsStatus = 'loyl_acct_terminated';
                                    break;
                                case 'terminatedgrace':
                                    loyaltyAnalyticsStatus = 'loyl_acct_terminated_grace';
                                    break;
                                default:
                                    loyaltyAnalyticsStatus = 'loyl_acct_active';
                            }
                            window.cmCreateRegistrationTag(profileEmailHash, profileEmailHash, city, state, zip, country, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + rewardsCount + '-_-' + loyaltyBalance + '-_--_--_-' + loyaltyAnalyticsStatus);
                            if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = loyaltyAnalyticsStatus;
                        } else {
                            window.cmCreateRegistrationTag(profileEmailHash, profileEmailHash, city, state, zip, country, '-_--_--_--_--_--_--_--_-0-_-0-_-0-_--_--_-bbw_acct');
                        }

                        // status message
                        setTimeout(function () {
                            window.location = Urls.accountEditProfile + '?showstatusmsg=true';
                        }, 500);
                    }
                } else if (data.success === false && data.error !== null) {
                    if ($('.account-change-notification').length) {
                        $('.account-change-notification').remove();
                    }
                    var validatorError = $('#RegistrationForm').validate();
                    validatorError.showErrors({
                        'dwfrm_profile_login_currentpassword1': data.error
                    });

                } else {
                    page.refresh();
                }
            }
        });
    });

    //change email form on the account page
    $('#ChangeMailForm').on('submit', function (e) {
        if (!$('#ChangeMailForm').valid()) {
            return false;
        }
        var birthday = $(this).find('.birthday').val();
        var birthdayPlaceholder = birthday;
        if (birthday) {

            e.preventDefault();
            birthday = birthday.replace(/\s+/g, '');
            birthday += '/1992';
            $(this).find('.birthday').val(birthday);
            $(this).find('.birthday').removeAttr('maxlength');

        }

        var url = $(this).attr('action'),
            data = $(this).serialize(),
            submitButton = $(this).find('button[type="submit"]').attr('name');
        if (birthday) {
            $(this).find('.birthday').val(birthdayPlaceholder);
        }

        $.ajax({
            type: 'POST',
            url: url,
            data: data + '&' + submitButton + '=x' + '&format=ajax',
            success: function (data) {
                if (window.digitalData.user.profile.profileInfo.loyaltyID) {
                    var profileEmailHash = window.digitalData.user.profile.profileInfo.profileEmailHash;
                    var city = '';
                    var state = '';
                    var zip = '';
                    var country = '';
                    var loyaltyID = window.digitalData.user.profile.profileInfo.loyaltyID;
                    var rewardsCount = '';
                    var loyaltyBalance = '';

                    if (window.digitalData.user.profile.address) {
                        city = window.digitalData.user.profile.address.city;
                        state = window.digitalData.user.profile.address.stateProvince;
                        zip = window.digitalData.user.profile.address.postalCode;
                        country = window.digitalData.user.profile.address.country;
                    }
                    if (window.digitalData.user.profile.profileInfo.rewardsCount) {
                        rewardsCount = window.digitalData.user.profile.profileInfo.rewardsCount;
                    }
                    if (window.digitalData.user.profile.profileInfo.currentSpend) {
                        loyaltyBalance = window.digitalData.user.profile.profileInfo.currentSpend;
                    }
                    window.cmCreateRegistrationTag(profileEmailHash, profileEmailHash, city, state, zip, country, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + rewardsCount + '-_-' + loyaltyBalance + '-_--_--_-loyl_acct_active');
                }
                $('#ChangeMailForm').find('.status-result').html(data);
            }
        });
    });
}

/**
 * @private
 * @function
 * @description initialize events for analytics
 */
function initAnalyticsEvents() {
    var registrationForm = $('#RegistrationForm');
    //var createAccountSubmit = registrationForm.find('button[type="submit"][name$="_confirm"]');
    var zipcode = registrationForm.find('input[name$="_postalCode"]');
    //zipcode validation on blur for analytics
    zipcode.on('blur', function () {
        if ($(this).hasClass('error')) {
            window.cmCreatePageElementTag('Signup failed', 'SIGNUP_ACCT_CREATE');
        }
    });
}

function initMyInfoEvents() {
    var registrationForm = $('#ChangePasswordForm');
    if (registrationForm.find('.account-change-notification').length > 0) {
        registrationForm.find('input[name$="_currentpassword"]').attr('aria-describedby', 'password-change-info-status').focus();
    }
}

//add event to fire join in modal if slot exist
function initHeaderRewardsPopup() {
    if ($('.loyalty-welcome-message').length > 0) {
        $('.loyalty-welcome-message').on('click', '#join-loyalty-header, .header-join-now', function (e) {
            e.preventDefault();
            if ($(window).width() < 768) {
                window.location = util.appendParamToURL(Urls.showRewardsDialog, 'page', 'myacc');
            } else {
                var rewardsUrl = Urls.showRewardsDialog;
                dialog.open({
                    url: rewardsUrl,
                    options: {
                        dialogClass: 'rewardspopup',
                        width: 425,
                        open: function () {
                            validator.init();
                            maskBirthdayInit();
                            var rewardsForm = $('form#join-rewards-form');
                            initRewardsPopupEvents(rewardsForm, 'header');
                            $('.ui-widget-overlay').on('click', function () {
                                dialog.close();
                            });
                            $('#cancel-rewards').on('click', function (e) {
                                e.preventDefault();
                                dialog.close();
                            });
                        }
                    }
                });
            }
        });
    }
}

function activateSubmitBtn() {
    var submitButton = $('#RegistrationForm').find('button[type="submit"]');

    var isValid = true;
    $('#RegistrationForm input').filter('.required:visible').each(function () {
        if ($(this).val() === '')
            isValid = false;
    });

    if (isValid) {
        submitButton.removeAttr('disabled');
    } else {
        submitButton.attr('disabled', 'disabled');
    }

    var changeMailForm = $('#ChangeMailForm');
    if (changeMailForm.length > 0) {
        var submitButtonMail = changeMailForm.find('button[type="submit"]');
        var isValidMail = true;
        changeMailForm.find('input').filter('.required:visible').each(function () {
            if ($(this).val() === '')
                isValidMail = false;
        });

        if (isValidMail) {
            submitButtonMail.removeAttr('disabled');
        } else {
            submitButtonMail.attr('disabled', 'disabled');
        }
    }
}
function inputTester() {
    $('input').on('blur', function () {
        activateSubmitBtn();
    });
}

/**
 * @private
 * @function
 * @description Function to validate the Corporate sales form submit button
 */
function activateCorporateGiftSubmitBtn() {
    var submitButton = $('#CorporateSalesForm').find('button[type="submit"]');
    var $corporateSalesForm = $('#CorporateSalesForm');

    var isValid = true;
    $('#CorporateSalesForm input, #CorporateSalesForm textarea').filter('.required:visible').each(function () {
        if ($(this).val() === '')
            isValid = false;
    });

    if (!$('input[name$="_giftcards"]').is(':checked') && !$('input[name$="_bulkproductsales"]').is(':checked') && !$('input[name$="_allotherinquiries"]').is(':checked')){
        isValid = false;
    }

    if (isValid) {
        if (!$corporateSalesForm.valid()) {
            return false;
        }
        submitButton.removeAttr('disabled');
    } else {
        submitButton.attr('disabled', 'disabled');
    }
}

/**
 * @private
 * @function
 * @description Trigger Corporate sales form validation on keyup and on click
 */
function corporateGiftInputTester() {
    $('#CorporateSalesForm input, #CorporateSalesForm textarea').on('keyup click', function () {
        activateCorporateGiftSubmitBtn();
    });

    $('#CorporateSalesForm .checkbox input').on('click', function () {
        activateCorporateGiftSubmitBtn();
    });
}


function fireAnalyticTagsOnAccountLogin() {
    if ($('.loggedInAnalytics').length) {
        var loyaltyStatus = $('h1.login-name-header').data('loyalty');
        var loyaltyID = $('h1.login-name-header').data('idloyalty');
        fireAnalyticsData(loyaltyID, loyaltyStatus, window.digitalData.user.profile.profileInfo.profileEmailHash);

        // POS pending subsequent login
        if ('localStorage' in window && window.localStorage.getItem('posPendingComplete')) {
            if ('digitalData' in window && 'user' in window.digitalData && 'attributes' in window.digitalData.user) {
                window.digitalData.user.attributes.rlpLogin = true;
                window.localStorage.removeItem('posPendingComplete');
            }
        }
    }
}


function initCSTabs() {
    var csTabs = $('#cs-tabs');
    if (csTabs.length > 0) {
        setTimeout(function () {
            csTabs.find('ul > li > a').each(function () {
                var href = $(this).data('href');
                $(this).attr('href', href);
            });
            csTabs.tabs();
        }, 100); // need to add timeout because of bbw-amaze script which adds event listeners to links with href attribute
    }
}

function getParameterByName(name) {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function handlePageNavigation(targetPage) {
    window.location.search = '?page=' + targetPage;
}

function setupPointsHistoryPagination() {
    var totalPages = Math.trunc(pagination.dataset.totalPages);
    var currentPage = getParameterByName('page') ? Math.trunc(getParameterByName('page')) : 1;
    var nextPage = document.querySelector('.js-next-page');
    var prevPage = document.querySelector('.js-prev-page');
    var arrows = [nextPage, prevPage];

    pagination.addEventListener('change', function(e){handlePageNavigation(Math.trunc(e.target.value))})

    arrows.forEach(function(arrow){
        arrow.addEventListener('click', function (e) {
            e.preventDefault();
            if (arrow.classList.contains('prev')) handlePageNavigation(currentPage - 1);
            if (arrow.classList.contains('next')) handlePageNavigation(currentPage + 1);
        })
    });

    if (currentPage === totalPages) {
        // last page of results
        nextPage.classList.add('invisible');
    } else if (currentPage === 1 || !currentPage) {
        // first page of results
        prevPage.classList.add('invisible');
    } else if (currentPage < 1 && currentPage !== totalPages && prevPage.classList.contains('invisible')) {
        // mid pages
        prevPage.classList.remove('invisible');
    }
}

function initPointsHistory() {
    if (pagination) setupPointsHistoryPagination();
}

function loyaltyInformationDialogs() {
    $(document).on('click', '#negative-balance-cta', function (e) {
        e.preventDefault();
        var url = util.appendParamsToUrl(e.target.href, {
            format: 'ajax'
        });
        dialog.open({
            url: url,
            options: {
                dialogClass: 'negative-balance',
                open: function () {
                    $('.ui-dialog-titlebar-close').attr('aria-label','Close');
                    $('body').css({
                        'overflow' : 'hidden',
                        'position' : 'relative'
                    });
                    $('#wrapper').attr('aria-hidden','true');
                    $('.ui-widget-overlay').on('click', function () {
                        dialog.close();
                    });
                },
                close: function () {
                    $('body').css({
                        'overflow' : '',
                        'position' : ''
                    });
                    $('#wrapper').removeAttr('aria-hidden');
                }
            }
        });
    });
}

function fireAnalyticsTagOnCorpSubmit() {
    if ($('#corpSalesSuccessMsg').length > 0) {
        if ('cmCreateElementTag' in window) {
            window.cmCreateElementTag('Inquiry submitted', 'Corporate Gifts');
        }
    }
}

function fireAnalyticsOnAccountLoad() {
    var $wrapper = $('#wrapper');
    if ($wrapper.hasClass('pt_account') &&
        !$wrapper.hasClass('addresses') &&
        $('#join-rewards').length > 0 &&
        'digitalData' in window) {
        window.digitalData.page.loyaltyJoinAsset = true;
    }
}

window.onload = function(){
    var formData = localStorage.getItem('billingaddress');
    if (formData !== null) {
        $('fieldset.address-element').deserialize(formData);
        localStorage.removeItem('billingaddress');
    }
}

function showThankyouModal () {
    if ($('body').hasClass('joinsuccess')) {
        $('body').addClass('open-modal');
        $('.ui-dialog').addClass('rewardspopupimg');
        $('.ui-dialog').removeClass('rewardspopup');
        $('.rewardspopupimg #dialog-container .loyalty-sign-up-wrapper').remove();
        $('.loyalty-signup-thankyou').show();
        $('.rewardspopupimg .ui-dialog-titlebar .ui-button-text').text('Clickable Close Button Close');
        $('.loyalty-signup-thankyou').removeAttr('aria-hidden');
        $('.rewardspopupimg .ui-dialog-titlebar button').focus();
    } else {
        $('body').removeClass('open-modal');
    }
}

function initMyPaymentMethod() {
    var storedCardSize = parseInt($('input[name="stored-card-size"]').val(), 10);
    if (storedCardSize <= 0){
        $('.my-other-cards h2').hide();
    }

    if (!$('.my-payment-add-new-card-form').hasClass('show')){
        $('.my-payment-add-new-card-form').hide();
    }
    $('.add-new-card-cta button').click(function() {
        $('.add-edit-headline').text(Resources.ADD_NEW_CARD_HEADLINE);
        $('#CreditCardForm').find('.form-credit-card-section').attr('disabled',false);
        var storedCardSize = parseInt($('input[name="stored-card-size"]').val(), 10);
        if (storedCardSize < SitePreferences.MAX_CREDIT_CARD_LIMIT){
            $('.add-new-card-cta button').hide();
            $('.my-other-cards .payment-list').hide();
            $('.my-payment-add-new-card-form').show();
        } else {
            $('.max-card-error').show();
            $('.max-card-error').focus();
            $('.max-card-error').attr('tabindex','0');
        }

        //display buttons
        $('#credit-card-edit').hide();
        $('#credit-card-create').show();

        $('.stored-payment-billing-address .form-row input').each(function(){
            $(this).val('');
        });

        $('input[name*="selectedBillingAddressID"]').on('click', function() {
            $('input[name*="selectedBillingAddressID"]').each(function(){
                $(this).closest('div.form-row').removeClass('errorSaveAddress');
                $(this).closest('div.form-row').removeClass('activeSaveAddress');
                if ($(this).is(':checked') === true) {
                    $(this).closest('div.form-row').addClass('activeSaveAddress');
                }
            });
        });

        initCreditCardSubmit();
    });

    $('#CreditCardForm .form-row-button .cancel-button').click(function(e) {
        e.preventDefault();
        $('.add-edit-headline').text(Resources.ADD_NEW_CARD_HEADLINE);
        $('#CreditCardForm').find('.form-credit-card-section').attr('disabled',false);
        $('.add-new-card-cta button').show();
        $('.payment-list').show();
        $('.paymentslist').show();
        $('.my-payment-add-new-card-form').hide();
        $('.stored-payment-billing-address').hide();
        $('#CreditCardForm .field-wrapper .input-text').removeClass('error');
        $('#CreditCardForm .field-wrapper span.error').remove();
        $('.form-validation-error-message').hide();
        $('.billing-address-error-message').hide();

        //Credit card edit cancel events
        $('#credit-card-edit').hide();
        $('#credit-card-create').show();

        //clear the credit card input fields
        $('input[name*=newcreditcard_owner]').val('');
        $('select[name*=newcreditcard_type]').val('Visa');
        $('input[name*=newcreditcard_number]').val('');
        $('select[name*=newcreditcard_expiration_month]').val('');
        $('select[name*=newcreditcard_expiration_year]').val('');
        $('.save-default-card').prop('checked', false);
        $('.saved-addresses .radio-select input').each(function(){
            $(this).prop('checked', false);
            $(this).closest('div.form-row').removeClass('activeSaveAddress');
            $(this).closest('div.form-row').removeClass('errorSaveAddress');
        });
        $('#CreditCardForm').removeClass('disabled-form');

    });

    $('.uncheck-default, #save-address-checkbox').click(function(e) {
        e.preventDefault();
    });

    $('.saved-addresses .radio-select input').on('click', function(){
        $('.billing-address-error-message').hide();
    })

    $('.billing-address-container .add-new-addr').on('click', function(){
        $('.stored-payment-billing-address').show();
        $('.saved-addresses .radio-select input').each(function(){
            $(this).prop('checked', false);
        });
        validator.init();
    });
}

/**
 * @private
 * @function
 * @description Track logged-in user loyalty program status
 */
function loyaltycustomerTracking() {
    if ('loyaltyZip' in window.digitalData.user.profile.profileInfo && window.SitePreferences.EXCLUDED_ZIP_CODES_LOYALTY.indexOf(window.digitalData.user.profile.profileInfo.loyaltyZip) == -1 && util.isUSPostalCode(window.digitalData.user.profile.profileInfo.loyaltyZip)) {
        window.digitalData.user.profile.profileInfo.loyalityEligibleMarket = 'Yes';
    } else {
        window.digitalData.user.profile.profileInfo.loyalityEligibleMarket = 'No';
    }
}

function initializeEvents() {
    initAccordion();
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    initLoginPage();
    updateAccountNav();
    anchors();
    anchorClick();
    passHideShow();
    validatePassword();
    userExistOnLoad();
    switchClassOnPasswordRules();
    maskBirthdayInit();
    initBarcodeToggle();
    initializeProgress();
    initMyAccountRewardsPopup();
    maskPhoneInit();
    checkLoyaltyOffer();
    preventFormSubmitIfUserExists();
    setAmountOnHowRewardsWorkPage();
    initSubmitAccountInformationForm();
    initRewardsMobile();
    initMyWalletEvents();
    initAnalyticsEvents();
    initMyInfoEvents();
    inputTester();
    initRegistrationTag();
    fireAnalyticsOnAccountLoad();
    fireAnalyticTagsOnAccountLogin();
    initCSTabs();
    loyaltyInformationDialogs();
    fireAnalyticsTagOnCorpSubmit();
    initMyPaymentMethod();

    if (!SitePreferences.ORDERGROOVE_ENABLED) {
        $('.secondary-navigation').find('li a[data-id="autoRefresh"]').remove();
        $('.account-options').find('.auto-refresh').remove();
    }
    var isPointsHistoryPage = document.querySelector('.points-history');

    if (isPointsHistoryPage) {
        initPointsHistory();
    }

    var accountChangeNotification = document.querySelector('.account-change-notification');
    if (accountChangeNotification) {
        var closeNotification = document.querySelector('.js-account-notif-close');
        var url= document.location.href;

        closeNotification.addEventListener('click', function() {
            accountChangeNotification.classList.add('hidden');
            if (url.includes('showstatusmsg')) window.history.pushState({}, '', url.split('?')[0]);
        });

        setTimeout(function () {
            window.history.pushState({}, '', url.split('?')[0]);
        }, 5000);
    }
}

var account = {
    init: function () {
        initializeEvents();
        giftcert.init();
        contactUsFormInit();
        initPhoneNumber();
        isUserExist();
        initializeAddressForm();
        loyaltycustomerTracking();
    },
    initCartLogin: function () {
        initLoginPage();
        passHideShow();
    },
    initPopupLogin: function () {
        passHideShow();
    },
    initAccountNav: function () {
        updateAccountNav();
    },
    initUserExistOnBlur: function () {
        isUserExist();
    },
    initHeaderRewardsSlot: function () {
        initHeaderRewardsPopup();
    },
    initMyAccountRewardsPopup : function () {
        initMyAccountRewardsPopup();
    },
    initBonusActivate : function () {
        var activateButtons = document.querySelectorAll('.bonus-activate button');
        var $rewardsBonusContainer = $('.my-rewards-bonus');

        wcagLoadSpinner.init($rewardsBonusContainer);

        for (var i = 0; i < activateButtons.length; i++) {
            activateButtons[i].addEventListener('click', function (e) {
                var bonusActivate = e.target.closest('.bonus-activate');
                var shopNowButton = bonusActivate.querySelector('.bonus-shop-now');
                var activateErrorClassName = 'activate-error';
                var promotionRedeemContainer = bonusActivate.closest('.reward-promotion-redeem');
                var offerId = e.target.getAttribute('data-offer-id');
                var activateURL = util.appendParamsToUrl(Urls.activateOfferActivate, {
                    offerId: offerId
                });

                if (promotionRedeemContainer.classList.contains(activateErrorClassName)) {
                    promotionRedeemContainer.classList.remove(activateErrorClassName);
                }

                wcagLoadSpinner.show($rewardsBonusContainer);

                $.ajax({
                    url: activateURL,
                    type: 'GET',
                    dataType: 'json'
                }).done(function (data) {
                    if (data.optInFl) {
                        $(e.target).fadeOut(500, function () {
                            this.remove();
                            bonusActivate.classList.add('bonus-activated');
                            shopNowButton.classList.remove('hidden');
                        });
                    } else {
                        promotionRedeemContainer.classList.add(activateErrorClassName);
                    }
                }).fail(function () {
                    promotionRedeemContainer.classList.add(activateErrorClassName);
                }).always(function () {
                    wcagLoadSpinner.hide($rewardsBonusContainer);
                });
            });
        }
    }

};

module.exports = account;
