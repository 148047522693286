'use strict'
var daysOfTheWeek = [
    {standard: 'Sunday', abr: 'Sun'},
    {standard: 'Monday', abr: 'Mon'},
    {standard: 'Tuesday', abr: 'Tue'},
    {standard: 'Wednesday', abr: 'Wed'},
    {standard: 'Thursday', abr: 'Thu'},
    {standard: 'Friday', abr: 'Fri'},
    {standard: 'Saturday', abr: 'Sat'}
];

function getHeadings(cutoffDate) {
    var todaysDate = new Date();
    var todaysDatePlusOne = new Date(todaysDate.getTime() + 86400000);
    var isWithin24Hours = cutoffDate.getTime() - todaysDate.getTime() <= 86400000;
    var cutoffDayOfTheWeek = daysOfTheWeek[cutoffDate.getDay()];
    var cutoffMonth = cutoffDate.toLocaleString('en-us',{month:'short'});
    var cutoffDayDate = cutoffDate.getDate();
    var isCutoffDayToday = todaysDate.toDateString() == cutoffDate.toDateString();
    var isCutoffDayTomorrow = todaysDatePlusOne.toDateString() == cutoffDate.toDateString();
    var desktopOne = isCutoffDayToday
        ? 'Today by '
        : isCutoffDayTomorrow
        ? 'Tomorrow by '
        : cutoffDayOfTheWeek.standard + ', ';
    var mobileOne = isCutoffDayToday
        ? 'Today, '
        : isCutoffDayTomorrow
        ? 'Tmr, '
        : cutoffDayOfTheWeek.abr + ', ';
    
    var desktopTwo = cutoffMonth + ' ' + cutoffDayDate;
    var mobileTwo = cutoffMonth + ' ' + cutoffDayDate;
    return {
        desktopOne: desktopOne,
        mobileOne: mobileOne,
        desktopTwo: isWithin24Hours ? formatTime(cutoffDate) : desktopTwo,
        mobileTwo: isWithin24Hours ? formatTime(cutoffDate) : mobileTwo
    };
}

function formatTime(time) {
    var twentyFourHourTime = time.getHours();
    var convertedHour = twentyFourHourTime == 0 ? 12 : twentyFourHourTime;
    convertedHour = convertedHour > 12 ? convertedHour - 12 : convertedHour;
    var timeMinutes= time.getMinutes();
    var timeMinutesFormatted = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
    var amPm = time.getHours() < 12 ? 'AM' : 'PM';
    return convertedHour + ':' + timeMinutesFormatted + ' ' + amPm;
}


module.exports = function () {
    var fulfillmentCardContents = document.getElementsByClassName('ds-fulfillment-content');

    for (var i = 0; i < fulfillmentCardContents.length; i++) {
        var fulfillmentCard = fulfillmentCardContents[i];
        var fulfillmentCardEpoch = fulfillmentCard.dataset.epoch;
        var fulfillmentCardEpochNumber = Number(fulfillmentCardEpoch);

        if (isNaN(fulfillmentCardEpochNumber)) {
            continue;
        }

        var fulfillmentCardDate = new Date(fulfillmentCardEpochNumber);
        var headings = getHeadings(fulfillmentCardDate);

        fulfillmentCard.querySelector('.ds-fulfillment-desktop.heading-1').innerHTML = headings.desktopOne + headings.desktopTwo;
        fulfillmentCard.querySelector('.ds-fulfillment-mobile.heading-1').innerHTML = headings.mobileOne + headings.mobileTwo;
    }
};
