/* eslint-disable */
'use strict';

var getElementsToHide = function (modalContainer) {
    var getSiblings = function (elementToFindSiblings) {
        var result = [],
            node = elementToFindSiblings.parentNode.firstChild;

        while (node) {
            if (node !== elementToFindSiblings && node.nodeType === Node.ELEMENT_NODE) {
                result.push(node);
            }
            node = node.nextElementSibling || node.nextSibling;
        }
        return result;
    };
    var getParentSiblings = function (elementToFindParents) {
        var currentParent = elementToFindParents.parentNode;
        var result = [];
        while (currentParent && currentParent.tagName !== "BODY") {
            result = result.concat(getSiblings(currentParent));
            currentParent = currentParent.parentNode;
        }
        return result;
    }
    var elementsToHide = getSiblings(modalContainer).concat(getParentSiblings(modalContainer));
    return elementsToHide;
}

var hideNonModal = function (modalContainer) {
    var elementsToHide = getElementsToHide(modalContainer);
    for (var i = 0; i < elementsToHide.length; i++) {
        var currentElementToHide = elementsToHide[i];
        if (currentElementToHide.getAttribute("aria-hidden") == "true") {
            currentElementToHide.setAttribute("data-already-aria-hidden", "true");
        } else {
            currentElementToHide.setAttribute("aria-hidden", "true");
        }
    }
}

var unhideNonModal = function (modalContainer) {
    var hiddenToShow = getElementsToHide(modalContainer);
    for (var i = 0; i < hiddenToShow.length; i++) {
        if (hiddenToShow[i].hasAttribute('data-already-aria-hidden')) {
            hiddenToShow[i].removeAttribute('data-already-aria-hidden');
        } else {
            hiddenToShow[i].setAttribute("aria-hidden", "false");
        }
    }
}

module.exports = {
    hideNonModal: hideNonModal,
    unhideNonModal: unhideNonModal
};
