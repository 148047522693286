/*jslint browser:true */
/*global document,google,console */
/*
 * All java script logic for google driven store locator.
 *
 * The code relies on the jQuery JS library to
 * be also loaded.
 *
 */

'use strict';
var util = require('./util');
var tealiumSearch = require('./tealium/pages/search');

//var MarkerWithLabel = require('./marker-with-label');

function getPhoneNumberAriaLabel(value, spaceRequiredAfter) {
    if (!value) return '';
    var space = spaceRequiredAfter || 1;
    var reg = new RegExp('.{' + space + '}','g');
    return value.replace(/[( ]/g, '').replace(/[)-]/g, '.').replace(reg, function (a) {
        return a + ' ';
    });
}

var bopisLocator = {
    // configuration parameters and required object instances
    bopisurl:			null,
    setstoreURL:		null,
    zoomradius:			{},
    radius:				50,
    map:				null,
    unit:				'km',
    isOpenedFromQL:       false,
    /*********************************************************
     * initialize the google map
     * @param - zoomradius : json object with radius settings for each google zoom level (0-20)
     * @param - bopisurl : url for querying nearest stores
     * @param - setstoreURL : url for setting store
     **********************************************************/

    init: function (zoomradius, bopisurl, setstoreURL, openedFromQL, productSku) {

        this.zoomradius = zoomradius;
        this.bopisurl = bopisurl;
        this.setstoreURL = setstoreURL;
        this.isOpenedFromQL = openedFromQL;
        this.productSku = productSku;

        var allowGeolocation = (document.cookie.indexOf('allow_geolocation=true')!= -1) ? true : false;
        var selectedStoreZipCode = window.SessionAttributes.BOPIS_SELECTED_STORE_ZIP_CODE;

        if (selectedStoreZipCode) {
            $('.bopisDialog .bopis-postal-code').val(selectedStoreZipCode);
            $('.bopis-loader').removeClass('hide');
            findGo();
        } else if (allowGeolocation) {
            if (window.User.geolocation.latitude !== '' && window.User.geolocation.longitude !== '') {
                bopisLocator.renderStores(window.User.geolocation.latitude, window.User.geolocation.longitude, $('.bopisDialog #bopiscountry').val(), $('.bopisDialog #bopisdistanceunitpref').val(), $('#bopisacceptedradius').val(), false);
                $('.bopisDialog .bopis-postal-code').val(User.geolocation.postalCode);
                $('.bopisDialog .bopis-store-search').removeClass('show-search-icon').addClass('show-remove-icon');
            }
        }
        $('.bopisDialog .bopis-currentlocation-link').click(function () {
            var currentLocationLink = $(this);
            $('.bopisDialog .current-location-not-found').remove();
            $('.bopis-loader').removeClass('hide');
            $.ajax({
                url : Urls.resourcesLoadTransient,
                success: function() {
                    if (window.SessionAttributes.IS_REQUEST_IN_AVAILABLE_BOPIS_MARKET) {
                        if (window.User.geolocation.latitude !== '' && window.User.geolocation.longitude !== '') {
                            bopisLocator.renderStores(window.User.geolocation.latitude, window.User.geolocation.longitude, $('.bopisDialog #bopiscountry').val(), $('.bopisDialog #bopisdistanceunitpref').val(), $('#bopisacceptedradius').val(), false);
                            $('.bopisDialog .bopis-postal-code').val(User.geolocation.postalCode);
                            $('.bopisDialog .bopis-store-search').removeClass('show-search-icon').addClass('show-remove-icon');
                        } else {
                            $('.bopis-loader').addClass('hide');
                            $('<div id="locationNotFound" class="current-location-not-found" role="alert">' + Resources.CURRENT_LOCATION_NOTFOUND + '</div>').insertAfter(currentLocationLink);
                        }


                    } else {
                        $('.bopisDialog .bopis-postal-code').addClass('error');
                        $('.bopisDialog #bopispostalcode-error').remove();
                        $('<span id="bopispostalcode-error" class="error bopispostalcode-error" role="alert">Sorry! There are no participating stores near that location. Please try another ZIP code.</span>').insertAfter($('.bopisDialog .bopis-postal-code'));
                    }
                },
                error: function() {
                    $('.bopis-loader').addClass('hide');
                    $('<div id="locationNotFound" class="current-location-not-found" role="alert">' + Resources.CURRENT_LOCATION_NOTFOUND + '</div>').insertAfter(currentLocationLink);
                }
            });
        });

        function findGo() {
            document.activeElement.blur();
            bopisLocator.getSearchPosition();
        }

        $(document).off().on('submit', '.bopisDialog #bopis-store-search-form', function (e) {
            if (!$(this).valid()) {
                e.preventDefault();
                return false;
            }
            $('.bopis-loader').removeClass('hide');
            findGo();
            return false;
        });

        $('.remove-bopis-search').on('click', function(){
            $('.bopis-postal-code').val('');
            $('.bopisDialog .bopis-store-search').removeClass('show-remove-icon').addClass('show-search-icon');
            $('.bopis-postal-code').focus();
        });

        $('.remove-bopis-search').on('keyup', function(event){
            if (event.keyCode === 13) {
                $('.bopis-postal-code').val('');
                $('.bopisDialog .bopis-store-search').removeClass('show-remove-icon').addClass('show-search-icon');
                $('.bopis-postal-code').focus();
            }

        });

        $('.bopis-postal-code').on('keyup', function(e){
            if (e.keyCode != 13) {
                $(this).removeClass('error');
                $(this).next('#bopispostalcode-error').html('');
            }
            if ($('.bopis-postal-code').val().trim() == ''){
                $('.bopisDialog .bopis-store-search').removeClass('show-remove-icon').addClass('show-search-icon');
            }
            if (e.keyCode == 8){
                $('.bopisDialog .bopis-store-search').removeClass('show-remove-icon').addClass('show-search-icon');
            }
        });
        $('.bopis-postal-code').on('blur', function(){
            if ($('.bopis-postal-code').val().trim() == ''){
                $('.bopisDialog .bopis-store-search').removeClass('show-remove-icon').addClass('show-search-icon');
            }
        });

        $('#bopisacceptedradius').change(function () {
            if ($('#bopisacceptedradius').val() !== '') {
                bopisLocator.radius = $('#bopisacceptedradius').val();
            } else {
                bopisLocator.radius = bopisLocator.zoomradius[self.map.getZoom()];
            }
        });
    },

    /******************************************************************
     * function to populate near by available storesin store locator modal
     * @param - stores : a json object containing stores
     * @param - map : the map
     ********************************************************************/
    populateStores: function (stores, noLoc, location) {
        var noLocation	= noLoc || false,
            thisLoc		= location || null,
            distance	= 0,
            storeCount	= 0,
            storeLatLng	= '';
        $('.bopis-loader').addClass('hide');
        // Function that fix borders
        $('#bopisstores').html('<div class="bopis-stores-container"></div>');

        //create array of store IDs and add distance if available
        var storesArray = [];
        for (var thisStore in stores) {
            var tempArray = [thisStore];
            if (!noLocation) {
                if (thisLoc && stores[thisStore].latitude && stores[thisStore].longitude) { //calculate distance from search location
                    storeLatLng = new google.maps.LatLng(stores[thisStore].latitude, stores[thisStore].longitude);
                    distance	= google.maps.geometry.spherical.computeDistanceBetween(thisLoc, storeLatLng, 3959).toFixed(1);
                    tempArray.push(distance);
                }
            }
            storesArray.push(tempArray);
        }

        //if we have stores with distances we sort the array of store IDs
        if (storesArray.length && storesArray[0].length > 1) {
            storesArray.sort(function (a, b) {return a[1] - b[1];});
        }
        var storeinfoopen = '<form class ="bopis-select-store-form" id="bopis-select-store-form"><fieldset class="bopis-stores-wrap-container"><legend class="visually-hidden">' + Resources.BOPIS_INSTORE_HEAD + '</legend>';
        storeinfoopen += '<ul class="bopis-stores-wrap" tabindex="-1"></ul><div id="un-available-in-store-error" class="product-un-available-in-store-err-msg hide"></div></fieldset>';
        $('#bopisstores .bopis-stores-container').append(storeinfoopen);

        for (var i = 0; i < storesArray.length; i++) {
            var store = storesArray[i][0];

            storeCount++;

            //format the address
            var formattedAddress = '';
            formattedAddress = (stores[store].address1) ? formattedAddress + stores[store].address1 : formattedAddress;
            formattedAddress = (stores[store].address2) ? formattedAddress + ', ' + stores[store].address2 : formattedAddress;
            formattedAddress = (stores[store].city) ? formattedAddress + ', ' + stores[store].city : formattedAddress;
            formattedAddress = (stores[store].stateCode)  ? formattedAddress + ', ' + stores[store].stateCode : formattedAddress;
            formattedAddress = (stores[store].postalCode) ? formattedAddress + ' ' + stores[store].postalCode : formattedAddress;

            //URL encode the address
            var encodedAddress = encodeURIComponent(formattedAddress);
            var productAvailabiltyListItemClassName = '';
            if (!noLocation) {
                //build the store info HTML for right column
                var storephone = stores[store].phone.replace(/[()-/\s]/g, '');
                var distanceToBopisStoreHtml = '';
                var ariaDiscribeId = stores[store].instoreQty != '' ? 'aria-describedby="availability-in-'+store+'"' : '';
                var storeName = stores[store].name;
                var storedetails = '<div class="bopis-store-list-headinfo"><label class="visually-hidden" for="'+store+'">'+storeName+'</label>';
                storedetails += '<input class="select-store-radio-btn" type="radio" id="'+store+'" name="storeid" value="'+store+'" tabindex="0" ' + ariaDiscribeId + '/>';
                storedetails += '<div class="bopis-store-name-and-details"><h3 class="bopis-store-name">' + (storeName ? storeName.toLowerCase(): '')+ '</h3>';
                storedetails += '<div tabindex="0" aria-expanded="false"  role="button" aria-label="'+storeName + ' Store Details "'+' class="bopis-store-show-details-link">Store Details </div></div>';
                storedetails += '<div class="bopis-store-dist-container">'; // using the distance container for the availability message too

                var bopisStoreUnavailableMessage = SitePreferences.BOPIS_STORE_UNAVAILABLE_CUSTOM_MESSAGE ?
                SitePreferences.BOPIS_STORE_UNAVAILABLE_CUSTOM_MESSAGE :
                Resources.PICKUP_INSTORE_UNAVAILABLE_ALERT;

                var bopisItemAvailableMessage = SitePreferences.BOPIS_ITEM_AVAILABLE_CUSTOM_MESSAGE ?
                SitePreferences.BOPIS_ITEM_AVAILABLE_CUSTOM_MESSAGE :
                Resources.PICKUP_INSTORE_ITEM_AVAILABLE_ALERT;

                var bopisItemUnavailableMessage = SitePreferences.BOPIS_ITEM_UNAVAILABLE_CUSTOM_MESSAGE ?
                SitePreferences.BOPIS_ITEM_UNAVAILABLE_CUSTOM_MESSAGE :
                Resources.PICKUP_INSTORE_ITEM_UNAVAILABLE_ALERT;

                var storeDisabled = ('bopisAvailable' in stores[store]) && !stores[store].bopisAvailable;

                if (thisLoc && stores[store].latitude && stores[store].longitude) { //calculate distance from search location
                    storeLatLng = new google.maps.LatLng(stores[store].latitude, stores[store].longitude);
                    distance	= google.maps.geometry.spherical.computeDistanceBetween(thisLoc, storeLatLng, 3959).toFixed(1);
                    distanceToBopisStoreHtml += '<div class="bopis-store-distance">' + distance + ' ' + $('#bopisdistanceunitpref').val() + '</div>';
                }

                // If bopis order limits feature is enabled
                if (storeDisabled) {
                    distanceToBopisStoreHtml += '<div id ="availability-in-'+store+'"class="bopis-product-availability">' + bopisStoreUnavailableMessage + '</div>';
                } else if (stores[store].instoreQty != '') {
                    distanceToBopisStoreHtml += '<div id ="availability-in-'+store+'"class="bopis-product-availability">' + (stores[store].instoreQty > 0 ? bopisItemAvailableMessage : bopisItemUnavailableMessage) + '</div>';
                    productAvailabiltyListItemClassName = stores[store].instoreQty > 0 ? 'bopis-product-available' : 'bopis-product-unavailable';
                }

                storedetails += distanceToBopisStoreHtml;

                storedetails += '</div>'; // close the distance container

                storedetails += '</div>'
                storedetails += '<div class="bopis-collapsedinfo hide"><div class="bopis-store-address">' + '<a target="_blank" href="https://maps.google.com/maps?daddr=' + encodedAddress + '">' + (stores[store].address1 ? stores[store].address1.toLowerCase() : '') + '<br>';
                storedetails +=  (stores[store].address2 ? stores[store].address2.toLowerCase() + '<br>' : '');
                storedetails +=  (stores[store].city ? stores[store].city.toLowerCase() : '') + ', ' + stores[store].stateCode + ' ' + stores[store].postalCode + '</a></div>';
                storedetails += '<div class="bopis-store-phone-no">' + '<a href="tel:+1'+ storephone +'" aria-label="' + getPhoneNumberAriaLabel(stores[store].phone, 1) + '">' + stores[store].phone + '</a></div>';
                storedetails += '<div class="bopis-store-hours">' + stores[store].storeHours + '</div>';
                var bopisAvailabilityClass = ('bopisAvailable' in stores[store] && stores[store].bopisAvailable == false) ? ' bopis-store-unavailable bopis-product-unavailable' : '';
                var storeDetailsListContainer = '<li class="' + productAvailabiltyListItemClassName + ' bopis-store-listitem' + bopisAvailabilityClass + (i === (storesArray.length - 1) ? ' last-list-item' : '') +'">';
                storeDetailsListContainer += '<div class="bopis-store-info-container">'+storedetails+'</div></li>';
                $('#bopisstores .bopis-stores-container .bopis-select-store-form .bopis-stores-wrap').append(storeDetailsListContainer);
            }
        }
        var selectedStoreId = window.SessionAttributes.BOPIS_SELECTED_STORE_ID;
        var storeinfoclose = '</form>';
        var storebutton = '<button type="submit" class="setstorebtn" id="submit" value="Set Store">Set Store</button>';
        $('#bopisstores .bopis-stores-container form').append(storebutton);
        $('#bopisstores .bopis-stores-container').append(storeinfoclose);
        var $selectedItemBtn = $('.select-store-radio-btn:first');
        if (selectedStoreId && $('input:radio[id=' + selectedStoreId + ']').length) {
            $selectedItemBtn = $('input:radio[id=' + selectedStoreId + ']');
        }
        $selectedItemBtn.attr('checked', true);
        $selectedItemBtn.attr('aria-checked', true);
        $selectedItemBtn.parents('.bopis-store-listitem').addClass('active-list-item');
        if ($selectedItemBtn.parents('.bopis-store-listitem').hasClass('bopis-product-unavailable')) {
            $selectedItemBtn.parents('.bopis-stores-wrap').addClass('product-unavailable-stores-wrap');
            $selectedItemBtn.attr('aria-describedby', 'un-available-in-store-error availability-in-'+$selectedItemBtn.attr('id'));
        }
        setTimeout(function() {
            $('.bopis-stores-wrap').focus();
            $('.bopisDialog').position({
                my: 'center',
                at: 'center',
                of: window,
                collision: 'flipfit'
            });
        },0)

        $('#bopis-select-store-form #submit').on('click', function (e) {
            e.preventDefault();
            // send the Acoustic/CoreMetrics element tag call
            tealiumSearch.setStore();
            // send AAI direct call request
            if ('_satellite' in window) window._satellite.track('bopissetstorecomplete');
            if (window.sessionStorage.setStoreButtonLocation && window.digitalData) {
                window.digitalData.page.attributes.setStoreLocation = window.sessionStorage.setStoreButtonLocation;
                delete window.sessionStorage.setStoreButtonLocation;
            }

            var selectedli = $('.bopis-store-listitem').find('input[name=storeid]:checked').closest('li');
            if (selectedli.hasClass('bopis-store-unavailable')) {
                $('.bopisDialog .product-un-available-in-store-err-msg').show().html(Resources.STORE_SELECTION_STORE_UNAVAILABLE);
                if (window.digitalData && window.digitalData.page && window.digitalData.page.attributes) {
                    window.digitalData.page.attributes.storeAvailabilityError = true;
                }
            } else if (window.pageContext.ns !== 'cart' && selectedli.hasClass('bopis-product-unavailable')) {
                $('.bopisDialog .product-un-available-in-store-err-msg').show().html(Resources.STORE_SELECTION_OUT_OF_STOCK);
                if (window.digitalData && window.digitalData.page && window.digitalData.page.attributes) {
                    window.digitalData.page.attributes.storeAvailabilityError = true;
                }
            } else {
                var storeID = $('.bopisDialog input[name="storeid"]:checked'). val();
                var url = util.appendParamsToUrl(Urls.setstoreURL, {storeID: storeID});
                $.ajax({
                    url : url,
                    success: function(data) {
                        var storeData = data;
                        if (window.pageContext && window.pageContext.ns && window.pageContext.ns === 'cart' && !bopisLocator.isOpenedFromQL) {
                            window.location.href = window.Urls.cartShow;
                        }
                        storeData.isSetStoreOpenedFromQL = bopisLocator.isOpenedFromQL;
                        window.storeChangedFromQL = bopisLocator.isOpenedFromQL;
                        var selectedStoreName = data.storeName ? data.storeName.toLowerCase() : '';
                        $('.bopisHeader .bopis-set-store').html(selectedStoreName).addClass('bopis-store-name');
                        $('.bopisHomePage .bopis-set-store').html(selectedStoreName).addClass('bopis-store-name');
                        $('.bopis-set-store-mobile').html(selectedStoreName).addClass('bopis-store-name');
                        $('.boipsrefinement .bopisToggle').attr('data-store-name', selectedStoreName);
                        var gridStoreInfoContainer = $('.boipsrefinement .selected-store-container');
                        if (window && window.SessionAttributes) {
                            window.SessionAttributes.BOPIS_SELECTED_STORE_ID = data.storeId;
                            window.SessionAttributes.BOPIS_SELECTED_STORE_NAME = selectedStoreName;
                        }
                        if (gridStoreInfoContainer.length) {
                            var gridSelectedStore = gridStoreInfoContainer.find('.selected-store');
                            var gridSetStore = gridStoreInfoContainer.find('.bopis-set-store');
                            gridSelectedStore.html(selectedStoreName);
                            gridSelectedStore.removeClass('hide');
                            gridSetStore.html(selectedStoreName);
                        }
                        $('.bopisDialog .ui-button-icon-primary.ui-icon.ui-icon-closethick').click();
                        $('body').trigger('setstore:success', storeData);

                        if (window.digitalData.page.attributes.storeAvailabilityError) {
                            delete window.digitalData.page.attributes.storeAvailabilityError;
                        }
                    }
                });
            }

        });

        if (storeCount < 1) {
            $('.bopis-loader').addClass('hide');
            $('#bopisstores').hide();
            $('.bopisDialog #bopispostalcode').addClass('error');
            $('.bopisDialog .bopis-stores-wrapper').addClass('hide');
            $('.bopisDialog #bopispostalcode-error').remove();
            $('<span id="bopispostalcode-error" class="error bopispostalcode-error" role="alert" >Sorry! There are no participating stores near that location. Please try another ZIP code.</span>').insertAfter($('.bopisDialog #bopispostalcode'));
        }

        $('.bopisDialog .bopis-store-show-details-link').on('click', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parents('.bopis-store-listitem').find('.bopis-collapsedinfo').hide();
                $(this).attr('aria-expanded',false);
            } else {
                $(this).addClass('active');
                $(this).attr('aria-expanded',true);
                $(this).parents('.bopis-store-listitem').find('.bopis-collapsedinfo').show();
            }
        });

        $('.bopisDialog .bopis-store-show-details-link').on('keyup', function (event) {
            // Number 13 is the "Enter" key on the keyboard

            if (event.keyCode === 13 || event.keyCode == 32) {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(this).parents('.bopis-store-listitem').find('.bopis-collapsedinfo').hide();
                    $(this).attr('aria-expanded',false);
                } else {
                    $(this).addClass('active');
                    $(this).attr('aria-expanded',true);
                    $(this).parents('.bopis-store-listitem').find('.bopis-collapsedinfo').show();
                }
            }
        });

        $('.bopisDialog .bopis-store-listitem .select-store-radio-btn').change(function () {
            $('.bopisDialog .bopis-stores-wrap').removeClass('product-unavailable-stores-wrap');
            $('.bopisDialog .setstorebtn').removeAttr('disabled')
            $('.bopisDialog .product-un-available-in-store-err-msg').html('').hide();
            $('.bopisDialog .bopis-store-listitem .select-store-radio-btn').each(function () {
                if ($(this).is(':checked')) {
                    $(this).attr('aria-checked', true);
                    $(this).parents('.bopis-store-listitem').addClass('active-list-item');
                    if ($(this).parents('.bopis-store-listitem').hasClass('bopis-product-unavailable')) {
                        $('.bopisDialog .bopis-stores-wrap').addClass('product-unavailable-stores-wrap');
                        $(this).attr('aria-describedby', 'un-available-in-store-error availability-in-'+$(this).attr('id'));
                    }
                } else {
                    $(this).attr('aria-checked', false);
                    $(this).parents('.bopis-store-listitem').removeClass('active-list-item')
                }
            });

            if (window.PRODUCTS_ELIGIBLE_FOR_STORE_PICKUP) {
                var getProductAvailabilityForSelectedStore = window.Urls.getProductAvailabilityForSelectedStore;
                var productidsStr = window.PRODUCTS_ELIGIBLE_FOR_STORE_PICKUP;
                var storeId = $(this).attr('id');
                var selectedStoreInPLP = $(this).parents('.bopis-store-listitem');
                $.get(util.appendParamsToUrl(getProductAvailabilityForSelectedStore, {'format': 'ajax', 'ajaxmeta': 'true','productidsArr':productidsStr,'storeId':storeId}), function (data) {
                    if (!data.isProductAvailableForSeletedStore) {
                        selectedStoreInPLP.addClass('bopis-product-unavailable');
                        $('.bopisDialog .bopis-stores-wrap').addClass('product-unavailable-stores-wrap');
                    }
                });
            }
        });

        $('.bopis-stores-wrapper .bopis-store-listitem').each(function(){
            var hours = $(this).find('.bopis-store-hours');
            if (hours.text().length !== 0) {
                var hoursText = hours.html();
                var lines = hoursText.split('<br>');
                var phrase = Array();
                var line;
                var d = new Date();
                var weekday = new Array(7);
                weekday[0] = 'Sunday';
                weekday[1] = 'Monday';
                weekday[2] = 'Tuesday';
                weekday[3] = 'Wednesday';
                weekday[4] = 'Thursday';
                weekday[5] = 'Friday';
                weekday[6] = 'Saturday';

                var n = weekday[d.getDay()].substr(0,3);
                for (line in lines) {
                    var subline
                    if (line != 'unique'){
                        subline = lines[line].split(':');
                    } else {
                        subline = [''];
                    }

                    switch (subline[0]) {
                        case 'MON':
                            subline[0] = 'Monday';
                            break;
                        case 'TUES':
                            subline[0] = 'Tuesday';
                            break;
                        case 'WED':
                            subline[0] = 'Wednesday';
                            break;
                        case 'THURS':
                            subline[0] = 'Thursday';
                            break;
                        case 'FRI':
                            subline[0] = 'Friday';
                            break;
                        case 'SAT':
                            subline[0] = 'Saturday';
                            break;
                        case 'SUN':
                            subline[0] = 'Sunday';
                            break;
                        default :
                    }
                    var lineItem;
                    switch (subline.length) {
                        case 1:
                            break;
                        case 3:
                            lineItem = '<div class="bopis-store-days">Today&#39;s Hours: ' +'<span class="bopis-store-time">' +subline[1]+':'+subline[2]+'</span>' + '</div>';
                            if (subline[0]==n) {
                                phrase.push(lineItem);
                            }
                            break;
                        case 4:
                            lineItem = '<div class="bopis-store-days">Today&#39;s Hours: ' +'<span class="bopis-store-time">' +subline[1]+':'+subline[2]+':'+subline[3]+'</span>' + '</div>';
                            if (subline[0]==n) {
                                phrase.push(lineItem);
                            }
                            break;
                        default :
                            lineItem = '<div class="bopis-store-days">Today&#39;s Hours: ' +'<span class="bopis-store-time">' +subline[1]+'</span>' + '</div>';
                            if (subline[0]==n) {
                                phrase.push(lineItem);
                            }
                    }
                }
                hours.html(phrase);
            }
        });
    },

    /*********************************************************
     * function to collect search data and retrieve a position
     **********************************************************/

    getSearchPosition: function (type) {
        var address = $('.bopisDialog #bopispostalcode').val(),
            radius  = $('.bopisDialog #bopisacceptedradius').val();

        if ($.trim(address) !== '') {
            this.geoCode(address, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    var location = results[0].geometry.location,
                        lat		= location.lat(),
                        lng		= location.lng();
                    if (type === 'onestore') {
                        var storeId = $('#storeId').val();
                        bopisLocator.renderOneStore(storeId, lat, lng, $('.bopisDialog #bopiscountry').val(), $('.bopisDialog #bopisdistanceunitpref').val(), radius, false);
                    } else {
                        bopisLocator.renderStores(lat, lng, $('.bopisDialog #bopiscountry').val(), $('.bopisDialog #bopisdistanceunitpref').val(), radius, false);
                    }
                    $('.bopisDialog .bopis-stores-wrapper').removeClass('hide');
                    $('.bopisDialog .bopis-store-search').removeClass('show-search-icon').addClass('show-remove-icon');
                    $('.bopisDialog #bopispostalcode').removeClass('error');
                    $('.bopisDialog #bopispostalcode-error').remove();
                    //scrollToStoreTiles();
                } else {
                    //window.console.error('Geocode was not successful for the following reason: ' + status);
                    $('.bopis-loader').addClass('hide');
                    $('.bopisDialog #bopispostalcode').addClass('error');
                    $('.bopisDialog .bopis-stores-wrapper').addClass('hide');
                    $('.bopisDialog #bopispostalcode-error').remove();
                    $('.bopisDialog .bopis-store-search').removeClass('show-search-icon').addClass('show-remove-icon');
                    $('<span id="bopispostalcode-error" class="error bopispostalcode-error" role="alert">Sorry! There are no participating stores near that location. Please try another ZIP code.</span>').insertAfter($('.bopisDialog #bopispostalcode'));
                }
            });
        } else {
            $('#bopisstores').html('');
        }
    },

    /*********************************************************
     * function to perform a google geocode (address -> LatLng)
     * @param - address : an address string to geocode
     * @param - callback : a callback function to handle the result
     **********************************************************/

    geoCode: function (address, callback) {
        var geocoder = new google.maps.Geocoder();

        var request = {
            address: address
        };

        geocoder.geocode(request, function (results, status) {
            //Middle of US which is returned when no locations are found
            var defaultLocation = {
                lat : 37.09024,
                lng : -95.712891
            }
            //If Result has Locations and is not defaultLocation
            if (status === google.maps.GeocoderStatus.OK && (results[0].geometry.location.lat() != defaultLocation.lat && results[0].geometry.location.lng() != defaultLocation.lng)) {
                callback(results, status);
            } else {
                //Query for PR Stores
                var request = {
                    address: address,
                    componentRestrictions: {
                        country: 'PR'
                    }
                };
                geocoder.geocode(request, function (results, status) {
                    callback(results, status);
                });
            }
        });
    },

    /*********************************************************
     * function to perform a nearest stores query
     * @param - zip : a postal code
     * @param - country : a country code
     * @param - unit : a distance unit (mi/km)
     * @param - radius : the radius to display stores from
     **********************************************************/

    renderStores: function (latitude, longitude, country, unit, radius, noLoc) {
        var pdpproductid;

        if (this.productSku) {
            pdpproductid = this.productSku;
        } else if (this.isOpenedFromQL) {
            pdpproductid = $('.pdp-ql-main-container').find('input[name="pdpproductid"]').val();
        } else {
            pdpproductid = $('.pdp-main-container').find('input[name="pdpproductid"]').val();
        }

        var requestMessage;
        var zipCode = $('.bopisDialog .bopis-postal-code').val();
        if (!(!pdpproductid || (pdpproductid && pdpproductid === 'null'))) {
            requestMessage = {
                'latitude': latitude,
                'longitude': longitude,
                'countryCode': country,
                'distanceUnit': unit,
                'maxdistance': radius,
                'zipCode':zipCode,
                'productId' : pdpproductid
            };
        } else {
            requestMessage = {
                'latitude': latitude,
                'longitude': longitude,
                'countryCode': country,
                'distanceUnit': unit,
                'zipCode':zipCode,
                'maxdistance': radius
            };
        }

        var xhr = $.getJSON(
            this.bopisurl,
            requestMessage,
            function (data) {
                window.SessionAttributes.BOPIS_SELECTED_STORE_ZIP_CODE = zipCode;
                $('.bopis-loader').addClass('hide');
                var size = 0,
                    key;
                for (key in data.stores) {
                    if (data.stores.hasOwnProperty(key)) { size++; }
                }

                if (size > 0) {
                    var location = new google.maps.LatLng(latitude, longitude);
                    var milesAround = $('#bopisacceptedradius').val();
                    var customerAddresses = $('#bopispostalcode').val();
                    $('.bopisDialog .bopis-stores-wrapper').removeClass('hide');
                    bopisLocator.populateStores(data.stores, noLoc, location, milesAround, customerAddresses);
                    $('#bopisstores').show();
                } else {
                    $('.bopis-loader').addClass('hide');
                    $('#bopisstores').hide();
                    $('.bopisDialog #bopispostalcode').addClass('error');
                    $('.bopisDialog .bopis-stores-wrapper').addClass('hide');
                    $('.bopisDialog #bopispostalcode-error').remove();
                    $('<span id="bopispostalcode-error" class="error bopispostalcode-error" role="alert" >Sorry! There are no participating stores near that location. Please try another ZIP code.</span>').insertAfter($('.bopisDialog #bopispostalcode'));
                }
            }
        );

        return xhr;
    },

    /*********************************************************
     * function to perform a nearest stores query
     * @param - zip : a postal code
     * @param - country : a country code
     * @param - unit : a distance unit (mi/km)
     * @param - radius : the radius to display stores from
     **********************************************************/

    renderOneStore: function (storeId, latitude, longitude, country, unit, radius, noLoc) {
        var self = this,
            xhr = $.getJSON(
                this.bopisurl,
                {'latitude': latitude, 'longitude': longitude, 'countryCode': country, 'distanceUnit': unit, 'maxdistance': radius},
                function (data) {
                    var size = 0,
                        key;
                    for (key in data.stores) {
                        if (data.stores.hasOwnProperty(key)) { size++; }
                    }
                    if (size > 0) {
                        var store = {};
                        store[storeId] = data.stores[storeId];

                        var location = new google.maps.LatLng(latitude, longitude);
                        var milesAround = $('.bopisDialog #bopisacceptedradius').val();
                        var customerAddresses = $('.bopisDialog #bopispostalcode').val();
                        bopisLocator.populateStores(store, noLoc, location, milesAround, customerAddresses);
                        self.map.setZoom(16);
                    } else {
                        $('#bopisstores').html('');
                    }
                }
            );

        return xhr;
    }
}; // end storelocator

module.exports = bopisLocator;
