'use strict';

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    $(document).tooltip({
        items: '.tooltip',
        track: false,
        content: function () {
            if ($(this).hasClass('withlink')) {
                return $(this).prop('title');
            } else {
                if ($.trim($(this).find('.tooltip-content').html()).length) {
                    return $(this).find('.tooltip-content').html();
                } else {
                    return false;
                }
            }
        },
        show: null,
        position: {
            my: 'left center',
            at: 'right+15 center'
        },
        open: function(event, ui) {
            if (typeof(event.originalEvent) === 'undefined') {
                return false;
            }
            var $id = $(ui.tooltip).attr('id');

            // close any lingering tooltips
            $('div.ui-tooltip').not('#' + $id).remove();

        },
        close: function(event, ui) {
            ui.tooltip.hover(function() {
                $(this).stop(true).fadeTo(1, 1);
            },
            function() {
                $(this).fadeOut('400', function() {
                    $(this).remove();
                });
            });
        }
    });

    $('.tooltip').on('click', function (e) {
        e.preventDefault();
    });
    $('.share-link').on('click', function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        if (!target) {
            return;
        }
        $(target).toggleClass('active');
    });
};
