'use strict';

var dialog = require('./dialog'),
    ajax = require('./ajax');

function languagePopupInit() { 
    //change URL
    var contentURL = Urls.languagePopup;
    ajax.load({
        url: contentURL,
        callback: function (data) {
            var jsonData = data;
            if (!jsonData) {
                return false;
            }
            var flyInWrap = '<div class="fly-in-wrap">' + jsonData + '</div>';
            $('#wrapper').append(flyInWrap);
            var flyInContent = $('.fly-in-wrap').html();
            dialog.open({
                html: flyInContent,
                options: {
                    width: 400,
                    dialogClass : 'fly-in-dialog language-popup',
                    show: {
                        effect: 'fade',
                        duration: 600
                    },
                    hide: {
                        effect: 'fade',
                        duration: 600
                    }
                },
                callback: function () {
                    $('.ui-dialog-titlebar-close').attr('aria-label','Close');
                    var $popupContainer = $('.language-popup');
                    var cmTag = window.cmCreatePageElementTag;
                    
                    var setSessionVar = function (el) {
                        $.getJSON(Urls.languageSetSessionVar + '?lppopupcomplete=true', function () {
                            // fires coremetrics tag for pop up close, cannot be inline
                            if (cmTag && !el.hasClass('link')) {
                                window.cmCreatePageElementTag('Continue to BBW.com', 'CANADA_INTERCEPT');
                            }
                            dialog.close();
                        });
                    };
                    if ($('#dialog-container .language-wrapper').length > 0 && 'digitalData' in window) {
                        var satelliteCheckInterval = setInterval(function() {
                            if ('_satellite' in window) {
                                window._satellite.track('canadainterceptdisplayed');
                                clearInterval(satelliteCheckInterval);
                            }
                        }, 500);
                    }
                    // fires coremetrics tag for pop up view, cannot be inline
                    if (cmTag) {
                        window.cmCreatePageElementTag('POP-UP', 'CANADA_INTERCEPT'); 
                    }
                    
                    $popupContainer.find('.switch').on('click', function () {
                        if ($(this).hasClass('active')) {return;}
                        $('.language-wrapper').find('.language').toggleClass('active');
                        $('.switch.active').focus();
                    });
                    
                    // Focus Select Language Title on Modal Load
                    setTimeout(function(){
                        $('#dialog-container .language.active .language-top-header').focus();
                    }, 1000);
                    
                    $('.ui-widget-overlay, .language-bottom-block a.link, .ui-dialog-titlebar-close').on('click', function (e) {
                        e.preventDefault();
                        setSessionVar($(this));
                        return true;
                    });
                    
                    $(document).keyup(function(e) {
                        if (e.keyCode === 27) {
                            setSessionVar();
                        }
                    });
                }
            });
        }
    });
}

function landingPageInit() {
    var cmTag = window.cmCreatePageElementTag;
    var language = $('.language-choose .active').attr('id');
    
    // page view tags, cannot be inline
    if (cmTag) {
        if (language == 'en') {
            window.cmCreatePageElementTag('English', 'CANADA_LP');
        } else {
            window.cmCreatePageElementTag('French', 'CANADA_LP');
        }
    }
    
    var showExitPopup = function (target) {
        var flyInContent = $('#exit-dialog').html();
        
        // popup view tag, cannot be inline
        if (cmTag) {
            window.cmCreatePageElementTag('POP-UP', 'CANADA_EXIT');
        }
        
        dialog.open({
            html: flyInContent,
            options: {
                width: 400,
                height:260,
                dialogClass : 'fly-in-dialog language-popup exit',
                show: {
                    effect: 'fade',
                    duration: 600
                },
                hide: {
                    effect: 'fade',
                    duration: 600
                }
            },
            callback: function () {
                var closeButton = $('.ui-dialog-titlebar-close');
                var exitLink = $('.exit a.button');
                
                closeButton.attr('aria-label','Close');
                
                exitLink.attr('href', target.href);
                $('.ui-widget-overlay').on('click', function () { 
                    dialog.close(); 
                });
            }
        });
    };
   
    $('.pt_canadalp .primary-logo a, .seo-link').on('click', function (e) {
        e.preventDefault();
        if ($(e.target).is('.seo-link')){
            var seoLinkText = $(this).attr('title');
            if ('digitalData' in window) {
                window.digitalData.linkText = {canadaSeo: seoLinkText}
            }
        }
        showExitPopup(e.currentTarget);
    });
    
    
    var canadalpForm = $('#canada-lp');
    canadalpForm.on('submit', function(e){
        e.preventDefault();
        $('#form-submit-error').hide();
        
        if (canadalpForm.find('input.error, select.error').length > 0) {
            canadalpForm.find('input.error, select.error').first().focus();
            return false;
        }
        
        $.ajax({
            type: 'POST',
            url: Urls.canadalpFormURL,
            data: canadalpForm.serialize(),
            success: function(data){
                if (data.success) {
                    canadalpForm.find('button[type=submit]').hide();
                    canadalpForm.find('.form-row-button').find('img').show().focus();
                    canadalpForm.find('input[type="text"], input[type="email"], select').val('');
                    // success event, cannot be inline
                    window.cmCreatePageElementTag('Email Thank You', 'CANADA_LP');
                    if ('_satellite' in window) window._satellite.track('clpemailsubmitted');
                } else {
                    $('#form-submit-error').show();
                }
            }            
        });
        return false;
    });
    //product info accordion init
    var icons = {
        header: 'iconClosed',
        activeHeader: 'iconOpen'
    };
    var canadaAccordion = $('.calp-accordion');
    if (canadaAccordion.length > 0) {
        canadaAccordion.accordion({
            heightStyle: 'content',
            active: false,
            collapsible: true,
            icons: icons
        });
        canadaAccordion.find('.store').attr('tabindex','-1');
        canadaAccordion.find('.accordion-header').on('keydown', function(e){
            if ($(this).attr('aria-expanded') == 'true') {
                var code = e.keyCode || e.which;
                if (code == 39 || code == 40) {
                    $(this).next('.fixed-height-block').find('.store').first().focus();
                }
            }
        });
        canadaAccordion.find('.store').on('keydown', function(e){
            var code = e.keyCode || e.which;
            if (code == 39 || code == 40) {
                if ($(this).next().length > 0) {
                    $(this).next().focus();
                } else {
                    $(this).parents('.fixed-height-block').next('.accordion-header').focus();
                }
            }
            if (code == 37 || code == 38) {
                if ($(this).prev().length > 0) {
                    $(this).prev().focus();
                } else {
                    $(this).parents('.fixed-height-block').prev('.accordion-header').focus();
                }
            }
        });
        
        /*Canada Landing web menu heperlink*/
        
        $('.webmenu-ca li a').click(function () {
            $('.webmenu-ca li a').removeClass('active');
            $('.webmenu-ca li a').removeAttr('aria-current');
            $(this).addClass('active');
            $(this).attr('aria-current', true);
        });

        /*Canada Landing Page Mobile menu heperlink*/
        
        $('.mobile-menu-ca select').change(function() {
            window.location = $(this).find('option:selected').val();
            setTimeout(function(){ $('.mobile-menu-ca').addClass('ca-fixed-mobile-menu'); }, 300);
            $(this).find('option').removeAttr('aria-current');
            $(this).find('option:selected').attr('aria-current', true);
        });

        $(window).on('scroll', function() {
            if ($(window).scrollTop() < 150) {
                $('.mobile-menu-ca').removeClass('ca-fixed-mobile-menu');
            } else {
                $('.mobile-menu-ca').addClass('ca-fixed-mobile-menu');
            }
        });
    }
}

var caLanding = {
    popup: function () {
        languagePopupInit();
    },
    page: function () {
        landingPageInit();
    }
}

module.exports = caLanding;
