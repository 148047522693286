'use strict';

/**
 * Copies the text from an input field to the clipboard when triggered by a button click.
 *
 * @private
 * @param {Event} event - The click event that triggers the copy operation.
 * @param {HTMLElement} triggerElement - The element that triggers the copy operation when clicked.
 * @param {string} inputFieldId - The ID of the input field containing the text to be copied.
 * @param {string} copiedText - Optional text to display temporarily on the trigger element to indicate the copy event fired.
 */
function handleCopyToClipboard(
    event,
    triggerElement,
    inputFieldId,
    copiedText
) {
    event.preventDefault();

    var originalTriggerText = triggerElement.innerText;
    var inputField = document.getElementById(inputFieldId);

    if (inputField) {
        inputField.select();
        document.execCommand('copy');
        inputField.blur();

        // Change the trigger element text
        if (copiedText) {
            triggerElement.innerText = copiedText;

            setTimeout(function () {
                triggerElement.innerText = originalTriggerText;
            }, 3000);
        }
    }
}

module.exports = {
    copyToClipboard: handleCopyToClipboard
};
