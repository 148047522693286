'use strict';
module.exports = function () {
    var userPanel = document.querySelector('.user-panel');
    var userInfo = document.querySelector('.user-info');
    var userAccount = document.querySelector('.user-account');
    var collapseAccountMenu = function () {
        userPanel.classList.remove('active');
        userAccount.setAttribute('aria-expanded', 'false');
        document.removeEventListener('click', clickOrFocusOutside);
        document.removeEventListener('focusin', clickOrFocusOutside);
    };

    var clickOrFocusOutside = function (e) {
        if (!userInfo.contains(e.target)) {
            collapseAccountMenu();
        }
    };

    if (userAccount && userInfo && userPanel) {
        userAccount.addEventListener('click', function (e) {
            e.preventDefault();
            userPanel.classList.toggle('active');
            userAccount.setAttribute('aria-expanded', userPanel.classList.contains('active'));
            document.addEventListener('click', clickOrFocusOutside);
            document.addEventListener('focusin', clickOrFocusOutside);
        });
        userInfo.addEventListener('keyup', function (e) {
            if (e.keyCode === 27) {
                collapseAccountMenu();
                userAccount.focus();
            }
        });
    }
}
