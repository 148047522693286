'use strict';

exports.init = function () {
    var countrySelect = $('select[name$="_country"]');
    if (countrySelect.size() > 0) {
        var selectedCountry = countrySelect.val(),
            statesCA = $('.statesCA'),
            statesUS = $('.statesUS'),
            stateOther = $('.statesOther');

        if (selectedCountry === 'US') {
            statesCA.hide();
            stateOther.hide();
            statesUS.show();
            $('.billing-canada-asset').hide();
        } else if (selectedCountry === 'CA') {
            statesCA.show();
            statesUS.hide();
            stateOther.hide();
            $('.billing-canada-asset').show();
        } else {
            stateOther.show();
            statesCA.hide();
            statesUS.hide();
        }
    }
};
