/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var pages = {
    product: require('./pages/product'),
    cart: require('./pages/cart'),
    account: require('./pages/account'),
    search: require('./pages/search'),
    wishlist: require('./pages/wishlist'),
    giftcardpage : require('./pages/giftcardpage')
}

exports.init = function () {
    try {

        /**
         * the following code helping determine if tealium library is ready for work, (document.ready not helped here)
         * so we can fire events after page load
         */
        var intervalHnadler = null;
        intervalHnadler = setInterval(function () {
            if (window.utag !== undefined) {
                var ns = window.pageContext.ns;
                if (ns && pages[ns] && pages[ns].init) {
                    pages[ns].init();
                }

                //global events
                var common = require('./pages/common');
                common.init();
                
                clearInterval(intervalHnadler);
            }
        }, 100);
    } catch (err) {
        window.console.log('tealium error: ' + err);
    }
}
