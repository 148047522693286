'use strict';

var dialog = require('./dialog'),
    validator = require('./validator'),
    util = require('./util'),
    SessionAttributes = window.SessionAttributes;

/**
 * @function captcha    Used to display/control the scrim containing the simulated captcha code
 **/
module.exports = function () {
    /**
     * if the session.privacy.ratelimited element is present then show the notification
     * NOTE: You will probably want to replace this with a call to an actual CAPTCHA system to replace the simple one here
     */
    if (SessionAttributes.SHOW_CAPTCHA) {
        dialog.open({
            url: Urls.loginCaptcha,
            options: {
                closeOnEscape: false,
                dialogClass: 'no-close captcha-dialog',
                width: 420,
                close: function() {
                    $('.captcha-dialog').removeClass('captcha-dialog no-close');
                },
                open: function () {
                    $('#pass-reset').on('click', function (e) {
                        e.preventDefault();
                        //$('.captcha-dialog').dialog('close');
                        dialog.open({
                            url: $(e.target).attr('href'),
                            options: {
                                title: this.title,
                                width: 565,
                                closeOnEscape: true,
                                dialogClass: '',
                                open: function () {
                                    validator.init();
                                    var $requestPasswordForm = $('[name$="_requestpassword"]'),
                                        $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                                    $($submit).on('click', function (e) {
                                        if (!$requestPasswordForm.valid()) {
                                            return;
                                        }
                                        e.preventDefault();
                                        dialog.submit($submit.attr('name'));
                                    });
                                }
                            }
                        });
                    });
                    $('.tryagain').on('click', function () {                        
                        var url = util.appendParamsToUrl(Urls.rateLimiterReset, {format: 'ajax'});
                        $.ajax({
                            url: url
                        });
                        dialog.close();
                    });
                }
            }
        });
    }
};
