'use strict';
var helper = require('./EmailInputHelper');
module.exports = function () {
    var emailModalDialog = document.querySelector('.email-modal-dialog');
    if (!emailModalDialog) {
        return;
    }

    var userInfoForm = emailModalDialog.querySelector('.ds-user-info-collection-form');
    if (!userInfoForm) {
        return;
    }

    var displaySuccessMessage = function () {
        var modalContent = emailModalDialog.querySelector('.ds-modal-content');
        var successContent = emailModalDialog.querySelector('.ds-modal-success');
        if (modalContent) {
            modalContent.classList.add('hide');
        }
        if (successContent) {
            successContent.classList.remove('hide');
        }
    }

    var handleFormSubmission = function (e) {
        helper.handleFormSubmission(e, displaySuccessMessage, this);
    }

    userInfoForm.addEventListener('submit', handleFormSubmission);
};
