'use strict';

var util = require('../../util');
var storeName = window.SessionAttributes.BOPIS_SELECTED_STORE_NAME.toLowerCase();
var bopisStoreFinder = window.Urls.bopisStoreFinder;
var productTile = require('../../product-tile');
var setStoreLinkText = function(linkText, productSku){
    return '<a class="choose-store bopis-set-store js-grid-set-store" href="javascript:void(0)" role="button" data-href="' + bopisStoreFinder + '" data-change-store="Change Store" tabindex="0" data-cm-tag-id="Grid Pop Up" data-productsku="' + productSku + '">' + linkText + '</a>';
}

var productAvailability = {
    updateStoreName: function() {
        storeName = window.SessionAttributes.BOPIS_SELECTED_STORE_NAME.toLowerCase();
    },
    inStoreAvailabilityMarkup: function(availability, productSku) { 
        var storeAvailabilityClass;
        var storeAvailability;
        var linkText;
        var atText = storeName ? 'at ' : '';
        if (!storeName) {
            storeAvailabilityClass = 'store-not-set';
            storeAvailability = 'To See Availability';
            linkText = 'Set Store';
        } else {
            storeAvailabilityClass = availability ? 'product-available' : 'product-unavailable';
            storeAvailability = availability ? 'In Stock' : 'Unavailable'; 
            linkText = storeName;
        }
    
        return '<span class="product-availability-message ' + storeAvailabilityClass + '">' + storeAvailability + '</span> ' + atText + setStoreLinkText(linkText, productSku);
        
    },
    updateStoreAvailability: function(productidsArr, isLastGridItemOdd) {
        var lineItemNumber = isLastGridItemOdd ? 2 : 1;

        if (SitePreferences.ENABLE_GRID_FULFILLMENT_METHOD) {
            if (!storeName) {
                var $storeAvailabilityContainers = $('.product-in-store-availability');
                $storeAvailabilityContainers.html(productAvailability.inStoreAvailabilityMarkup());
    
                return;
            }        
        
            storeName = window.SessionAttributes.BOPIS_SELECTED_STORE_NAME.toLowerCase();
            var bopisStoreFilterUrl = window.Urls.showBopisProduct;
            $.get(util.appendParamsToUrl(bopisStoreFilterUrl, {'format': 'ajax', 'ajaxmeta': 'true','productidsArr':productidsArr}), function (data) {
                var productids = data;
    
                for (var key in productids) {
                    if (productids.hasOwnProperty(key)) {
                        var $productLi = $('.search-result-items').find('[data-itemid=' + key + ']').parent();
                        var val = productids[key];
                        var $inStoreAvailability = $productLi.find('.product-in-store-availability');
                        $inStoreAvailability.html(productAvailability.inStoreAvailabilityMarkup(val, key));
                        var $shippingAvailability = $productLi.find('.product-shipping-availability .product-availability-message');
                        var $addToBag = $productLi.find('.add-to-cart');

                        if (!val && $shippingAvailability.hasClass('product-unavailable') && !$shippingAvailability.hasClass('alwaysShow')) {
                            $productLi.remove();
                        } else if (!val && $shippingAvailability.hasClass('alwaysShow')) {
                            $addToBag.prop('disabled', true);
                            lineItemNumber++; 
                        } else {
                            $productLi.removeClass('even odd').addClass(lineItemNumber % 2 ? 'odd' : 'even')
                            lineItemNumber++;  
                        }
                    }
                }

                $('.tiles-container').each(function () {
                    productTile.syncRowItemHeights($(this).find('.grid-tile'));
                });

            });

        }
    }
}  

module.exports = productAvailability;
