/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var tealium = require('../tealium');
	//product = require('./product')
var tealiumGlobalEvents = require('./common');

var search = {
    init: function (){
        //unbind events to avoid duplicate event call
        //$(document).off('click', '.add-to-cart', product.cartAdd);
        $(document).off('click', '.quickview.button', search.qv);

        //$(document).on('click', '.add-to-cart', product.cartAdd);
        $(document).on('click', '.quickview.button', search.qv);
    },
    qv: function(){
        var element = $(this),
            data = element.parents('.product-tile').find('button').data('tealium');
        tealium.eventCount ++;
        var obj = {
            'product_id': data.id,
            'product_category': data.category,
            'product_price': data.price,
            'product_original_price': data.original_price,
            'product_brand': data.brand,
            'product_color': data.color,
            'product_size': data.size,
            'product_sku': data.sku,
            'product_name': data.sku,
            'event_category' : 'search',
            'event_value' : tealium.eventCount + '',
            'event_action' : 'Product Quick View',
            'event_label' : 'Product Quick View',
            'event_name' : 'product_quick_view'
        }
        tealium.triggerEvent(obj);
    },
    
    setStore: function(){
        if ('cmCreateElementTag' in window) {
            window.cmCreateElementTag('Set Store Complete', 'BOPIS Set Store', '-_--_--_-' + tealiumGlobalEvents.getPageId());
        }
    },

    toggleOn :  function(){
        if ('cmCreateElementTag' in window) {
            window.cmCreateElementTag('Toggle On', 'BOPIS Filter', '-_--_--_-' + tealiumGlobalEvents.getPageId());
        }
    }
}
exports.init = search.init;
exports.setStore = search.setStore;
exports.toggleOn = search.toggleOn;
