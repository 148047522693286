'use strict';

var pwHadFocus = false;


/**
 * @private
 * @function
 * @description trigger registration tag
 */
function triggerRegistrationTag() {
    if ($('.pos-main').find('.success').length !== 0){
        var emailHash = $('.pos-body').data('eh');
        var loyaltyID = $('.pos-body').data('lid');
        var currentSpend = $('.pos-body').data('cs');
        var rewardsCount = $('.pos-body').data('rc');
        window.cmCreateRegistrationTag(emailHash, emailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, window.digitalData.user.profile.attributes.country_code, '-_--_--_--_--_--_--_--_-' + loyaltyID + '-_-' + rewardsCount + '-_-' + currentSpend + '-_--_--_-loyl_acct_active');
        if (window.digitalData.user) window.digitalData.user.profile.profileInfo.loyaltyaccountstatus = 'loyl_acct_active';
    }
}


/**
 * @private
 * @function
 * @description Show/Hide the password for login
 */
function passHideShow() {

    var hideShowButton = $('.hide-show'), hideShowSpan = hideShowButton.find('span');

    hideShowButton.show();
    hideShowSpan.addClass('show');
    hideShowButton.click(function() {
        var hideShowThisButton = $(this), hideShowThisSpan = hideShowThisButton.find('span');

        if (hideShowThisSpan.hasClass('show')) {
            hideShowThisSpan.text('Hide');
            hideShowThisButton.attr('aria-label', 'Hide Password');
            hideShowThisButton.siblings('input[name$="password"], input[name$="currentpassword1"]').attr('type', 'text').focus();
            hideShowThisSpan.removeClass('show');
        } else {
            hideShowThisSpan.text('Show');
            hideShowThisButton.attr('aria-label', 'Show Password');
            hideShowThisButton.siblings('input[name$="password"], input[name$="currentpassword1"]').attr('type', 'password').focus();
            hideShowThisSpan.addClass('show');
        }
    });
    $('form button[type="submit"]').on('click', function() {
        hideShowSpan.text('Show').addClass('show');
        hideShowButton.attr('aria-label', 'Show Password');
        hideShowButton.parent().find('input[name$="_password"]').attr('type', 'password');
    });
}

/**
 * @private
 * @function
 * @description validate email to specs in BBWS-672
 */
function validatePassword() {
    var submitButton = $('#RegistrationForm').find('button[type="submit"]');
    $('input#dwfrm_loyalty_password').on('input', function() {
        /*^(?=.{8,50})(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?!.*\s)*/
        var inputText = $(this).val();
        var inputForm = $(this).parents('form');
        var passRequirments = inputForm.find('#password-requirements');
        var eightToFifty = /^(?=.{8,50})/;
        var numerical = /^(?=.*[0-9])/;
        var capital = /^(?=.*[A-Z])/;
        var lowercase = /^(?=.*[a-z])/;
        var noSpaces = /^(?!.*\s)/;
        var errorMessageMet = '';
        var errorMessageNotMet = '';
        if (passRequirments.css('display') == 'none') {
            passRequirments.show();
        }
        if (inputText.match(eightToFifty)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_LENGTH + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.rule8to50char').removeClass('pw-error');
            inputForm.find('.rule8to50char').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_LENGTH + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.rule8to50char').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.rule8to50char').addClass('pw-error');
            }
        }

        if (inputText.match(numerical)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_NUMBER + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.rule1number').removeClass('pw-error');
            inputForm.find('.rule1number').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_NUMBER + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.rule1number').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.rule1number').addClass('pw-error');
            }
        }

        if (inputText.match(capital)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_UPPERCASE + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.rule1uppercase').removeClass('pw-error');
            inputForm.find('.rule1uppercase').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_UPPERCASE + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.rule1uppercase').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.rule1uppercase').addClass('pw-error');
            }
        }

        if (inputText.match(lowercase)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_LOWERCASE + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.rule1lowercase').removeClass('pw-error');
            inputForm.find('.rule1lowercase').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_LOWERCASE + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.rule1lowercase').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.rule1lowercase').addClass('pw-error');
            }
        }

        if (inputText.match(noSpaces)) {
            errorMessageMet = errorMessageMet + ' ' + Resources.VALIDATE_PASSWORD_SPACE + ' ' + Resources.PASSWORD_CONDITIONS_MET;
            inputForm.find('.ruleNoSpaces').removeClass('pw-error');
            inputForm.find('.ruleNoSpaces').addClass('success');
        } else {
            errorMessageNotMet = errorMessageNotMet + ' ' + Resources.VALIDATE_PASSWORD_SPACE + ' ' + Resources.PASSWORD_CONDITIONS_NOTMET;
            inputForm.find('.ruleNoSpaces').removeClass('success');
            if (pwHadFocus == true) {
                inputForm.find('.ruleNoSpaces').addClass('pw-error');
            }
        }

        if (inputText.match(eightToFifty) && inputText.match(numerical) && inputText.match(capital) && inputText.match(lowercase) && inputText.match(noSpaces)) {
            submitButton.removeAttr('data-disallow');
        } else {
            submitButton.attr('data-disallow', 'true');
        }

        if (inputText.length >= 1) {
            inputForm.find('#password-requirments-aria').text(passRequirments.find('.success').length + ' ' + Resources.PASSWORD_CONDITIONS_5 + ' ' + errorMessageNotMet + errorMessageMet);
            if (inputForm.find('#password-requirements').attr('aria-hidden') !== 'true') {
                inputForm.find('#password-requirements').attr('aria-hidden', 'true');
                inputForm.find('#password-requirements').find('span').attr('aria-hidden', 'true');
            }
        }
        
        activateSubmitBtn();
    });
    submitButton.on('click', function(e) {
        var $pwReqError = $('.pwreq-error');
        var thisForm = $(this).parents('form');
        var errorMsgWrapper = thisForm.find('input[id$="password"]').last().closest('.field-wrapper');
        if ($(this).attr('data-disallow') === 'true') {
            e.preventDefault();
            if ($pwReqError.length > 0) {
                $pwReqError.empty().remove();
            }
            $('<span class="pwreq-error" id="pwreq-error">' + Resources.VALIDATE_REQPASSWORD + '</span>').appendTo(errorMsgWrapper);
            thisForm.find('input[id$="password"]').last().focus();
        } else {
            if ($pwReqError.length > 0) {
                $pwReqError.empty().remove();
            }
            if (thisForm.attr('id') === 'RegistrationForm' && !$('#RegistrationForm').hasClass('pospasswordlandingform')) {
                var emailValue = thisForm.find('input[name$="_email"]').val();
                //customerCity, customerState should be empty (email, City, State, ZIP, subscribe)
                //registration tag when submitting form for analytics
                if (typeof window.digitalData.user.profile.profileInfo.profileEmailHash === 'undefined') {
                    $.post(Urls.firstLookEmailHash,
                            {email: emailValue},
                            function (data){
                                if (data.success) {
                                    window.cmCreateRegistrationTag(data.hashedEmailId, data.hashedEmailId, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, 'subscribe');
                                }
                            },'json');
                } else {
                    window.cmCreateRegistrationTag(window.digitalData.user.profile.profileInfo.profileEmailHash, window.digitalData.user.profile.profileInfo.profileEmailHash, window.digitalData.user.profile.attributes.customer_city, window.digitalData.user.profile.attributes.customer_state, window.digitalData.user.profile.attributes.customer_postal_code, 'subscribe');
                }
            }

        }
    });
    
    $('#RegistrationForm').on('submit', function() {
        if (!$('#RegistrationForm').valid()) {
            return false;
        }
    });
}
/**
 * @private
 * @function
 * @description switch grey status to red on blur of password field in BBWS-672
 */
function switchClassOnPasswordRules() {
    $('input#dwfrm_loyalty_password').on('focus', function() {
        var inputForm = $(this).parents('form');
        if (inputForm.find('#password-requirements').css('display') == 'none') {
            inputForm.find('#password-requirements').show();
        }
    });
    $('input#dwfrm_loyalty_password').on('blur', function() {
        pwHadFocus = true;
        var inputForm = $(this).parents('form');
        if (!$(this).val()) {
            inputForm.find('#password-requirements').hide();
        }
        if ($(this).val() && inputForm.find('#password-requirements').css('display') == 'none') {
            inputForm.find('#password-requirements').show();
        }
        if (!inputForm.find('.rule8to50char').hasClass('success')) {
            inputForm.find('.rule8to50char').addClass('pw-error');
        }
        if (!inputForm.find('.rule1number').hasClass('success')) {
            inputForm.find('.rule1number').addClass('pw-error');
        }
        if (!inputForm.find('.rule1uppercase').hasClass('success')) {
            inputForm.find('.rule1uppercase').addClass('pw-error');
        }
        if (!inputForm.find('.rule1lowercase').hasClass('success')) {
            inputForm.find('.rule1lowercase').addClass('pw-error');
        }
        if (!inputForm.find('.ruleNoSpaces').hasClass('success')) {
            inputForm.find('.ruleNoSpaces').addClass('pw-error');
        }
        activateSubmitBtn();
    });
}

/**
 * @private
 * @function
 * @description mask of Birthday Input
 */
function maskBirthdayInit() {
    $('.birthday').mask('00 / 00');
}

/**
 * @private
 * @function
 * @description Submit Button Activate or Disabled
 */
function activateSubmitBtn() {
    var submitButton = $('#RegistrationForm').find('button[type="submit"]');
    
    var success = $('#password-requirements .success').length
    var required = $('#password-requirements .pw-requirement').length

    if (!$('input').hasClass('error') && !$('.pw-error')[0] && (success==required)) {
        submitButton.removeAttr('disabled');
    } else {
        submitButton.attr('disabled', 'disabled');
    }
}

function setSuccessFlag() {
    if ($('div#posPendingSuccess').length > 0) {
        if ('localStorage' in window) {
            localStorage.setItem('posPendingComplete', true);
        }
    }
}

function initializeEvents() {
    passHideShow();
    validatePassword();
    switchClassOnPasswordRules();
    maskBirthdayInit();
    triggerRegistrationTag();
    setSuccessFlag();
}

var createyourpassword = {
    init : function() {
        initializeEvents();
    }
};

module.exports = createyourpassword;
