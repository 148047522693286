'use strict';

var dialog = require('./dialog'),
    util = require('./util');

module.exports = function () {
	// User browser version
    navigator.browserV = (function(){
        var ua= navigator.userAgent, tem, 
            M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])){
            tem=  (/\brv[ :]+(\d+)/g.exec(ua) || []);
            return 'IE '+(tem[1] || '');
        }
        if (M[1]=== 'Chrome') {
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        // Captures android webview and chrome app for android
        if (/(\bandroid\b)|(\bandroid\b.*wv)/i.test(ua)){
            return {
                name: M[1],
                version: M[2]
            };
        }
        M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if ((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
        return {
            name: M[0],
            version: M[1]
        };
    })();
    // Redirect or notification for old browser versions
    function unsupported(keywords) {
        var browser = navigator.browserV.name;
        var browserVersion = navigator.browserV.version*1;
        for (var i = 0; i < keywords.length; i++) {
            var oldBrowser = keywords[i].split('/');
            
            if (!util.isMobileDevice() && browser == oldBrowser[0] && browserVersion <= oldBrowser[2]*1) {
                window.location.href=Urls.unsupportedBrowserPage;
                return;
                    //return; //Once we find and process a matching keyword, return to prevent less "specific" classes from being added
            } else if (!util.isMobileDevice() && document.cookie.indexOf('unsupported_true') < 0 && browser == oldBrowser[0] && browserVersion <= oldBrowser[1]*1) {
                dialog.open({
                    url: Urls.unsupportedBrowser,
                    options: {
                        width: 806,
                        dialogClass : 'unsupported-dialog',
                        show: {
                            effect: 'fade',
                            duration: 600
                        },
                        hide: {
                            effect: 'fade',
                            duration: 600
                        }
                    }
                });
                if (document.cookie.indexOf('unsupported=1') < 0) {
                    document.cookie = 'unsupported=1; path=/; SameSite=Lax';
                }
                if (document.cookie.indexOf('unsupported_true') < 0) {
                    document.cookie = 'unsupported_true=1; path=/; SameSite=Lax';
                }
                return;
            }
        }
    }
    // Redirect or notification for old browser versions
    function unsupportedMobile(keywords) {
        var browser = navigator.browserV.name;
        var browserVersion = navigator.browserV.version*1;

        for (var i = 0; i < keywords.length; i++) {
            var oldBrowser = keywords[i].split('/');
            
            if (util.isMobileDevice() && document.cookie.indexOf('unsupported_true') < 0 && browser == oldBrowser[0] && browserVersion <= oldBrowser[1]*1) {
                dialog.open({
                    url: Urls.unsupportedBrowserMobile,
                    options: {
                        width: 300,
                        dialogClass : 'unsupported-dialog mobile',
                        show: { 
                            effect: 'fade',
                            duration: 600
                        },
                        hide: {
                            effect: 'fade',
                            duration: 600
                        }
                    }
                });
                if (document.cookie.indexOf('unsupported=1') < 0) {
                    document.cookie = 'unsupported=1; path=/; SameSite=Lax';
                }
                if (document.cookie.indexOf('unsupported_true') < 0) {
                    document.cookie = 'unsupported_true=1; path=/; SameSite=Lax';
                }
                return;
            }
        }
    }
    unsupported(['Chrome/40/7', 'Firefox/40/2', 'MSIE/10/8', 'Safari/8/6']);
    unsupportedMobile(['Chrome/4','Chrome/40', 'Android/4', 'IEMobile/10', 'Safari/5', 'Firefox/40']);
};
