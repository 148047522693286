'use strict';

var loyaltyExclusive = {
    // Takes nodelist of items with quantity-selector class
    updateLoyaltyExclusiveButtons: function(loyaltyExlusiveItems) {
        if (loyaltyExlusiveItems && loyaltyExlusiveItems.length) {
            var customerStatus = window && window.digitalData && window.digitalData.user && window.digitalData.user.attributes && window.digitalData.user.attributes.customer_status ? window.digitalData.user.attributes.customer_status : '';
            var signInText = SitePreferences.LOYALTY_EXCLUSIVE_BUTTON_SIGNIN_TEXT || 'Sign in to Buy';

            if (customerStatus === 'anonymous') {
                loyaltyExlusiveItems.forEach(function(loyaltyExlusiveItem){
                    var inventory = loyaltyExlusiveItem.querySelector('.inventory');
                    var addToBagButton = loyaltyExlusiveItem.querySelector('.add-to-cart, #add-to-cart');

                    if (addToBagButton) {
                        addToBagButton.innerHTML = signInText;
                        addToBagButton.setAttribute('title', signInText);
                        addToBagButton.classList.add('loyalty-exclusive-button');
                        addToBagButton.dataset.target = 'auth-modal';
                    }
                    
                    if (inventory) {
                        inventory.style.display = 'none';
                    }
                });
            }
        }
    }
}

module.exports = loyaltyExclusive;


