/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var product = require('./product');

var wishlist = {
    init: function () {
        $(document).on('click', '.add-to-cart', product.cartAdd);
    }
};

module.exports = wishlist;
