'use strict';

var _ = require('lodash');

var $form, $continue, $requiredInputs, validator, fakeContinue, triggerEventName, eventSuccess = false;

var hasEmptyRequired = function () {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.filter(':visible').map(function () {
        var $this = $(this);

        if (!$this.hasClass('imHiding') && !$this.hasClass('bbwd-tooltip-close-button')) {
            if ($this.attr('type') === 'checkbox') {
                // For required checkboxes, set as an empty string to return as false. This can be refactored to validate required checkboxes based on :checked value and required input fields based on value length separately.
                return $this.is(':checked') ? 'true' : '';
            }

            return $this.val();
        }
    });
    return _(requiredValues).contains('');
};

/**
 * Sets an event name to the param eventName. Event will be triggered on custom validation success
 */
var setCustomValidationEvent = function (eventName) {
    resetCustomValidationSuccess();
    if (eventName) {
        $(document).on(eventName, function () {
            eventSuccess = true;
        });
        
        triggerEventName = eventName;
    }
};

/**
 * Removes the event used to track the success of the custom validation. Resets triggerEventName to skip extra check in validateForm and validateEl
 */
var removeCustomValidationEvent = function (eventName) {
    $(document).off(eventName);
    if (eventName === triggerEventName) {
        triggerEventName = '';
    }
    resetCustomValidationSuccess();
}

/**
 * Check if custom validation is open by looking for triggerEventName. Else always return valid to pass validateForm and validateEl
 */
var isCustomValidationEventValid = function () {
    if (triggerEventName && triggerEventName !== '') {
        return eventSuccess;
    }
    return true;
}

var resetCustomValidationSuccess = function () {
    eventSuccess = false;
}

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }
    if (!hasEmptyRequired() && isCustomValidationEventValid() && validator.form()) {
        $continue.removeAttr('disabled');
        fakeContinue.removeAttr('disabled');
        $('.verify-field').hide();
    } else {
        $continue.attr('disabled', 'disabled');
        fakeContinue.attr('disabled', 'disabled');
        $('.verify-field').show();
    }
    if ($('.no-shipping-methods').size() > 0 || $('.stop-checkout').size() > 0) {
        $continue.attr('disabled', 'disabled');
        fakeContinue.attr('disabled', 'disabled');
    }
};

var validateEl = function () {
    if ($(this).val() === '') {
        $continue.attr('disabled', 'disabled');
        fakeContinue.attr('disabled', 'disabled');
        $('.verify-field').show();
    } else {
        // enable continue button on last required field that is valid
        // only validate single field
        if (validator.element(this) && !hasEmptyRequired() && isCustomValidationEventValid() && validator.form()) {
            $continue.removeAttr('disabled');
            fakeContinue.removeAttr('disabled');
            $('.verify-field').hide();
        } else {
            $continue.attr('disabled', 'disabled');
            fakeContinue.attr('disabled', 'disabled');
            $('.verify-field').show();
        }
    }
    if ($('.no-shipping-methods').size() > 0 || $('.stop-checkout').size() > 0) {
        $continue.attr('disabled', 'disabled');
        fakeContinue.attr('disabled', 'disabled');
    }
    if (SitePreferences.ORDERGROOVE_ENABLED){
        if ($('.default-card-unset-error').length) {
            $continue.attr('disabled', 'disabled');
        }
    }
};

var debounceValidateEle = _.debounce(validateEl, 200);

var updateRequireInputs = function() {
    $requiredInputs = $form.find('input[required], input.required');
    $requiredInputs.off('change', validateEl).on('change', validateEl);
    $requiredInputs.filter('input:not(.postal)').off('keyup', debounceValidateEle).on('keyup', debounceValidateEle);
}

var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    fakeContinue = $(opts.fakeContinue);
    validator = $form.validate();
    $requiredInputs = $form.find('input[required], input.required');
    validateForm();
    // start listening
    $requiredInputs.on('change', validateEl);
    $requiredInputs.filter('input:not(.postal)').on('keyup', debounceValidateEle);
};

exports.init = init;
exports.validateForm = validateForm;
exports.hasEmptyRequired = hasEmptyRequired;
exports.validateEl = validateEl;
exports.updateRequireInputs = updateRequireInputs;
exports.setCustomValidationEvent = setCustomValidationEvent;
exports.removeCustomValidationEvent = removeCustomValidationEvent;
exports.resetCustomValidationSuccess = resetCustomValidationSuccess;
