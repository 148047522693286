'use strict';

var digitalDataPageType;
var kibo = {
    sendKiboData: function(pageView) {
        if (SitePreferences.KIBO_ENABLED) {
            digitalDataPageType = window.digitalData && window.digitalData.pageInstanceID ? window.digitalData.pageInstanceID : 'null';

            if (digitalDataPageType === 'confirmation') {
                this.addPurchaseRows();
            } else {
                this.addCartRows();

                if (digitalDataPageType === 'search' || digitalDataPageType === 'category'){
                    this.addProducts();
                } else if (digitalDataPageType === 'product'){
                    var productID = window.digitalData && window.digitalData.product && window.digitalData.product[0] && window.digitalData.product[0].productInfo && window.digitalData.product[0].productInfo.productID ? window.digitalData.product[0].productInfo.productID : 'null';
        
                    window.monetateQ = window.monetateQ || [];
                    window.monetateQ.push(['addProductDetails', [productID]]);
                }
            }

            this.setPageType();
            window.monetateQ = window.monetateQ || [];

            // Setting nonPageView to true lets kibo know the request is not from a separate page view https://docs.monetate.com/docs/ajax-site-updates
            var isNonPageView = pageView === 'nonPageView';
            window.monetateQ.push([
                'trackData',
                {
                    'nonPageView': isNonPageView
                }
            ]);
        }
    },
    addPurchaseRows: function() {
        if (SitePreferences.KIBO_ENABLED) {
            window.monetateQ = window.monetateQ || [];
            var purchaseRows = [];
            var currentTransactionID = localStorage.getItem('currentTransactionID');
            var newTransactionID = window.digitalData && window.digitalData.transaction && window.digitalData.transaction.transactionID ? window.digitalData.transaction.transactionID : 'null';
            var transactionItems = window.digitalData && window.digitalData.transaction && window.digitalData.transaction.item ? window.digitalData.transaction.item : [];
            if (transactionItems.length && currentTransactionID !== newTransactionID){
                transactionItems.forEach(function(item) {
                    purchaseRows.push({
                        'purchaseId': newTransactionID,
                        'productId': item.productInfo.productID,
                        'quantity': item.quantity,
                        'unitPrice': item.price
                    });
                });
                window.monetateQ.push(['addPurchaseRows', purchaseRows]);
                localStorage.setItem('currentTransactionID', newTransactionID);
            }
        }
    },
    addCartRows: function() {
        if (SitePreferences.KIBO_ENABLED) {
            window.monetateQ = window.monetateQ || [];   
            var cartLineItems = [];
            if (digitalDataPageType === 'checkout' || digitalDataPageType === 'cart') {
                var cartItems = window.digitalData && window.digitalData.cart && window.digitalData.cart.item ? window.digitalData.cart.item : [];
                if (cartItems.length) {
                    cartItems.forEach(function(cartItem) {
                        cartLineItems.push({
                            'productId': cartItem.productInfo.productID,
                            'quantity': cartItem.quantity,
                            'unitPrice': cartItem.price
                        });
                    });
                }
            }  else {
                var kiboCartData = $('.mini-cart-link').attr('data-kiboCartData');
                if (kiboCartData) {
                    cartLineItems = JSON.parse(kiboCartData);
                }
            }
            if (cartLineItems && cartLineItems.length) {
                window.monetateQ.push(['addCartRows', cartLineItems]);
            }
        }
    },
    addProducts: function(){
        if (SitePreferences.KIBO_ENABLED) {
            var searchResultItems = [];

            var productIDs = $.makeArray($('#search-result-items li.grid-tile .product-tile[data-itemid]'));
            if (productIDs.length) {
                productIDs.forEach(function(prodID) {
                    var productId = prodID.getAttribute('data-itemid');
                    searchResultItems.push({'productId': productId});
                });
    
                window.monetateQ = window.monetateQ || [];
                window.monetateQ.push(['addProducts', searchResultItems]);
            }
        }
    },
    addProductDetails: function(productID){
        if (SitePreferences.KIBO_ENABLED) {
            window.monetateQ = window.monetateQ || [];
            window.monetateQ.push(['addProductDetails', [productID]]);

            digitalDataPageType = 'product';
            this.addCartRows();
            this.setPageType();

            window.monetateQ.push([
                'trackData'
            ]);
        }
    },
    setPageType: function(){
        if (SitePreferences.KIBO_ENABLED) {
            var searchPageType = window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo && window.digitalData.page.pageInfo.pageID && window.digitalData.page.pageInfo.pageID === 'SEARCH UNSUCCESSFUL' ? 'Nosearch' : 'Search';
            var pageContextTypeDict = {
                'home': 'Home',
                'checkout': 'Checkout',
                'search': searchPageType,
                'category': 'Category',
                'product': 'Product',
                'myaccount': 'Account',
                'account': 'Account',
                'wishlist': 'Wishlist',
                'cart': 'Cart',
                'confirmation': 'Confirmation',
                'null': 'undefined'
            };
    
            window.monetateQ = window.monetateQ || [];
            window.monetateQ.push(['setPageType', pageContextTypeDict[digitalDataPageType]]);
        }
    }
}         

module.exports = kibo;
