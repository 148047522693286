'use strict';

var addToCart = require('./product/addToCart'),
    quickview = require('../quickview'),
    certona = require('../certona');

function setAnalyticsLoyaltyAsset() {
    if ($('#wrapper').hasClass('pt_storefront') && 'digitalData' in window) {
        // Analytics for join loyalty banner
        if ($('.geo-home-bottom-center').length > 0) {
            window.digitalData.page.loyaltyJoinAsset = true;
        }

        // Analytics for BOPIS loyalty banner
        if ($('.bopis-bottom-banner').length > 0) {
            window.digitalData.page.bopisLoyaltyAsset = true;
        }

        // Analytics for loyalty member echo loyalty banner
        if ($('.loybanner').length > 0) {
            window.digitalData.page.loyaltySignInEcho = true;
        }
    }

    $('.promo-display').on('dragged.owl.carousel', function (event) {
        if ('digitalData' in window && window.digitalData.hasOwnProperty('page'))
            window.digitalData.page.modulename = 'Home Promo Offers';
        if (event.relatedTarget._drag.direction === 'left') {
            if ('_satellite' in window) window._satellite.track('rightswipe');
        }
        if (event.relatedTarget._drag.direction === 'right') {
            if ('_satellite' in window) window._satellite.track('leftswipe');
        }
    });

    $('#shop-the-image-carousel').on('dragged.owl.carousel', function (event) {
        if ('digitalData' in window && window.digitalData.hasOwnProperty('page'))
            window.digitalData.page.modulename = 'Shop the Image';
        if (event.relatedTarget._drag.direction === 'left') {
            if ('_satellite' in window) window._satellite.track('rightswipe');
        }
        if (event.relatedTarget._drag.direction === 'right') {
            if ('_satellite' in window) window._satellite.track('leftswipe');
        }
    });

    $('.social-feed').on('dragged.owl.carousel', function (event) {
        if ('digitalData' in window && window.digitalData.hasOwnProperty('page'))
            window.digitalData.page.modulename = 'Instagram';
        if (event.relatedTarget._drag.direction === 'left') {
            if ('_satellite' in window) window._satellite.track('rightswipe');
        }
        if (event.relatedTarget._drag.direction === 'right') {
            if ('_satellite' in window) window._satellite.track('leftswipe');
        }
    });
}

exports.init = function () {
    certona.init();
    addToCart();
    setAnalyticsLoyaltyAsset();
    // configuring carousel for home page
    if ($('.home-carousel').children().length > 1) {
        var owl = $('.home-carousel')
        owl.owlCarousel({
            loop: true,
            items: 1,
            margin: 0,
            responsiveClass: true,
            navRewind: false,
            mouseDrag: true,
            navSpeed: 1800,
            dotsSpeed: 1800,
            autoplaySpeed: 1800,
            autoHeight: false,
            lazyLoad: true,
            lazyContent: true,
            dots: true,
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
            responsive: {
                0: {
                    nav: false
                },
                768: {
                    nav: true
                }
            }
        });
        /*$('.play').on('click', function(){
            owl.trigger('autoplay.play.owl',[5000])
        })
        $('.stop').on('click', function(){
            owl.trigger('autoplay.stop.owl');
        })*/
    }
    var i = 1;
    $('.home-carousel .owl-dot').each(function(){
        $(this).text(i);
        i++;
    });
    var gridItem = $('.grid-item.zone-4');
    if (window.innerWidth <= 767 && $('.left').find(gridItem).length < 1) {
        gridItem.insertAfter('.grid-item.zone-3');
    }
    $(window).smartresize(function () {
        if (window.innerWidth <= 767 && $('.left').find(gridItem).length < 1) {
            gridItem.insertAfter('.grid-item.zone-3');
        } else if (window.innerWidth > 767 && $('.right').find(gridItem).length < 1) {
            gridItem.insertBefore('.grid-item.zone-6');
        }
    });
    
    // quickview for hp-modules
    $('.hp-module').on('click', '.quickview', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.currentTarget.href,
            source: 'cart'
        });
    });
};

