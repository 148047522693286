'use strict';
var setupModalDialog = require('./setupModalDialog');

module.exports = function () {
    var modalDialog = document.querySelector('.ds-international-redirect');
    var usersCountryName = User.geolocation.countryName;
    var usersCountryCode = User.geolocation.countryCode;
    var allowedShippingCountryCodes = ['US', 'CA', 'PR', 'VI', 'GU', 'AS', 'MP', 'FM', 'MH', 'PW'];
    var cookieIsSet = document.cookie.match('(^|;) ?' + 'InternationalModalClosed' + '=([^;]*)(;|$)');

    if (!modalDialog || allowedShippingCountryCodes.includes(usersCountryCode)) {
        return;
    }

    var header = document.querySelector(
        '#heading-international-redirect-modal'
    );
    var paragraph = document.querySelector(
        '#paragraph-international-redirect-modal'
    );

    if (Resources.VALID_COUNTRIES.includes(User.geolocation.countryCode)) {
        header.innerHTML = Resources.INTL_REDIRECT_AVAILABLE_HEADER + ' ' + usersCountryName + '!';
        paragraph.innerHTML = Resources.INTL_REDIRECT_AVAILABLE_BODY;
    } else {
        header.innerHTML = Resources.INTL_REDIRECT_UNAVAILABLE_HEADER + ' ' + usersCountryName + '.';
        paragraph.innerHTML = Resources.INTL_REDIRECT_UNAVAILABLE_BODY;
    }

    var setCookie = function (){
        var date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
        var expiration = 'expires=' + date.toUTCString();
        document.cookie = 'InternationalModalClosed=true;' + expiration;
    }

    var closingActions = function () {
        setCookie();
        setupModalDialog.openEmailModal();
    };

    var closeButtonClicked = function () {
        closingActions();
    };

    var escapeCloseModal = function (e) {
        if (e.key == 'Escape') {
            document.removeEventListener('keydown', escapeCloseModal);
            closingActions();
        }
    };

    var redirectButton = function () {
        var redirectButton = document.querySelector('.ds-redirect-button');
        var selectEl = document.getElementById('international-redirect-select');
        redirectButton.addEventListener('click', function (event) {
            if (selectEl.value == 'https://www.bathandbodyworks.com/') {
                closingActions();
            } else if (selectEl.value == '') {
                selectEl.classList.add('error');
                selectEl.addEventListener('change', function () {
                    if (!selectEl.value == '') {
                        selectEl.classList.remove('error');
                    }
                });
            } else {
                event.preventDefault();
                var url = selectEl.value;
                window.location = url;
            }
        });
    };

    var defaultOption = function () {
        var selectEl = document.getElementById('international-redirect-select');
        var options = selectEl.options;
        for (var i = 0; i < options.length; i++) {
            if (options[i].text == usersCountryName) {
                selectEl.selectedIndex = i;
                break;
            }
        }
    };

    if (modalDialog) {
        var closeButtons =
            modalDialog.getElementsByClassName('ds-close-action');

        for (var j = 0; j < closeButtons.length; j++) {
            closeButtons[j].addEventListener('click', closeButtonClicked);
        }

        if (!cookieIsSet) {
            var selectEl = document.getElementById('international-redirect-modal');
            selectEl.addEventListener('request-open-modal', function(){
                redirectButton();
                defaultOption();
                var event = new Event('open-modal');
                selectEl.dispatchEvent(event);
            })
        }
    }

    document.addEventListener('keydown', escapeCloseModal);
};
