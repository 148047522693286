'use strict';

var dialog = require('./dialog'),
    minicart = require('./minicart');

var jATCShippingInfo = function(storeName, eligibleBopis, addedForShipping) {
    var shippingInfoHTML = '<div class="changedeliveryoption">';
    var bopisMessage = Resources.ADDED_TO_CART_BOPIS_MESSAGE;
    if (addedForShipping) {
        shippingInfoHTML += '<h3 class="added-for-shipping">Added for shipping</h3>'
    } else {
        shippingInfoHTML += '<div class="added-for-pick-up"><div class="pick-up-icon"></div><h3 class="pick-up-store-msg">Added for pickup at <span class="store-name">' + storeName.toLowerCase() + '</span></h3></div>'
    }
    if (eligibleBopis === true) shippingInfoHTML += '<p class="change-option-info">' + bopisMessage + '</p></div>';
    return shippingInfoHTML;
}

/**
 * Check the HTML to see if the AR promotion should be displayed
 * 
 * @param {string} html : the html in string format for the content of the JATC modal
 * @returns {jQuery Object}
 */
var checkForARProducts = function (html) {
    var $html = $(html)
    if (window.OG) {
        // check to see if item is OG subscribed
        try {
            var optins = window.OG.getOptins();
            for (var i in optins) {
                var pid = optins[i].product,
                    $miniCartProductUI = $html.find('og-offer[product='+pid+']').siblings('.promotion');
                if ($miniCartProductUI.length) {
                    $miniCartProductUI.each(function() {
                        var $promotion = $(this);
                        if ($promotion.find('.ar-promotion .freq_indicator').length) {
                            $promotion.removeClass('display-none');
                        } else {
                            $promotion.addClass('display-none');
                        }
                    });
                }
            }

            return $html;
        } catch (err) {
            console.error(err);
        }
    } else {
        return $html;
    }
}

var addedProductsView = {
    /**
     * @function
     * @description Open added product dialog
     */
    show: function (item, pid, storename, addedForShipping) {
        //build response for added product in pop up
        var resultCart = null;
        var $addedProductInfo = $(item).find('.mini-cart-products').find('*[data-productid="' + pid + '"]:first');
        var simpleProdCode = $addedProductInfo.html();
        
        // This could be improved in the future by refactoring the code to construct markup in a new ISML, or in the show function, instead of relying on modifying in the open method after content is added to DOM.
        var isBopisEligible = $addedProductInfo[0] ? $addedProductInfo[0].getAttribute('data-productbopis') : null;
        var miniCartSlot = $(item).find('.mini-cart-slot').html();
        var totalCode = $(item).find('.mini-cart-totals').length > 0 ? $(item).find('.mini-cart-totals').html() : '';
        
        // Existing implementation passes the undefined simpleProdCode into the modal even when it's coming through as undefined. Recommending to refactor code to manually trigger an error view and skip all other logic when $addedProductInfo has no value.
        if (simpleProdCode && miniCartSlot) {
            resultCart = checkForARProducts(simpleProdCode+miniCartSlot+'<div data-showchangeshippingmessage="' + isBopisEligible + '" class="show-bopis-message-flag display-none"></div><div class="clearfix"></div>');
        } else {
            resultCart = simpleProdCode+miniCartSlot+'<div class="clearfix"></div>';
        }

        // var itemInBag = '<div class="mini-cart-subtotals top"><span class="label">items in your bag: </span><span class="value">'+$(item).find('.minicart-quantity').text()+'</span></div>';
        var itemInBag = $(item).find('.minicart-quantity').length > 0 ? '<div class="mini-cart-subtotals top"><span class="label">items in your bag: </span><span class="value">'+$(item).find('.minicart-quantity').text()+'</span></div>' : '';
        var newQty = $(item).find('.mini-cart-products').length  > 0 ? parseInt($(item).find('.mini-cart-products').attr('data-newqty'), 10) : 0;

        dialog.open({
            html: resultCart,
            options: {
                width: 570,
                title: newQty === 0 ? Resources.NOT_ADDED_TO_CART_TITLE : Resources.ADDED_TO_CART,
                draggable: false,
                buttons: {
                    'CONTINUE SHOPPING': {
                        click: function(){
                            $(this).dialog('close');
                        },
                        text: 'CONTINUE SHOPPING',
                        'class': 'cancelButton'
                    },
                    'VIEW BAG & CHECKOUT': function() {
                        location.href = Urls.cartShow;
                        $(this).dialog('close');
                    }
                },
                show: {
                    effect: 'fade',
                    duration: 100
                },
                hide: {
                    effect: 'fade',
                    duration: 100
                },
                open: function() {   
                    if (totalCode != '' && itemInBag != '') {
                        $('.ui-dialog-buttonpane').prepend('<div class="oversubtotal">' + totalCode + itemInBag + '</div>');
                    }
                   // $('.ui-dialog-buttonpane').prepend('<div class="oversubtotal">'+totalCode+itemInBag+'</div>');
                    var isCCUser = $('.current-user-name').val() != 'storefront';
                    var eligibleBopis = $(this).find('.show-bopis-message-flag').data('showchangeshippingmessage');
                    if ((newQty > 0 && SitePreferences.STORE_PICKUP && window.SessionAttributes.IS_REQUEST_IN_AVAILABLE_BOPIS_MARKET && !isCCUser) || (isCCUser && window.SessionAttributes.IS_CSR_BOPIS_EXPERIENCE_ALLOWED)) {
                        $('.ui-dialog-buttonpane .ui-dialog-buttonset').prepend(jATCShippingInfo(storename, eligibleBopis, addedForShipping));
                    }
                    
                    $('.dialog-content .promotion').insertAfter($('.mini-cart-price'));
                    $('.dialog-content .qty-block').appendTo($('.mini-cart-attributes'));
                    $('.ui-widget-overlay').hide().fadeIn().on('click', function() {
                        $('.ui-dialog-titlebar-close').trigger('click');
                    });
                    $('.add-to-cart').addClass('nonblur');
                    scroller.disableScroll();
                    var standardPrice = $(simpleProdCode).find('input[name="standard-price"]');
                    var salesPrice = $(simpleProdCode).find('input[name="sales-price"]');
                    var priceHtml = '<span class="product-sales-price">' + salesPrice.val() + '</span>';
                    var itemTotalQtyInput = $('.dialog-content .qty-block').find('input[name="item-total-qty"]');
                    if (standardPrice.length > 0) {
                        priceHtml = '<span class="product-standard-price">' + standardPrice.val() + '</span><span class="product-sales-price"> <span class="promo-word">Now:</span>' + salesPrice.val() + '</span>';
                    }
                    $('.dialog-content').find('.mini-cart-price').addClass('product-pricing').html(priceHtml);
                    
                    if (itemTotalQtyInput.length && $.isNumeric(itemTotalQtyInput.val())) {
                        var itemRealQty = parseInt(itemTotalQtyInput.val(), 10);
                        $('.dialog-content .qty-block').find('span[class="value"]').html(itemRealQty);
                    }

                    if (newQty == 0) {
                        $('.dialog-content').html('<p class="not-added">' + Resources.NOT_ADDED_TO_CART_MESSAGE + '</p><p class="not-added error">' + Resources.NOT_ADDED_TO_CART + '</p>');
                    }
                    
                    // make sure this class is removed
                    $('.ui-dialog').removeClass('global-email-dialog');
                },
                create: function () {
                    $(window).resize(function () {
                        $('.ui-dialog').position({
                            my: 'center',
                            at: 'center',
                            of: window
                        });
                    });
                    $(window).scroll(function () {
                        $('.ui-dialog').position({
                            my: 'center',
                            at: 'center',
                            of: window
                        });
                    });
                },
                close: function(){
                    $('.oversubtotal').remove();
                    $('.changedeliveryoption').remove();
                    $('.add-to-cart').removeClass('nonblur');
                    scroller.enableScroll();
                },
                beforeClose: function(){
                    $('.ui-widget-overlay:first')
                        .clone()
                        .appendTo('body')
                        .show()
                        .fadeOut(400, function(){
                            $(this).remove();
                        })
                    ;
                }
            },
            callback: function () {
                $('#mini-cart').html(item);
                minicart.init();
            }
        });
    }
};

var scroller = {
    preventDefault: function(e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;
    },

    preventDefaultForScrollKeys: function(e) {
        var keys = {37: 1, 38: 1, 39: 1, 40: 1};
        if (keys[e.keyCode]) {
            scroller.preventDefault(e);
            return false;
        }
    },
    disableScroll: function(){
        if (window.addEventListener) {
            window.addEventListener('DOMMouseScroll', scroller.preventDefault, false);
        }
        window.onwheel = scroller.preventDefault; // modern standard
        window.onmousewheel = document.onmousewheel = scroller.preventDefault; // older browsers, IE
        window.ontouchmove  = scroller.preventDefault; // mobile
        document.onkeydown  = scroller.preventDefaultForScrollKeys;
    },
    enableScroll: function(){
        if (window.removeEventListener) {
            window.removeEventListener('DOMMouseScroll', scroller.preventDefault, false);
        }
        window.onmousewheel = document.onmousewheel = null;
        window.onwheel = null;
        window.ontouchmove = null;
        document.onkeydown = null;
    }
};

module.exports = addedProductsView;
