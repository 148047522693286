'use strict';
// var Bopishelper = require('int_bopis/cartridge/scripts/helpers/BopisHelpers.js');
var getPhoneNumberAriaLabel = function(value, spaceRequiredAfter) {
    if (!value) return '';
    var space = spaceRequiredAfter || 1;
    var reg = new RegExp('.{'+ space + '}','g');
    return value.replace(/[( ]/g, '').replace(/[)-]/g, '.').replace(reg, function (a) {
        return a + ' ';
    });
}

var storeDetailsHTMLDOM = function (storeData) {
    var formattedAddress = '';
    formattedAddress = (storeData.storeAddress1) ? formattedAddress + storeData.storeAddress1 : formattedAddress;
    formattedAddress = (storeData.storeCity) ? formattedAddress + ', ' + storeData.storeCity : formattedAddress;
    formattedAddress = (storeData.storeStateCode)  ? formattedAddress + ', ' + storeData.storeStateCode : formattedAddress;
    formattedAddress = (storeData.storeZipCode) ? formattedAddress + ' ' + storeData.storeZipCode : formattedAddress;

    //URL encode the address
    var encodedAddress = encodeURIComponent(formattedAddress);
    var storephone = storeData.storePhone ? storeData.storePhone.replace(/[()-/\s]/g, '') : '';

    return '<div class="pdp-show-bopis-store-detail">'
        + '<div tabindex="0" aria-expanded="false" role="button" class="pdp-show-selected-store-details-link">Store Details</div>'
        + '<div class="pdp-show-collapsed-info hide">'
        + '<div class="address-city-state-zip">'
        + '<a class="address-city-state-zip-link" href="https://maps.google.com/maps?daddr=' + encodedAddress + '" target="_blank">'
        + (storeData.storeAddress1 ? storeData.storeAddress1.toLowerCase() : '') + '<br/>'
        + (storeData.storeAddress2 ? storeData.storeAddress2.toLowerCase() + '<br/>' : '')
        + (storeData.storeCity ? storeData.storeCity.toLowerCase() : '') + ',' + storeData.storeStateCode + ' '
        + storeData.storeZipCode + '</a></div>'
        + '<div class="store-phone-no">'
        + '<a href="tel:+1' + storephone + '" aria-label="' + getPhoneNumberAriaLabel(storeData.storePhone , 1) + '">' + storeData.storePhone + '</a></div>'
        + '<div class="store-available-hours">'
        + 'Today\'s Hours:' + storeData.storeHours + '</div></div></div>';
};

var clearOldStoreDetails = function () {
    $('.bopis-pfs-option-main-container .pick-up-in-store-msg').removeClass('add-semi-colon');
    $('.bopis-pfs-with-store-container .bopis-set-store-container').removeClass('bopis-pdp-set-store bopis-pdp-change-store');
    $('.bopis-pfs-option-main-container .pdp-selected-store-name').remove();
    $('.bopis-eligible-for-pickup .pdp-show-bopis-store-detail').remove();
}

var updateStoreDetails = function (e, storeData) {
    var updatedStoreName = '<span class="pdp-selected-store-name" id="'+ (storeData.isSetStoreOpenedFromQL ? 'ql-ada-bopis-storename' : 'pdp-ada-bopis-storename') +'">'+ (storeData.storeName ? storeData.storeName.toLowerCase() : '') +'</span>';
    clearOldStoreDetails();
    $('input[name="storeid"]').val(storeData.storeId);
    $('input[name="storename"]').val(storeData.storeName);
    $('.bopis-pfs-option-main-container .pick-up-in-store-msg').addClass('add-semi-colon');
    $('.bopis-pfs-option-main-container .bopis-pick-from-store-option-label').after(updatedStoreName);
    $('.bopis-eligible-for-pickup').append(storeDetailsHTMLDOM(storeData));
    $('.bopis-pfs-with-store-container .bopis-set-store-container').addClass('bopis-pdp-change-store');
    $('.bopis-pfs-with-store-container .bopis-set-store').html($('.bopis-pfs-with-store-container .bopis-set-store').attr('data-change-store'));
    if (!$('.pdp-change-store-inline').length) {
        $('.pdp-main-container .pdp-selected-store-name').after('<span class="pdp-change-store-inline"></span>');
        $('.bopis-set-store-container .bopis-set-store').clone().appendTo('.pdp-main-container .pdp-change-store-inline');
    }

    if (!storeData.isSetStoreOpenedFromQL) {
        $('.pdp-main-container').find('.bopis-pick-from-store-option').trigger('click');
    }
};

/**
 *
 * @description toggle the store details in PDP
 */
function toggleStoreDetails() {
    if ($(this).hasClass('active')) {
        $('.pdp-show-bopis-store-detail .pdp-show-collapsed-info').removeClass('hide').addClass('hide');
        $(this).removeClass('active');
        $(this).attr('aria-expanded',false);
    } else {
        $(this).addClass('active');
        $('.pdp-show-bopis-store-detail .pdp-show-collapsed-info').removeClass('hide');
        $(this).attr('aria-expanded',true);
    }
}

var handleKeyChangesOnStoreDetails = function (event) {
    if (event.keyCode === 13 || event.keyCode == 32) {
        if ($(this).hasClass('active')) {
            $('.pdp-show-bopis-store-detail .pdp-show-collapsed-info').removeClass('hide').addClass('hide');
            $(this).removeClass('active');
            $(this).attr('aria-expanded',false);
        } else {
            $(this).addClass('active');
            $('.pdp-show-bopis-store-detail .pdp-show-collapsed-info').removeClass('hide');
            $(this).attr('aria-expanded',true);
        }
    }
}


module.exports = function () {
    $('body').off('setstore:success', updateStoreDetails);
    $('body').on('setstore:success', updateStoreDetails);
    $('body').off('click', '.pdp-show-bopis-store-detail .pdp-show-selected-store-details-link', toggleStoreDetails);
    $('body').on('click', '.pdp-show-bopis-store-detail .pdp-show-selected-store-details-link', toggleStoreDetails);
    $('body').off('keyup', '.pdp-show-bopis-store-detail .pdp-show-selected-store-details-link', handleKeyChangesOnStoreDetails);
    $('body').on('keyup', '.pdp-show-bopis-store-detail .pdp-show-selected-store-details-link', handleKeyChangesOnStoreDetails);
};
