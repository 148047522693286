'use strict';

var modalHelper = {
    closeModal: function closeModal(modalDialogToClose, previousActiveElement) {
        modalDialogToClose.classList.remove('ds-modal-open');
        if (previousActiveElement) {
            previousActiveElement.focus();
        }
    },

    openModal: function openModal(modalDialog) {
        modalDialog.classList.add('ds-modal-open');
    },

    setFocusTrap: function (modalDialog) {
        var focusableEls = modalDialog.querySelectorAll('a, select, button, input');
        var firstFocusableEl = focusableEls[0];
        var lastFocusableEl = focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;

        var trapFocus = function (e) {
            //if modal is closed, end this trap
            if (!modalDialog.classList.contains('ds-modal-open')) {
                document.removeEventListener('keydown', trapFocus);
                return;
            }

            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }

        document.addEventListener('keydown', trapFocus);
    }

};

module.exports = modalHelper;
