'use strict';
/* eslint-disable object-curly-spacing */
var quickview = null,
    wishlist = null;

var JATCRecommendations = {
    inited: false,
    modalCreated: false,
    schemaHolder: [],
    previousSchema: [],
    init: function () {
        JATCRecommendations.prepareModal();
        if ('certona' in window && !JATCRecommendations.inited) {
            quickview = require('./quickview');
            wishlist = require('./pages/wishlist');
            window.certonaRecommendations = this.certonaRecommendations;

            /**
             * manually run callback with test data if site preference value 'CertonaMockEnabled' enabled
             */
            // if ('certona' in window && 'developerMode' in window.certona && window.certona.developerMode === true) {
            //     window.certonaRecommendations(this.getFakeData('addtocart1_rr'));
            // }
            wishlist.init();
            JATCRecommendations.inited = true;
        }

        JATCRecommendations.modalCreated = true;
        if (Object.entries(JATCRecommendations.schemaHolder).length !== 0) {
            JATCRecommendations.proceedItems(JATCRecommendations.schemaHolder);
        } else {
            setTimeout(function() {
                var hasLoaded = $('.recommendations-container').hasClass('loaded');
                if (!hasLoaded && Object.entries(JATCRecommendations.previousSchema).length !== 0) {
                    // find the previous recos and load them
                    // JATCRecommendations.certonaRecommendations
                    JATCRecommendations.proceedItems(JATCRecommendations.previousSchema);
                } else if (!hasLoaded) {
                    var minHeight = parseInt($('.ui-dialog.jatc-recommendations').css('min-height'), 10);
                    $('.ui-dialog.jatc-recommendations').addClass('collapsed').css('min-height', minHeight-300);
                }
            }, 1500);
        }
    },
    certonaRecommendations: function (obj) {
        JATCRecommendations.prepareModal();
        if ('resonance' in obj) {
            var schemes = obj.resonance.schemes;
            for (var k = 0; k < schemes.length; k++) {
                var schema = schemes[k];
                if (schema !== undefined && 'display' in schema && schema.display.toLowerCase() === 'yes') {
                    if (JATCRecommendations.modalCreated) {
                        JATCRecommendations.proceedItems(schema);
                    } else {
                        JATCRecommendations.schemaHolder = schema;
                    }
                }
            }
        }
    },
    proceedItems: function (schema) {
        var recommendationsBlock = $('.ui-dialog.jatc-recommendations #' + schema.scheme),
            items = [],
            recsUrl = Urls.certonaReccomendations;

        // #854981 - Certona_Duplicate Containers on PDP
        recommendationsBlock.find('.horizontal-carousel').remove();
        // #852871 - [Certona] incorrect amount of items in most Certona slots & no scrollability (no arrows) present
        var itemsLimit = 12;
        for (var i = 0; i < schema.items.length; i++) {
            if (i >= itemsLimit) {
                break;
            }
            var item = schema.items[i];
            if (item !== undefined && 'id' in item) {
                items.push(item.id);
            }
        }
        /**
        * Pass the Certona object to the pipeline call
        */
        var certonaItems = JSON.stringify(schema.items);

        if (items.length > 0) {
            $.post(recsUrl, { items: items.join(','), scheme: schema.scheme, certonaItems: certonaItems }, function (data) {
                var $data = $(data);
                $data.find('.recommendation-item').slice(6).remove();
                recommendationsBlock.addClass('promo-block').append($data);

                /**
                 * #847791
                 * we need run separate page scroll here in case if load Certona products after all page load 
                 */
                //firescroll appear in DOM as hidden input if customer apply coupon code on cart page
                if ($('#firescroll').length > 0) {
                    var imgCount = recommendationsBlock.find('img').length;

                    $('img', recommendationsBlock).load(function () {
                        imgCount--;
                        if (imgCount === 0 && $('.error-form').length == 0) {
                            $('html,body').animate({ scrollTop: $('.cart-footer').offset().top - $('.promo-block').first().height() });
                        }
                    });
                }

                var owlConfig = {
                    loop: false,
                    responsiveClass: true,
                    mouseDrag: true,
                    nav: true,
                    navSpeed: 200,
                    navRewind: false,
                    dots: true,
                    responsive: {
                        0: {
                            center: true,
                            items: 1,
                            margin: 30
                        },
                        768: {
                            items: 3,
                            margin: 15,
                            stagePadding: 15,
                            mouseDrag: false,
                            slideBy: 3
                        }
                    },
                    onInitialized: JATCRecommendations.carouselLoaded
                };
                JATCRecommendations.quickView();
                var owlCarousel = $('.horizontal-carousel');
                owlCarousel.on('dragged.owl.carousel', function () {
                    recommendationsBlock.find('.product-tile.tooltip').show();
                });
                owlCarousel.owlCarousel(owlConfig);

            });
        } else {
            recommendationsBlock.hide();
        }
        JATCRecommendations.modalCreated = false;
        if (Object.entries(JATCRecommendations.schemaHolder).length !== 0) {
            JATCRecommendations.previousSchema = Object.assign({}, JATCRecommendations.schemaHolder);
        }
    },
    quickView: function () {
        if (!$('.qv-off').length && !quickview.isMobile()) {
            $('.grid-tile.recommendation-item').on('mouseenter', function () {
                var $qvButton = $('#quickviewbutton'),
                    pid = $(this).find('.capture-product-id').text(),
                    index = $(this).parent().index();

                if ($qvButton.length === 0) {
                    $qvButton = $('<a id="quickviewbutton" class="quickview button">' + Resources.QUICK_VIEW + '</a>');
                }
                var imgWrapper = $(this).find('.recommendation_image');
                var $link = $(this).find('.qv-link');
                $qvButton.attr({
                    'href': $link.attr('href'),
                    'title': $link.attr('title'),
                    'pid': pid
                }).appendTo(imgWrapper);
                $qvButton.off('click');
                $qvButton.on('click', function (e) {
                    e.preventDefault();
                    /* eslint-disable */
                    if('cmCreateElementTag' in window){
                        cmCreateElementTag('Quicklook Click', 'JATC', $qvButton.attr('pid') + "-_-" + $qvButton.attr('title') + "-_--_--_-" + index + "-_-" + window.digitalData.page.pageInfo.pageID);
                    }
                    /* eslint-enable */
                    quickview.show({
                        url: $(this).attr('href'),
                        source: 'quickview'
                    });
                });
            });
            var search = require('./tealium/pages/search');
            search.init();
        }
    },
    // getFakeData: function (scheme) {
    //     var data = {
    //         'addtocart1_rr': {
    //             'resonance': {
    //                 'schemes': [
    //                     {
    //                         'scheme': 'product1_rr',
    //                         'explanation': 'More You\'ll Adore',
    //                         'display': 'yes',
    //                         'items': [{ 'id': '020002432' }, { 'id': '020026246' }, { 'id': '020039097' }, { 'id': '020043936' }, { 'id': '020044669' }]
    //                     }
    //                 ]
    //             }
    //         }
    //     };

    //     return data[scheme];
    // },
    carouselLoaded: function () {
        // this function is for post-owl rendering ONLY for the homepage
        // adding ATB button via JS - should be fixed with template update in future Certona enhancement work
        $('#addtocart1_rr .product-tile').each(function (index) {
            var pid = $(this).find('.capture-product-id').text(),
                $button = $(this).find('button.hide'),
                title = $(this).find('.recommendation_image img').attr('title');
            $button.detach();
            $button.attr('class', 'add-to-cart').attr('type', 'submit').attr('title', 'Add to Bag').attr('value', 'Add to Bag').text('Add to Bag');
            var $form = $('<form method="post" action="' + window.location.origin + '/on/demandware.store/Sites-BathAndBodyWorks-Site/en_US/Cart-AddProduct" novalidate="novalidate"><fieldset><input type="hidden" name="pid" value="' + pid + '"><input type="hidden" name="maxQtyAvailable" value="null"><input type="hidden" name="purchaseLimit" value="null"><input type="hidden" id="totalQty" name="totalQty" value="null"><input type="hidden" name="Quantity" value="1"><div class="clearfix centered"></div></fieldset></form>');
            $form.find('div.centered').append($button);
            $button.on('click', function () {
                /* eslint-disable */
                if ('cmCreateElementTag' in window) {
                    cmCreateElementTag('Add to Bag', 'JATC', pid + "-_-" + title + "-_--_--_-" + index + "-_-" + window.digitalData.page.pageInfo.pageID);
                }
                /* eslint-enable */
            })
            $(this).append($form);
            // fake in an empty 'promotion' div for alignment. TODO: add an additional template to the pipeline to address, or better, use the producttile module.
            if (!$(this).children('.promotion').length) {
                $form.before('<div class="promotion"><div class="promotion-callout"><div class= "callout-message clearfix"></div></div></div>');
            }


            $(this).find('.recommendation_image a, .product-name a').on('click', function () {
                /* eslint-disable */
                if ('cmCreateElementTag' in window) {
                    cmCreateElementTag('PDP Click', 'JATC', pid + "-_-" + title + "-_--_--_-" + index + "-_-" + window.digitalData.page.pageInfo.pageID);
                }
                /* eslint-enable */
            })
        });

        $('#addtocart1_rr').addClass('loaded');

        // Force all carousel items to have the same height - related changes in _minicart.scss
        setTimeout(function() {
            $('#addtocart1_rr .owl-item').css('height', $('#addtocart1_rr .owl-stage').height());
            var promotionMsgHeight = 0;
            var prodContHeight = 0;
            // var formHeight = 0;
            $('#addtocart1_rr .owl-item').each(function() {
                $(this).find('.product-name, .sub-attribute').wrapAll('<div class="product-cont"></div>');
                var tempPromoHeight = $(this).find('.callout-message').height();
                var tempProdContHeight = $(this).find('.product-cont').height();
                // var tempFormHeight = $(this).find('.sub-attribute').height();
                promotionMsgHeight = promotionMsgHeight > tempPromoHeight ? promotionMsgHeight : tempPromoHeight;
                prodContHeight = prodContHeight > tempProdContHeight ? prodContHeight : tempProdContHeight;
                // formHeight = formHeight > tempFormHeight ? formHeight : tempFormHeight;
            });
            $('#addtocart1_rr .owl-item').find('.callout-message').height(promotionMsgHeight);
            $('#addtocart1_rr .owl-item').find('.product-cont').css('flex-basis', prodContHeight + 'px');
            // $('#addtocart1_rr .owl-item').find('.sub-attribute').css('flex', '0 1 ' + formHeight + 'px');
        }, 500);
        
    },
    prepareModal: function () {
        $('.ui-dialog.jatc-recommendations').removeClass('collapsed');
    }
}

/* eslint-enable object-curly-spacing */
module.exports = JATCRecommendations;
