'use strict';

var handleDigitalData = require('../../handleDigitalData');

//BBWDP-12479
var signInButton = document.querySelector('.js-auth-submit');
var modalLoaderExperienceIsOn = SitePreferences.IS_LOGIN_LOADER_FOR_MODAL_ON;

function redirectUserTo(redirectTarget) {
    var targetUrl = redirectTarget || sessionStorage.getItem('signinTarget');
    if (targetUrl && targetUrl.length > 1 && window.location.href !== targetUrl) {
        if (!redirectTarget) sessionStorage.removeItem('signinTarget');
        window.location = targetUrl;
    } else {
        window.location.reload();
    }
}

//BBWDP-12479
function handleLoginLoading(isVisible) {
    var spinnerContainer = document.querySelector('.js-auth-loader');
    signInButton.disabled = isVisible;

    var rotateStatusMessages = function (index) {
        var statusMessages = ['Signing you in...', 'Getting your points...', 'Loading your offers...'];

        spinnerContainer.querySelector('span').remove();
        spinnerContainer.querySelector('img').insertAdjacentHTML('afterend', '<span>' + statusMessages[index] + '</span>');

        setTimeout(function () {
            if (index === 2) rotateStatusMessages(0);
            else rotateStatusMessages(index + 1);
        }, 3000);
    }

    if (isVisible) {
        spinnerContainer.classList.remove('hidden');
        rotateStatusMessages(0);
    } else if (!isVisible && !spinnerContainer.classList.contains('hidden')) {
        spinnerContainer.classList.add('hidden');
    }
}
// end BBWDP-12479

var modalSignIn = function (activeModal, redirectTarget) {
    $('#dwfrm_login_' + activeModal.id + '_' + activeModal.dataset.modalLocation).off('submit').on('submit', function (e) {
        e.preventDefault();

        if (!$(this).valid()) {
            return false;
        }

        var errorDiv = $(this).find('#account-error-response');
        var usernameInput = $(this).find('input[name$="_login_username"]');
        var passwordInput = $(this).find('input[name$="_login_password"]');
        var bothInputFields = usernameInput.add(passwordInput);
        errorDiv.empty();
        var submitButton = $(this).find('input[type="submit"]').attr('name');
        var data = $(this).serialize() + '&' + submitButton + '=x' + '&format=ajax';

        // BBWDP-12479
        if (modalLoaderExperienceIsOn) handleLoginLoading(true);

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        }).done(function (response) {
            if (document.getElementsByClassName('pt_cart').length > 0) {
                history.scrollRestoration = 'manual';
            }

            if (response.emailHash != null) {
                $.ajax({
                    type: 'GET',
                    url: Urls.getRewardsDataShort,
                    dataType: 'json'
                }).done(function (data) {
                    handleDigitalData(data);
                    window._satellite.track('signin-member-popup');
                    redirectUserTo(redirectTarget);
                });
            } else if (response.success === false && response.error !== null) {
                errorDiv.html(response.error);
                passwordInput.val('');

                var bindEvent = 'focus.loginerror change.loginerror keydown.loginerror';
                bothInputFields.addClass('loginerror').on(bindEvent, function() {
                    bothInputFields.removeClass('loginerror').off(bindEvent);
                });

                // BBWDP-12479
                if (modalLoaderExperienceIsOn) handleLoginLoading(false);
            } else {
                redirectUserTo();
            }
        });
    })
}

module.exports = {
    modalSignIn: modalSignIn
}
