'use strict';
var body = document.querySelector('body');
var overlay = document.querySelector('.js-modal-overlay');

var modalHelper = {
    closeModal: function closeModal(modalDialogToClose, previousActiveElement) {
        modalDialogToClose.classList.remove('open');

        if (previousActiveElement) {
            previousActiveElement.focus();
        }

        if (modalDialogToClose.dataset.preventScroll) {
            document.querySelector('html').classList.remove('dialog-modal-opened');
        }

        if (modalDialogToClose.dataset.showOverlay) {
            overlay.classList.remove('visible');
        }
    },

    openModal: function openModal(modalDialog) {
        body.appendChild(overlay);
        body.appendChild(modalDialog);
        modalDialog.classList.add('open');

        if (modalDialog.dataset.preventScroll) {
            document.querySelector('html').classList.add('dialog-modal-opened');
        }

        if (modalDialog.dataset.showOverlay) {
            overlay.classList.add('visible');
        }
    },

    setFocusTrap: function (modalDialog) {
        var focusableEls = modalDialog.querySelectorAll('a, select, button, input');
        var firstFocusableEl = focusableEls[0];
        var lastFocusableEl = focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;

        var trapFocus = function (e) {
            //if modal is closed, end this trap
            if (!modalDialog.classList.contains('open')) {
                document.removeEventListener('keydown', trapFocus);
                return;
            }

            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }

        document.addEventListener('keydown', trapFocus);
    }
};

module.exports = modalHelper;
