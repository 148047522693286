'use strict';
module.exports = function () {
    var smoothScrolls = document.getElementsByClassName('ds-smooth-scroll');

    var createSliderEvents = function (slider) {

        var scrolledLeft = false;
        var scrolledRight = false;
        var originalScrollPosition = slider.scrollLeft;

        var startX = 0;
        var scrollLeft = 0;
        var analyticsModuleName = slider.dataset.analyticsModuleName;
        var allowContinuedScroll = slider.dataset.allowContinuedScroll;
        var slidingClass = 'ds-sliding';

        var scrollSpace = allowContinuedScroll ? document.querySelector('body') : slider;
        var scrollTiles = slider.querySelectorAll('.ds-smooth-scroll-tile');

        var mouseMove = function (e) {
            e.preventDefault();
            var endX = e.pageX - slider.offsetLeft;
            var deltaX = (endX - startX) * 2.5;
            slider.scrollLeft = scrollLeft - deltaX;
            if (!slider.classList.contains(slidingClass) && Math.abs(endX - startX) > 5) {
                slider.classList.add(slidingClass);
            }
        }

        var unsetMouseDown = function () {
            scrollSpace.removeEventListener('mouseleave', unsetMouseDown);
            scrollSpace.removeEventListener('mouseup', unsetMouseDown);
            scrollSpace.removeEventListener('mousemove', mouseMove);
        };

        slider.addEventListener('mousedown', function (e) {
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            slider.classList.remove(slidingClass);

            scrollSpace.addEventListener('mouseleave', unsetMouseDown);
            scrollSpace.addEventListener('mouseup', unsetMouseDown);
            scrollSpace.addEventListener('mousemove', mouseMove);
        });

        for (var i = 0; i < scrollTiles.length; i++) {
            var scrollTile = scrollTiles[i];
            scrollTile.addEventListener('click', function (e) {
                if (slider.classList.contains(slidingClass)) {
                    e.preventDefault();
                }
            });
        }

        slider.addEventListener('mousewheel', function (ev) {
            var maxX = slider.scrollWidth - slider.offsetWidth;
            var toScroll = slider.scrollLeft + ev.deltaX;

            if (toScroll < 0 || toScroll > maxX) {
                ev.preventDefault();
                slider.scrollLeft = Math.max(0, Math.min(maxX, toScroll));
            }
        });

        var scrollEventListener = function () {
            if (window && window._satellite && window._satellite.track) {
                if (!scrolledRight && originalScrollPosition < slider.scrollLeft) {
                    scrolledRight = true;
                    window._satellite.track('smooth-scroll', {
                        'moduleName': analyticsModuleName, 'direction': 'right'
                    });
                }
                if (!scrolledLeft && originalScrollPosition > slider.scrollLeft) {
                    scrolledLeft = true;
                    window._satellite.track('smooth-scroll', {
                        'moduleName': analyticsModuleName, 'direction': 'left'
                    });
                }
            }
            if (scrolledRight && scrolledLeft) {
                slider.removeEventListener('scroll', scrollEventListener);
            }
        }
        if (analyticsModuleName) {
            slider.addEventListener('scroll', scrollEventListener);
        }
    }
    var addGrabGesture = function (slider) {
        if (slider.scrollWidth > slider.clientWidth) {
            $(slider).addClass('ds-smooth-scroll-grab');
        }
    };

    for (var i = 0; i < smoothScrolls.length; i++) {
        var slider = smoothScrolls[i];
        createSliderEvents(slider);
        addGrabGesture(slider);
    }
}
