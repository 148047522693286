'use strict'

var postUrl = SitePreferences.KIBO_POST_URL;
var channel = SitePreferences.KIBO_RECOMMENDATIONS_CHANNEL;

var kiboClient = {
    recordRecClicks: function (recClick) {
        var events = [{
            'eventType': 'monetate:record:RecClicks',
            'recClicks': [recClick]
        }];
        kiboClient.postToKibo(events);
    },
    postToKibo: function (events) {
        var postReqObj = {
            url: postUrl,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: {
                'channel': channel
            }
        }
        if (userIDCookie) {
            postReqObj.data.monetateId = userIDCookie;
        }
        postReqObj.data.events = events;
        postReqObj.data = JSON.stringify(postReqObj.data);
        if (typeof(events) !== 'undefined') {
            $.ajax(postReqObj);
        }
    },
    getCookie: function (name) {
        var cookie = {};
        document.cookie.split(';').forEach(function (el) {
            var k = el.split('=')[0];
            var v = el.split('=')[1];
            cookie[k.trim()] = v;
        });
        return cookie[name];
    }
}

var userIDCookie = kiboClient.getCookie('mt.v');
module.exports = kiboClient;
